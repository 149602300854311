/*------------------------------------*\
    #Accordion
\*------------------------------------*/
/* Accordion Base */

.accordion {
    border-top   : 4px solid var(--global--color-primary);
    margin-bottom: 40px;

    .card,
    .card:first-of-type,
    .card:not(:first-of-type):not(:last-of-type) {
        padding      : 23px 20px 22px;
        box-shadow   : none;
        border       : 0;
        border-bottom: 2px solid #eaeaea;
        margin-bottom: 0px;
        border-radius: 0px;
        transition   : 0.3s ease-in-out;
        text-align   : left;

        &.active-acc {
            .card-heading {
                .card-link {
                    &::before {
                        color: var(--global--color-primary);
                    }
                }
            }
        }

        .card-heading {
            padding: 0;

            .card-link {
                font-family   : var(--global--font-heading);
                font-size     : 19px;
                font-weight   : 500;
                color         : #273a69;
                font-weight   : 500;
                text-transform: capitalize;
                position      : relative;
                transition    : 0.3s ease-in-out;
                display       : block;
                padding-left  : 0;
                display       : flex;
                align-items   : center;

                &:hover {
                    color: var(--global--color-primary);
                }

                &::before {
                    /* symbol for "opening" cards */
                    transition  : 0.3s ease-in-out;
                    font-family : "Font Awesome 5 Free";
                    font-weight : 700;
                    content     : "\f068";
                    font-size   : 11px;
                    margin-right: 20px;
                    color       : #273a69;
                }

                &.collapsed {
                    &::before {
                        /* symbol for "collapsed" cards */
                        content: "\f067";
                    }
                }
            }
        }

        .card-body {
            font-family: var(--global--font-body);
            color      : var(--global--color-body);
            padding    : 16px 0 0 31px;
            font-size  : 15px;
            font-weight: 400;
            line-height: 25px;
        }
    }

    .card:last-of-type {
        margin-bottom: 0px;
    }

    &.accordion-2 {
        padding-top   : 130px;
        padding-bottom: 110px;
        border-top    : 0;
        margin-bottom : 0;

        p {
            font-size    : 16px;
            line-height  : 27px;
            color        : var(--global--color-body);
            margin-bottom: 41px;
        }

        .accordion-holder {
            border-top: 4px solid var(--global--color-primary);
        }
    }

    &.accordion-3 {
        margin-bottom: 70px;
    }
}