@media only screen and (min-width: 1200px) {
    .navbar-collapse {
        padding-right: 0;
        padding-left : 0;
    }

    /* Menu with Dropdown */
    .navbar-nav {
        >li.has-dropdown {
            >ul.dropdown-menu {
                box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
                transition      : all 300ms linear;
                opacity         : 0;
                visibility      : hidden;
                position        : absolute;
                left            : 0;
                right           : auto;
                transform-origin: left top;
                transform       : translateY(20px);
                display         : block;
                width           : 210px;
                padding         : 20px 20px;
                background-color: rgb(255, 255, 255);
                border-radius   : 0px 0px 8px 8px;

                >li {
                    padding-right : 0;
                    position      : relative;
                    vertical-align: top;
                }

                li {
                    a {
                        transition    : 0.3s ease-in-out;
                        position      : relative;
                        font-family   : var(--global--font-body);
                        color         : var(--global--color-secondary);
                        padding       : 8px 15px;
                        margin-bottom : 3px;
                        display       : block;
                        font-size     : 14px;
                        font-weight   : 400;
                        transition    : all 300ms ease-in-out;
                        text-transform: capitalize;
                        border-radius : 8px 8px 0 8px;

                        &:active,
                        &:focus,
                        &:hover {
                            background-color: var(--global--color-primary-light);
                            color           : var(--global--color-primary);
                        }
                    }

                    &.current {
                        >a {
                            background-color: var(--global--color-primary-light);
                            color           : var(--global--color-primary);
                        }
                    }
                }
            }

            &:hover {
                >ul.dropdown-menu {
                    opacity   : 1;
                    visibility: visible;
                    transform : translateY(0);
                    z-index   : 100;
                    display   : block;
                }
            }
        }

        li {
            ul.dropdown-menu {
                &.left {
                    left       : -650px !important;
                    margin-left: 0 !important;
                }
            }
        }

        /* Mega Menu Content */
        li.has-dropdown {
            &.mega-dropdown {
                >.dropdown-menu {
                    background-color: var(--global--color-white);
                    padding         : 0;
                    width           : 510px;

                    li {
                        padding-left : 0;
                        padding-right: 0;
                    }

                    .row {
                        background-color: var(--global--color-white);
                        margin-right    : 0;
                        margin-left     : 0;
                        padding         : 35px 23px;

                        .menu-head {
                            font-size     : 14px;
                            font-family   : var(--global--font-heading);
                            font-weight   : 700;
                            color         : var(--global--font-heading);
                            text-transform: capitalize;
                            margin-bottom : 15px;
                        }

                        ul {
                            margin : 0;
                            padding: 0;

                            li {
                                line-height: 2.5;

                                &.title {
                                    color         : var(--global--color-heading);
                                    font-size     : 13px;
                                    font-weight   : 600;
                                    text-transform: capitalize;
                                }

                                a {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.ml-auto.mr-auto {
            li.has-dropdown {
                &.mega-dropdown {
                    position: absolute
                }
            }
        }

        &.ml-auto:not(.mr-auto) {
            li.has-dropdown {
                &.mega-dropdown {
                    position: relative
                }
            }
        }
    }

}