/*------------------------------------*\
	#Widgets
\*------------------------------------*/

.sidebar {
    @media #{$minLarge} {
        max-width  : 330px;
        margin-left: auto;
    }
}

/* Widget */
.widget {
    background-color: var(--global--color-primary-light);
    padding         : 33px 40px 40px;
    margin-bottom   : 40px;
    border-radius   : 8px 8px 0 8px;
    position        : relative;

    &::before {
        content         : '';
        position        : absolute;
        bottom          : 0;
        left            : 50%;
        transform       : translateX(-50%);
        height          : 3px;
        width           : calc(100% - 80px);
        background-color: var(--global--color-primary);
    }

    .widget-title {
        text-align   : center;
        margin-bottom: 22px;

        @media #{$minLarge} {
            text-align: left;
        }

        h5 {
            font-size     : 19px;
            line-height   : 30px;
            font-weight   : 500;
            font-family   : var(--global--font-heading);
            color         : var(--global--color-heading);
            text-transform: capitalize;
            margin-bottom : 0;
            position      : relative;
        }
    }
}


/* Category Widget */
.widget-categories {
    padding-bottom: 28px;

    .widget-title {
        margin-bottom: 8px;
    }

    ul {
        padding-left : 0;
        list-style   : none;
        margin-bottom: 0;

        li {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            &:hover {
                a {
                    padding-left: 15px;
                    color       : var(--global--color-primary);
                }

                span {
                    background-color: var(--global--color-primary);
                    border-color    : var(--global--color-primary);
                    color           : var(--global--color-white);
                }
            }

            a {
                color         : var(--global--color-secondary);
                font-size     : 15px;
                font-family   : var(--global--font-body);
                font-weight   : 700;
                line-height   : 36px;
                text-transform: capitalize;
                position      : relative;
                transition    : 0.3s ease-in-out;
                display       : inline-flex;
                align-items   : center;

                &:before {
                    display         : inline-block;
                    content         : '';
                    width           : 12px;
                    height          : 2px;
                    margin-right    : 10px;
                    background-color: var(--global--color-primary);
                }
            }

            span {
                font-family     : var(--global--font-body);
                font-weight     : 400;
                font-size       : 13px;
                padding         : 1px 7px;
                color           : var(--global--color-primary);
                background-color: var(--global--color-white);
                border          : 1px solid var(--global--color-gray-light);
                border-radius   : 8px 8px 0 8px;
                transition      : 0.3s ease-in-out;
            }
        }
    }
}

/* Tags Widget */
.widget-tags {
    .widget-title {
        margin-bottom: 22px;
    }

    .widget-content {
        text-align: center;

        @media #{$minLarge} {
            text-align: left;
        }

        a {
            display         : inline-block;
            background-color: var(--global--color-white);
            font-family     : var(--global--font-body);
            font-size       : 12px;
            font-weight     : 400;
            line-height     : 28px;
            color           : var(--global--color-primary);
            margin-right    : 10px;
            margin-bottom   : 10px;
            text-transform  : capitalize;
            padding         : 0px 9px;
            border          : 1px solid var(--global--color-gray-light);
            border-radius   : 8px 8px 0 8px;
            transition      : 0.3s ease-in-out;

            &:hover {
                color           : var(--global--color-white);
                background-color: var(--global--color-primary);
                border-color    : var(--global--color-primary);
            }
        }
    }
}

/* Recent Posts */
.widget-recent-posts {
    padding-bottom: 40px;

    .widget-title {
        margin-bottom: 20px;
    }

    .post {
        position     : relative;
        margin-bottom: 25px;
        display      : flex;

        &:last-child {
            margin-bottom: 0;
        }

        .post-img {
            flex-shrink  : 0;
            max-width    : 100%;
            margin-right : 20px;
            overflow     : hidden;
            border-radius: 8px 8px 0 8px;
            width        : 80px;
            height       : 80px;

            img {
                width : 100%;
                height: 100%;
            }
        }

        .post-content {
            position: relative;

            .post-date {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                font-size     : 13px;
                line-height   : 20px;
                color         : var(--global--color-heading-darken);
                text-transform: capitalize;

                span {
                    &:after {
                        position    : relative;
                        content     : ',';
                        margin-right: 4px;
                    }

                    &:last-child {
                        &:after {
                            content: unset;
                        }
                    }
                }
            }

            .post-title {
                a {
                    font-family   : var(--global--font-heading);
                    color         : var(--global--color-heading);
                    font-size     : 16px;
                    font-weight   : 500;
                    line-height   : 20px;
                    text-transform: capitalize;

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }
        }
    }

    .carousel-dots .owl-dots {
        text-align: center;
        margin-top: 26px;

        @media #{$minLarge} {
            text-align: left;
        }

        .owl-dot {
            &.active {
                span {
                    background-color: var(--global--color-primary);
                    border-color    : var(--global--color-primary);
                }

            }

            span {
                width           : 16px;
                height          : 6px;
                border-radius   : 2px;
                border-width    : 3px;
                background-color: var(--global--color-heading);
                border-color    : var(--global--color-heading);
                margin-right    : 12px;
            }
        }
    }
}

/* Search Widget */
.widget-search {
    .form-search {
        border-radius   : 3px;
        border          : 1px solid var(--global--color-gray-light);
        margin-bottom   : 0;
        border-radius   : 8px 8px 0 8px;
        background-color: var(--global--color-white);

        .form-control {
            border          : none;
            box-shadow      : none;
            color           : var(--global--color-heading);
            font-size       : 14px;
            font-family     : var(--global--font-body);
            font-weight     : 400;
            line-height     : 5px;
            height          : 55px;
            text-transform  : capitalize;
            padding-left    : 20px;
            background-color: transparent;
            border-radius   : 0;
            margin-bottom   : 0;

            &::-webkit-input-placeholder {
                font-size     : 14px;
                font-family   : var(--global--font-body);
                color         : var(--global--color-heading);
                text-transform: capitalize;
            }

            &:-moz-placeholder {
                font-size     : 14px;
                font-family   : var(--global--font-body);
                color         : var(--global--color-heading);
                text-transform: capitalize;
            }
        }

        .btn {
            background-color: transparent;
            border-radius   : 0;
            border          : 0;
            width           : 45px;
            height          : 55px;
            line-height     : 29px;
            font-size       : 16px;

            i {
                color       : var(--global--color-heading);
                transition  : 0.3s ease-in-out;
                margin-right: 0;
            }

            &:hover {
                i {
                    color: var(--global--color-primary);
                }
            }
        }
    }
}

/* Reservation Widget */
.widget-reservation {
    position     : relative;
    overflow     : hidden;
    padding      : 50px 40px 60px;
    border-radius: 8px 8px 0 8px;

    &:hover {
        &::before {
            opacity   : 0;
            visibility: hidden;
        }

        &::after {
            opacity   : 0.9;
            visibility: visible;
        }

        .widget-content {
            a {
                span {
                    background-color: var(--global--color-heading-darken);
                }
            }
        }
    }

    img {
        position: absolute;
        top     : 0;
        left    : 0;
        right   : 0;
        width   : 100%;
        height  : 100%;
        z-index : 1;
    }

    &::before {
        content         : '';
        position        : absolute;
        top             : 0;
        left            : 0;
        right           : 0;
        width           : 100%;
        height          : 100%;
        background-image: linear-gradient(180deg, rgba(6, 30, 59, 0) 0%, var(--global--color-primary) 0%);
        transform       : translateX(0);
        z-index         : 2;
        transition      : 0.5s ease-in-out;
        opacity         : 0.9;
        visibility      : visible;
    }

    &::after {
        content         : '';
        background-image: linear-gradient(180deg, rgba(19, 189, 213, 0) 0%, #1d294d 0%);
        position        : absolute;
        top             : 0;
        left            : 0;
        right           : 0;
        width           : 100%;
        height          : 100%;
        transform       : translateX(0);
        z-index         : 2;
        transition      : 0.5s ease-in-out;
        opacity         : 0;
        visibility      : hidden;
    }

    @media #{$maxTablet} {
        text-align: center;
    }

    .widget-content {
        position: relative;
        z-index : 3;

        i {
            display      : block;
            margin-bottom: 11px;

            &:before {
                font-size  : 50px;
                color      : var(--global--color-white);
                margin-left: 0;
            }
        }

        h5 {
            text-transform: capitalize;
            font-family   : var(--global--font-heading);
            font-weight   : 500;
            font-size     : 19px;
            line-height   : 28px;
            color         : var(--global--color-white);
            margin-bottom : 16px;
        }

        p {
            font-family  : var(--global--font-body);
            font-weight  : 400;
            font-size    : 14px;
            line-height  : 24px;
            color        : #f4f4f4;
            margin-bottom: 21px;
        }

        a {
            font-family: var(--global--font-heading);
            font-weight: 400;
            color      : var(--global--color-white);
            font-size  : 24px;
            display    : flex;
            align-items: center;
            transition : 0.3s ease-in-out;

            @media #{$maxTablet} {
                justify-content: center;
            }

            span {
                transition      : 0.3s ease-in-out;
                display         : inline-flex;
                justify-content : center;
                align-items     : center;
                width           : 42px;
                height          : 42px;
                background-color: #1aadcd;
                color           : var(--global--color-white);
                border-radius   : 8px 8px 0 8px;
                font-size       : 16px;
                margin-right    : 10px;
            }
        }
    }

    &.widget-reservation-2 {
        &::before {
            content: unset;
        }

        &:after {
            opacity   : 0.9;
            visibility: visible;
        }

        .widget-content {
            a {
                span {
                    background-color: var(--global--color-heading-darken);
                }
            }
        }
    }
}

/* Opening Hours Widget */
.widget-opening-hours {
    position        : relative;
    overflow        : hidden;
    padding         : 50px 40px 60px;
    border-radius   : 8px 8px 0 8px;
    background-color: #f6f6f6;

    @media #{$maxTablet} {
        text-align: center;
    }

    &::before {
        content         : '';
        position        : absolute;
        bottom          : 0;
        left            : 50%;
        transform       : translateX(-50%);
        background-color: var(--global--color-heading-darken);
        height          : 3px;
        width           : calc(100% - 80px);
    }

    .widget-content {
        position: relative;
        z-index : 3;

        i {
            display      : block;
            margin-bottom: 11px;

            &:before {
                font-size  : 50px;
                color      : #51668a;
                margin-left: 0;
            }
        }

        h5 {
            text-transform: capitalize;
            font-family   : var(--global--font-heading);
            font-weight   : 500;
            font-size     : 19px;
            line-height   : 28px;
            color         : var(--global--color-heading);
            margin-bottom : 8px;
        }

        ul {
            margin-bottom: 0;

            li {
                display        : flex;
                align-items    : center;
                justify-content: space-between;
                font-size      : 14px;
                font-weight    : 400;
                font-family    : var(--global--font-body);
                color          : var(--global--color-body);
                text-transform : capitalize;
                border-bottom  : 1px solid #dcdee2;
                padding        : 9px 0;
            }
        }
    }
}

/* Widget Download */
.widget-download {
    padding         : 0;
    background-color: transparent;

    &::before {
        content: unset;
    }

    ul {
        margin: 0;

        li {
            &:last-of-type {
                a {
                    margin-bottom: 0;
                }
            }

            &.inversed {
                a {
                    background-color: var(--global--color-primary);

                    &::before {
                        background-color: var(--global--color-secondary);
                    }
                }
            }

            a {
                display         : flex;
                align-items     : center;
                padding         : 0 40px;
                height          : 100px;
                background-color: var(--global--color-secondary);
                color           : var(--global--color-white);
                transition      : 0.3s ease-in-out;
                border-radius   : 8px 8px 0 8px;
                margin-bottom   : 20px;
                position        : relative;

                @media #{$maxSmall} {
                    padding: 0 20px;
                    height : 80px;
                }

                &::before {
                    content         : '';
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    height          : 3px;
                    width           : calc(100% - 80px);
                    background-color: var(--global--color-primary);

                    @media #{$maxSmall} {
                        width: calc(100% - 40px);
                    }
                }

                svg {
                    margin-right: 20px;
                    fill        : var(--global--color-white);
                }

                span {
                    font-family   : var(--global--font-heading);
                    font-size     : 17px;
                    font-weight   : 500;
                    text-transform: capitalize;
                }
            }
        }
    }
}

/* Widget Recent Products */
.widget-recent-products {
    .product {
        display      : flex;
        align-items  : center;
        margin-bottom: 30px;

        .product-img {
            flex-shrink  : 0;
            margin-right : 20px;
            width        : 60px;
            height       : 60px;
            border-radius: 8px 8px 0 8px;

            img {
                border-radius: 8px 8px 0 8px;
                max-width    : 100%;
            }
        }

        .product-desc {
            .product-title {
                a {
                    text-transform: capitalize;
                    font-family   : var(--global--font-heading);
                    font-weight   : 500;
                    font-size     : 16px;
                    line-height   : 23px;
                    color         : var(--global--color-heading);
                    margin-bottom : 4px;
                    display       : inline-block;

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            .product-meta {
                span {
                    font-family: var(--global--font-body);
                    font-size  : 15px;
                    font-weight: 700;
                    color      : var(--global--color-primary);
                }
            }
        }
    }
}

/* Widget Services */
.widget-services {
    .widget-content {
        ul {
            margin-bottom: 0;

            li {
                margin-bottom: 4px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display         : flex;
                    align-items     : center;
                    font-size       : 15px;
                    font-weight     : 700;
                    color           : var(--global--color-secondary);
                    text-transform  : capitalize;
                    background-color: var(--global--color-white);
                    border-radius   : 8px 8px 0 8px;
                    transition      : 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
                    box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.05);
                    height          : 65px;
                    padding         : 0 30px;

                    &:hover {
                        background-color: var(--global--color-secondary);
                        color           : var(--global--color-white);

                        .line {
                            span {
                                opacity   : 0;
                                visibility: hidden;
                            }

                            &::after {
                                transform : translateY(-50%) translateX(0px);
                                opacity   : 1;
                                visibility: visible;
                            }
                        }
                    }

                    .line {
                        display        : flex;
                        justify-content: center;
                        align-items    : center;
                        width          : 12px;
                        position       : relative;
                        margin-right   : 10px;

                        span {
                            display         : block;
                            width           : 12px;
                            height          : 2px;
                            background-color: var(--global--color-secondary);
                            transition      : 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
                        }

                        &::after {
                            position   : absolute;
                            top        : 50%;
                            transform  : translateY(-50%) translateX(-15px);
                            left       : 0;
                            content    : '\e901';
                            font-family: 'icomoon';
                            font-size  : 10px;
                            color      : var(--global--color-white);
                            display    : inline-block;
                            opacity    : 0;
                            visibility : hidden;
                            transition : 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
                        }
                    }
                }
            }
        }
    }
}

/* Widget Filter */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
}

.ui-slider-horizontal {
    .ui-slider-handle {
        position   : absolute;
        top        : -2px;
        margin-left: -0.6em;
    }

    .ui-slider-range {
        position: absolute;
        top     : 0;
        height  : 100%;
    }

    .ui-slider-range-min {
        left: 0;
    }

    .ui-slider-range-max {
        right: 0;
    }
}

.ui-slider-vertical {
    width : 0.8em;
    height: 100px;

    .ui-slider-handle {
        left         : -0.3em;
        margin-left  : 0;
        margin-bottom: -0.6em;
    }

    .ui-slider-range {
        left : 0;
        width: 100%;
    }

    .ui-slider-range-min {
        bottom: 0;
    }

    .ui-slider-vertical .ui-slider-range-max {
        top: 0;
    }
}

.widget-filter {
    .ui-slider {
        position        : relative;
        height          : 4px;
        background-color: rgba(34, 34, 34, 0.06);
        border-radius   : 0;
        margin-bottom   : 20px;
        margin-top      : 5px;
    }

    .ui-slider-handle {
        /* Style for "Line" */
        width           : 8px;
        height          : 8px;
        margin-left     : 0;
        background-color: var(--global--color-primary);
        border-radius   : 50%;
    }

    .ui-slider-range {
        height          : 4px;
        background-color: var(--global--color-primary);
        margin-bottom   : 20px;
    }

    .slider-mount {
        display    : flex;
        align-items: center;
        font-family: var(--global--font-body);
        font-size  : 14px;
        font-weight: 400;
        line-height: 1;
    }

    label {
        color : var(--global--color-body);
        margin: 0;
    }

    input {
        color           : var(--global--color-body);
        border          : none;
        background-color: transparent;
    }

    .btn-filter {
        float         : right;
        font-family   : var(--global--font-body);
        font-size     : 14px;
        font-weight   : 700;
        text-transform: uppercase;
        color         : var(--global--color-secondary);
        flex-grow     : 2;
        text-align    : right;

        &:hover {
            color: var(--global--color-primary);
        }
    }
}


/* Doctors sidebar */
.sidebar-doctors {
    @media #{$minLarge} {
        margin-top  : 292px;
        max-width   : 330px;
        margin-left : 0;
        margin-right: auto;
    }

    &.sidebar-doctors-2 {
        @media #{$minLarge} {
            margin-top: 0px;
        }
    }

    &.sidebar-doctors-3 {
        @media #{$minLarge} {
            margin-top: 0px;
        }

        .team-modern {
            .team-member {
                box-shadow   : 0px 5px 83px 0px rgba(40, 40, 40, 0.06);
                margin-bottom: 40px;
            }
        }
    }
}

/* blog sidebar */
.sidebar-blog {

    .form-search {
        background-color: var(--global--color-white);
    }

    .widget-recent-posts {
        .entry {
            margin-bottom: 30px;
        }

        .entry:last-of-type {
            margin-bottom: 0;
        }

        .entry-title {
            a {
                font-weight: 700;
            }
        }

        .entry-date {
            font-family: var(--global--font-body);
            font-size  : 13px;
            font-weight: 600;
            color      : #616161;
        }
    }

}

/* Shop Sidebar */
.sidebar-shop {
    .widget {
        padding         : 0;
        background-color: var(--global--color-white);

        &::before {
            content: unset;
        }
    }

    .widget-categories {
        margin-bottom: 35px;
    }
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .sidebar {
        margin-top: 40px;
    }

    .widget {
        margin-bottom: 30px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar {
        margin-top: 40px;
    }

    .widget-recent .entry .entry-desc {
        margin-left: 70px;
    }
}