/*------------------------------------*\
    #MODULE
\*------------------------------------*/
.module-holder {
    margin-left: auto;
    display    : flex;
    align-items: center;
    height     : 105px;

    @media (min-width: 1200px) and(max-width: 1350px) {
        margin-left: 20px;
    }

    @media #{$maxLarge} {
        margin-top: 20px;
        height    : auto;
    }

    .module-language,
    .module-search,
    .module-cart {
        @media #{$maxLarge} {
            display: none;
        }
    }

    &.module-holder-phone {
        display: none;

        @media #{$maxLarge} {
            display   : flex;
            height    : 105px;
            margin-top: 0;
        }

        .navbar-toggler {
            display        : flex;
            justify-content: center;
            align-items    : center;
            width          : 30px;
            height         : 30px;
            transition     : 0.3s ease-in-out;
            border         : 0;
            padding        : 0;
            outline        : 0;
            cursor         : pointer;
            border-radius  : 0;

            &:focus {
                box-shadow: none;
            }

            @media #{$minXlarge} {
                display: none;
            }

            &:not(.collapsed) {
                .navbar-toggler-icon {
                    background-color: transparent;

                    &:before,
                    &:after {
                        top: 0;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .navbar-toggler-icon {
                background-color: var(--global--color-heading);
                width           : 100%;
                height          : 2px;
                position        : relative;
                transition      : 0.3s ease-in-out;

                &::before,
                &::after {
                    content         : '';
                    position        : absolute;
                    left            : 0;
                    height          : 2px;
                    width           : 100%;
                    background-color: var(--global--color-heading);
                    transition      : 0.3s ease-in-out;
                }

                &::before {
                    top: -10px;
                }

                &::after {
                    top: 10px;
                }
            }
        }

        .module-language {
            display     : inline-flex;
            margin-right: 15px;

            @media (min-width:992px) and (max-width:1200px) {
                top: 140%;
            }

            @media #{$minLarge} {
                display: none;
            }

            .selected {
                img {
                    margin-right: 0;
                }

                span,
                i {
                    display: none;
                }

                .lang-list {
                    left: -45px;
                }
            }
        }

        .module-search {
            display      : inline-flex;
            border-bottom: 0;
            padding      : 0;
            padding-right: 20px;
            margin-right : 20px;
        }

        .module-cart {
            display: inline-flex;
        }
    }
}

.module {
    display : inline-block;
    position: relative;

    span.title {
        display: none;
    }

    .module-icon {
        cursor: pointer;

        i {
            font-size  : 14px;
            line-height: 24px;
            color      : var(--global--color-white);
        }
    }

    &:last-of-type {
        .module-icon {
            margin-right: 0;
        }
    }

    .menu-icon-double {
        i {
            margin-left: 30px;
        }
    }

    .module-label {
        width           : 16px;
        height          : 16px;
        line-height     : 16px;
        border-radius   : 50%;
        background-color: var(--global--color-primary);
        color           : var(--global--color-white);
        font-size       : 10px;
        font-weight     : 400;
        text-align      : center;
        position        : absolute;
        top             : -4px;
        left            : 10px;
    }

    .module-box {
        position        : absolute;
        display         : block;
        box-shadow      : 0 2px 3px rgba(40, 40, 40, 0.1);
        background-color: #1b1a1a;
        opacity         : 0;
        visibility      : hidden;
        transition      : all 100ms linear;
        right           : 0;
        width           : 290px;
        border-radius   : 6px;
        padding         : 30px;
        z-index         : 99;
        transform       : translateY(10%);
        height          : 0;
        margin-top      : 1px;
    }

    .widget-contact-info {
        padding-bottom: 18px;

        ul {
            transform: translateY(-5px);

            li {
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
                font-family   : var(--global--font-body);
                line-height   : 23px;
                color         : #a5a5a5;

                &:first-of-type {
                    margin-bottom: 12px;
                }
            }
        }
    }
}

/* Module Fullscreen */
.module-fullscreen {
    left            : 0;
    top             : 0;
    right           : 0;
    z-index         : 9999;
    display         : block;
    height          : 100%;
    width           : 100%;
    opacity         : 0;
    visibility      : hidden;
    background-color: var(--global--color-white);
    position        : fixed;

    .module-cancel {
        top             : 40px;
        right           : 40px;
        border-radius   : 8px 8px 0 8px;
        position        : absolute;
        width           : 50px;
        height          : 50px;
        font-size       : 14px;
        font-weight     : 700;
        text-transform  : uppercase;
        color           : var(--global--color-white);
        background-color: var(--global--color-primary);
        display         : flex;
        justify-content : center;
        align-items     : center;
    }

    &.module-active {
        visibility: visible;
        opacity   : 1;
        transition: opacity .5s ease-in-out;
    }
}

.module-fullscreen.fullscreen-light {
    background-color: var(--global--color-white);
}

.module-active {
    .module-box {
        opacity   : 1;
        visibility: visible;
        display   : block;
        transform : translateY(0px);
        height    : inherit;
    }
}



/* Module Search */
.module-search {
    position     : relative;
    padding-right: 30px;
    margin-right : 30px;

    @media (min-width: 1200px) and(max-width: 1350px) {
        padding-right: 20px;
        margin-right : 20px;
    }

    &::after {
        content         : '';
        width           : 2px;
        height          : 27px;
        display         : inline-flex;
        position        : absolute;
        top             : 50%;
        transform       : translateY(-50%);
        right           : 0;
        background-color: #d9dce1;
    }

    .module-icon {
        i {
            transition: 0.3s ease-in-out;
        }
    }

    &.module-search-2 {
        .form-search {
            margin-bottom: 0;
            display      : flex;

            button {
                &::before {
                    position   : absolute;
                    content    : "\e906";
                    top        : 0;
                    right      : 0;
                    color      : var(--global--color-white);
                    font-family: "icomoon";
                    font-size  : 24px;
                    font-weight: 400;
                    line-height: 70px;
                    z-index    : 999;
                    cursor     : pointer;
                    font-size  : 17px;
                    transition : 0.3s ease-in-out;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .form-control {
                height       : 70px;
                border       : 0;
                margin-bottom: 0;
                padding      : 20px 30px;
                font-size    : 13px;
                color        : var(--global--color-body);

                &::-webkit-input-placeholder {
                    color      : var(--global--color-body);
                    font-family: var(--global--font-heading);
                    font-size  : 13px;
                }

                &::-moz-placeholder {
                    color      : var(--global--color-body);
                    font-family: var(--global--font-heading);
                    font-size  : 13px;
                }
            }
        }
    }
}

.module-search-box {
    .module-cancel {
        color: var(--global--color-white);
    }

    .form-search {
        margin-bottom: 0;

        button {
            &::before {
                position   : absolute;
                left       : 0;
                top        : 0;
                content    : "\e906";
                color      : var(--global--color-heading);
                font-family: "icomoon";
                font-size  : 24px;
                font-weight: 400;
                line-height: 70px;
                z-index    : 999;
                cursor     : pointer;
            }
        }
    }

    .form-control {
        position        : relative;
        font-family     : var(--global--font-heading);
        font-size       : 25px;
        font-weight     : 400;
        line-height     : 70px;
        height          : 70px;
        color           : #9b9b9b;
        border          : none;
        background-color: transparent;
        width           : 100%;
        border-bottom   : 2px solid var(--global--color-gray-light);
        padding         : 17px 30px 17px 46px;
        margin-bottom   : 0;
        transition      : 400ms linear;
        text-transform  : capitalize;
        border-radius   : 0;

        &:focus,
        &:active {
            border-color: var(--global--color-primary);
        }

        &::-webkit-input-placeholder {
            color      : #9b9b9b;
            font-family: var(--global--font-heading);
            font-size  : 35px;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }

        &::-moz-placeholder {
            color      : #9b9b9b;
            font-family: var(--global--font-heading);
            font-size  : 35px;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }

        &::-moz-placeholder {
            color      : #9b9b9b;
            font-family: var(--global--font-heading);
            font-size  : 35px;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }

        &:-ms-input-placeholder {
            color      : #9b9b9b;
            font-family: var(--global--font-heading);
            font-size  : 35px;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }
    }

    .btn {
        background-color: transparent;
        border-radius   : 0;
        width           : 35px;
        line-height     : 57px;
        color           : var(--global--color-white);
        font-size       : 28px;
        font-weight     : 400;
        text-align      : right;
        margin-left     : 0;
        padding         : 0;
    }
}

/* Module Cart */
.module-cart {
    margin-right: 20px;

    .module-icon {
        position: relative;

        i {
            transition: 0.3s ease-in-out;
            font-size : 16px;
            position  : relative;
        }
    }

    .cart-box {
        position        : absolute;
        box-shadow      : 0px 5px 41.5px rgba(9, 29, 62, 0.15);
        top             : 64px;
        left            : -150px;
        width           : 290px;
        background-color: var(--global--color-white);
        padding         : 30px;
        border-radius   : 0 0 8px 8px;
        opacity         : 0;
        visibility      : hidden;
        transform       : translateY(40px);
        transition      : 500ms;

        @media (max-width:1250px) {
            left: -250px;
        }

        @media #{$maxSmall} {
            left: -200px;
        }

        @media #{$minTablet} {
            width: 330px;
        }

        &.active {
            opacity   : 1;
            visibility: visible;
            transform : translateY(0);
        }

        .cart-overview {
            margin-bottom: 0;
            width        : 100%;

            li {
                padding-bottom : 30px;
                position       : relative;
                display        : flex;
                align-items    : center;
                justify-content: space-between;

                &:last-child {
                    margin-bottom: 0;
                }

                .cart-cancel {
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    color           : var(--global--color-white);
                    font-size       : 7px;
                    flex-shrink     : 0;
                    width           : 20px;
                    height          : 20px;
                    background-color: var(--global--color-secondary);
                    border-radius   : 1px;
                    margin-left     : 15px;

                    &:hover {
                        background-color: var(--global--color-primary);
                    }
                }

                img {
                    flex-shrink  : 0;
                    width        : 60px;
                    height       : 60px;
                    margin-right : 15px;
                    border-radius: 8px 8px 0 8px;
                }

                .product-meta {
                    h5 {
                        color         : var(--global--color-heading);
                        font-family   : var(--global--font-heading);
                        font-size     : 15px;
                        text-transform: capitalize;
                        line-height   : 1;
                        font-weight   : 500;
                        margin-bottom : 8px;
                    }

                    p {
                        font-family  : var(--global--font-body);
                        color        : var(--global--color-primary);
                        font-size    : 16px;
                        line-height  : 20px;
                        font-weight  : 700;
                        margin-bottom: 0;
                    }
                }
            }

        }

        span {
            font-family   : var(--global--font-heading);
            color         : var(--global--color-heading);
            font-size     : 17px;
            font-weight   : 500;
            line-height   : 22px;
            display       : inline-block;
            text-transform: capitalize;
            margin-bottom : 17px;
            transform     : translateY(-7px);

            .total-price {
                font-style: normal;
            }
        }

        .cart--control {
            .btn {
                height          : 60px;
                width           : 100%;
                border-radius   : 8px 8px 0 8px;
                background-color: var(--global--color-secondary);
                color           : var(--global--color-white);

                &::before {
                    background-color: var(--global--color-primary);
                }
            }
        }
    }
}

/* Module Contact */
.module-contact {
    display    : flex;
    align-items: center;

    @media #{$maxLarge} {
        flex-direction: column;
        width         : 100%;
    }

    .btn {
        width         : 190px;
        height        : 50px;
        margin-right  : 40px;
        text-transform: capitalize;
        font-size     : 15px;
        font-weight   : bold;
        border-radius : 8px 8px 0 8px;
        cursor        : pointer;
        transition    : background .24s cubic-bezier(.37, .31, .31, .9), color .24s cubic-bezier(.37, .31, .31, .9);
        overflow      : hidden;

        @media (min-width: 1200px) and(max-width: 1350px) {
            margin-right: 20px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            width           : auto;
            height          : auto;
            background-color: transparent;
            color           : var(--global--color-secondary);
            border          : 0;

            &::before {
                @media #{$minXlarge} {
                    content: unset;
                }
            }

            &:hover,
            &:active,
            &:focus {
                color: var(--global--color-primary);
            }
        }

        @media #{$maxLarge} {

            &:first-child,
            &:last-child {
                display: flex;
                width  : 100%;
            }

            &:first-child {
                margin-bottom   : 10px;
                width           : 100%;
                color           : var(--global--color-white);
                height          : 50px;
                margin-right    : 0;
                background-color: var(--global--color-secondary);

                &::before {
                    background-color: var(--global--color-primary);
                }

                &:hover,
                &:active,
                &:focus {
                    color: var(--global--color-white);
                }
            }
        }
    }

    &.module-contact-2 {
        .btn {
            &:first-child {
                width           : 190px;
                height          : 51px;
                background-color: var(--global--color-primary);
                color           : var(--global--color-white);
                border          : 0;

                &::before {
                    background-color: var(--global--color-secondary);
                    width           : 100%;
                    height          : 100%;
                    top             : 0;
                    left            : 0;
                }

                @media #{$maxLarge} {
                    width: 100%;
                }
            }
        }
    }

    &.module-contact-3 {
        .btn {
            &:first-child {
                width : 140px;
                height: 40px;

                @media #{$maxLarge} {
                    width: 100%;
                }
            }
        }
    }
}

/* Module Language */

.module-language {
    display    : inline-flex;
    align-items: center;
    position   : relative;
    height     : 100%;

    &.module-active,
    &:hover {
        .lang-list {
            opacity   : 1;
            visibility: visible;
        }
    }

    .selected {
        display    : flex;
        align-items: center;

        img {
            margin-right: 10px;
        }

        span {
            font-family   : var(--global--font-body);
            font-size     : 13px;
            font-weight   : 400;
            text-transform: capitalize;
            color         : var(--global--color-white);
            margin-right  : 5px;
            cursor        : pointer;
        }

        i {
            font-size: 11px;
            color    : var(--global--color-white);
        }
    }

    .lang-list {
        background-color: var(--global--color-white);
        color           : var(--global--color-heading);
        position        : absolute;
        top             : 100%;
        padding         : 6px 22px;
        box-shadow      : 0px 5px 41.5px rgba(9, 29, 62, 0.11);
        z-index         : 5555;
        left            : -22px;
        visibility      : hidden;
        opacity         : 0;
        transition      : 0.3s ease-in-out;
        border-radius   : 0 0 8px 8px;

        @media (min-width:992px) and (max-width:1200px) {
            top: 140%;
        }

        ul {
            margin-bottom: 0;

            li {
                display    : flex;
                align-items: center;

                &:last-of-type {
                    a {
                        border-bottom: none;

                        &::after {
                            content: none;
                        }
                    }
                }

                img {
                    margin-right: 10px;
                }

                a {
                    font-size     : 14px;
                    display       : block;
                    color         : var(--global--color-body);
                    text-transform: capitalize;
                    border-bottom : 1px solid #eaeaea;
                    padding       : 8px 0px;
                    cursor        : pointer;
                    transition    : 0.3s ease;
                    position      : relative;

                    &::after {
                        content         : '';
                        position        : absolute;
                        bottom          : 0;
                        left            : 0;
                        height          : 1px;
                        width           : 0px;
                        transition      : 0.3s ease;
                        background-color: transparent;
                    }

                    &:hover {
                        color: var(--global--color-primary);

                        &::after {
                            width           : 100%;
                            background-color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }
}

.navbar-fixed {
    .module-language {

        span,
        i {
            color: var(--global--color-heading);
        }
    }
}

/* Module Social */
.module-social {
    line-height  : 100px;
    height       : 100px;
    margin-right : 20px;
    margin-bottom: 0;

    @media #{$maxTablet} {
        border-bottom: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    a {
        color           : var(--global--color-white);
        font-size       : 17px;
        font-weight     : 400;
        margin-right    : 10px;
        position        : relative;
        overflow        : hidden;
        width           : 35px;
        height          : 35px;
        background-color: var(--global--color-primary);
        border-radius   : 50%;
        display         : inline-flex;
        justify-content : center;
        align-items     : center;
        transition      : 300ms ease-in-out;

        &:hover {
            transform: scale(0.9);
        }
    }

    &.module-social-2 {
        line-height : 70px;
        height      : 70px;
        border-right: 2px solid #292929;
        margin-right: 0;

        &:last-child {
            margin-right: 0;
        }

        a {
            color           : var(--global--color-white);
            background-color: transparent;
            margin-right    : 20px;
            width           : auto;
            height          : auto;
            border-radius   : 0;

            &:hover {
                color    : var(--global--color-primary);
                transform: scale(1);
            }
        }
    }
}

/* Navbar For header Top Bar */

/* Small Devices, Tablets */
@media only screen and (max-width: 991px) {


    .module-content {
        margin-bottom: 10px;
    }

    .cart-box {
        .cart-overview h5 {
            color: var(--global--color-white);
        }
    }

    .module-search .search-icon i {
        border-left  : none;
        padding-left : 0;
        padding-right: 5px;
    }
}

.module-cart.active .cart-box {
    opacity   : 1;
    visibility: visible;
    transform : translateY(0);
    opacity   : 1;
}

.module.module-active .cart-box,
.module.module-active .search-box {
    display: block !important;
}

.module-search.module-active .search-box .search-form {
    margin-bottom: 10px;
}

.module-search.module-active .search-box .search-form input {
    background-color: var(--global--color-white);
    margin-bottom   : 0;
    height          : 35px;
    line-height     : 35px;
    padding-left    : 10px;
    max-width       : 100%;
}


.module-cart.module-active .cart-box .cart-control {
    margin-right: auto;
    margin-left : auto;
    text-align  : center;
}

.module-cart.module-active .cart-box .cart-control .pull-right {
    float: none !important;
}

.module-consultation {
    padding: 10px 0;

    .btn {
        border-color: var(--global--color-heading);
        color       : var(--global--color-heading);
        margin      : 0;
    }
}

.module-social {
    a {
        color      : var(--global--color-heading);
        line-height: 36px;
    }
}


.module-sidearea {
    .module-sidearea-wrap {
        width  : 320px;
        padding: 40px;
    }
}