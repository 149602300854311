/*------------------------------------*\
	#Slider
\*------------------------------------*/

.slider {
	padding-top   : 0;
	padding-bottom: 0;

	.slide {
		padding-top: 0;
		height     : 800px;

		@media #{$maxSmall} {
			height: 700px;
		}

		@media (min-width:992px) and (max-width:1250px) {
			height: auto;
		}

		.slide-content {
			padding-top    : 321px;
			display        : flex;
			justify-content: center;
			flex-direction : column;

			@media (min-width:992px) and (max-width:1250px) {
				padding-bottom: 100px;
			}

			@media #{$maxTablet} {
				align-items: center;
			}

			@media #{$maxSmall} {
				padding-top: 200px;
			}

			.slide-subheadline {
				font-family   : var(--global--font-body);
				font-weight   : 700;
				font-size     : 16px;
				line-height   : 1;
				color         : var(--global--color-gray);
				margin-bottom : 20px;
				text-transform: capitalize;

				@media #{$maxTablet} {
					text-align : center;
					line-height: 1.5;
				}
			}

			.slide-headline {
				font-family   : var(--global--font-heading);
				font-weight   : 500;
				font-size     : 75px;
				line-height   : 70px;
				color         : var(--global--color-white);
				margin-bottom : 24px;
				text-transform: capitalize;

				@media (min-width:992px) and (max-width:1199px) {
					font-size: 35px;
					;
					line-height: 1.4;
				}

				@media #{$maxSmall} {
					font-size  : 32px;
					line-height: 1.4;
				}

				@media #{$maxTablet} {
					text-align: center;
				}
			}

			.slide-desc {
				font-family  : var(--global--font-body);
				font-weight  : 700;
				font-size    : 17px;
				line-height  : 26px;
				color        : var(--global--color-gray);
				margin-bottom: 33px;

				@media #{$maxTablet} {
					text-align: center;
				}
			}

			.slide-action {
				margin-bottom: 0px;

				@media #{$maxSmall} {
					display       : flex;
					flex-direction: column;
					align-items   : center;
				}

				.btn {
					font-family  : var(--global--font-body);
					font-size    : 15px;
					font-weight  : 700;
					display      : inline-flex;
					width        : 170px;
					height       : 62px;
					border-radius: 8px 8px 0 8px;
					margin-right : 30px;

					&:last-child {
						margin-right: 0;
						width       : 182px;

						@media #{$maxSmall} {
							width: 190px;
						}
					}

					@media (min-width:992px) and (max-width:1199px) {
						width: 170px;
					}

					@media #{$maxSmall} {
						margin-right : 0;
						margin-bottom: 15px;
						height       : 50px;
						width        : 190px;
					}

					&.btn--white {
						box-shadow: 2.121px 2.121px 3px rgba(3, 21, 50, 0.05);
					}
				}
			}

			.slide-list {
				@media #{$maxSmall} {
					display        : flex;
					flex-wrap      : wrap;
					align-items    : center;
					justify-content: center;
				}

				.icon {
					margin-right: 30px;
					display     : inline-block;

					@media #{$maxSmall} {
						margin-right: 0;
						margin      : 0 15px 25px;
					}

					&:last-child {
						margin-right: 0;
					}

					i {
						display         : flex;
						justify-content : center;
						align-items     : center;
						border-radius   : 8px 8px 0 8px;
						width           : 90px;
						height          : 90px;
						background-color: var(--global--color-white);
						margin-bottom   : 9px;

						&::before {
							margin-left: 0;
							font-size  : 50px;
							color      : var(--global--color-heading);
						}
					}

					p {
						text-align    : center;
						font-size     : 15px;
						font-weight   : 700;
						color         : var(--global--color-white);
						line-height   : 24px;
						margin-bottom : 0;
						text-transform: capitalize;
					}
				}
			}
		}
	}

	.carousel-navs {
		.owl-nav {
			@media #{$maxTablet} {
				display: none;
			}

			span {
				font-size: 0;
			}

			right   : auto;
			top     : auto;
			position: initial;

			.owl-next:before,
			.owl-prev:before {
				color      : rgba(255, 255, 255, 0.5);
				font-family: "carousel" !important;
				font-size  : 44px;
				transition : 0.3s ease-in-out;
			}

			.owl-prev:before {
				content: "\e990";
			}

			.owl-next:before {
				content: "\e991";
			}

			.owl-next,
			.owl-prev {
				position : absolute;
				top      : 50%;
				transform: translateY(-50%);

				&:hover {
					&::before {
						color: white;
					}
				}
			}

			.owl-next {
				right: 50px;
			}

			.owl-prev {
				margin-right: 0;
				left        : 50px;
			}
		}
	}

	.carousel-dots {
		position: relative;

		button.owl-dot,
		button.owl-dot:focus {
			padding         : 0;
			background-color: transparent;
			border          : none;
			outline         : none;
			box-shadow      : none;
		}

		.owl-dots {
			display        : flex;
			justify-content: center;
			z-index        : 5;

			@media #{$minLarge} {
				right: auto;
			}

			.owl-dot {
				margin-right: 20px;
				display     : inline-flex;

				&:last-child {
					margin-bottom: 0;
				}

				&.active {
					span {
						&::before {
							background-color: rgba(var(--global--color-white), 0.3);
						}
					}

				}

				span {
					display         : inline-block;
					width           : 9px;
					height          : 9px;
					border-radius   : 50%;
					background-color: var(--global--color-white);
					transition      : 0.3s ease-in-out;
					margin-right    : 0;
					position        : relative;

					&::before {
						content         : '';
						border-radius   : 50%;
						position        : absolute;
						top             : 50%;
						left            : 50%;
						z-index         : -1;
						transform       : translate(-50%, -50%);
						width           : 25px;
						height          : 25px;
						background-color: transparent;
						transition      : 0.3s ease-in-out;
					}
				}
			}
		}
	}

	&.slider-2 {
		.slide {
			height: 780px;

			@media (min-width:992px) and (max-width:1250px) {
				height: auto;
			}

			@media #{$maxSmall} {
				height        : auto;
				padding-bottom: 180px;
			}
		}

		.slide-content {
			padding-top: 254px;

			@media #{$maxSmall} {
				padding-top: 200px;
			}

			@media (min-width:992px) and (max-width:1250px) {
				padding-bottom: 80px;
			}
		}
	}

	&.slider-3 {
		.slide {
			@media (min-width:992px) and (max-width:1250px) {
				height: 850px;
			}

			.slide-content {
				padding-top: 293px;

				@media #{$maxSmall} {
					padding-top: 200px;
				}

				.slide-action {
					@media #{$maxTablet} {
						text-align: center;
					}

					.btn-line {
						&:last-child {
							.line {
								span {
									background-color: var(--global--color-white);
								}
							}
						}
					}
				}
			}
		}
	}

	&.slider-4 {
		.slide {
			background-position: 50% -100%;
			height             : 777px;

			@media #{$maxSmall} {
				height: 730px;
			}

			@media (min-width:992px) and (max-width:1250px) {
				height: 850px;
			}

			.slide-content {
				padding-top: 270px;

				@media (min-width:992px) and (max-width:1250px) {
					padding-bottom: 100px;
				}

				@media #{$maxSmall} {
					padding-top: 210px;
				}

				.slide-action {
					.btn {
						&:first-child {
							width: 180px;
						}
					}
				}
			}
		}
	}

	&.slider-5 {
		.slide {
			height             : 784px;
			background-position: 50% -85%;

			@media #{$maxSmall} {
				height: 680px;
			}

			.slide-content {
				padding-top: 308px;

				@media #{$maxSmall} {
					padding-top: 200px;
				}
			}
		}
	}
}

.hero {
	padding-bottom: 0;

	.hero-content {
		padding-top   : 185px;
		padding-bottom: 26px;

		@media #{$maxTablet} {
			text-align : center;
			padding-top: 120px;
		}

		.hero-subtitle {
			font-family  : var(--global--font-body);
			color        : var(--global--color-gray);
			font-size    : 16px;
			line-height  : 1;
			font-weight  : 700;
			margin-bottom: 15px;

			@media #{$maxTablet} {
				line-height: 1.5;
			}
		}

		.hero-title {
			font-family   : var(--global--font-heading);
			color         : var(--global--color-white);
			font-weight   : 500;
			font-size     : 40px;
			line-height   : 55px;
			margin-bottom : 19px;
			text-transform: capitalize;

			@media (min-width:992px) and (max-width:1199px) {
				font-size: 35px;
				;
				line-height: 1.4;
			}

			@media #{$maxSmall} {
				font-size  : 32px;
				line-height: 1.4;
			}
		}

		.hero-desc {
			font-family  : var(--global--font-body);
			color        : var(--global--color-gray);
			font-size    : 16px;
			font-weight  : 400;
			line-height  : 27px;
			margin-bottom: 31px;
		}

		.hero-action {
			margin-bottom: 105px;

			@media #{$maxSmall} {
				display       : flex;
				flex-direction: column;
				align-items   : center;
				margin-bottom : 70px;
			}

			.btn {
				font-family : var(--global--font-body);
				font-size   : 15px;
				font-weight : 700;
				display     : inline-flex;
				width       : 210px;
				height      : 60px;
				margin-right: 30px;

				@media #{$maxSmall} {
					margin-right : 0;
					margin-bottom: 15px;
					height       : 50px;
					width        : 210px;
				}

				@media (min-width:992px) and (max-width:1250px) {
					width        : 170px;
					margin-bottom: 15px;
				}

				&:last-child {
					margin-right: 0;
				}

				&.btn--white {
					box-shadow: 2.121px 2.121px 3px rgba(3, 21, 50, 0.05);
				}
			}
		}

		.breadcrumb {
			@media #{$maxTablet} {
				justify-content: center;
			}

			.breadcrumb-item+.breadcrumb-item {
				padding-left: 0;
				display     : flex;
				align-items : center;

				a {
					font-size  : 14px;
					line-height: 1;
				}
			}

			>li+li:before {
				font-family: "Font Awesome 5 Free";
				font-weight: 700;
				font-size  : 10px;
				padding    : 0 10px;
				color      : var(--global--color-white);
				content    : "\f054";
			}
		}
	}

	.hero-panel-holder {
		display        : flex;
		justify-content: flex-end;

		@media #{$maxTablet} {
			justify-content: center;
			margin-bottom  : 30px;
		}

		.hero-panel {
			background-color: var(--global--color-white);
			border-radius   : 8px 0 8px 8px;
			padding         : 26px 40px 39px;
			max-width       : 290px;

			.panel-content {
				display       : flex;
				flex-direction: column;

				@media #{$maxTablet} {
					align-items: center;
				}

				i {
					color        : #51668a;
					margin-bottom: 12px;
					display      : inline-block;

					&::before {
						margin-left: 0;
						font-size  : 49px;
					}
				}

				h5 {
					font-family   : var(--global--font-heading);
					font-weight   : 500;
					font-size     : 19px;
					line-height   : 28px;
					color         : var(--global--color-heading);
					margin-bottom : 14px;
					text-transform: capitalize;
				}

				p {
					font-family  : var(--global--font-body);
					font-weight  : 400;
					font-size    : 14px;
					line-height  : 24px;
					color        : var(--global--color-body);
					margin-bottom: 21px;
				}

				.btn {
					width              : 155px;
					height             : 42px;
					border-radius      : 8px 8px 0 8px;
					border             : 2px solid;
					transition-property: background, color, border-color;
					border-color       : #687596;

					&::before {
						background-color: var(--global--color-secondary);
						width           : calc(100% + 4px);
						height          : calc(100% + 4px);
						top             : -2px;
						left            : -2px;
					}

					&:hover {
						border-color: #213360;
					}
				}
			}

			&.hero-panel-dark {
				background-color: var(--global--color-heading-darken);

				.panel-content {

					i,
					h5,
					p {
						color: var(--global--color-white);
					}

					.btn {
						background-color   : transparent;
						color              : var(--global--color-white);
						border-color       : #7284b9;
						border             : 2px solid;
						transition-property: background, color, border-color;

						&::before {
							background-color: var(--global--color-primary);
							width           : calc(100% + 4px);
							height          : calc(100% + 4px);
							top             : -2px;
							left            : -2px;
						}

						&:hover {
							border-color: var(--global--color-primary);
							color       : var(--global--color-white);
						}
					}
				}
			}
		}

		.phone-card {
			display         : flex;
			background-color: var(--global--color-heading-darken);
			padding         : 35px 40px 31px;
			border-radius   : 0 32px 0 0;
			max-width       : 470px;

			@media #{$minLarge} {
				transform: translateY(80px);
			}

			@media #{$maxSmall} {
				flex-direction: column;
				align-items   : center;
				text-align    : center;
			}

			.card-icon {
				flex-shrink : 0;
				margin-right: 30px;

				@media #{$maxSmall} {
					margin-right: 0;
				}

				i {
					&::before {
						display    : flex;
						transform  : translateY(-4px);
						font-size  : 50px;
						margin-left: 0;
						color      : var(--global--color-white);
					}
				}
			}

			.card-content {
				h5 {
					font-weight   : 500;
					font-size     : 19px;
					line-height   : 28px;
					color         : var(--global--color-white);
					text-transform: capitalize;
					margin-bottom : 16px;
				}

				p {
					font-size    : 14px;
					color        : var(--global--color-gray);
					line-height  : 24px;
					margin-bottom: 15px;
				}

				a {
					display    : flex;
					align-items: center;
					transition : 0.3s ease-in-out;
					color      : var(--global--color-white);

					@media #{$maxTablet} {
						justify-content: center;
					}

					span {
						transition : 0.3s ease-in-out;
						font-family: var(--global--font-heading);
						font-weight: 400;
						font-size  : 24px;
					}

					i {
						margin-bottom: 4px;
						transition   : 0.3s ease-in-out;
						font-size    : 19px;
						margin-right : 15px;
					}
				}
			}
		}

		&.hero-panel-holder-2 {
			display        : flex;
			flex-direction : column;
			flex-grow      : 1;
			justify-content: flex-end;
			align-items    : flex-end;

			@media #{$maxTablet} {
				align-items: center;
			}
		}
	}

	.hero-rating {
		display    : flex;
		align-items: center;

		.num {
			font-family : var(--global--font-heading);
			font-weight : 500;
			font-size   : 37px;
			line-height : 22px;
			color       : var(--global--color-white);
			margin-right: 18px;
		}

		.rating-body {
			p {
				font-family  : var(--global--font-body);
				font-weight  : 400;
				font-size    : 13px;
				line-height  : 23px;
				color        : var(--global--color-gray);
				margin-bottom: 0;

				a {
					font-family   : var(--global--font-body);
					font-weight   : 700;
					font-size     : 14px;
					line-height   : 23px;
					color         : var(--global--color-secondary);
					text-transform: capitalize;
				}
			}
		}
	}

	&.hero-1 {
		background-position-y: 150px;

		@media #{$maxTablet} {
			background-position-y: 100px;
		}

		.hero-content {
			.hero-action {
				.btn {
					&:last-child {
						width: 180px;
					}
				}
			}
		}
	}

	&.hero-2 {
		background-position-y: 150px;

		@media #{$maxTablet} {
			background-position-y: 100px;
		}

		.hero-content {
			.hero-action {
				margin-bottom: 35px;

				.btn {
					&:first-child {
						border-radius: 8px 8px 0 8px;
					}

					&:last-child {
						width        : 180px;
						border-radius: 8px 8px 0 8px;
					}
				}
			}

			.breadcrumb {
				margin-top     : 70px;
				justify-content: center;
			}
		}
	}

	&.hero-3 {
		overflow             : visible;
		background-position-y: 150px;

		@media #{$maxTablet} {
			background-position-y: 100px;
		}

		.hero-content {
			.hero-action {
				margin-bottom: 35px;

				.btn {
					&:first-child {
						border-radius: 8px 8px 0 8px;
					}

					&:last-child {
						width        : 180px;
						border-radius: 8px 8px 0 8px;
					}
				}
			}

			.breadcrumb {
				margin-top     : 70px;
				justify-content: center;
			}
		}

		.hero-panel-holder {
			.hero-panel {
				@media #{$minLarge} {
					transform: translateY(180px);
				}
			}
		}
	}

	&.hero-4 {
		overflow             : visible;
		background-position-y: 100px;

		&::before {
			background-image: linear-gradient(180deg, #13C1D9BF 18%, var(--global--color-primary) 70%);
		}

		.hero-content {
			padding-top   : 144px;
			padding-bottom: 0;

			.hero-action {
				display      : flex;
				margin-bottom: 40px;
			}

			.hero-rating {
				margin-bottom: 45px;
			}

			.team-modern {
				.team-member {
					@media #{$minLarge} {
						max-width : 330px;
						transform : translateY(359px);
						margin-top: -359px;
						box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06);
					}
				}
			}

			.content-merged {
				flex-direction : column;
				justify-content: flex-end;
				display        : flex;

				@media #{$maxTablet} {
					align-items: center;
				}
			}
		}
	}

	&.hero-5 {
		background-position-y: 150px;

		@media #{$maxTablet} {
			background-position-y: 100px;
		}

		.hero-content {
			padding-top: 165px;

			.hero-desc {
				font-weight: 700;
			}
		}

		.hero-action {
			margin-bottom: 100px;

			@media #{$maxTablet} {
				margin-bottom: 70px;
			}

			.btn {
				border-radius: 8px 8px 0 8px;

				&:first-child {
					width: 170px;
				}

				&:last-child {
					width: 190px;

					@media (min-width:992px) and (max-width:1250px) {
						width: 170px;
					}
				}
			}
		}
	}

	&.hero-6 {
		.hero-content {
			.hero-title {
				margin-bottom: 36px;
			}

			.hero-action {
				display    : flex;
				align-items: center;

				.btn {
					width        : 180px;
					height       : 60px;
					border-radius: 8px 8px 0 8px;
				}

				@media (min-width: 768px) and (max-width: 992px) {
					justify-content: center;
				}

				@media #{$maxSmall} {
					flex-direction: column;
					align-items   : center;
				}
			}
		}
	}

	&.hero-7 {
		.hero-content {
			text-align : center;
			padding-top: 175px;

			.hero-title {
				margin-bottom: 27px;
			}

			.hero-action {
				margin-bottom: 245px;

				.btn {
					border-radius: 8px 8px 0 8px;
					width        : 170px;
					height       : 60px;
				}
			}
		}
	}

	&.hero-8 {
		overflow: visible;

		.hero-content {
			padding-top: 165px;

			.hero-action {
				margin-bottom: 90px;

				@media #{$maxTablet} {
					margin-bottom: 50px;
				}

				.btn {
					width        : 180px;
					height       : 60px;
					border-radius: 8px 8px 0 8px;
				}
			}
		}
	}

	&.hero-9 {
		.hero-content {
			padding-top: 147px;

			.hero-title {
				font-size    : 55px;
				margin-bottom: 24px;

				@media #{$maxSmall} {
					font-size  : 32px;
					line-height: 1.4;
				}
			}

			.hero-desc {
				font-size    : 17px;
				line-height  : 26px;
				font-weight  : 700;
				margin-bottom: 32px;
			}

			.hero-action {
				display      : flex;
				align-items  : center;
				margin-bottom: 80px;

				.btn {
					width        : 180px;
					height       : 60px;
					border-radius: 8px 8px 0 8px;
				}

				@media (min-width: 768px) and (max-width: 992px) {
					justify-content: center;
				}

				@media #{$maxSmall} {
					flex-direction: column;
					align-items   : center;
				}
			}
		}
	}
}