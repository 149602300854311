/*------------------------------------*\
    #BACK TO TOP
\*------------------------------------*/

#back-to-top {
    z-index         : 999;
    position        : fixed;
    right           : 40px;
    bottom          : 40px;
    opacity         : 0;
    width           : 50px;
    height          : 50px;
    border-radius   : 50%;
    display         : flex;
    justify-content : center;
    align-items     : center;
    cursor          : pointer;
    transition      : 300ms ease-in-out;
    background-color: var(--global--color-secondary);

    &:hover {
        background-color: var(--global--color-heading);
    }

    &.show {
        opacity: 1;
    }

    svg {
        color: var(--global--color-white);
    }
}


@media only screen and (max-width: 992px) {
    #back-to-top.show {
        right : 20px;
        bottom: 30px;
    }
}