/*------------------------------------*\
    #Footer
\*------------------------------------*/
/* Parallax Effect */
#wrapperParallax {
    position  : relative;
    z-index   : 1060;
    min-height: 100vh;
}

#footerParallax {
    position: fixed;
    z-index : -1;
    left    : 0;
    right   : 0;
    bottom  : 0;
}

@media only screen and (max-width: 991px) {
    #wrapperParallax {
        margin-bottom: 0 !important;
    }

    #footerParallax {
        position  : static;
        text-align: center;
    }
}

.footer {
    background-color: var(--global--color-heading-light);
    padding-top     : 0;
    padding-bottom  : 0;
    position        : relative;

    &::after {
        content         : '';
        position        : absolute;
        bottom          : 0;
        left            : 50%;
        transform       : translateX(-50%);
        background-color: var(--global--color-primary);
        width           : calc(100% - 120px);
        height          : 4px;

        @media #{$maxTablet} {
            width: calc(100% - 60px);
        }

        @media #{$maxSmall} {
            width: calc(100% - 40px);
        }
    }

    .footer-top {
        padding-top   : 120px;
        padding-bottom: 84px;

        @media #{$maxTablet} {
            padding-top   : 40px;
            padding-bottom: 50px;
        }
    }

    /* Footer Widgets */
    .footer-widget {
        text-align: center;

        @media #{$minTablet} {
            text-align: left;
        }

        .footer-widget-title {
            h5 {
                font-family   : var(--global--font-heading);
                color         : var(--global--color-white);
                font-size     : 16px;
                font-weight   : 500;
                line-height   : 30px;
                text-transform: capitalize;
                margin-bottom : 23px;
            }
        }

        .widget-content {
            p {
                color        : var(--global--color-body);
                font-size    : 14px;
                font-weight  : 400;
                line-height  : 25px;
                margin-bottom: 25px;
            }

            .module-social {
                height     : auto;
                line-height: normal;
            }
        }
    }

    .widget-newsletter {
        .widget-content {
            display       : flex;
            flex-direction: column;
            align-items   : center;

            .form-newsletter {
                overflow      : hidden;
                margin-bottom : 0;
                display       : flex;
                flex-direction: column;
                position      : relative;

                @media #{$maxTablet} {
                    width: 100%;
                }

                .form-group {
                    position     : relative;
                    margin-bottom: 15px;

                    .form-control {
                        background-color: #283b69;
                        color           : var(--global--color-gray-darken);
                        font-family     : var(--global--font-body);
                        font-size       : 14px;
                        font-weight     : 400;
                        height          : 60px;
                        line-height     : 60px;
                        margin-bottom   : 0px;
                        border-radius   : 8px 8px 0 8px;
                        padding-left    : 20px;
                        padding-right   : 50px;
                        width           : 100%;
                        outline         : none;
                        text-transform  : capitalize;

                        @media #{$minLarge} {
                            margin-bottom: 0;
                            margin-right : 30px;
                        }

                        &::-webkit-input-placeholder {
                            color: var(--global--color-gray-darken);
                        }

                        &::-moz-placeholder {
                            color: var(--global--color-gray-darken);
                        }

                        &:-ms-input-placeholder {
                            color: var(--global--color-gray-darken);
                        }
                    }

                    .submit {
                        position        : absolute;
                        top             : 50%;
                        transform       : translateY(-50%);
                        right           : 15px;
                        width           : 24px;
                        height          : 24px;
                        border-radius   : 50%;
                        background-color: var(--global--color-primary);
                        display         : flex;
                        align-items     : center;
                        justify-content : center;

                        &:hover {
                            &::before {
                                opacity   : 0;
                                visibility: hidden;
                            }

                            &::after {
                                opacity   : 1;
                                visibility: visible;
                            }
                        }

                        input {
                            border    : 0;
                            outline   : 0;
                            background: transparent;
                            position  : absolute;
                            top       : 0;
                            left      : 0;
                            width     : 100%;
                            height    : 100%;
                        }

                        &::before {
                            content    : '\e901';
                            font-family: 'icomoon';
                            font-size  : 11px;
                            color      : var(--global--color-white);
                            transform  : translateY(1px);
                            transition : 0.3s ease-in-out;
                        }

                        &:after {
                            content         : '';
                            display         : inline-block;
                            position        : absolute;
                            top             : 50%;
                            left            : 50%;
                            transform       : translate(-50%, -50%);
                            width           : 12px;
                            height          : 2px;
                            background-color: var(--global--color-white);
                            transition      : 0.3s ease-in-out;
                            opacity         : 0;
                            visibility      : hidden;
                        }
                    }
                }

                .custom-radio-group {
                    @media #{$maxTablet} {
                        justify-content: center;
                    }

                    .custom-control {
                        label {
                            font-size     : 14px;
                            color         : var(--global--color-gray);
                            text-transform: lowercase;

                            &::before {
                                border-color: rgb(48, 67, 119);
                            }
                        }
                    }
                }
            }
        }
    }

    .widget-links {
        ul {
            padding-left : 0;
            list-style   : none;
            margin-bottom: 0;
            overflow     : hidden;
            transform    : translateY(-5px);

            li {
                display: block;

                a {
                    color         : var(--global--color-gray-darken);
                    font-size     : 14px;
                    font-weight   : 400;
                    line-height   : 33px;
                    text-transform: capitalize;
                    font-family   : var(--global--font-body);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }
        }
    }

    .widget-contact {
        .widget-content {
            p {
                color        : var(--global--color-white);
                margin-bottom: 15px;
            }

            .phone {
                margin-bottom: 18px;

                a {
                    color      : var(--global--color-primary);
                    font-family: var(--global--font-heading);
                    font-size  : 22px;
                    transition : 0.3s ease-in-out;

                    &:hover {
                        color: var(--global--color-white);

                        i {
                            color: var(--global--color-white);
                        }
                    }

                    i {
                        color       : var(--global--color-primary);
                        transition  : 0.3s ease-in-out;
                        display     : inline-block;
                        font-size   : 18px;
                        margin-right: 9px;
                    }
                }
            }

            .address {
                a {
                    color    : var(--global--color-body);
                    font-size: 14px;

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            .directions {
                font-family   : var(--global--font-secondary);
                font-weight   : 700;
                font-size     : 15px;
                color         : var(--global--color-primary);
                text-transform: capitalize;
                display       : inline-flex;
                align-items   : center;
                transition    : 0.3s ease-in-out;
                position      : relative;
                padding-left  : 22px;

                &::before {
                    content         : '';
                    display         : inline-block;
                    position        : absolute;
                    top             : 50%;
                    transform       : translateY(-50%);
                    left            : 0;
                    width           : 12px;
                    height          : 2px;
                    background-color: var(--global--color-primary);
                    transition      : 0.3s ease-in-out;
                }

                i {
                    position  : absolute;
                    top       : 50%;
                    transform : translateY(-50%);
                    left      : 0;
                    font-size : 12px;
                    opacity   : 0;
                    visibility: hidden;
                    transition: 0.3s ease-in-out;
                    color     : var(--global--color-white);
                }

                &:hover {
                    color: var(--global--color-white);

                    &::before {
                        opacity   : 0;
                        visibility: hidden;
                    }

                    i {
                        opacity   : 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.footer-copyright {
    padding   : 50px 0 50px;
    border-top: 2px solid #2f3e66;

    .copyright {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        width          : 100%;

        @media #{$maxTablet} {
            flex-direction: column;
        }

        span {
            font-family   : var(--global--font-body);
            font-weight   : 400;
            font-size     : 14px;
            color         : var(--global--color-white);
            text-transform: capitalize;

            @media #{$maxTablet} {
                margin-bottom: 40px;
                text-align   : center;
            }

            a {
                color: var(--global--color-primary);

                &:hover {
                    color: var(--global--color-white);
                }
            }
        }

        .social-icons {
            margin-bottom: 0;

            li {
                display     : inline-block;
                margin-right: 10px;

                a {
                    width           : 50px;
                    height          : 50px;
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    background-color: #304377;
                    font-size       : 16px;
                    color           : var(--global--color-white);
                    transition      : 0.3s ease-in-out;
                    border-radius   : 8px 8px 0 8px;

                    &:hover {
                        background-color: var(--global--color-primary);
                        transform       : translateY(-10px);
                    }
                }
            }
        }
    }

}

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .footer {
        .footer-widget {
            padding-top   : 60px;
            padding-bottom: 0;
        }

        .footer-widget-title {
            h5 {
                margin-bottom: 15px;
            }
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer {
        .footer-widget {
            padding-bottom: 40px;
            padding-top   : 60px;

            .footer-widget-title {
                h5 {
                    margin-bottom: 15px;
                }
            }
        }
    }
}