/*------------------------------------*\
    #Lists
\*------------------------------------*/

ul.list-icons {
    padding        : 0;
    font-size      : 14px;
    list-style-type: none;

    li {
        font-family: var(--global--font-body);
        color      : var(--global--color-body);
        font-size  : 16px;
        font-weight: 400;
        line-height: 29px;
        position   : relative;

        i {
            font-size   : 14px;
            color       : var(--global--color-heading);
            margin-right: 10px;
        }
    }
}