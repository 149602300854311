/*--------------------------------
    #Services
---------------------------------*/
.services {
    padding-top   : 130px;
    padding-bottom: 90px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .service-panel {
        margin-bottom   : 80px;
        box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.08);
        background-color: var(--global--color-white);
        transition      : 0.3s ease-in-out;
        border-radius   : 8px 0 8px 8px;

        @media #{$maxTablet} {
            max-width: 370px;
            margin   : 0 auto 80px;
        }

        &:hover {
            box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);

            .service-panel-holder {
                .service-img {
                    img {
                        transform: scale3d(1, 1, 1);
                    }

                    &::before {
                        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
                    }
                }

                .service-content {
                    .service-more {
                        a {
                            background-color: var(--global--color-primary);
                            border-color    : var(--global--color-primary);
                            color           : var(--global--color-white);

                            .line {
                                span {
                                    opacity   : 0;
                                    visibility: hidden;
                                }

                                &::after {
                                    opacity   : 1;
                                    visibility: visible;
                                    color     : var(--global--color-white);
                                }
                            }
                        }
                    }
                }
            }
        }

        .service-panel-holder {
            overflow: visible;
            position: relative;
            padding : 0 40px 182px;

            @media #{$maxSmall} {
                padding: 0 30px 182px;
            }

            .service-img {
                position     : relative;
                overflow     : hidden;
                border-radius: 8px 0 8px 8px;
                position     : absolute;
                bottom       : -40px;
                left         : 50%;
                transform    : translateX(-50%);
                width        : calc(100% - 80px);

                @media #{$maxSmall} {
                    width: calc(100% - 60px);
                }

                a {
                    position: absolute;
                    top     : 0;
                    left    : 0;
                    right   : 0;
                    width   : 100%;
                    height  : 100%;
                    z-index : 2;
                }

                &::before {
                    position  : absolute;
                    top       : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 100%;
                    background: rgba(255, 255, 255, 0.5);
                    content   : '';
                    z-index   : 1;
                    transition: transform 0.6s;
                    transform : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
                }

                img {
                    max-width : 100%;
                    height    : auto;
                    transition: 0.5s ease-in-out;
                }
            }

            .service-content {
                text-align: center;

                @media #{$minLarge} {
                    text-align: left;
                }

                .service-title {
                    h4 {
                        font-family   : var(--global--font-heading);
                        font-size     : 23px;
                        font-weight   : 500;
                        line-height   : 32px;
                        margin-bottom : 16px;
                        text-transform: capitalize;

                        a {
                            color: var(--global--color-heading);

                            &:hover {
                                color: var(--global--color-primary);
                            }
                        }
                    }

                }

                .service-desc {
                    margin-bottom: 22px;

                    p {
                        font-family  : var(--global--font-body);
                        color        : var(--global--color-body);
                        font-size    : 15px;
                        font-weight  : 400;
                        line-height  : 25px;
                        margin-bottom: 0;
                    }
                }

                .service-icon {
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    width           : 94px;
                    height          : 94px;
                    border-radius   : 0 0 8px 8px;
                    background-color: #e7f9fc;
                    margin-bottom   : 32px;

                    @media #{$maxTablet} {
                        display: inline-flex;
                    }

                    i {
                        &::before {
                            color      : var(--global--color-heading);
                            font-size  : 64px;
                            margin-left: 0;
                        }
                    }
                }

                .service-more {
                    @media #{$maxTablet} {
                        display: inline-flex;
                    }

                    a {
                        font-size          : 15px;
                        font-weight        : 700;
                        font-family        : var(--global--font-secondary);
                        text-transform     : capitalize;
                        transition         : 0.3s ease-in-out;
                        width              : 124px;
                        border-radius      : 8px 8px 0 8px;
                        height             : 42px;
                        border             : 2px solid;
                        border-color       : #687596;
                        transition-property: background, color, border-color;

                        &::before {
                            background-color: var(--global--color-primary);
                            width           : calc(100% + 4px);
                            height          : calc(100% + 4px);
                            top             : -2px;
                            left            : -2px;
                        }

                        &:active,
                        &:focus,
                        &:hover {
                            color       : var(--global--color-white);
                            border-color: var(--global--color-primary);
                        }
                    }
                }
            }
        }

        &.service-panel-2 {
            margin-bottom: 30px;
            border-radius: 8px 0 8px 8px;

            &:hover {
                .service-panel-holder {
                    .service-content {
                        .service-icon {
                            background-color: #e7f9fc;
                        }
                    }
                }
            }

            .service-panel-holder {
                padding: 50px 40px;

                .service-content {
                    @media #{$minLarge} {
                        text-align: center;
                    }

                    .service-icon {
                        width           : 114px;
                        height          : 114px;
                        border-radius   : 50%;
                        border          : 5px solid #e7f9fc;
                        background-color: transparent;
                        transition      : 0.3s ease-in-out;

                        @media #{$minLarge} {
                            display: inline-flex;
                        }
                    }

                    .service-more {
                        a {
                            @media #{$minLarge} {
                                display: inline-flex;
                            }
                        }
                    }
                }
            }
        }

        &.service-panel-3 {
            margin-bottom: 30px;
            border-radius: 8px 0 8px 8px;

            .service-panel-holder {
                padding: 50px 40px;

                .service-content {
                    @media #{$minLarge} {
                        text-align: center;
                    }

                    .service-icon {
                        width           : 92px;
                        height          : 92px;
                        background-color: transparent;
                        transition      : 0.3s ease-in-out;

                        img {
                            max-width: 100%;
                        }

                        @media #{$minLarge} {
                            display: inline-flex;
                        }
                    }

                    .service-more {
                        a {
                            @media #{$minLarge} {
                                display: inline-flex;
                            }
                        }
                    }
                }
            }
        }
    }

    .more-services {
        text-align: center;

        p {
            font-family  : var(--global--font-body);
            font-weight  : 700;
            font-size    : 14px;
            color        : var(--global--color-body);
            margin-bottom: 0;

            a {
                position      : relative;
                color         : var(--global--color-primary);
                transition    : 0.3s ease-in-out;
                text-transform: capitalize;
                padding-bottom: 8px;

                &:hover {
                    &::before {
                        width: 70%;
                    }
                }

                &::before {
                    content         : '';
                    transition      : 0.3s ease-in-out;
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    width           : 100%;
                    height          : 2px;
                    background-color: var(--global--color-primary);
                }
            }
        }
    }

    &.services-2 {
        padding-bottom: 100px;

        @media #{$maxTablet} {
            padding-bottom: 70px;
        }
    }

    &.services-3 {
        padding-bottom: 100px;

        @media #{$maxTablet} {
            padding-bottom: 70px;
        }
    }

    &.services-4 {
        padding-top   : 324px;
        padding-bottom: 100px;

        @media #{$maxTablet} {
            padding-top   : 80px;
            padding-bottom: 70px;
        }

        .service-panel {
            .service-img {
                img {
                    width: auto;
                }
            }
        }

        .owl-stage-outer {
            overflow: visible;

            .owl-item {
                opacity: 0;

                &.active {
                    opacity: 1;
                }
            }
        }

        .owl-dots {
            margin-bottom: 40px;
            margin-top   : 0;

            @media #{$minLarge} {
                right        : 23px;
                bottom       : -18px;
                margin-bottom: 0;
            }

            .owl-dot {
                display: inline-flex;
            }
        }

        .more-services {
            @media #{$minLarge} {
                text-align  : left;
                padding-left: 40px;
            }
        }
    }
}

.single-service {
    padding-bottom: 30px;
    padding-top   : 120px;

    @media #{$maxTablet} {
        padding-top: 70px;
    }

    .sidebar-service {
        @media #{$minLarge} {
            margin-top: 8px;
        }
    }

    .service-heading {
        font-weight  : 500;
        font-size    : 22px;
        line-height  : 35px;
        color        : #0e204d;
        margin-bottom: 40px;
    }

    .entry-introduction {
        margin-bottom: 70px;

        .head {
            font-family  : var(--global--font-body);
            font-weight  : 700;
            font-size    : 16px;
            line-height  : 27px;
            color        : var(--global--color-secondary);
            margin-bottom: 25px;
        }

        .desc {
            font-size    : 16px;
            line-height  : 27px;
            color        : var(--global--color-body);
            margin-bottom: 25px;
        }

        .signature-block {
            display    : flex;
            align-items: center;

            @media #{$maxTablet} {
                justify-content: center;
            }

            @media #{$minLarge} {
                margin-top: 7px;
            }

            .signature-body {
                position: relative;

                img {
                    position: absolute;
                    top     : -18px;
                    left    : 2px;
                    right   : 0;
                }

                h6 {
                    font-family   : var(--global--font-heading);
                    font-weight   : 500;
                    color         : var(--global--color-secondary);
                    font-size     : 19px;
                    line-height   : 29px;
                    text-transform: capitalize;
                    margin-bottom : 0;
                }

                p {
                    font-family   : var(--global--font-body);
                    font-weight   : 400;
                    color         : var(--global--color-primary);
                    font-size     : 14px;
                    line-height   : 26px;
                    text-transform: capitalize;
                    margin-bottom : 0;
                }
            }

            .btn {
                border-radius: 8px 8px 0 8px;
                margin-right : 40px;
                width        : 170px;
                height       : 60px;
            }
        }

        .advantages {
            margin-bottom: 0;

            @media #{$maxTablet} {
                margin-bottom: 30px;
                text-align   : left;
            }

            li {
                display       : block;
                font-family   : var(--global--font-body);
                font-weight   : 700;
                font-size     : 15px;
                line-height   : 36px;
                color         : var(--global--color-body);
                text-transform: capitalize;

                i {
                    width           : 22px;
                    height          : 22px;
                    display         : inline-flex;
                    justify-content : center;
                    align-items     : center;
                    background-color: var(--global--color-heading-darken);
                    color           : var(--global--color-white);
                    margin-right    : 10px;
                    border-radius   : 50%;
                    font-size       : 10px;
                }
            }
        }
    }

    .entry-infos {
        @media #{$maxTablet} {
            text-align: center;
        }

        .entry-heading {
            font-size     : 24px;
            line-height   : 1;
            font-weight   : 500;
            margin-bottom : 19px;
            color         : #0e204d;
            text-transform: capitalize;
        }

        .entry-desc {
            font-size    : 15px;
            line-height  : 26px;
            margin-bottom: 32px;
        }
    }

    .health-plans {
        padding      : 45px 50px 50px;
        border       : 2px solid rgb(230, 232, 235);
        border-radius: 8px 8px 0 8px;
        margin-bottom: 67px;

        @media #{$maxSmall} {
            padding-left : 25px;
            padding-right: 25px;
        }

        .advantages-box {
            background-color: var(--global--color-primary-light);
            padding         : 32px 40px;
            border-radius   : 8px 8px 0 8px;
            margin-bottom   : 40px;

            @media #{$maxSmall} {
                padding-left : 20px;
                padding-right: 20px;
            }

            @media #{$minLarge} {
                display        : flex;
                justify-content: space-between;
            }

            .advantages-list {
                margin-bottom: 0;
                margin       : 0;
                padding      : 0;
                list-style   : none;

                @media #{$maxTablet} {
                    margin-bottom: 0px;
                }

                li {
                    display    : block;
                    font-family: var(--global--font-body);
                    font-weight: 700;
                    font-size  : 15px;
                    line-height: 36px;
                    color      : var(--global--color-heading);

                    i {
                        width           : 22px;
                        height          : 22px;
                        display         : inline-flex;
                        justify-content : center;
                        align-items     : center;
                        background-color: var(--global--color-primary);
                        color           : var(--global--color-white);
                        margin-right    : 10px;
                        border-radius   : 50%;
                        font-size       : 10px;
                    }
                }
            }
        }

        .entry-action {
            display    : flex;
            align-items: center;

            @media #{$maxTablet} {
                flex-direction: column;
                align-items   : center;
            }

            p {
                margin-bottom: 0px;
                font-family  : var(--global--font-heading);
                font-weight  : 400;
                color        : var(--global--color-heading-darken);
                display      : flex;
                align-items  : baseline;

                @media #{$minLarge} {
                    margin-right: 30px;
                }

                @media screen and(max-width:991.98px) {
                    justify-content: center;
                    margin-bottom  : 20px;
                }

                span {
                    &::before {
                        content: '/';
                    }

                    &:first-of-type {
                        &::before {
                            content: unset;
                        }
                    }
                }

                .currency {
                    font-size: 50px;
                }

                .time {
                    font-size: 14px;
                }
            }

            .btn {
                width        : 214px;
                height       : 60px;
                border-radius: 8px 8px 0 8px;
            }
        }
    }

    .core-values {
        margin-bottom: 27px;

        .entry-desc {
            margin-bottom: 42px;
        }

        .entry-value {
            padding      : 34px 38px 32px;
            display      : flex;
            border       : 2px solid var(--global--color-gray-light);
            border-radius: 8px 8px 0 8px;
            transition   : 0.3s ease-in-out;
            cursor       : pointer;
            margin-bottom: 40px;

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
                padding       : 28px 25px 26px;
                text-align    : center;
            }

            &:hover {
                border-color: transparent;
                box-shadow  : 0px 5px 41.5px rgba(9, 29, 62, 0.15);
            }

            i {
                &::before {
                    margin-left: 0;
                    font-size  : 49px;
                    color      : #354f8e;
                }

                margin-right: 30px;

                @media #{$minTablet} {
                    transform: translateY(-6px);
                }

                @media#{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 20px;
                }
            }

            h5 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                color         : var(--global--color-heading);
                font-size     : 19px;
                line-height   : 28px;
                margin-bottom : 11px;
                text-transform: capitalize;
            }

            p {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                font-size     : 14px;
                line-height   : 24px;
                margin-bottom : 0;
                text-transform: capitalize;
            }
        }
    }

    .tips-info {
        margin-bottom: 67px;

        .entry-desc {
            margin-bottom: 42px;
        }

        .prices-list {
            padding         : 37px 40px 28px;
            border-radius   : 8px 8px 0 8px;
            background-color: #f6f6f6;

            h6 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                font-size     : 19px;
                line-height   : 1;
                margin-bottom : 26px;
                color         : var(--global--color-heading);
                text-transform: capitalize;
            }

            ul {
                margin-bottom: 0;

                li {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    text-transform : capitalize;
                    font-size      : 15px;
                    font-weight    : 700;
                    font-family    : var(--global--font-body);
                    color          : var(--global--color-body);
                    text-transform : capitalize;
                    border-bottom  : 1px solid var(--global--color-gray-light);
                    margin-bottom  : 14px;
                    padding-bottom : 12px;

                    &:last-child {
                        @media #{$minTablet} {
                            margin-bottom: 0px;
                            border-bottom: 0;
                        }
                    }

                    .price {
                        font-family     : var(--global--font-secondary);
                        font-weight     : 700;
                        font-size       : 15px;
                        color           : var(--global--color-white);
                        background-color: var(--global--color-heading-darken);
                        padding         : 3px 10px;
                        border-radius   : 8px 8px 0 8px;
                    }
                }
            }
        }

        .infos {
            h6 {
                font-family  : var(--global--font-body);
                font-weight  : 700;
                font-size    : 16px;
                line-height  : 27px;
                color        : var(--global--color-secondary);
                margin-bottom: 25px;
            }

            p {
                font-size    : 16px;
                line-height  : 27px;
                color        : var(--global--color-body);
                margin-bottom: 25px;
            }
        }
    }

    .doctors-prief {
        .entry-desc {
            margin-bottom: 42px;
        }
    }
}