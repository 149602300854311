@mixin bp-large {
    @media only screen and (max-width: 60em) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (max-width: 40em) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (max-width: 30em) {
        @content;
    }
}

@mixin transition($trans) {
    -webkit-transition: $trans;
    -moz-transition   : $trans;
    -ms-transition    : $trans;
    -o-transition     : $trans;
    transition        : $trans;
}

@mixin rounded ($r) {
    -webkit-border-radius: $r;
    -moz-border-radius   : $r;
    -o-border-radius     : $r;
    border-radius        : $r;
}



@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}


// Add prefixes before elements
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }

    #{$property}: $value;
}