/*------------------------------------*\
    #About
\*------------------------------------*/
.about {
    padding-top   : 116px;
    padding-bottom: 130px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    overflow: visible;

    .about-img {
        position : relative;
        transform: translateY(12px);

        @media #{$maxTablet} {
            transform: translateY(0px);
            width    : max-content;
            margin   : 0 auto 80px;
        }

        img {
            border-radius: 32px 32px 0 32px;
            height       : 677px;

            @media #{$maxSmall} {
                height: 450px;
            }
        }

        .btn-video {
            position: absolute;
            left    : 0;
            bottom  : 0;

            @media #{$maxSmall} {
                height: 75px
            }
        }
    }

    .about-image-bottom {
        position  : relative;
        overflow  : visible;
        margin-top: 12px;

        img {
            position     : absolute;
            top          : -95px;
            left         : 0;
            z-index      : 5;
            display      : block;
            width        : 470px;
            height       : 314px;
            border-radius: 32px 32px 0 32px;

            @media #{$maxTablet} {
                height   : auto;
                top      : -27px;
                left     : 50%;
                transform: translateX(-50%);
            }

            @media #{$maxSmall} {
                width    : auto;
                max-width: 100%;
            }

            @media (min-width: 992px) and (max-width: 1200px) {
                top: -30px;
            }
        }
    }

    .signature-block {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        .signature-body {
            position: relative;

            img {
                position: absolute;
                top     : -18px;
                left    : 2px;
                right   : 0;

                @media#{$maxSmall} {
                    top      : 0;
                    left     : -20px;
                    max-width: 130%;
                }
            }

            h6 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                color         : var(--global--color-secondary);
                font-size     : 19px;
                line-height   : 29px;
                text-transform: capitalize;
                margin-bottom : 0;
            }

            p {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                color         : var(--global--color-primary);
                font-size     : 14px;
                line-height   : 26px;
                text-transform: capitalize;
                margin-bottom : 0;
            }
        }

        .btn {
            border-radius: 8px 8px 0 8px;
            margin-right : 40px;
            width        : 170px;
            height       : 60px;
        }
    }

    .advantages-list {
        text-align: left;
        margin    : 0;
        padding   : 0;
        list-style: none;

        @media #{$maxTablet} {
            text-align: left;
        }

        li {
            display       : block;
            font-family   : var(--global--font-body);
            font-weight   : 700;
            font-size     : 15px;
            line-height   : 36px;
            color         : var(--global--color-body);
            text-transform: capitalize;

            i {
                width           : 22px;
                height          : 22px;
                display         : inline-flex;
                justify-content : center;
                align-items     : center;
                background-color: var(--global--color-heading-darken);
                color           : var(--global--color-white);
                margin-right    : 10px;
                border-radius   : 50%;
                font-size       : 10px;
            }
        }
    }

    &.about-2 {
        padding-top   : 0;
        padding-bottom: 0;

        .about-img {
            width        : auto;
            transform    : translateY(-130px);
            margin-bottom: -130px;
            z-index      : 5;

            img {
                height: auto;
            }

            .btn-video {
                left  : auto;
                right : 30px;
                bottom: -30px;
            }
        }
    }

    &.about-3 {
        padding-top   : 122px;
        padding-bottom: 0;
        overflow      : visible;
        z-index       : 5;

        @media #{$maxTablet} {
            padding-top: 70px;
        }

        .about-img-holder {
            display       : flex;
            flex-direction: column;

            .about-img {
                transform: translateY(0);

                @media #{$minLarge} {
                    transform: translateY(-100px);
                }

                width         : auto;
                display       : flex;
                flex-direction: column;
                flex-grow     : 1;

                @media #{$maxTablet} {
                    margin-bottom: 0;
                }

                img {
                    height       : 100%;
                    border-radius: 0 32px 32px 0;

                    @media #{$maxTablet} {
                        border-radius: 0px 0px 32px 32px;
                    }
                }

                .btn-video {
                    bottom: 40px;
                    left  : 40px;

                    @media #{$maxSmall} {
                        height: 61px;
                    }
                }
            }
        }

        .video-card {
            transform : translateY(210px);
            margin-top: -210px;
            z-index   : 6;

            .card-left {
                border-radius   : 32px 0 0 32px;
                background-color: var(--global--color-white);
                padding         : 53px 60px 60px;
                box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);

                @media #{$maxTablet} {
                    border-radius: 32px 32px 0 0;
                }

                @media #{$maxSmall} {
                    padding-left : 25px;
                    padding-right: 25px;
                }

                .title {
                    font-weight  : 700;
                    font-size    : 16px;
                    line-height  : 27px;
                    color        : var(--global--color-secondary);
                    margin-bottom: 25px;
                }

                .desc {
                    font-size    : 16px;
                    line-height  : 27px;
                    color        : var(--global--color-body);
                    margin-bottom: 32px;
                }

                .card-action {
                    display    : flex;
                    align-items: center;

                    @media #{$maxTablet} {
                        justify-content: center;
                    }

                    @media #{$maxSmall} {
                        flex-direction: column;
                    }

                    .btn {
                        width        : 170px;
                        height       : 60px;
                        border-radius: 8px 8px 0 8px;
                        margin-right : 30px;

                        @media #{$maxSmall} {
                            margin-right : 0;
                            margin-bottom: 20px;
                        }

                        &:last-child {
                            border-radius      : 8px 8px 8px 0;
                            border             : 2px solid;
                            border-color       : #687696;
                            transition-property: background, color, border-color;

                            &::before {
                                background-color: var(--global--color-secondary);
                                width           : calc(100% + 4px);
                                height          : calc(100% + 4px);
                                top             : -2px;
                                left            : -2px;
                            }

                            &:hover {
                                border-color: var(--global--color-secondary);
                            }
                        }
                    }
                }
            }
        }
    }

    &.about-4 {
        padding-top   : 109px;
        padding-bottom: 0;

        @media #{$maxTablet} {
            padding-top: 70px;
        }

        .avatars-holder {
            position: relative;
            z-index : 5;

            .avatar {
                display: inline-flex;

                @media #{$maxTablet} {
                    align-items  : center;
                    margin-bottom: 30px;
                }

                img {
                    width : 100%;
                    height: 100%;

                    @media #{$maxTablet} {
                        width    : auto;
                        margin   : 0 auto;
                        max-width: 100%;
                    }
                }
            }

            .block-top {
                margin-bottom: 40px;

                .col-12 {
                    display        : flex;
                    flex-direction : column;
                    justify-content: flex-end;

                    .avatar {
                        width: 100%;
                    }
                }
            }

            .block-bottom {
                @media #{$minLarge} {
                    margin-bottom: -313px;
                }

                .col-12 {
                    display        : flex;
                    flex-direction : column;
                    justify-content: flex-start;

                    @media #{$maxTablet} {
                        align-items: center;
                    }
                }
            }

            .avatar-1 {
                height: 170px;

                img {
                    border-radius: 32px 0 32px 32px;
                }
            }

            .avatar-2 {
                height       : 307px;
                border-radius: 0px 32px 32px 32px;
                padding      : 71px 50px 50px;

                &::before {
                    background-image: linear-gradient(180deg, #13C1D9BF 18%, var(--global--color-primary) 70%);
                }

                @media #{$maxSmall} {
                    height: auto;
                }

                .avatar-content {
                    p {
                        font-family  : var(--global--font-heading);
                        font-weight  : 500;
                        font-style   : italic;
                        font-size    : 18px;
                        line-height  : 29px;
                        color        : #f4f4f4;
                        margin-bottom: 20px;
                    }

                    .btn {
                        width              : 143px;
                        height             : 42px;
                        border             : 2px solid;
                        border-color       : #4ed4e6;
                        transition-property: background, color, border-color;

                        &::before {
                            background-color: var(--global--color-secondary);
                            width           : calc(100% + 4px);
                            height          : calc(100% + 4px);
                            top             : -2px;
                            left            : -2px;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            border-color: var(--global--color-secondary);
                        }
                    }
                }
            }

            .avatar-3 {
                height: 225px;

                img {
                    border-radius: 32px 32px 32px 0px;
                }
            }

            .avatar-4 {
                height: 405px;

                @media #{$minLarge} {
                    transform: translateY(-210px);
                }

                img {
                    border-radius: 0px 32px 32px 32px;
                }
            }

            .avatar-5 {
                height: 180px;

                img {
                    border-radius: 32px 32px 32px 0px;
                }
            }

            .avatar-6 {
                height: 307px;

                img {
                    border-radius: 0px 32px 32px 32px;
                }
            }
        }
    }
}