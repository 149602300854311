/*------------------------------------*\
    #Contacts
\*------------------------------------*/
.contact-panel {
    padding: 50px 55px;

    @media #{$maxSmall} {
        padding: 40px 25px;
    }

    &.contact-panel-2 {
        display   : flex;
        padding   : 0;
        transform : translateY(398px);
        margin-top: -398px;

        @media #{$maxTablet} {
            flex-direction: column;
        }

        .contact-card {
            padding      : 57px 50px 60px;
            box-shadow   : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
            border-radius: 0 0 0px 8px;

            @media #{$minLarge} {
                border-radius: 0 0 8px 0;
            }

            @media #{$maxSmall} {
                padding-left : 25px;
                padding-right: 25px;
            }
        }

        .img-card {
            border-radius: 8px 8px 0 0px;

            @media #{$minLarge} {
                border-radius: 8px 0 0 8px;
            }
        }
    }

    &.contact-panel-3 {
        display: flex;
        padding: 0;

        @media #{$maxTablet} {
            flex-direction: column;
        }

        @media #{$minLarge} {
            justify-content: space-between;
        }

        .heading {
            @media#{$minLarge} {
                flex-basis : 40%;
                flex-shrink: 0;
            }
        }

        .contact-card {
            padding      : 57px 50px 60px;
            box-shadow   : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
            border-radius: 0 8px 8px 8px;
            height       : max-content;

            @media #{$minLarge} {
                flex-basis : 54%;
                flex-shrink: 0;
            }

            @media #{$maxSmall} {
                padding-left : 25px;
                padding-right: 25px;
            }

            .btn {
                width: 100%
            }
        }
    }

    &.contact-panel-4 {
        padding         : 60px 60px 60px;
        background-color: var(--global--color-white);
        box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
        border-radius   : 8px 0 8px 8px;
        transform       : translateY(-130px);
        margin-bottom   : -130px;
        z-index         : 5;

        @media #{$maxSmall} {
            padding-left : 25px;
            padding-right: 25px;
        }

        .contact-card {
            padding: 0;

            .contact-body {
                .form-control {
                    margin-bottom: 25px
                }
            }
        }
    }

    &.contact-panel-5 {
        padding         : 60px 60px 60px;
        background-color: var(--global--color-white);
        box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
        border-radius   : 8px 0 8px 8px;
        transform       : translateY(400px);
        margin-top      : -400px;
        z-index         : 5;

        @media #{$maxSmall} {
            padding-left : 25px;
            padding-right: 25px;
        }

        .contact-card {
            padding: 0;
        }
    }
}

.contact-card {
    background-color: var(--global--color-white);
    border-radius   : 8px 8px 0 8px;
    padding         : 47px 60px 50px;

    @media #{$maxSmall} {
        padding: 37px 25px 40px;
    }

    position: relative;

    .contact-body {
        @media #{$maxTablet} {
            text-align: center;
        }

        .card-heading {
            color         : var(--global--color-heading);
            font-family   : var(--global--font-heading);
            font-size     : 24px;
            line-height   : 1;
            font-weight   : 500;
            text-transform: capitalize;
            margin-bottom : 20px;
        }

        .card-desc {
            font-family  : var(--global--font-body);
            font-weight  : 400;
            font-size    : 15px;
            line-height  : 26px;
            color        : var(--global--color-body);
            margin-bottom: 40px;
        }

        form {
            margin-bottom: 0;
        }

        .form-control {
            border-radius: 0 8px 8px 8px;
            border       : 2px solid transparent;
            transition   : 0.3s ease-in-out;
            margin-bottom: 30px;

            &:hover,
            &:active,
            &:focus {
                background-color: var(--global--color-white);
                border-color    : var(--global--color-primary);
            }

            &.nice-select {
                width      : 100%;
                display    : flex;
                align-items: center;

                &::after {
                    width       : 7px;
                    height      : 7px;
                    border-color: var(--global--color-heading);
                    right       : 25px;
                }

                &.open {
                    .list {
                        width: 100%;
                    }
                }

                .current {
                    text-transform: capitalize;
                }

                .list {
                    li {
                        text-transform: capitalize;
                    }
                }
            }
        }

        .btn {
            height       : 70px;
            border-radius: 0 8px 8px 8px;
        }

        .select-holder {
            position: relative;

            .badge {
                transition      : 0.3s ease-in-out;
                opacity         : 0;
                visibility      : hidden;
                transform       : translateX(-15px);
                height          : 26px;
                padding         : 0 10px;
                background-color: var(--global--color-primary);
                font-family     : var(--global--font-body);
                font-weight     : 700;
                font-size       : 14px;
                color           : var(--global--color-white);
                display         : flex;
                justify-content : center;
                align-items     : center;
                position        : absolute;
                left            : 0px;
                top             : -24px;
                border-radius   : 8px 8px 0 0;
                text-transform  : capitalize;
            }

            .form-control {
                &:hover {
                    +.badge {
                        transform : translateX(0);
                        opacity   : 1;
                        visibility: visible;
                    }
                }
            }
        }

        .time-select,
        .date-select {
            position        : relative;
            background-color: var(--global--color-primary-light);
            height          : 60px;
            border-radius   : 0 8px 8px 8px;
            border          : 2px solid transparent;
            transition      : 0.3s ease-in-out;
            margin-bottom   : 30px;
            display         : flex;
            align-items     : center;

            &:hover,
            &:active,
            &:focus {
                background-color: var(--global--color-white);
                border-color    : var(--global--color-primary);
            }

            input {
                position        : relative;
                background-color: transparent;
                margin-bottom   : 0;
                z-index         : 1;

                &:hover,
                &:active,
                &:focus {
                    background-color: transparent;
                    border-color    : transparent;
                }

                &::-webkit-calendar-picker-indicator,
                &::-webkit-inner-spin-button {
                    display           : block;
                    -webkit-appearance: block;
                    opacity           : 0;
                }
            }

            i {
                position : absolute;
                top      : 50%;
                transform: translateY(-50%);
                font-size: 16px;
                right    : 20px;
                color    : var(--global--color-heading);
            }

            label {
                &.error {
                    bottom: -23px;
                    left  : 0px;
                }
            }
        }

        .contact-result {
            margin-top: 10px;
            text-align: center;
        }
    }
}

.img-card {
    flex-shrink: 0;
    height     : auto;
    padding    : 65px 60px 60px;
    display    : flex;
    align-items: flex-end;
    text-align : center;

    @media #{$minLarge} {
        text-align: left;
        padding   : 55px 60px 60px;
        flex-basis: 46%;
    }

    @media #{$maxSmall} {
        padding-left : 25px;
        padding-right: 25px;
    }

    &::before {
        background: linear-gradient(180deg, #00000000 19%, var(--global--color-primary) 71%);
    }

    .card-content {
        max-width: 100%;

        h3 {
            font-weight   : 500;
            font-size     : 40px;
            line-height   : 55px;
            color         : var(--global--color-white);
            text-transform: capitalize;
            margin-bottom : 10px;

            @media #{$maxSmall} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        p {
            font-size    : 17px;
            color        : var(--global--color-gray);
            line-height  : 26px;
            font-weight  : 700;
            margin-bottom: 33px;
        }
    }

    &.img-card-2 {
        display              : flex;
        flex-direction       : column;
        flex-grow            : 1;
        border-radius        : 8px 0 8px 8px;
        padding-top          : 50px;
        background-position-x: 81%;

        @media #{$maxTablet} {
            margin-top: 50px;
        }

        &::before {
            background      : unset;
            background-color: #14c5dddb;
        }

        .card-content {
            flex-direction : column;
            display        : flex;
            flex-grow      : 1;
            justify-content: space-between;

            h3 {
                font-family: var(--global--font-heading);
                font-size  : 30px;
                line-height: 45px;
                font-weight: 500;
                color      : var(--global--color-white);

                @media #{$maxTablet} {
                    margin-bottom: 30px;
                }
            }

            p {
                font-family: var(--global--font-heading);
                font-size  : 18px;
                line-height: 29px;
                font-weight: 500;
                color      : #f4f4f4;
                font-style : italic;
            }

            .btn {
                width        : 170px;
                height       : 60px;
                border-radius: 8px 8px 0 8px;

                @media #{$maxTablet} {
                    display: inline-flex;
                }

                .line {
                    span {
                        background-color: var(--global--color-secondary);
                    }
                }
            }
        }
    }
}

.img-card-holder {
    display       : flex;
    flex-direction: column;
}

.contact-1 {
    iframe {
        position: absolute;
        top     : 0;
        left    : 0;
        width   : 100%;
        height  : 100%;

        @media screen and (max-width: 991.98px) {
            position: relative;
            width   : 100%;
            height  : 500px;
        }
    }
}


.map {
    position: relative;
    height  : 720px;

    iframe {
        position: absolute;
        top     : 0;
        left    : 0;
        right   : 0;
        height  : 100%;
        width   : 100%;

        &.map-gray {
            filter: brightness(104%) contrast(99%) saturate(0%) blur(0px) hue-rotate(0deg);
        }
    }

    &.map-2 {
        padding: 0;
        height : 620px;
    }

    &.map-3 {
        height : auto;
        padding: 90px 0;
    }
}

.company-data {
    padding-top   : 45px;
    padding-bottom: 45px;
    border-bottom : 1px solid #eaeaea;
    overflow      : visible;

    .data {
        display    : flex;
        align-items: center;

        .data-icon {
            margin-right: 20px;

            i {
                &::before {
                    margin-left: 0;
                    color      : var(--global--color-primary);
                    font-size  : 50px;
                }
            }
        }

        .data-body {
            h6 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                font-size     : 18px;
                line-height   : 24px;
                color         : #282828;
                text-transform: capitalize;
                margin-bottom : 7px;
            }

            a {
                color      : var(--global--color-body);
                transition : 0.3s ease-in-out;
                font-family: var(--global--font-body);
                font-weight: 400;
                font-size  : 14px;
                line-height: 24px;
                display    : block;

                &:hover {
                    color: var(--global--color-primary);
                }
            }
        }
    }

    .panels-holder {
        display   : flex;
        margin-top: 40px;

        @media #{$minLarge} {
            margin-top     : 0px;
            justify-content: flex-end;
            margin-bottom  : -136px;
            transform      : translateY(-136px);
            position       : relative;
            z-index        : 1000;
        }

        .panel-action {
            background-color: var(--global--color-primary);
            transition      : 0.3s ease-in-out;
            padding         : 28px 0px 32px 40px;
            display         : flex;
            flex-direction  : column;
            max-width       : 230px;
            cursor          : pointer;

            @media (max-width:375px) {
                padding-left: 20px;
            }

            @media #{$maxTablet} {
                max-width: none;
            }

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;

                .panel-content {
                    border-right: 0;
                }
            }

            .panel-content {
                border-right : 1px solid #e74a39;
                padding-right: 40px;

                @media (max-width:375px) {
                    padding-right: 20px;
                }

                .panel-icon {
                    margin-bottom: 10px;

                    i {
                        &::before {
                            color      : var(--global--color-white);
                            font-size  : 50px;
                            margin-left: 0;
                        }
                    }
                }

                .panel-heading {
                    h6 {
                        font-family  : var(--global--font-body);
                        font-weight  : 700;
                        font-size    : 16px;
                        line-height  : 29px;
                        margin-bottom: 0;
                        transition   : 0.3s ease-in-out;
                        color        : var(--global--color-white);
                    }
                }
            }
        }
    }
}




.contact-info {
    padding : 0;
    overflow: visible;
}

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .contact {
        .contact-card {
            padding: 70px 20px;
        }
    }

    .contact-3 {
        .contact-card {
            padding      : 0 50px;
            margin-bottom: 30px;
        }
    }
}

/* Tablets  */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-3 {
        .contact-card {
            margin-bottom: 30px;
            padding      : 0;
        }
    }
}