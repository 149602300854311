/*-----------------------------------------------------------------------------------

	Theme Name: Medisch
	Theme URI: http://themeforest.net/user/zytheme
	Description: Medisch is a pixel perfect creative medical HTML5 tempalte based on bootstrap 5, designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author: zytheme
	Author URI: http://themeforest.net/user/zytheme
	Version: 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

	1.  Global Styles
	2.  Typography
	3.	Color
	4.	Align
	5.	Grid
	6.	Blockquotes
	7.	Background
	8.	Breadcrumbs
	9.	Buttons
	10. Forms
	11. Icons
	12. List
	13. Tabs
	14. Accordions
	15. Pagination
	16. Alerts
	17. Backtop
	18. Page Loading
	19. Header
	20. Menu
	21. Module
	22. Topbar
	23. Page Title
	24. Carousel
	25. Heading
	26. Hero
	27. Blog
	28. Shop
	29. Team
	30. Timetable
	31. Skills
	32. About
	33. Features
	34. Sidebar
	35. Pricing
	36. Donations
	37. Processes
	38. Contact
	39. Clients
	40. Testimonial
	41. Video
	42. Action
	43. 404
	44. Services
	45. Action
	46. Departments
	47. Footer

/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/