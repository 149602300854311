/*------------------------------------*\
    #FORMS
\*------------------------------------*/
form {
    position: relative;

    .col-12 {
        position: relative;
    }
}


.form-control {
    color           : var(--global--color-heading);
    font-size       : 14px;
    font-weight     : 400;
    height          : 60px;
    line-height     : 59px;
    border-radius   : 8px 8px 8px 0;
    border          : none;
    background-color: var(--global--color-primary-light);
    padding-left    : 25px;
    margin-bottom   : 20px;
    position        : relative;
    box-shadow      : none;
    transition      : all 0.3s ease-in-out;

    &:focus {
        background-color: var(--global--color-primary-light);
        box-shadow      : none;
    }

    &::-webkit-input-placeholder {
        font-size     : 14px;
        font-family   : var(--global--font-body);
        color         : var(--global--color-heading);
        text-transform: capitalize;
    }

    &:-moz-placeholder {
        font-size     : 14px;
        font-family   : var(--global--font-body);
        color         : var(--global--color-heading);
        text-transform: capitalize;
    }

    &::-moz-placeholder {
        font-size     : 14px;
        font-family   : var(--global--font-body);
        color         : var(--global--color-heading);
        text-transform: capitalize;
    }

    &:-ms-input-placeholder {
        font-size     : 14px;
        font-family   : var(--global--font-body);
        color         : var(--global--color-heading);
        text-transform: capitalize;
    }

    &+.error {
        position: absolute;
        bottom  : 11px;
        left    : 17px;
        color   : red;
    }

    &.error {
        border-color: red;
    }
}


.custom-radio-group {
    display: flex;

    @media #{$maxSmall} {
        flex-wrap: wrap;
    }

    .custom-control {
        position     : relative;
        min-height   : 0;
        margin-bottom: 14px;

        .custom-control-input {
            opacity: 0;

            &:checked {
                ~label {
                    &::after {
                        background-color: var(--global--color-primary);
                        opacity         : 1;
                        transform       : scale(1);
                    }
                }
            }
        }

        label {
            font-size     : 13px;
            font-family   : var(--global--font-body);
            font-weight   : 400;
            color         : var(--global--color-body);
            margin-bottom : 0;
            line-height   : 1.9;
            text-transform: capitalize;
            cursor        : pointer;
            padding-left  : 10px;

            @media screen and (max-width:767px) {
                padding-left: 18px;
            }

            &::before {
                content      : '';
                position     : absolute;
                top          : 50%;
                transform    : translateY(-50%);
                left         : 0;
                border-radius: 3px;
                border       : 3px solid #eaeaea;
                width        : 15px;
                height       : 15px;
            }

            &::after {
                content         : '';
                display         : inline-block;
                position        : absolute;
                width           : 7px;
                height          : 7px;
                border-radius   : 50%;
                left            : 4px;
                top             : 9px;
                background-color: transparent;
                transition      : 0.3s ease-in-out;
                opacity         : 0;
                transform       : scale(0)
            }
        }
    }
}

textarea.form-control {
    height: auto;
}

.form-select {
    position: relative;

    i {
        position      : absolute;
        font-size     : 19px;
        font-weight   : 400;
        line-height   : 49px;
        right         : 20px;
        top           : 0;
        z-index       : 1;
        pointer-events: none;
        cursor        : pointer;
        color         : var(--global--color-body);
    }

    select {
        line-height: 1;
        color      : var(--global--color-body);
        cursor     : pointer;
        appearance : none;
    }
}

/* Input Checkbox */

.input-checkbox {
    margin-bottom: 30px;

    p {
        display      : block;
        position     : relative;
        font-size    : 13px;
        padding-left : 30px;
        line-height  : 20px;
        text-align   : left;
        margin-bottom: 0;
    }
}

.label-checkbox {
    display    : block;
    position   : relative;
    cursor     : pointer;
    font-size  : 18px;
    line-height: 18px;
    z-index    : 2;

    input {
        position: absolute;
        z-index : -1;
        opacity : 0;
    }

    .check-indicator {
        position     : absolute;
        top          : 2px;
        left         : 0;
        height       : 17px;
        width        : 17px;
        background   : transparent;
        border       : 1px solid #e1e3e3;
        border-radius: 2px;

        &:after {
            content     : "";
            position    : absolute;
            display     : none;
            left        : 4px;
            top         : 1px;
            width       : 6px;
            height      : 12px;
            border      : solid var(--global--color-primary);
            border-width: 0 2px 2px 0;
            transform   : rotate(45deg);
        }
    }

    input:checked~.check-indicator {
        background: var(--global--color-white);

        &:after {
            display: block;
        }
    }
}