/*------------------------------------*\
    #Timetable
\*------------------------------------*/
.timetable {
    padding-top   : 60px;
    padding-bottom: 110px;

    .table-sort {
        margin-bottom: 50px;
        height       : 60px;

        @media #{$maxTablet} {
            display        : flex;
            justify-content: center;
        }

        .nice-select {
            padding       : 9px 25px;
            width         : 250px;
            border-radius : 0 8px 8px 8px;
            border-color  : #e5e7eb;
            height        : 100%;
            font-size     : var(--global--font-body);
            font-weight   : 400;
            font-size     : 14px;
            color         : var(--global--color-heading);
            text-transform: capitalize;

            &::after {
                width       : 7px;
                height      : 7px;
                border-color: var(--global--color-heading);
                right       : 25px;
            }

            .list {
                width: 100%;
            }
        }
    }

    .table-holder {
        @media #{$minLarge} {
            display              : grid;
            grid-template-columns: repeat(8, 1fr);
        }

        .day-appointment {
            background-color: var(--global--color-primary-light);
            border-radius   : 4px;
            display         : flex;
            justify-content : center;
            align-items     : center;
            font-family     : var(--global--font-body);
            font-weight     : 700;
            font-size       : 14px;
            color           : var(--global--color-body);
            text-transform  : capitalize;
            margin          : 2px;

            @media #{$maxTablet} {
                margin : 0;
                padding: 15px 0;
            }
        }

        .appointment {
            display         : flex;
            flex-direction  : column;
            align-items     : center;
            justify-content : center;
            text-align      : center;
            font-family     : var(--global--font-body);
            font-weight     : 700;
            font-size       : 14px;
            color           : var(--global--color-body);
            text-transform  : capitalize;
            margin          : 2px;
            border-radius   : 4px;
            background-color: var(--global--color-primary-light);
        }

        .time-table {
            padding         : 0 15px;
            display         : flex;
            flex-direction  : column;
            align-items     : center;
            justify-content : center;
            text-align      : center;
            background-color: var(--global--color-heading-darken);
            margin          : 2px;
            border-radius   : 4px;
            transition      : 0.3s ease-in-out;
            opacity         : 0;
            visibility      : hidden;

            &.active {
                opacity   : 1;
                visibility: visible;
            }

            @media (min-width:992px) and (max-width:1200px) {
                padding: 0;
            }

            &:hover {
                background-color: var(--global--color-primary);
            }

            @media #{$maxTablet} {
                flex-direction  : row;
                justify-content : space-between;
                margin          : 15px 0;
                border-radius   : 0;
                background-color: transparent;
                border-bottom   : 1px solid #eaeaea;
                opacity         : 1;
                visibility      : visible;
                display         : none;

                &.active {
                    display: flex;
                }

                &:hover {
                    background-color: transparent;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }

            .clinic {
                font-weight   : 500;
                font-family   : var(--global--font-heading);
                font-size     : 17px;
                line-height   : 28px;
                color         : var(--global--color-white);
                text-transform: capitalize;
                margin-bottom : 5px;

                &:hover {
                    text-decoration: underline;
                }

                @media #{$maxTablet} {
                    color: var(--global--color-body);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .type {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                font-size     : 13px;
                line-height   : 23px;
                color         : #f4f4f4;
                text-transform: capitalize;
                margin-bottom : 19px;

                @media #{$maxTablet} {
                    display: none;
                }
            }

            .hours {
                font-family  : var(--global--font-body);
                font-weight  : 400;
                font-size    : 14px;
                line-height  : 20px;
                color        : #f4f4f4;
                margin-bottom: 14px;

                span {
                    display: block;
                }

                @media #{$maxTablet} {
                    color        : var(--global--color-body);
                    margin-bottom: 0;

                    span {
                        display    : inline-flex;
                        align-items: center;

                        &:last-child {
                            &:after {
                                content: unset;
                            }
                        }

                        &:after {
                            content: '-';
                            margin : 4px;
                        }
                    }
                }
            }

            .doctor {
                font-family  : var(--global--font-heading);
                font-weight  : 400;
                font-size    : 14px;
                line-height  : 32px;
                color        : #f4f4f4;
                margin-bottom: 0;

                @media #{$maxTablet} {
                    display: none;
                }
            }
        }

        .table-column {
            @media #{$minLarge} {
                display           : grid;
                grid-template-rows: 50px repeat(6, 200px);
            }

            .double {
                grid-row: span 2;
            }

            &.first-column {
                @media #{$maxTablet} {
                    display: none;
                }
            }
        }

        .day-appointment {
            width : auto;
            height: auto;
        }

        .appointment {
            width : auto;
            height: auto;
        }

        .time-table {
            width : auto;
            height: auto;
        }
    }
}