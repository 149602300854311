/*------------------------------------*\
    #Client
\*------------------------------------*/

.clients {
    &.bg-grey {
        color: var(--global--color-gray);
    }

    .client {
        position   : relative;
        line-height: 50px;
        transition : 0.3s ease;

        img {
            max-width   : 100%;
            width       : auto !important;
            display     : block;
            margin-right: auto;
            margin-left : auto;
        }
    }
}

.certificate {
    img {
        display: block;
        margin : 0 auto;
    }
}

/* Client #1 */

.clients-1 {
    padding-top   : 48px;
    padding-bottom: 48px;
}

/* Client #2 */
.clients-2 {
    border-top: 1px solid #eaeaea;
}

/* Cases Clients */
.cases-clients {
    padding-top          : 343px;
    padding-bottom       : 25px;
    background-image     : url('../images/background/pattern.png');
    background-attachment: fixed;

    .team-standard {
        padding-bottom: 0;
        padding-top   : 0;
    }

    &.cases-clients {
        background-image     : unset;
        background-attachment: unset;
        background-color     : var(--global--color-white);
    }
}

@media only screen and (max-width: 991px) {
    .client {
        margin-bottom: 20px;
    }
}