/*------------------------------------*\
    #Heading
\*------------------------------------*/

.heading {
    margin-bottom: 48px;

    @media #{$maxTablet} {
        text-align: center;
    }

    .heading-subtitle {
        font-family   : var(--global--font-body);
        text-transform: capitalize;
        color         : var(--global--color-primary);
        font-size     : 15px;
        font-weight   : 700;
        line-height   : 1;
        margin-bottom : 15px;

        @media #{$maxTablet} {
            line-height: 1.5;
        }
    }

    .heading-title {
        font-family   : var(--global--font-heading);
        text-transform: capitalize;
        font-size     : 40px;
        line-height   : 55px;
        margin-bottom : 25px;
        font-weight   : 500;
        color         : #0e204d;

        @media (min-width:992px) and (max-width:1199px) {
            font-size: 35px;
            ;
            line-height: 1.4;
        }

        @media #{$maxSmall} {
            font-size  : 32px;
            line-height: 1.4;
        }
    }

    .heading-desc {
        font-family  : var(--global--font-body);
        color        : var(--global--color-body);
        font-size    : 16px;
        font-weight  : 400;
        line-height  : 27px;
        margin-bottom: 0;
    }

    .paragraph {
        font-weight  : 700;
        font-size    : 16px;
        line-height  : 27px;
        color        : var(--global--color-secondary);
        margin-bottom: 0px;
    }

    &.heading-light {
        .heading-subtitle {
            color: var(--global--color-gray);
        }

        .heading-title {
            color: var(--global--color-white);
        }

        .heading-desc {
            color: var(--global--color-gray);
        }

        .paragraph {
            color: var(--global--color-gray);
        }
    }

    &.heading-2 {
        .heading-title {
            margin-bottom: 50px;
        }

        .paragraph {
            margin-bottom: 25px;
        }

        .heading-desc {
            margin-bottom: 40px;
        }
    }

    &.heading-3 {
        margin-bottom: 45px;

        .heading-title {
            transform: translateY(-6px)
        }

        .paragraph {
            margin-bottom: 26px;
        }

        .heading-desc {
            margin-bottom: 42px;
        }

        .actions-holder {
            .btn {
                &:first-child {
                    width        : 190px;
                    height       : 60px;
                    border-radius: 8px 8px 0 8px;
                }

                &:last-child {
                    border-radius: 8px 8px 8px 0;
                    width        : 170px;
                    height       : 60px;
                }
            }
        }
    }

    &.heading-4 {
        margin-bottom: 0px;

        .heading-subtitle {
            margin-bottom: 20px
        }

        .heading-title {
            transform    : translateY(-6px);
            margin-bottom: 70px
        }

        .paragraph {
            margin-bottom: 26px;
        }

        .heading-desc {
            margin-bottom: 42px;
        }

    }

    &.heading-5 {
        margin-bottom: 52px;
        position     : relative;

        .heading-title {
            font-size    : 37px;
            line-height  : 54px;
            color        : #0e204d;
            margin-bottom: 10px;

            @media #{$maxSmall} {
                font-size  : 32px;
                line-height: 1.4;
            }
        }
    }

    &.heading-6 {
        margin-bottom: 64px;

        .heading-title {
            margin-bottom: 50px;
        }
    }

    &.heading-7 {
        .heading-subtitle {
            color        : var(--global--color-heading-darken);
            font-size    : 16px;
            margin-bottom: 13px;
        }

        .heading-title {
            font-size  : 37px;
            line-height: 54px;
            color      : #0e204d;

            @media #{$maxSmall} {
                font-size  : 32px;
                line-height: 1.4;
            }
        }
    }

    &.heading-8 {
        margin-bottom: 25px;

        .heading-title {
            margin-bottom: 39px;
        }
    }

    &.heading-9 {
        margin-bottom: 53px;

        .heading-title {
            font-size    : 37px;
            line-height  : 54px;
            color        : #0e204d;
            margin-bottom: 9px;

            @media #{$maxSmall} {
                font-size  : 32px;
                line-height: 1.4;
            }
        }
    }

    &.heading-10 {
        margin-bottom: 58px;

        .heading-subtitle {
            color    : var(--global--color-primary);
            font-size: 16px;
        }
    }

    &.heading-11 {
        margin-bottom: 0px;

        .heading-title {
            transform    : translateY(-6px);
            margin-bottom: 25px
        }

        .heading-desc {
            margin-bottom: 32px;
        }
    }

    &.heading-12 {
        .heading-subtitle {
            color        : var(--global--color-heading-darken);
            font-size    : 16px;
            margin-bottom: 13px;
        }

        .heading-title {
            font-size  : 37px;
            line-height: 54px;
            color      : #0e204d;

            @media #{$maxSmall} {
                font-size  : 32px;
                line-height: 1.4;
            }
        }
    }

    &.heading-13 {
        margin-bottom: 88px;
    }

    &.heading-14 {
        margin-bottom: 45px;

        .heading-subtitle {
            font-size    : 16px;
            margin-bottom: 7px;
            color        : var(--global--color-primary);
        }

        .heading-title {
            font-size  : 160px;
            line-height: 1;

            @media #{$maxSmall} {
                font-size: 60px;
            }
        }

        .paragraph {
            margin-bottom: 25px;

            @media #{$minLarge} {
                margin-top: 13px;
            }
        }

        .heading-desc {
            color        : var(--global--color-gray-darken);
            margin-bottom: 42px;
        }
    }

    &.heading-15 {
        margin-top   : 92px;
        margin-bottom: 50px;

        .heading-title {
            transform: translateY(-6px)
        }

        .paragraph {
            margin-bottom: 26px;
        }

        .heading-desc {
            margin-bottom: 42px;
        }
    }

    &.heading-16 {
        margin-top   : 93px;
        margin-bottom: 24px;
    }

    &.heading-17 {
        margin-bottom: 90px;

        @media #{$maxTablet} {
            margin-bottom: 50px;
        }

        .heading-title {
            transform: translateY(-6px)
        }

        .paragraph {
            margin-bottom: 26px;
        }

        .heading-desc {
            margin-bottom: 42px;
        }
    }

    &.heading-18 {
        margin-bottom: 100px;

        .heading-subtitle {
            margin-bottom: 20px
        }

        .heading-title {
            transform    : translateY(-6px);
            margin-bottom: 70px
        }

        .paragraph {
            margin-bottom: 26px;
        }

        .heading-desc {
            margin-bottom: 34px;
        }
    }

    &.heading-19 {
        margin-bottom: 38px;

        .heading-title {
            margin-bottom: 29px;
        }
    }
}