/*------------------------------------*\
    #Header
\*------------------------------------*/

.header {
    height: 80px;

    @media #{$minLarge} {
        height: 100px;
    }

    .navbar-brand {
        padding     : 0;
        margin      : 0;
        margin-right: 40px;

        @media (min-width: 1200px) and(max-width: 1350px) {
            margin-right: 20px;
        }

        .logo {
            max-width: 100%;
            width    : 180px;
            height   : 47px;

            @media #{$maxTablet} {
                width: auto;
            }
        }
    }

    &.header-light {
        background-color: var(--global--color-white);

        .navbar {
            background-color: var(--global--color-white);

            .logo-dark {
                display: inline-block;

                @media #{$maxTablet} {
                    display: none;
                }
            }

            .logo-light {
                display: none;
            }

            .logo-mobile {
                display: inline-block;

                @media #{$minLarge} {
                    display: none;
                }
            }

            .navbar-nav {
                >li {
                    >a {
                        color: var(--global--color-secondary);

                        &::before {
                            background-color: var(--global--color-secondary);
                        }
                    }
                }
            }

            .module {
                .module-icon {
                    i {
                        color: var(--global--color-secondary);

                        &:hover {
                            color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.header-topbar {
        @media #{$minTablet} {
            height: 155px;
        }

        .top-bar {
            @media #{$maxTablet} {
                display: none;
            }
        }

        .navbar {
            padding: 0 20px;

            @media #{$minXlarge} {
                min-height: 105px;
                position  : relative;
                top       : auto;
                bottom    : auto;
                right     : auto;
                left      : auto;
            }

            .navbar-nav {
                >li {
                    >a {
                        @media #{$minXlarge} {
                            line-height: 105px;
                        }
                    }
                }
            }
        }

        &.header-shadow {
            .navbar {
                box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
            }
        }

        &.header-topbar3 {
            .navbar-brand {
                margin-right: 25px;
            }

            .module-holder {
                .module-search {
                    margin-right : 20px;
                    padding-right: 20px;

                    @media #{$maxSmall} {
                        margin-right : 10px;
                        padding-right: 10px;
                    }
                }
            }

            .navbar {
                padding-right: 25px;
                padding-left : 25px;

                .navbar-nav {
                    >li {
                        &#departments {
                            @media #{$minXlarge} {
                                padding-right: 20px;
                                margin-right : 20px;
                            }
                        }
                    }
                }
            }
        }

        &.header-topbar5 {
            .navbar {
                .navbar-brand {
                    @media #{$minXlarge} {
                        display      : flex;
                        align-items  : center;
                        height       : 105px;
                        padding-right: 40px;
                        margin-right : 0;
                        border-right : 1px solid #dadde2;
                    }
                }

                .navbar-nav {
                    @media #{$minXlarge} {
                        margin-left : auto;
                        margin-right: auto;
                    }

                    >li {
                        &#departments {
                            @media #{$minXlarge} {
                                padding-right: 0px;
                                margin-right : 20px;
                                order        : unset;
                                position     : relative;

                                &::before {
                                    content: unset
                                }
                            }

                            @media (min-width: 1200px) and(max-width: 1350px) {
                                padding-right: 0px;
                                margin-right : 20px;
                            }
                        }
                    }
                }
            }

            .module-holder {
                @media #{$minXlarge} {
                    padding-left: 40px;
                    border-left : 1px solid #dadde2;
                }

                .module-search {
                    padding-right: 0;
                    margin-right : 30px;

                    &::after {
                        content: unset;
                    }
                }
            }
        }

        &.header-topbar6 {
            .navbar {
                .navbar-nav {
                    @media #{$minXlarge} {
                        margin-left: auto;
                    }

                    >li {
                        &#departments {
                            @media #{$minXlarge} {
                                padding-right: 0px;
                                margin-right : 20px;
                                order        : unset;
                                position     : relative;

                                &::before {
                                    content: unset
                                }
                            }

                            @media (min-width: 1200px) and(max-width: 1350px) {
                                padding-right: 0px;
                                margin-right : 20px;
                            }
                        }
                    }
                }
            }

            .module-holder {
                @media #{$minXlarge} {
                    margin-left: 30px;
                }

                &.module-holder-phone {
                    .module-search {
                        margin-left : 0;
                        margin-right: 30px;
                    }
                }

                .module-search {
                    padding-right: 0;
                    margin-right : 0px;
                    margin-left  : 30px;

                    &::after {
                        content: unset;
                    }
                }
            }
        }

        &.header-topbar7 {
            .navbar {
                .navbar-brand {
                    @media #{$minXlarge} {
                        display      : flex;
                        align-items  : center;
                        height       : 105px;
                        padding-right: 40px;
                        margin-right : 0;
                        border-right : 1px solid #dadde2;
                    }

                    @media (min-width: 1200px) and(max-width: 1350px) {
                        padding-right: 20px;
                    }
                }

                .navbar-nav {
                    @media #{$minXlarge} {
                        margin-left : 40px;
                        margin-right: auto;
                    }

                    @media (min-width: 1200px) and(max-width: 1350px) {
                        margin-left: 20px;
                    }

                    >li {
                        &#departments {
                            @media #{$minXlarge} {
                                padding-right: 0px;
                                margin-right : 20px;
                                order        : unset;
                                position     : relative;

                                &::before {
                                    content: unset
                                }
                            }

                            @media (min-width: 1200px) and(max-width: 1350px) {
                                padding-right: 0px;
                                margin-right : 20px;
                            }
                        }
                    }
                }
            }

            .module-holder {
                .module-contact {
                    @media #{$minXlarge} {
                        height       : 105px;
                        padding-right: 25px;
                        border-right : 1px solid #dadde2;
                    }
                }

                .module-search {
                    padding-right: 0;
                    margin-right : 0px;
                    margin-left  : 25px;

                    &::after {
                        content: unset;
                    }

                    @media #{$maxLarge} {
                        margin-right: 25px;
                    }
                }
            }
        }

        &.header-topbar8 {
            .navbar {
                .navbar-brand {
                    @media #{$minXlarge} {
                        display      : flex;
                        align-items  : center;
                        height       : 105px;
                        padding-right: 40px;
                        margin-right : 0;
                        border-right : 1px solid #dadde2;
                    }

                    @media (min-width: 1200px) and(max-width: 1350px) {
                        padding-right: 20px;
                    }
                }

                .navbar-nav {
                    @media #{$minXlarge} {
                        margin-left : 40px;
                        margin-right: auto;
                    }

                    @media (min-width: 1200px) and(max-width: 1350px) {
                        margin-left: 20px;
                    }

                    >li {
                        &#departments {
                            @media #{$minXlarge} {
                                padding-right: 0px;
                                margin-right : 20px;
                                order        : unset;
                                position     : relative;

                                &::before {
                                    content: unset
                                }
                            }

                            @media (min-width: 1200px) and(max-width: 1350px) {
                                padding-right: 0px;
                                margin-right : 20px;
                            }
                        }
                    }
                }
            }

            .module-holder {
                .module-cart {
                    .cart-box {
                        @media #{$minXlarge} {
                            top: 100%;
                        }
                    }
                }

                .module-search {
                    padding-right: 0;
                    margin-right : 0px;
                    margin-left  : 40px;

                    &::after {
                        content: unset;
                    }

                    @media #{$maxLarge} {
                        margin-right: 25px;
                    }
                }
            }

            .module-contact {
                @media #{$minXlarge} {
                    margin-left: 30px;
                }
            }

            .module-cart {
                @media #{$minXlarge} {
                    height       : 105px;
                    padding-right: 40px;
                    border-right : 1px solid #dadde2;
                    margin-right : 0;
                    display      : flex;
                    align-items  : center;
                }
            }
        }
    }
}


.header-topbar+.page-title,
.header-topbar+.hero,
.header-topbar+.slider {
    position  : relative;
    margin-top: -155px;
}