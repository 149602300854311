/*------------------------------------*\
    #Shop
\*------------------------------------*/
.shop {
    padding-top   : 110px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .sidebar-shop {
        transform: translateY(-5px);
    }

    &.shop-2 {
        padding-top   : 47px;
        padding-bottom: 61px;

        h5 {
            @media #{$maxTablet} {
                text-align: center;
            }

            font-family   : var(--global--font-heading);
            font-weight   : 500;
            font-size     : 21px;
            color         : var(--global--color-heading);
            text-transform: capitalize;
        }
    }

    &.shop-3 {
        padding-top   : 130px;
        padding-bottom: 80px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }
    }
}



/* Product Item */

.product-item {
    margin-bottom: 40px;

    .product-img {
        border-radius: 8px;
        position     : relative;
        overflow     : hidden;

        &:hover {
            img {
                transform: scale(1.1);
            }

            .add-to-cart {
                opacity   : 1;
                transform : translateX(-50%) translateY(0px);
                visibility: visible;
            }
        }

        img {
            border-radius: 8px;
            max-width    : 100%;
            transition   : 0.4s ease-in-out;
            transform    : scale(1);
        }

        .badge {
            position        : absolute;
            top             : 20px;
            right           : 20px;
            background-color: var(--global--color-primary);
            color           : var(--global--color-white);
            border-radius   : 4px 4px 0 4px;
            font-family     : var(--global--font-body);
            font-size       : 14px;
            font-weight     : 700;
            line-height     : 1;
            padding         : 8px 15px;
            text-transform  : capitalize;
        }

        .add-to-cart {
            position        : absolute;
            left            : 50%;
            transform       : translateX(-50%) translateY(20px);
            bottom          : 40px;
            width           : 190px;
            height          : 60px;
            display         : flex;
            justify-content : center;
            align-items     : center;
            color           : var(--global--color-white);
            background-color: var(--global--color-secondary);
            text-transform  : capitalize;
            border-radius   : 8px 8px 0 8px;
            font-family     : var(--global--font-body);
            font-weight     : 700;
            font-size       : 15px;
            transition      : 0.3s ease-in-out;
            opacity         : 0;
            visibility      : hidden;

            &:hover {
                background-color: var(--global--color-heading);
            }

            i {
                font-size   : 15px;
                margin-right: 10px;
            }
        }
    }

    .product-content {
        padding-top: 21px;
        text-align : center;

        .product-title {
            a {
                text-transform: capitalize;
                font-family   : var(--global--font-heading);
                font-size     : 21px;
                line-height   : 32px;
                font-weight   : 500;
                color         : var(--global--color-heading);
                margin-bottom : 5px;
            }
        }

        .product-price {
            span {
                font-family: var(--global--font-body);
                font-weight: 700;
                font-size  : 19px;
                color      : var(--global--color-primary);
                line-height: 28px;
            }
        }
    }
}

/* Shop Options */

.shop-options {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 30px;

    @media #{$maxSmall} {
        flex-direction: column;
        align-items   : center;
    }

    .products-show {
        @media #{$maxSmall} {
            margin-bottom: 25px;
        }

        p {
            font-family   : var(--global--font-body);
            font-weight   : 700;
            font-size     : 14px;
            text-transform: capitalize;
            margin-bottom : 0;
        }
    }

    .products-sort {
        .nice-select {
            padding-left : 23px;
            border-radius: 0 8px 8px 8px;
            border-color : var(--global--color-gray-light);
            height       : 60px;
            width        : 250px;
            display      : flex;
            align-items  : center;

            &::after {
                width       : 7px;
                height      : 7px;
                border-color: var(--global--color-heading);
                transform   : rotate(45deg) translateY(-50%);
                right       : 24px;
            }

            .current {
                font-family: var(--global--font-body);
                font-size  : 14px;
                color      : var(--global--color-heading);
            }

            .list {
                width: 100%;
            }
        }
    }
}



/* Shop Single */
.single-product {
    padding-top   : 0;
    padding-bottom: 0;

    .checkout-banner {
        display         : flex;
        align-items     : center;
        justify-content : space-between;
        padding         : 25px;
        background-color: var(--global--color-primary-light);
        border-radius   : 8px 8px 0 8px;
        margin-bottom   : 40px;

        @media #{$maxSmall} {
            flex-direction: column;
            align-items   : center;
        }

        p {
            font-family  : var(--global--font-heading);
            font-weight  : 500;
            font-size    : 19px;
            color        : var(--global--color-heading);
            margin-bottom: 0px;

            @media #{$maxSmall} {
                margin-bottom: 20px;
            }
        }

        .btn {
            height       : 60px;
            border-radius: 8px 8px 0 8px;
        }
    }

    .product-img {
        border-radius: 8px;
        position     : relative;

        @media #{$maxTablet} {
            margin-bottom: 25px;
        }

        a {
            position: absolute;
            top     : 0;
            left    : 0;
            right   : 0;
            width   : 100%;
            height  : 100%;
            cursor  : zoom-in;
        }
    }

    .product-content {
        padding-top: 15px;

        @media #{$maxTablet} {
            text-align: center;
        }

        .product-title {
            h3 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                color         : var(--global--color-heading);
                font-size     : 29px;
                line-height   : 1;
                text-transform: capitalize;
                margin-bottom : 12px;
            }
        }

        .product-price {
            font-family  : var(--global--font-body);
            color        : var(--global--color-primary);
            font-size    : 22px;
            font-weight  : 700;
            line-height  : 28px;
            margin-bottom: 26px;
        }

        .product-review {
            margin-bottom: 21px;

            span {
                color         : var(--global--color-body);
                font-size     : 13px;
                line-height   : 1;
                font-weight   : 400;
                font-family   : var(--global--font-body);
                text-transform: capitalize;

                &::after {
                    content: '/';
                    margin : 0 6px;
                }

                &:last-child {
                    &::after {
                        content: unset;
                    }
                }

                a {
                    color: var(--global--color-body);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            .product-rating {
                margin-right: 30px;
                display     : inline-block;

                i {
                    color       : var(--global--color-body);
                    font-size   : 15px;
                    line-height : 1;
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                i.active {
                    color: var(--global--color-primary);
                }
            }
        }

        .product-desc {
            p {
                font-family  : var(--global--font-body);
                font-weight  : 400;
                font-size    : 15px;
                line-height  : 25px;
                margin-bottom: 32px;
                color        : var(--global--color-body);
            }
        }

        .product-details {
            margin-bottom: 20px;

            .table {
                margin-bottom: 0;

                @media #{$minLarge} {
                    width: 60%;
                }

                td {
                    border-top    : 0;
                    padding-bottom: 5px;
                }

                .name {
                    font-family   : var(--global--font-heading);
                    font-weight   : 500;
                    font-size     : 16px;
                    color         : var(--global--color-heading);
                    text-transform: capitalize;

                    @media #{$minLarge} {
                        padding-left: 0;
                    }
                }

                .value {
                    font-family   : var(--global--font-body);
                    font-weight   : 400;
                    font-size     : 15px;
                    color         : var(--global--color-body);
                    text-transform: capitalize;
                }
            }
        }

        .product-share {
            display    : flex;
            align-items: center;
            flex-wrap  : nowrap;

            @media #{$maxTablet} {
                justify-content: center;
                flex-wrap      : wrap;
            }

            a {
                color           : var(--global--color-white);
                background-color: var(--global--color-heading);
                border-radius   : 8px 8px 0 8px;
                margin-right    : 15px;
                transition      : 0.3s ease-in-out;
                width           : 52px;
                height          : 40px;
                display         : flex;
                justify-content : center;
                align-items     : center;
                transition      : 0.3s ease-in-out;

                @media #{$minLarge} {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    background-color: var(--global--color-primary);
                    transform       : translateY(-5px);
                }

                &.facebook-bg {
                    background-color: #3b5999;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.twitter-bg {
                    background-color: #55acee;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.instagram-bg {
                    background-color: #0092ff;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.pinterest-bg {
                    background-color: #ca1f27;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }
            }
        }

        .product-action {
            display: flex;

            @media #{$maxTablet} {
                justify-content: center;
            }

            margin-bottom: 31px;

            .product-quantity {
                display      : flex;
                border       : 2px solid var(--global--color-gray-light);
                border-radius: 8px 8px 0 8px;
                width        : 80px;
                margin-right : 15px;

                span {
                    display       : flex;
                    flex-direction: column;

                    a {
                        border-left: 2px solid var(--global--color-gray-light);

                        &:first-child {
                            border-bottom: 2px solid var(--global--color-gray-light);
                        }

                        i {
                            width      : 24px;
                            height     : 24px;
                            font-size  : 10px;
                            line-height: 25px;
                            color      : var(--global--color-heading);
                            text-align : center;
                        }
                    }
                }

                input {
                    width           : 50px;
                    height          : 50px;
                    color           : var(--global--color-heading);
                    font-size       : 20px;
                    font-weight     : 700;
                    line-height     : 22px;
                    font-family     : var(--global--font-body);
                    text-align      : center;
                    background-color: transparent;
                    border-color    : transparent;
                }
            }

            .btn {
                height          : 54px;
                background-color: var(--global--color-secondary);
                color           : var(--global--color-white);

                &::before {
                    background-color: var(--global--color-primary);
                }

                &:active,
                &:focus,
                &:hover {
                    color: var(--global--color-white);
                }
            }
        }
    }

    .product-tabs {
        background-color: var(--global--color-white);
        margin-top      : 95px;
        margin-bottom   : 40px;

        .nav-tabs {
            background-color: var(--global--color-white);
            border          : 0;

            li {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color         : #273a69;
                    font-size     : 16px;
                    font-weight   : 700;
                    text-transform: capitalize;
                    font-family   : var(--global--font-body);
                    text-transform: capitalize;
                    border-radius : 0;
                    margin-right  : 0;
                    padding-bottom: 14px;
                    display       : block;
                    border-right  : 0;
                    position      : relative;

                    &::after {
                        content         : '';
                        position        : absolute;
                        bottom          : -2px;
                        left            : 0;
                        width           : 100%;
                        height          : 2px;
                        background-color: transparent;
                        transition      : 0.3s ease-in-out;

                        @media (max-width:420px) {
                            content: unset;
                        }
                    }

                    &:focus,
                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            li>.active,
            li>.active:focus,
            li>.active:hover {
                color: var(--global--color-primary);

                &::after {
                    background-color: var(--global--color-primary);
                }
            }
        }

        .tab-content {
            border-top : 2px solid var(--global--color-gray-light);
            padding-top: 25px;

            p {
                font-family: var(--global--font-body);
                font-size  : 15px;
                font-weight: 400;
                line-height: 25px;
                color      : var(--global--color-body);
            }

            h5 {
                font-size: 13px;
            }

            .review-comment {
                .review-body {
                    display      : flex;
                    align-items  : center;
                    margin-bottom: 25px;

                    img {
                        width        : 60px;
                        height       : 60px;
                        border-radius: 8px 8px 0 8px;
                        margin-right : 30px;
                    }

                    h6,
                    p {
                        margin-bottom: 0;
                    }

                    .product-rating {
                        i {
                            &.active {
                                color: var(--global--color-primary);
                            }
                        }
                    }
                }

                .product-comment {
                    margin-bottom: 35px;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .form-review {
                .nice-select {
                    .list {
                        width: 100%;
                    }
                }
            }
        }
    }



    .form-review .form-control {
        margin-bottom: 30px;
    }
}

/*Shop Cart*/
.shop-cart {
    padding-top: 0;

    .cart-table {
        margin-bottom: 30px;

        &.table-responsive {
            min-height: 0.01%;
            overflow-x: auto;
        }

        .table {
            background-color: var(--global--color-white);
            border-radius   : 0;
            margin-bottom   : 0;
            color           : var(--global--color-body);

            tr {
                &:first-child {
                    th {
                        border-top: 0;

                        &:first-child {
                            border-left: 0;
                        }

                        &:last-child {
                            border-right: 0;
                        }
                    }
                }

                th {
                    font-size     : 16px;
                    font-weight   : 700;
                    text-align    : center;
                    text-transform: capitalize;
                    color         : var(--global--color-body);
                    font-family   : var(--global--font-body);
                    padding       : 10px 0;
                    border-right  : 1px solid #e5e8ed;
                    border-bottom : 1px solid #e5e8ed;
                }
            }

            tbody {
                tr {
                    td {
                        padding      : 20px;
                        border-right : 1px solid #e5e8ed;
                        border-bottom: 1px solid #e5e8ed;
                        border-top   : 0;
                        border-left  : 0;

                        &:last-child {
                            border-right: 0;
                        }

                        &.cart-product-price,
                        &.cart-product-total {
                            text-align    : center;
                            font-size     : 16px;
                            font-weight   : 700;
                            line-height   : 20px;
                            color         : var(--global--color-primary);
                            font-family   : var(--global--font-body);
                            vertical-align: middle;
                        }

                        &.cart-product-item {
                            display        : flex;
                            justify-content: flex-start;
                            align-items    : center;

                            .cart-product-remove {
                                display         : flex;
                                justify-content : center;
                                align-items     : center;
                                width           : 19px;
                                height          : 19px;
                                background-color: rgb(40, 59, 106);
                                border-radius   : 1px;
                                text-align      : center;
                                cursor          : pointer;
                                margin-right    : 20px;
                                transition      : 0.3s ease-in-out;

                                &:hover {
                                    background-color: var(--global--color-primary);
                                }
                            }

                            .cart-product-img {
                                width       : 70px;
                                height      : 70px;
                                margin-right: 20px;

                                img {
                                    max-width: 100%;
                                }
                            }

                            .cart-product-name {
                                h6 {
                                    font-family   : var(--global--font-heading);
                                    color         : var(--global--color-heading);
                                    font-size     : 16px;
                                    font-weight   : 500;
                                    line-height   : 27px;
                                    text-transform: capitalize;
                                    margin-bottom : 0px;
                                }
                            }
                        }

                        &.cart-product-quantity {
                            .product-quantity {
                                display      : flex;
                                border       : 2px solid var(--global--color-gray-light);
                                border-radius: 8px 8px 0 8px;
                                width        : 80px;
                                margin-right : 0px;
                                margin       : 0 auto;

                                span {
                                    display       : flex;
                                    flex-direction: column;

                                    a {
                                        border-left: 2px solid var(--global--color-gray-light);

                                        &:first-child {
                                            border-bottom: 2px solid var(--global--color-gray-light);
                                        }

                                        i {
                                            width      : 24px;
                                            height     : 24px;
                                            font-size  : 10px;
                                            line-height: 25px;
                                            color      : var(--global--color-heading);
                                            text-align : center;
                                        }
                                    }
                                }

                                input {
                                    width           : 50px;
                                    height          : 50px;
                                    color           : var(--global--color-heading);
                                    font-size       : 20px;
                                    font-weight     : 700;
                                    line-height     : 22px;
                                    font-family     : var(--global--font-body);
                                    text-align      : center;
                                    background-color: transparent;
                                    border-color    : transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-product-action {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        margin-bottom  : 82px;

        @media #{$maxSmall} {
            flex-direction: column;
            align-items   : center;
        }

        form {
            display      : flex;
            align-items  : center;
            margin-bottom: 0;

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
                margin-bottom : 20px;
            }

            .form-control {
                border-radius   : 8px 8px 0 8px;
                margin-bottom   : 0;
                border          : 1px solid var(--global--color-gray-light);
                background-color: var(--global--color-white);
                margin-right    : 10px;
                height          : 42px;
                width           : 250px;
                line-height     : 42px;
                margin-bottom   : 0px;

                @media #{$maxSmall} {
                    margin-bottom: 15px;
                    margin-right : 0;
                }
            }

            .btn {
                margin-top : 0;
                flex-shrink: 0;
            }
        }

        .btn {
            width       : 134px;
            height      : 42px;
            line-height : 39px;
            margin-right: 10px;

            @media screen and (max-width: 575.98px) {
                margin-top: 20px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        div {
            display    : flex;
            align-items: center;
        }
    }

    .cart-total-amount {
        h5 {
            color        : var(--global--color-heading);
            font-size    : 21px;
            font-weight  : 500;
            line-height  : 30px;
            font-family  : var(--global--font-heading);
            margin-bottom: 13px;
            position     : relative;
        }

        .table {
            margin-bottom: 40px;

            tbody {
                .head {
                    font-family   : var(--global--font-heading);
                    font-weight   : 500;
                    font-size     : 17px;
                    color         : var(--global--color-heading);
                    text-transform: capitalize;
                }

                td {
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    font-size    : 17px;
                    color        : var(--global--color-body);
                    border-top   : 0;
                    border-bottom: 1px solid #e5e8ed;
                    padding-left : 0;

                    &.amount {
                        font-weight: 700;
                    }
                }
            }
        }

        .btn {
            width : 270px;
            height: 50px;
        }
    }
}


/* Shop Checkout */
.shop-checkout {
    padding-bottom: 120px;

    .cart-empty,
    .cart-coupon {
        padding         : 30px;
        background-color: #f5f5f5;
        border-top      : 3px solid #1e85be;
        font-size       : 18px;
        font-weight     : 400;
        font-family     : var(--global--font-body);
        line-height     : 1;
        color           : #515151;
        margin-bottom   : 35px;

        a {
            color: var(--global--color-primary);
        }
    }
}

.checkout-widget {
    padding      : 20px;
    margin-bottom: 45px;

    .widget-title {
        margin-bottom : 15px;
        text-transform: capitalize;
    }

    label {
        font-family  : var(--global--font-heading);
        font-weight  : 500;
        font-size    : 16px;
        line-height  : 2;
        margin-bottom: 10px;
        ;
        text-transform: capitalize;
        color         : var(--global--color-heading);
        display       : block;

        .required {
            margin-left: 3px;
            color      : red;
        }
    }

    .form-control {
        margin-bottom: 12px;
    }

    .nice-select {
        display    : flex;
        align-items: center;
        width      : 100%;

        .list {
            width: 100%;
        }
    }
}

.your-order {
    margin-bottom: 53px;
    border-radius: 8px 8px 0 8px;
    overflow     : hidden;

    h5 {
        text-transform: capitalize;
    }

    .table-order {
        background-color: var(--global--color-gray);
        padding         : 30px 30px 12px;

        table {
            width: 100%;

            thead {
                .order-heading {
                    border-bottom: 1px solid #eaeaea;

                    th {
                        font-family   : var(--global--font-body);
                        font-size     : 16px;
                        color         : var(--global--color-heading);
                        font-weight   : 700;
                        text-transform: capitalize;
                        padding-bottom: 3px
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #eaeaea;

                    &:last-child {
                        border-bottom: 0;
                    }

                    td {
                        font-family   : var(--global--font-body);
                        font-size     : 14px;
                        color         : var(--global--color-heading);
                        text-transform: capitalize;
                        font-weight   : 700;
                        padding-top   : 13px;
                        padding-bottom: 13px;

                        &:last-of-type {
                            font-weight: 400;
                            color      : var(--global--color-body)
                        }

                        &.product-name {
                            padding-left: 10px;
                        }
                    }

                    &.cart-total {
                        td {
                            &:last-of-type {
                                font-weight: 700;
                                color      : var(--global--color-heading);
                            }
                        }
                    }
                }
            }
        }
    }
}

.payment {
    background-color: var(--global--color-gray);
    padding         : 33px 15px;
    border-radius   : 8px 8px 0 8px;

    .payment-method {
        margin: 0 15px;

        .no-payment {
            border-top: 3px solid #1e85be;
            padding   : 27px 0;

            p {
                font-family: var(--global--font-body);
                padding    : 0 30px;
                font-size  : 18px;
                line-height: 2;
                font-weight: 500;
                color      : var(--global--color-heading);
            }
        }
    }

    .payment-conditions {
        margin: 0 15px;

        .message {
            padding-top  : 10px;
            font-family  : var(--global--font-body);
            font-size    : 17px;
            line-height  : 2;
            color        : var(--global--color-body);
            font-weight  : 400;
            margin-bottom: 30px;
        }

        label {
            text-transform: capitalize;
            font-weight   : 500;
            color         : var(--global--color-heading);
            font-size     : 16px;
            margin-bottom : 30px;

            a {
                color: var(--global--color-primary);
            }
        }
    }
}

.login {
    padding-bottom: 140px;

    .login-body {
        background-color: var(--global--color-primary-light);
        border-radius   : 8px 8px 8px 0;
        padding         : 40px;

        label {
            font-family  : var(--global--font-heading);
            font-weight  : 500;
            font-size    : 16px;
            line-height  : 2;
            margin-bottom: 5px;
            ;
            text-transform: capitalize;
            color         : #222222;

            .required {
                margin-left: 3px;
                color      : red;
            }
        }

        .form-control {
            background-color: var(--global--color-white);
            margin-bottom   : 8px;
        }

        .check {
            margin-top   : 10px;
            margin-bottom: 5px;

            label {
                margin-left: 5px;
            }
        }

        .forget {
            margin-top : 13px;
            font-family: var(--global--font-body);
            font-size  : 15px;
            font-weight: 700;
            color      : var(--global--color-heading);

            &:hover {
                color: var(--global--color-primary);
            }
        }
    }
}

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .product-item {
        max-width: 270px;
        margin   : 0 auto 30px;
    }

    .product-options2 {
        span {
            display      : inline-block;
            min-width    : 50px;
            margin-bottom: 10px;
        }
    }


}

@media only screen and (min-width: 768px) and (max-width: 991px) {}

@media only screen and (min-width: 992px) and (max-width: 1200px) {}