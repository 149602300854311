/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.btn {
    display            : flex;
    justify-content    : center;
    align-items        : center;
    font-family        : var(--global--font-secondary);
    position           : relative;
    z-index            : 2;
    font-size          : 15px;
    font-weight        : 700;
    text-transform     : capitalize;
    padding            : 0;
    border             : none;
    width              : 170px;
    height             : 50px;
    transition         : .24s cubic-bezier(.37, .31, .31, .9);
    transition-property: background, color;
    border-radius      : 8px 8px 8px 0;

    &.btn-radius-right {
        border-radius: 8px 8px 0 8px;
    }

    &.no-shadow {
        box-shadow: none;
    }

    &:focus,
    &.active,
    &:active {
        box-shadow: none;
        outline   : none;
    }

    i {
        margin-right: 7px;
        transition  : all 0.3s ease-in-out;
    }

    &::before {
        content         : '';
        position        : absolute;
        width           : calc(100%);
        height          : calc(100%);
        top             : 0px;
        left            : 0px;
        z-index         : -1;
        transform       : scaleX(0);
        transform-origin: right center;
        transition      : transform .24s cubic-bezier(.37, .31, .31, .9);
        border-radius   : inherit;
    }

    &:hover {
        &::before {
            transform       : scaleX(1);
            transform-origin: left center;
        }
    }
}

.btn-line {

    &:hover,
    &:active,
    &:focus {
        .line {
            span {
                opacity   : 0;
                visibility: hidden;
            }

            &::after {
                opacity   : 1;
                visibility: visible;
            }
        }
    }

    .line {
        display        : flex;
        justify-content: center;
        align-items    : center;
        width          : 12px;
        position       : relative;

        span {
            display         : block;
            width           : 12px;
            height          : 2px;
            background-color: var(--global--color-white);
            opacity         : 1;
            visibility      : visible;
            transition      : 0.3s ease-in-out;
        }

        &::after {
            position   : absolute;
            top        : 50%;
            transform  : translateY(-50%);
            left       : 0;
            content    : '\e901';
            font-family: 'icomoon';
            font-size  : 10px;
            color      : var(--global--color-white);
            display    : inline-block;
            opacity    : 0;
            visibility : hidden;
            transition : 0.3s ease-in-out;
        }
    }

    &.btn-line-before {
        .line {
            margin-right: 10px;
        }
    }

    &.btn-line-after {
        .line {
            margin-left: 10px;
        }
    }

    &.btn-line-inversed {
        &:hover {
            .line {
                &::after {
                    color: var(--global--color-white);
                }
            }
        }

        .line {
            span {
                background-color: var(--global--color-secondary);
            }

            &::after {
                color: var(--global--color-white);
            }
        }
    }
}

/* Button Primary */
.btn--primary {
    background-color: var(--global--color-primary);
    color           : var(--global--color-white);

    &::before {
        background-color: var(--global--color-secondary);
    }

    &:active,
    &:focus,
    &:hover {
        border-color: var(--global--color-secondary);
        color       : var(--global--color-white);
    }

    &.btn--inversed {
        &::before {
            background-color: var(--global--color-white);
        }

        &:active,
        &:focus,
        &:hover {
            border-color: var(--global--color-white);
            color       : var(--global--color-heading);
        }
    }
}

/* Button Secondary*/
.btn--secondary {
    background-color: var(--global--color-secondary);
    color           : var(--global--color-white);

    &::before {
        background-color: var(--global--color-primary);
    }

    &:active,
    &:focus,
    &:hover {
        color       : var(--global--color-white);
        border-color: var(--global--color-primary);
    }

    &.btn--inversed {
        &::before {
            background-color: var(--global--color-heading-darken);
        }

        &:active,
        &:focus,
        &:hover {
            color       : var(--global--color-white);
            border-color: var(--global--color-heading-darken);
        }
    }
}

/* Button White */
.btn--white {
    background-color: var(--global--color-white);
    color           : var(--global--color-secondary);
    border-color    : var(--global--color-white);

    &::before {
        background-color: var(--global--color-secondary);
    }

    &:active,
    &:focus,
    &:hover {
        color       : var(--global--color-white);
        border-color: var(--global--color-secondary);
    }
}

/* Button Transparent */
.btn--transparent {
    background-color   : transparent;
    color              : var(--global--color-white);
    border             : 2px solid var(--global--color-white);
    transition-property: background, color, border-color;

    &::before {
        background-color: var(--global--color-primary);
        width           : calc(100% + 4px);
        height          : calc(100% + 4px);
        top             : -2px;
        left            : -2px;
    }

    &:active,
    &:focus,
    &:hover {
        color       : var(--global--color-white);
        border-color: var(--global--color-primary);
    }

    &.btn--inversed {
        &::before {
            background-color: var(--global--color-secondary);
        }

        &:active,
        &:focus,
        &:hover {
            color       : var(--global--color-white);
            border-color: var(--global--color-secondary);
        }
    }
}

/* Buttons Custom */
.btn--danger {
    background-color: #dd3333;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

.btn--success {
    background-color: #81d742;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

.btn--prime {
    background-color: #1e73be;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

.btn--purble {
    background-color: #8224e3;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

/* Button Fluid */
.btn--block {
    width: 100%;
}

/*Button Auto */

.btn--auto {
    width  : auto;
    padding: 0 20px;
}

/* Button Rounded */
.btn--rounded {
    border-radius: 50px;
}

/* Buttons Shadow */
.btn-shadow {
    box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
}

.btn-shadow-hover {

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
    }
}

/* Button Bordered */
.btn--bordered {
    background-color: transparent;

    &.btn--primary {
        color       : var(--global--color-primary);
        border-color: var(--global--color-primary);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-primary);
            border-color    : var(--global--color-primary);
        }
    }

    &.btn--secondary {
        color       : var(--global--color-secondary);
        border-color: var(--global--color-secondary);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-secondary);
            border-color    : var(--global--color-secondary);
        }
    }

    &.btn--white {
        color       : var(--global--color-white);
        border-color: var(--global--color-white);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-primary);
            background-color: var(--global--color-white);
            border-color    : var(--global--color-white);
        }
    }
}

.btn--slide {
    :last-of-type {
        margin-left: -6px;
    }
}

.btn--slide-wide {
    width: 270px;

    @media screen and (max-width: 360px) {
        width: 240px;
    }
}

.btn-video {
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 220px;
    height          : 96px;
    padding         : 0 30px;
    border-radius   : 32px 32px 0 32px;
    background-color: var(--global--color-heading-darken);
    color           : var(--global--color-white);
    transition      : 300ms ease-in-out;
    cursor          : pointer;
    position        : relative;

    i {
        display         : flex;
        flex-shrink     : 0;
        justify-content : center;
        align-items     : center;
        width           : 54px;
        height          : 36px;
        border-radius   : 6px 6px 0 6px;
        background-color: var(--global--color-white);
        z-index         : 5;
        color           : var(--global--color-secondary);
        font-size       : 16px;
        transition      : 300ms ease-in-out;
        margin-right    : 15px;
    }

    span {
        font-family   : var(--global--font-body);
        font-weight   : 700;
        font-size     : 15px;
        line-height   : 21px;
        text-transform: capitalize;
        color         : var(--global--color-white);
    }

    &.btn-video-2 {
        display         : inline-flex;
        width           : 61px;
        height          : 61px;
        padding         : 0;
        border-radius   : 50%;
        background-color: var(--global--color-white);

        &::before {
            content         : '';
            height          : 100%;
            width           : 100%;
            position        : absolute;
            top             : 0;
            left            : 0;
            right           : 0;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius   : 100%;
            transform       : scale(1);
            opacity         : 1;
            animation       : pulse 1s ease-out 0s infinite;
            z-index         : -1;
        }

        i {
            width           : auto;
            height          : auto;
            margin-right    : 0;
            background-color: transparent;
            color           : var(--global--color-secondary);
        }

        &:hover {
            background-color: var(--global--color-secondary);

            i {
                color: var(--global--color-white);
            }
        }
    }
}

.btn-transparent {
    color           : var(--global--color-primary);
    border-color    : var(--global--color-primary);
    background-color: transparent;

    &:active,
    &:focus,
    &:hover {
        color           : var(--global--color-white);
        background-color: var(--global--color-secondary);
        border-color    : var(--global--color-secondary)
    }
}

/* Buttons Sizes */
.btn-small {
    width      : 170px;
    height     : 41px;
    line-height: 41px;
}

.btn-super {
    width      : 170px;
    height     : 61px;
    line-height: 61px;
}