/*------------------------------------*\
    #COLORS
\*------------------------------------*/

.color-heading {
    color: var(--global--color-heading) !important;
}

.color-theme {
    color: var(--global--color-primary) !important;
}

.color-white {
    color: var(--global--color-white) !important;
}

.color-gray {
    color: var(--global--color-gray) !important;
}