/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/

body,
html {
    overflow-x: hidden;
}

body {
    background-color: var(--global--color-white);
    font-family     : var(--global--font-body);
    font-size       : 14px;
    font-weight     : 400;
    color           : var(--global--color-body);
    margin          : 0;

    @media #{$minLarge} {
        cursor: none;
    }
}

.wrapper {
    background-color: var(--global--color-white);

    &.sidearea-active {
        .sidearea-overlay {
            &::before {
                visibility: visible;
                opacity   : 1;
                right     : 0;
            }
        }
    }
}

/* Cursor */
.cursor {
    @media #{$maxTablet} {
        display: none;
    }

    .cursor__inner {
        z-index       : 9999;
        pointer-events: none;
        position      : absolute;
        top           : 0;
        left          : 0;
        mix-blend-mode: difference;
        border-radius : 50%;

        &.cursor__inner--dot {
            width     : 6px;
            height    : 6px;
            background: var(--global--color-white);
        }

        &.cursor__inner--circle {
            width : 20px;
            height: 20px;
            border: 1px solid var(--global--color-white);
        }
    }
}

/* Cursor */
::selection {
    background-color: var(--global--color-primary);
    color           : var(--global--color-white);
    text-shadow     : none;
}

a {
    color          : var(--global--color-primary);
    transition     : all 300ms ease-in-out;
    text-decoration: none;

    &:hover {
        //color          : darken(var(--global--color-primary), 10%);
        text-decoration: none;
    }
}

textarea {
    resize: none;
}

.fullscreen {
    height: 100vh !important;
}

section {
    background-color: var(--global--color-white);
    padding         : 120px 0;
    overflow        : hidden;

    @media only screen and (max-width: 767px) {
        padding: 70px 0;
    }
}

hr {
    background-color: #eaeaea;
    border-color    : #eaeaea;
    margin          : 0;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
    .fullscreen {
        height: auto !important;
    }
}