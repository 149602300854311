/*------------------------------------*\
#Skills
\*------------------------------------*/

.skills {
    .progressbar {
        margin-bottom: 29px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .progress-title {
            display      : block;
            position     : relative;
            width        : 100%;
            margin-bottom: 7px;
            line-height  : 1;

            .title {
                font-family   : var(--global--font-body);
                color         : var(--global--color-heading);
                font-size     : 15px;
                font-weight   : 700;
                line-height   : 1;
                margin-bottom : 2px;
                text-transform: capitalize;
            }

            .value {
                font-family   : var(--global--font-body);
                color         : var(--global--color-body);
                font-weight   : 700;
                font-size     : 15px;
                line-height   : 1px;
                text-transform: uppercase;
                bottom        : 7px;
                position      : absolute;
                margin-left   : -27px;
                opacity       : 0;
                transition    : opacity 300ms linear;
            }
        }

        .progress {
            height          : 12px;
            background-color: transparent;
            box-shadow      : none;
            padding         : 0;
            width           : 100%;
            border-bottom   : 2px solid #dcdee2;
            margin-bottom   : 0;
            border-radius   : 0;

            .progress-bar {
                height          : 7px;
                background-color: var(--global--color-heading-darken);
                border-radius   : 4px;
                width           : 0;
            }
        }
    }




}

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .skills .heading {
        margin-bottom: 30px;
    }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .skills .heading {
        margin-bottom: 30px;
    }
}