/*-----------------------------------------------------------------------------------

	Theme Name: Medisch
	Theme URI: http://themeforest.net/user/zytheme
	Description: Medisch is a pixel perfect creative medical HTML5 tempalte based on bootstrap 5, designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author: zytheme
	Author URI: http://themeforest.net/user/zytheme
	Version: 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

	1.  Global Styles
	2.  Typography
	3.	Color
	4.	Align
	5.	Grid
	6.	Blockquotes
	7.	Background
	8.	Breadcrumbs
	9.	Buttons
	10. Forms
	11. Icons
	12. List
	13. Tabs
	14. Accordions
	15. Pagination
	16. Alerts
	17. Backtop
	18. Page Loading
	19. Header
	20. Menu
	21. Module
	22. Topbar
	23. Page Title
	24. Carousel
	25. Heading
	26. Hero
	27. Blog
	28. Shop
	29. Team
	30. Timetable
	31. Skills
	32. About
	33. Features
	34. Sidebar
	35. Pricing
	36. Donations
	37. Processes
	38. Contact
	39. Clients
	40. Testimonial
	41. Video
	42. Action
	43. 404
	44. Services
	45. Action
	46. Departments
	47. Footer

/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/
/*------------------------------------*\
    #CSS VARIBALES
\*------------------------------------*/
:root {
  --global--color-primary: #13c5dd;
  --global--color-primary-darken: #263967;
  --global--color-primary-light: #e6f8fb;
  --global--color-secondary: #283b6a;
  --global--color-heading: #26365e;
  --global--color-heading-darken: #435ba1;
  --global--color-heading-light: #1d2a4d;
  --global--color-body: #848e9f;
  --global--color-white: #ffffff;
  --global--color-gray: #f9f9f9;
  --global--color-gray-darken: #788fc8;
  --global--color-gray-light: #e6e8eb;
  --global--font-body: 'Roboto', sans-serif;
  --global--font-heading: 'Rubik', sans-serif;
  --global--font-secondary: 'Heebo', sans-serif; }

/*------------------------------------*\
    #RESET
\*------------------------------------*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
dl,
dt,
dd,
ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

*,
:active,
:focus {
  outline: none !important; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

textarea {
  resize: none; }

::selection {
  text-shadow: none; }

::-moz-selection {
  text-shadow: none; }

::-webkit-selection {
  text-shadow: none; }

.form-control {
  box-shadow: none; }

button {
  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0; }

textarea {
  resize: none; }

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
body,
html {
  overflow-x: hidden; }

body {
  background-color: var(--global--color-white);
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 400;
  color: var(--global--color-body);
  margin: 0; }
  @media screen and (min-width: 992px) {
    body {
      cursor: none; } }

.wrapper {
  background-color: var(--global--color-white); }
  .wrapper.sidearea-active .sidearea-overlay::before {
    visibility: visible;
    opacity: 1;
    right: 0; }

/* Cursor */
@media screen and (max-width: 991.98px) {
  .cursor {
    display: none; } }

.cursor .cursor__inner {
  z-index: 9999;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  border-radius: 50%; }
  .cursor .cursor__inner.cursor__inner--dot {
    width: 6px;
    height: 6px;
    background: var(--global--color-white); }
  .cursor .cursor__inner.cursor__inner--circle {
    width: 20px;
    height: 20px;
    border: 1px solid var(--global--color-white); }

/* Cursor */
::selection {
  background-color: var(--global--color-primary);
  color: var(--global--color-white);
  text-shadow: none; }

a {
  color: var(--global--color-primary);
  transition: all 300ms ease-in-out;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

textarea {
  resize: none; }

.fullscreen {
  height: 100vh !important; }

section {
  background-color: var(--global--color-white);
  padding: 120px 0;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    section {
      padding: 70px 0; } }

hr {
  background-color: #eaeaea;
  border-color: #eaeaea;
  margin: 0; }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .fullscreen {
    height: auto !important; } }

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--global--color-heading);
  font-family: var(--global--font-heading);
  font-weight: 700;
  margin: 0 0 28px;
  line-height: 1; }

.h1,
h1 {
  font-size: 52px; }

.h2,
h2 {
  font-size: 42px; }

.h3,
h3 {
  font-size: 38px; }

.h4,
h4 {
  font-size: 28px; }

.h5,
h5 {
  font-size: 24px; }

.h6,
h6 {
  font-size: 18px; }

p {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px; }

.lead {
  font-size: 16px;
  line-height: 1.8; }

/* Aligning Text */
.text--left {
  text-align: left !important; }

.text--right {
  text-align: right !important; }

.text--center {
  text-align: center !important; }

.text--just {
  text-align: justify !important; }

.align--top {
  vertical-align: top; }

.align--bottom {
  vertical-align: bottom; }

.align--middle {
  vertical-align: middle; }

.align--baseline {
  vertical-align: baseline; }

/* Weight Text */
.bold {
  font-weight: bold !important; }

.regular {
  font-weight: normal !important; }

.medium {
  font-weight: 500 !important; }

.italic {
  font-style: normal; }

.break-word {
  word-wrap: break-word; }

.no-wrap {
  white-space: nowrap; }

/* Text Color */
.text-white {
  color: var(--global--color-white) !important; }

.text-gray {
  color: var(--global--color-gray) !important; }

.text-black {
  color: var(--global--color-heading); }

.text-theme {
  color: var(--global--color-primary) !important; }

.text--capitalize {
  text-transform: capitalize !important; }

.text--uppercase {
  text-transform: uppercase !important; }

.font-heading {
  font-family: var(--global--font-heading); }

.font-heading {
  font-family: var(--global--font-heading); }

.font-18 {
  font-size: 24px; }

.font-16 {
  font-size: 16px; }

.font-20 {
  font-size: 20px; }

.font-40 {
  font-size: 40px; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  text-center-xs {
    text-align: center !important; } }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  text-center-sm {
    text-align: center !important; } }

.higlighted-style1 {
  background-color: var(--global--color-primary);
  color: var(--global--color-white);
  padding: 5px 0; }

.higlighted-style2 {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
  padding: 5px 0; }

.higlighted-style3 {
  background-color: var(--global--color-gray);
  color: var(--global--color-primary);
  padding: 5px 0; }

/*------------------------------------*\
    #COLORS
\*------------------------------------*/
.color-heading {
  color: var(--global--color-heading) !important; }

.color-theme {
  color: var(--global--color-primary) !important; }

.color-white {
  color: var(--global--color-white) !important; }

.color-gray {
  color: var(--global--color-gray) !important; }

/*------------------------------------*\
    #Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 20px; }

/* All margin */
.m-0 {
  margin: 0 !important; }

.m-xs {
  margin: 10px; }

.m-sm {
  margin: 20px; }

.m-md {
  margin: 40px; }

.m-lg {
  margin: 80px; }

/* top margin */
.mt-0 {
  margin-top: 0; }

.mt-xs {
  margin-top: 10px; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-150 {
  margin-top: 150px; }

/* bottom margin */
.mb-0 {
  margin-bottom: 0 !important; }

.mb-15 {
  margin-bottom: 15px; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-55 {
  margin-bottom: 50px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

/* right margin */
.mr-0 {
  margin-right: 0; }

.mr-25 {
  margin-right: 25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-50 {
  margin-right: 50px; }

.mr-60 {
  margin-right: 60px; }

.mr-150 {
  margin-right: 150px; }

/* left margin */
.ml-0 {
  margin-left: 0; }

.ml-xs {
  margin-left: 10px; }

.ml-sm {
  margin-left: 20px; }

.ml-md {
  margin-left: 40px; }

.ml-lg {
  margin-left: 80px; }

.ml-30 {
  margin-left: 30px !important; }

.ml-50 {
  margin-left: 50px; }

.ml-60 {
  margin-left: 60px; }

.ml-150 {
  margin-left: 150px; }

/* All padding */
.p-0 {
  padding: 0 !important; }

.p-xs {
  padding: 10px; }

.p-sm {
  padding: 20px; }

.p-md {
  padding: 40px; }

.p-lg {
  padding: 80px; }

/* top padding */
.pt-0 {
  padding-top: 0 !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-130 {
  padding-top: 130px !important; }

.pt-150 {
  padding-top: 150px !important; }

.pt-180 {
  padding-top: 180px !important; }

/* bottom padding */
.pb-0 {
  padding-bottom: 0 !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-130 {
  padding-bottom: 130px !important; }

.pb-150 {
  padding-bottom: 150px !important; }

/* right padding */
.pr-0 {
  padding-right: 0; }

.pr-xs {
  padding-right: 10px; }

.pr-sm {
  padding-right: 20px; }

.pr-md {
  padding-right: 40px; }

.pr-lg {
  padding-right: 80px; }

.pr-15 {
  padding-right: 15px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-50 {
  padding-right: 50px; }

.pr-60 {
  padding-right: 60px; }

.pr-100 {
  padding-right: 100px !important; }

.pr-150 {
  padding-right: 150px; }

/* left padding */
.pl-0 {
  padding-left: 0; }

.pl-30 {
  padding-left: 30px; }

.pl-50 {
  padding-left: 50px; }

.pl-60 {
  padding-left: 60px; }

.pl-70 {
  padding-left: 70px; }

.pl-100 {
  padding-left: 100px !important; }

.pl-150 {
  padding-left: 150px; }

/* Postions */
.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static; }

/* Zindex*/
.zindex-1 {
  z-index: 1; }

.zindex-2 {
  z-index: 2; }

.zindex-3 {
  z-index: 3; }

/* Borders */
.border-all {
  border: 1px solid var(--global--color-primary); }

.border-top {
  border-top: 1px solid var(--global--color-primary); }

.border-bottom {
  border-bottom: 1px solid var(--global--color-primary); }

.border-right {
  border-right: 1px solid var(--global--color-primary); }

.border-left {
  border-left: 1px solid var(--global--color-primary); }

/* Display */
.inline {
  display: inline; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.hide {
  display: none; }

.flex {
  display: flex; }

@media only screen and (max-width: 767px) {
  .text-center-xs {
    text-align: center !important; }
  .pull-none-xs {
    float: none !important;
    text-align: center !important; }
  .mb-15-xs {
    margin-bottom: 15px; }
  .mb-30-xs {
    margin-bottom: 30px !important; }
  .mb-40-xs {
    margin-bottom: 40px !important; }
  .mb-50-xs {
    margin-bottom: 50px; }
  .mb-60-xs {
    margin-bottom: 60px !important; }
  .p-none-xs {
    padding-right: 0;
    padding-left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-center-sm {
    text-align: center !important; }
  .mb-0-sm {
    margin-bottom: 0; }
  .mb-15-sm {
    margin-bottom: 15px; }
  .mb-30-sm {
    margin-bottom: 30px !important; }
  .mb-40-sm {
    margin-bottom: 40px; }
  .mb-50-sm {
    margin-bottom: 50px; }
  .mb-60-sm {
    margin-bottom: 60px; }
  .pb-15-sm {
    padding-bottom: 15px; }
  .pb-30-sm {
    padding-bottom: 30px; }
  .pb-50-sm {
    padding-bottom: 50px; }
  .pb-60-sm {
    padding-bottom: 60px; }
  .p-none-sm {
    padding-right: 0;
    padding-left: 0; }
  .pull-none-sm {
    float: none !important;
    text-align: center !important; } }

.border-b {
  border-bottom: 1px solid var(--global--color-gray); }

.mt--80 {
  margin-top: -80px; }

.p-0 {
  padding: 0; }

.pt-200 {
  padding-top: 200px; }

.pt-10 {
  padding-top: 10px; }

.pt-20 {
  padding-top: 20px; }

.pt-25 {
  padding-top: 25px !important; }

.pt-55 {
  padding-top: 55px; }

.pt-90 {
  padding-top: 90px; }

.pt-120 {
  padding-top: 120px; }

.pt-130 {
  padding-top: 130px; }

.pt-145 {
  padding-top: 145px; }

.pt-180 {
  padding-top: 180px; }

.pt-210 {
  padding-top: 210px; }

.pt-225 {
  padding-top: 225px; }

.pt-240 {
  padding-top: 240px; }

.pt-250 {
  padding-top: 250px; }

.pt-260 {
  padding-top: 260px; }

.pt-265 {
  padding-top: 265px; }

.pt-300 {
  padding-top: 300px; }

.pt-355 {
  padding-top: 355px; }

.pt-370 {
  padding-top: 370px; }

.pt-380 {
  padding-top: 380px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-35 {
  padding-bottom: 35px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-115 {
  padding-bottom: 115px; }

.pb-120 {
  padding-bottom: 120px; }

.pb-130 {
  padding-bottom: 130px; }

.pb-170 {
  padding-bottom: 170px; }

.pb-190 {
  padding-bottom: 190px; }

.pb-200 {
  padding-bottom: 200px; }

.mt-45 {
  margin-top: 45px; }

.mt-35 {
  margin-top: 35px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mt--7 {
  margin-top: -7px; }

.overflow-visible {
  overflow: visible; }

.mr-10 {
  margin-right: 10px; }

.mr-20 {
  margin-right: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

/*------------------------------------*\
    #Grid
\*------------------------------------*/
.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-5ths {
  width: 20%;
  float: left; }

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left; } }

.col-content {
  padding: 120px 100px 110px 120px; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .col-content {
    padding: 125px 18px 0 !important; } }

/* Postion Helpers */
.pos-fixed {
  position: fixed; }

.pos-relative {
  position: relative; }

.pos-absolute {
  position: absolute; }

.pos-static {
  position: static; }

.pos-top {
  top: 0; }

.pos-bottom {
  bottom: 0; }

.pos-right {
  right: 0; }

.pos-left {
  left: 0; }

.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.height-700 {
  height: 700px !important; }

.height-500 {
  height: 500px !important; }

.height-800 {
  height: 800px !important; }

@media only screen and (min-width: 992px) {
  .hidden-lg,
  .hidden-md {
    display: none; } }

@media only screen and (min-width: 768px) {
  .col-lg-5ths {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

/*------------------------------------*\
    #Blockquotes
\*------------------------------------*/
.blockquote,
blockquote {
  font-size: 20px;
  font-family: var(--global--font-heading);
  color: var(--global--color-body);
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  padding: 30px 0 30px 30px;
  position: relative;
  border-left: 6px solid var(--global--color-heading); }
  .blockquote .quote-author,
  blockquote .quote-author {
    font-family: var(--global--font-heading);
    display: block;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    color: var(--global--color-heading);
    margin-top: 18px; }
  .blockquote .author,
  blockquote .author {
    font-family: var(--global--font-body);
    display: block;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    line-height: 1;
    color: var(--global--color-heading);
    margin-top: 18px; }

.blockquote-1 {
  border-left: 6px solid var(--global--color-heading); }

.blockquote-2 {
  position: relative;
  border-left: none; }
  .blockquote-2::before, .blockquote-2::after {
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    position: absolute;
    color: var(--global--color-primary);
    line-height: 1.8;
    font-weight: 600; }
  .blockquote-2::before {
    content: "\f10d";
    top: 0;
    left: 0; }
  .blockquote-2::after {
    content: "\f10e";
    right: 0;
    bottom: 0; }

.blockquote-3 {
  background-color: var(--global--color-heading); }
  .blockquote-3 .author {
    color: var(--global--color-body); }

/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
.bg-theme {
  background-color: var(--global--color-primary) !important; }

.bg-gray {
  background-color: var(--global--color-gray) !important; }

.bg-dark-gray {
  background-color: #3a3a3a; }

.bg-dark {
  background-color: #222222 !important; }

/* Background Image */
.bg-section {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%; }

.bg-parallax {
  background-attachment: fixed; }

.bg-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1; }

.bg-overlay-2:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.bg-overlay-light:before {
  background-color: var(--global--color-white);
  opacity: 0.8; }

.bg-overlay-dark:before {
  background-color: rgba(29, 42, 77, 0.45); }

.bg-overlay-dark2:before {
  background-color: #1B1A1A5e; }

.bg-overlay-dark-slider {
  background-position-y: 150px; }
  @media screen and (max-width: 991.98px) {
    .bg-overlay-dark-slider {
      background-position-y: 100px; } }
  .bg-overlay-dark-slider:before {
    background-image: linear-gradient(180deg, var(--global--color-heading-light) 34%, #1B1A1A 84%);
    opacity: 0.15; }

.bg-overlay-theme:before {
  background-image: linear-gradient(-90deg, #13C1D9BF 18%, var(--global--color-primary) 70%); }

.bg-overlay-theme2:before {
  background-image: linear-gradient(0deg, rgba(6, 22, 51, 0) 0%, #1d294d 0%); }

.bg-overlay-theme3:before {
  background: var(--global--color-heading-light) F0; }

.bg-overlay-video:before {
  background-image: linear-gradient(180deg, rgba(6, 23, 51, 0) 0%, rgba(29, 42, 77, 0) 62%, #1d2a4d 97%); }

/* Background Video */
.bg-video {
  position: relative; }
  .bg-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: rotateZ(0); }
  .bg-video h2 {
    text-transform: capitalize;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 5; }

.no-shadow {
  box-shadow: none !important; }

.bg-animate {
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: animatedBackground 30s linear infinite; }

@-webkit-keyframes animatedBackground {
  from {
    background-position: 0 0; }
  to {
    background-position: 100% 0; } }

@keyframes animatedBackground {
  from {
    background-position: 0 0; }
  to {
    background-position: 100% 0; } }

/* Elements Background */
.el-bg {
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .el-bg h2 {
    color: var(--global--color-white); }

/*------------------------------------*\
    #BREADCRUMB
\*------------------------------------*/
/* Breadcrumb Style 1 */
.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  border-radius: 0;
  color: #e5e4e4;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  display: block; }
  .breadcrumb > li {
    display: inline-block; }
    .breadcrumb > li + li:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      padding: 0 10px;
      color: var(--global--color-gray);
      content: "\f054"; }
    .breadcrumb > li a {
      color: var(--global--color-gray); }
      .breadcrumb > li a:hover {
        color: var(--global--color-primary); }
  .breadcrumb > .active {
    color: var(--global--color-heading-darken); }

/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--global--font-secondary);
  position: relative;
  z-index: 2;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0;
  border: none;
  width: 170px;
  height: 50px;
  transition: 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
  transition-property: background, color;
  border-radius: 8px 8px 8px 0; }
  .btn.btn-radius-right {
    border-radius: 8px 8px 0 8px; }
  .btn.no-shadow {
    box-shadow: none; }
  .btn:focus, .btn.active, .btn:active {
    box-shadow: none;
    outline: none; }
  .btn i {
    margin-right: 7px;
    transition: all 0.3s ease-in-out; }
  .btn::before {
    content: '';
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    top: 0px;
    left: 0px;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
    border-radius: inherit; }
  .btn:hover::before {
    transform: scaleX(1);
    transform-origin: left center; }

.btn-line:hover .line span, .btn-line:active .line span, .btn-line:focus .line span {
  opacity: 0;
  visibility: hidden; }

.btn-line:hover .line::after, .btn-line:active .line::after, .btn-line:focus .line::after {
  opacity: 1;
  visibility: visible; }

.btn-line .line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  position: relative; }
  .btn-line .line span {
    display: block;
    width: 12px;
    height: 2px;
    background-color: var(--global--color-white);
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease-in-out; }
  .btn-line .line::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '\e901';
    font-family: 'icomoon';
    font-size: 10px;
    color: var(--global--color-white);
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out; }

.btn-line.btn-line-before .line {
  margin-right: 10px; }

.btn-line.btn-line-after .line {
  margin-left: 10px; }

.btn-line.btn-line-inversed:hover .line::after {
  color: var(--global--color-white); }

.btn-line.btn-line-inversed .line span {
  background-color: var(--global--color-secondary); }

.btn-line.btn-line-inversed .line::after {
  color: var(--global--color-white); }

/* Button Primary */
.btn--primary {
  background-color: var(--global--color-primary);
  color: var(--global--color-white); }
  .btn--primary::before {
    background-color: var(--global--color-secondary); }
  .btn--primary:active, .btn--primary:focus, .btn--primary:hover {
    border-color: var(--global--color-secondary);
    color: var(--global--color-white); }
  .btn--primary.btn--inversed::before {
    background-color: var(--global--color-white); }
  .btn--primary.btn--inversed:active, .btn--primary.btn--inversed:focus, .btn--primary.btn--inversed:hover {
    border-color: var(--global--color-white);
    color: var(--global--color-heading); }

/* Button Secondary*/
.btn--secondary {
  background-color: var(--global--color-secondary);
  color: var(--global--color-white); }
  .btn--secondary::before {
    background-color: var(--global--color-primary); }
  .btn--secondary:active, .btn--secondary:focus, .btn--secondary:hover {
    color: var(--global--color-white);
    border-color: var(--global--color-primary); }
  .btn--secondary.btn--inversed::before {
    background-color: var(--global--color-heading-darken); }
  .btn--secondary.btn--inversed:active, .btn--secondary.btn--inversed:focus, .btn--secondary.btn--inversed:hover {
    color: var(--global--color-white);
    border-color: var(--global--color-heading-darken); }

/* Button White */
.btn--white {
  background-color: var(--global--color-white);
  color: var(--global--color-secondary);
  border-color: var(--global--color-white); }
  .btn--white::before {
    background-color: var(--global--color-secondary); }
  .btn--white:active, .btn--white:focus, .btn--white:hover {
    color: var(--global--color-white);
    border-color: var(--global--color-secondary); }

/* Button Transparent */
.btn--transparent {
  background-color: transparent;
  color: var(--global--color-white);
  border: 2px solid var(--global--color-white);
  transition-property: background, color, border-color; }
  .btn--transparent::before {
    background-color: var(--global--color-primary);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px; }
  .btn--transparent:active, .btn--transparent:focus, .btn--transparent:hover {
    color: var(--global--color-white);
    border-color: var(--global--color-primary); }
  .btn--transparent.btn--inversed::before {
    background-color: var(--global--color-secondary); }
  .btn--transparent.btn--inversed:active, .btn--transparent.btn--inversed:focus, .btn--transparent.btn--inversed:hover {
    color: var(--global--color-white);
    border-color: var(--global--color-secondary); }

/* Buttons Custom */
.btn--danger {
  background-color: #dd3333;
  color: var(--global--color-white); }
  .btn--danger:active, .btn--danger:focus, .btn--danger:hover {
    background-color: var(--global--color-secondary);
    color: var(--global--color-white);
    border-color: var(--global--color-secondary); }

.btn--success {
  background-color: #81d742;
  color: var(--global--color-white); }
  .btn--success:active, .btn--success:focus, .btn--success:hover {
    background-color: var(--global--color-secondary);
    color: var(--global--color-white);
    border-color: var(--global--color-secondary); }

.btn--prime {
  background-color: #1e73be;
  color: var(--global--color-white); }
  .btn--prime:active, .btn--prime:focus, .btn--prime:hover {
    background-color: var(--global--color-secondary);
    color: var(--global--color-white);
    border-color: var(--global--color-secondary); }

.btn--purble {
  background-color: #8224e3;
  color: var(--global--color-white); }
  .btn--purble:active, .btn--purble:focus, .btn--purble:hover {
    background-color: var(--global--color-secondary);
    color: var(--global--color-white);
    border-color: var(--global--color-secondary); }

/* Button Fluid */
.btn--block {
  width: 100%; }

/*Button Auto */
.btn--auto {
  width: auto;
  padding: 0 20px; }

/* Button Rounded */
.btn--rounded {
  border-radius: 50px; }

/* Buttons Shadow */
.btn-shadow {
  box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15); }

.btn-shadow-hover:hover, .btn-shadow-hover:active, .btn-shadow-hover:focus {
  box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15); }

/* Button Bordered */
.btn--bordered {
  background-color: transparent; }
  .btn--bordered.btn--primary {
    color: var(--global--color-primary);
    border-color: var(--global--color-primary); }
    .btn--bordered.btn--primary:active, .btn--bordered.btn--primary:focus, .btn--bordered.btn--primary:hover {
      color: var(--global--color-white);
      background-color: var(--global--color-primary);
      border-color: var(--global--color-primary); }
  .btn--bordered.btn--secondary {
    color: var(--global--color-secondary);
    border-color: var(--global--color-secondary); }
    .btn--bordered.btn--secondary:active, .btn--bordered.btn--secondary:focus, .btn--bordered.btn--secondary:hover {
      color: var(--global--color-white);
      background-color: var(--global--color-secondary);
      border-color: var(--global--color-secondary); }
  .btn--bordered.btn--white {
    color: var(--global--color-white);
    border-color: var(--global--color-white); }
    .btn--bordered.btn--white:active, .btn--bordered.btn--white:focus, .btn--bordered.btn--white:hover {
      color: var(--global--color-primary);
      background-color: var(--global--color-white);
      border-color: var(--global--color-white); }

.btn--slide :last-of-type {
  margin-left: -6px; }

.btn--slide-wide {
  width: 270px; }
  @media screen and (max-width: 360px) {
    .btn--slide-wide {
      width: 240px; } }

.btn-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 96px;
  padding: 0 30px;
  border-radius: 32px 32px 0 32px;
  background-color: var(--global--color-heading-darken);
  color: var(--global--color-white);
  transition: 300ms ease-in-out;
  cursor: pointer;
  position: relative; }
  .btn-video i {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 36px;
    border-radius: 6px 6px 0 6px;
    background-color: var(--global--color-white);
    z-index: 5;
    color: var(--global--color-secondary);
    font-size: 16px;
    transition: 300ms ease-in-out;
    margin-right: 15px; }
  .btn-video span {
    font-family: var(--global--font-body);
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    text-transform: capitalize;
    color: var(--global--color-white); }
  .btn-video.btn-video-2 {
    display: inline-flex;
    width: 61px;
    height: 61px;
    padding: 0;
    border-radius: 50%;
    background-color: var(--global--color-white); }
    .btn-video.btn-video-2::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      transform: scale(1);
      opacity: 1;
      animation: pulse 1s ease-out 0s infinite;
      z-index: -1; }
    .btn-video.btn-video-2 i {
      width: auto;
      height: auto;
      margin-right: 0;
      background-color: transparent;
      color: var(--global--color-secondary); }
    .btn-video.btn-video-2:hover {
      background-color: var(--global--color-secondary); }
      .btn-video.btn-video-2:hover i {
        color: var(--global--color-white); }

.btn-transparent {
  color: var(--global--color-primary);
  border-color: var(--global--color-primary);
  background-color: transparent; }
  .btn-transparent:active, .btn-transparent:focus, .btn-transparent:hover {
    color: var(--global--color-white);
    background-color: var(--global--color-secondary);
    border-color: var(--global--color-secondary); }

/* Buttons Sizes */
.btn-small {
  width: 170px;
  height: 41px;
  line-height: 41px; }

.btn-super {
  width: 170px;
  height: 61px;
  line-height: 61px; }

/*------------------------------------*\
    #FORMS
\*------------------------------------*/
form {
  position: relative; }
  form .col-12 {
    position: relative; }

.form-control {
  color: var(--global--color-heading);
  font-size: 14px;
  font-weight: 400;
  height: 60px;
  line-height: 59px;
  border-radius: 8px 8px 8px 0;
  border: none;
  background-color: var(--global--color-primary-light);
  padding-left: 25px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: none;
  transition: all 0.3s ease-in-out; }
  .form-control:focus {
    background-color: var(--global--color-primary-light);
    box-shadow: none; }
  .form-control::-webkit-input-placeholder {
    font-size: 14px;
    font-family: var(--global--font-body);
    color: var(--global--color-heading);
    text-transform: capitalize; }
  .form-control:-moz-placeholder {
    font-size: 14px;
    font-family: var(--global--font-body);
    color: var(--global--color-heading);
    text-transform: capitalize; }
  .form-control::-moz-placeholder {
    font-size: 14px;
    font-family: var(--global--font-body);
    color: var(--global--color-heading);
    text-transform: capitalize; }
  .form-control:-ms-input-placeholder {
    font-size: 14px;
    font-family: var(--global--font-body);
    color: var(--global--color-heading);
    text-transform: capitalize; }
  .form-control + .error {
    position: absolute;
    bottom: 11px;
    left: 17px;
    color: red; }
  .form-control.error {
    border-color: red; }

.custom-radio-group {
  display: flex; }
  @media screen and (max-width: 767.98px) {
    .custom-radio-group {
      flex-wrap: wrap; } }
  .custom-radio-group .custom-control {
    position: relative;
    min-height: 0;
    margin-bottom: 14px; }
    .custom-radio-group .custom-control .custom-control-input {
      opacity: 0; }
      .custom-radio-group .custom-control .custom-control-input:checked ~ label::after {
        background-color: var(--global--color-primary);
        opacity: 1;
        transform: scale(1); }
    .custom-radio-group .custom-control label {
      font-size: 13px;
      font-family: var(--global--font-body);
      font-weight: 400;
      color: var(--global--color-body);
      margin-bottom: 0;
      line-height: 1.9;
      text-transform: capitalize;
      cursor: pointer;
      padding-left: 10px; }
      @media screen and (max-width: 767px) {
        .custom-radio-group .custom-control label {
          padding-left: 18px; } }
      .custom-radio-group .custom-control label::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        border-radius: 3px;
        border: 3px solid #eaeaea;
        width: 15px;
        height: 15px; }
      .custom-radio-group .custom-control label::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        left: 4px;
        top: 9px;
        background-color: transparent;
        transition: 0.3s ease-in-out;
        opacity: 0;
        transform: scale(0); }

textarea.form-control {
  height: auto; }

.form-select {
  position: relative; }
  .form-select i {
    position: absolute;
    font-size: 19px;
    font-weight: 400;
    line-height: 49px;
    right: 20px;
    top: 0;
    z-index: 1;
    pointer-events: none;
    cursor: pointer;
    color: var(--global--color-body); }
  .form-select select {
    line-height: 1;
    color: var(--global--color-body);
    cursor: pointer;
    appearance: none; }

/* Input Checkbox */
.input-checkbox {
  margin-bottom: 30px; }
  .input-checkbox p {
    display: block;
    position: relative;
    font-size: 13px;
    padding-left: 30px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 0; }

.label-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  z-index: 2; }
  .label-checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .label-checkbox .check-indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    background: transparent;
    border: 1px solid #e1e3e3;
    border-radius: 2px; }
    .label-checkbox .check-indicator:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 1px;
      width: 6px;
      height: 12px;
      border: solid var(--global--color-primary);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }
  .label-checkbox input:checked ~ .check-indicator {
    background: var(--global--color-white); }
    .label-checkbox input:checked ~ .check-indicator:after {
      display: block; }

/*------------------------------------*\
    #Lists
\*------------------------------------*/
ul.list-icons {
  padding: 0;
  font-size: 14px;
  list-style-type: none; }
  ul.list-icons li {
    font-family: var(--global--font-body);
    color: var(--global--color-body);
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    position: relative; }
    ul.list-icons li i {
      font-size: 14px;
      color: var(--global--color-heading);
      margin-right: 10px; }

/*------------------------------------*\
    #Tabs
\*------------------------------------*/
.tabs .tab-content > .tab-pane {
  font-family: var(--global--font-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px; }

.tabs-1 .nav-pills .nav-link.active,
.tabs-1 .nav-pills .show > .nav-link {
  color: var(--global--color-primary);
  border-color: var(--global--color-primary); }

.tabs-1 .nav-pills {
  display: flex;
  width: 100%;
  justify-content: center; }
  .tabs-1 .nav-pills .nav-link {
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: var(--global--color-heading);
    font-family: var(--global--font-heading);
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    padding-top: 0;
    border-radius: 0;
    transition: 0.3s ease-in-out; }
    .tabs-1 .nav-pills .nav-link:hover {
      color: var(--global--color-primary);
      border-color: var(--global--color-primary); }

.tabs-2 .nav-pills .nav-link.active,
.tabs-2 .nav-pills .show > .nav-link {
  color: var(--global--color-primary); }
  .tabs-2 .nav-pills .nav-link.active i,
  .tabs-2 .nav-pills .show > .nav-link i {
    color: var(--global--color-white);
    background-color: var(--global--color-primary); }

.tabs-2 .nav-pills {
  display: flex;
  width: 100%;
  justify-content: center; }
  .tabs-2 .nav-pills .nav-link {
    border: 0;
    background-color: transparent;
    color: var(--global--color-heading);
    font-family: var(--global--font-heading);
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    padding-top: 0;
    border-radius: 0;
    transition: 0.3s ease-in-out; }
    .tabs-2 .nav-pills .nav-link i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      color: var(--global--color-heading);
      transition: 0.3s ease-in-out;
      font-size: 25px;
      margin-bottom: 15px; }
    .tabs-2 .nav-pills .nav-link:hover {
      color: var(--global--color-primary); }
      .tabs-2 .nav-pills .nav-link:hover i {
        color: var(--global--color-white);
        background-color: var(--global--color-primary); }

/*------------------------------------*\
    #Accordion
\*------------------------------------*/
/* Accordion Base */
.accordion {
  border-top: 4px solid var(--global--color-primary);
  margin-bottom: 40px; }
  .accordion .card,
  .accordion .card:first-of-type,
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    padding: 23px 20px 22px;
    box-shadow: none;
    border: 0;
    border-bottom: 2px solid #eaeaea;
    margin-bottom: 0px;
    border-radius: 0px;
    transition: 0.3s ease-in-out;
    text-align: left; }
    .accordion .card.active-acc .card-heading .card-link::before,
    .accordion .card:first-of-type.active-acc .card-heading .card-link::before,
    .accordion .card:not(:first-of-type):not(:last-of-type).active-acc .card-heading .card-link::before {
      color: var(--global--color-primary); }
    .accordion .card .card-heading,
    .accordion .card:first-of-type .card-heading,
    .accordion .card:not(:first-of-type):not(:last-of-type) .card-heading {
      padding: 0; }
      .accordion .card .card-heading .card-link,
      .accordion .card:first-of-type .card-heading .card-link,
      .accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link {
        font-family: var(--global--font-heading);
        font-size: 19px;
        font-weight: 500;
        color: #273a69;
        font-weight: 500;
        text-transform: capitalize;
        position: relative;
        transition: 0.3s ease-in-out;
        display: block;
        padding-left: 0;
        display: flex;
        align-items: center; }
        .accordion .card .card-heading .card-link:hover,
        .accordion .card:first-of-type .card-heading .card-link:hover,
        .accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link:hover {
          color: var(--global--color-primary); }
        .accordion .card .card-heading .card-link::before,
        .accordion .card:first-of-type .card-heading .card-link::before,
        .accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link::before {
          /* symbol for "opening" cards */
          transition: 0.3s ease-in-out;
          font-family: "Font Awesome 5 Free";
          font-weight: 700;
          content: "\f068";
          font-size: 11px;
          margin-right: 20px;
          color: #273a69; }
        .accordion .card .card-heading .card-link.collapsed::before,
        .accordion .card:first-of-type .card-heading .card-link.collapsed::before,
        .accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link.collapsed::before {
          /* symbol for "collapsed" cards */
          content: "\f067"; }
    .accordion .card .card-body,
    .accordion .card:first-of-type .card-body,
    .accordion .card:not(:first-of-type):not(:last-of-type) .card-body {
      font-family: var(--global--font-body);
      color: var(--global--color-body);
      padding: 16px 0 0 31px;
      font-size: 15px;
      font-weight: 400;
      line-height: 25px; }
  .accordion .card:last-of-type {
    margin-bottom: 0px; }
  .accordion.accordion-2 {
    padding-top: 130px;
    padding-bottom: 110px;
    border-top: 0;
    margin-bottom: 0; }
    .accordion.accordion-2 p {
      font-size: 16px;
      line-height: 27px;
      color: var(--global--color-body);
      margin-bottom: 41px; }
    .accordion.accordion-2 .accordion-holder {
      border-top: 4px solid var(--global--color-primary); }
  .accordion.accordion-3 {
    margin-bottom: 70px; }

/*------------------------------------*\
    #PAGINATION
\*------------------------------------*/
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 0; }
  .pagination li {
    margin-right: 10px;
    display: inline-block; }
    .pagination li a,
    .pagination li span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: var(--global--color-white);
      color: var(--global--color-heading);
      font-family: var(--global--font-body);
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0;
      width: 50px;
      height: 50px;
      border-radius: 8px 8px 0 8px;
      border: 2px solid var(--global--color-gray-light);
      transition: 300ms ease-in-out; }
      .pagination li a:hover,
      .pagination li span:hover {
        background-color: var(--global--color-primary-darken);
        color: var(--global--color-white);
        border-color: var(--global--color-primary-darken); }
      .pagination li a i,
      .pagination li span i {
        font-size: 12px; }
      .pagination li a.current,
      .pagination li span.current {
        background-color: var(--global--color-primary-darken);
        color: var(--global--color-white);
        border-color: var(--global--color-primary-darken); }

/*------------------------------------*\
    #ALERT MESSAGE
\*------------------------------------*/
.alerts {
  background-color: var(--global--color-gray);
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 30px; }
  .alerts .alert-icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    background-color: var(--global--color-primary);
    border-radius: 2px 1px 1px 2px;
    text-align: center;
    position: absolute; }
    .alerts .alert-icon i {
      color: var(--global--color-white);
      font-size: 15px;
      font-weight: 400;
      line-height: 28px; }
  .alerts .alert-content {
    padding-left: 68px; }
    .alerts .alert-content h4 {
      color: var(--global--color-primary);
      font-size: 15px;
      font-weight: 800;
      line-height: 32px;
      text-transform: capitalize;
      margin-bottom: 0;
      margin-top: -6px; }
    .alerts .alert-content p {
      font-family: var(--global--font-body);
      color: var(--global--color-body);
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0; }
  .alerts.alert-warning h4 {
    color: #8a6d3b; }
  .alerts.alert-warning .alert-icon {
    background-color: #fcf8e3; }
    .alerts.alert-warning .alert-icon i {
      color: #8a6d3b; }
  .alerts.alert-danger h4 {
    color: #a94442; }
  .alerts.alert-danger .alert-icon {
    background-color: #f2dede; }
    .alerts.alert-danger .alert-icon i {
      color: #a94442; }

.alert {
  font-size: 16px;
  font-family: var(--global--font-body); }

/* Subscribe Alert */
.subscribe-alert {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  margin: 0;
  cursor: pointer;
  transform: translate3d(0, 200%, 0);
  animation: alertfadein .35s ease .5s both; }
  .subscribe-alert .alert {
    margin: 0 auto;
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    border: none;
    border-radius: 0;
    padding: 0 30px;
    color: var(--global--color-white); }
    .subscribe-alert .alert:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      background-color: rgba(0, 0, 0, 0.1);
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      transition: background-color .25s ease;
      content: "\f00d";
      font-family: "Font Awesome 5 Free"; }
    .subscribe-alert .alert.alert-danger {
      background-color: #cc0000; }
    .subscribe-alert .alert.alert-success {
      background-color: #198c19; }

@keyframes alertfadein {
  0% {
    transform: translate3d(0, 100%, 0); }
  100% {
    transform: none; } }

/* Contact Alert */
#contact-result .alert {
  margin: 10px auto;
  font-size: 12px; }

/*------------------------------------*\
    #BACK TO TOP
\*------------------------------------*/
#back-to-top {
  z-index: 999;
  position: fixed;
  right: 40px;
  bottom: 40px;
  opacity: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 300ms ease-in-out;
  background-color: var(--global--color-secondary); }
  #back-to-top:hover {
    background-color: var(--global--color-heading); }
  #back-to-top.show {
    opacity: 1; }
  #back-to-top svg {
    color: var(--global--color-white); }

@media only screen and (max-width: 992px) {
  #back-to-top.show {
    right: 20px;
    bottom: 30px; } }

/*------------------------------------*\
    #Page Loading
\*------------------------------------*/
.preloader {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99999;
  background-color: var(--global--color-white);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }
  .preloader .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear; }
  .preloader .dot1,
  .preloader .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--global--color-heading);
    border-radius: 100%;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out; }
  .preloader .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/*------------------------------------*\
    #Header
\*------------------------------------*/
.header {
  height: 80px; }
  @media screen and (min-width: 992px) {
    .header {
      height: 100px; } }
  .header .navbar-brand {
    padding: 0;
    margin: 0;
    margin-right: 40px; }
    @media (min-width: 1200px) and (max-width: 1350px) {
      .header .navbar-brand {
        margin-right: 20px; } }
    .header .navbar-brand .logo {
      max-width: 100%;
      width: 180px;
      height: 47px; }
      @media screen and (max-width: 991.98px) {
        .header .navbar-brand .logo {
          width: auto; } }
  .header.header-light {
    background-color: var(--global--color-white); }
    .header.header-light .navbar {
      background-color: var(--global--color-white); }
      .header.header-light .navbar .logo-dark {
        display: inline-block; }
        @media screen and (max-width: 991.98px) {
          .header.header-light .navbar .logo-dark {
            display: none; } }
      .header.header-light .navbar .logo-light {
        display: none; }
      .header.header-light .navbar .logo-mobile {
        display: inline-block; }
        @media screen and (min-width: 992px) {
          .header.header-light .navbar .logo-mobile {
            display: none; } }
      .header.header-light .navbar .navbar-nav > li > a {
        color: var(--global--color-secondary); }
        .header.header-light .navbar .navbar-nav > li > a::before {
          background-color: var(--global--color-secondary); }
      .header.header-light .navbar .module .module-icon i {
        color: var(--global--color-secondary); }
        .header.header-light .navbar .module .module-icon i:hover {
          color: var(--global--color-primary); }
  @media screen and (min-width: 768px) {
    .header.header-topbar {
      height: 155px; } }
  @media screen and (max-width: 991.98px) {
    .header.header-topbar .top-bar {
      display: none; } }
  .header.header-topbar .navbar {
    padding: 0 20px; }
    @media screen and (min-width: 1200px) {
      .header.header-topbar .navbar {
        min-height: 105px;
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
        left: auto; } }
    @media screen and (min-width: 1200px) {
      .header.header-topbar .navbar .navbar-nav > li > a {
        line-height: 105px; } }
  .header.header-topbar.header-shadow .navbar {
    box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11); }
  .header.header-topbar.header-topbar3 .navbar-brand {
    margin-right: 25px; }
  .header.header-topbar.header-topbar3 .module-holder .module-search {
    margin-right: 20px;
    padding-right: 20px; }
    @media screen and (max-width: 767.98px) {
      .header.header-topbar.header-topbar3 .module-holder .module-search {
        margin-right: 10px;
        padding-right: 10px; } }
  .header.header-topbar.header-topbar3 .navbar {
    padding-right: 25px;
    padding-left: 25px; }
    @media screen and (min-width: 1200px) {
      .header.header-topbar.header-topbar3 .navbar .navbar-nav > li#departments {
        padding-right: 20px;
        margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar5 .navbar .navbar-brand {
      display: flex;
      align-items: center;
      height: 105px;
      padding-right: 40px;
      margin-right: 0;
      border-right: 1px solid #dadde2; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar5 .navbar .navbar-nav {
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar5 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px;
      order: unset;
      position: relative; }
      .header.header-topbar.header-topbar5 .navbar .navbar-nav > li#departments::before {
        content: unset; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar5 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar5 .module-holder {
      padding-left: 40px;
      border-left: 1px solid #dadde2; } }
  .header.header-topbar.header-topbar5 .module-holder .module-search {
    padding-right: 0;
    margin-right: 30px; }
    .header.header-topbar.header-topbar5 .module-holder .module-search::after {
      content: unset; }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar6 .navbar .navbar-nav {
      margin-left: auto; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar6 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px;
      order: unset;
      position: relative; }
      .header.header-topbar.header-topbar6 .navbar .navbar-nav > li#departments::before {
        content: unset; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar6 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar6 .module-holder {
      margin-left: 30px; } }
  .header.header-topbar.header-topbar6 .module-holder.module-holder-phone .module-search {
    margin-left: 0;
    margin-right: 30px; }
  .header.header-topbar.header-topbar6 .module-holder .module-search {
    padding-right: 0;
    margin-right: 0px;
    margin-left: 30px; }
    .header.header-topbar.header-topbar6 .module-holder .module-search::after {
      content: unset; }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar7 .navbar .navbar-brand {
      display: flex;
      align-items: center;
      height: 105px;
      padding-right: 40px;
      margin-right: 0;
      border-right: 1px solid #dadde2; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar7 .navbar .navbar-brand {
      padding-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar7 .navbar .navbar-nav {
      margin-left: 40px;
      margin-right: auto; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar7 .navbar .navbar-nav {
      margin-left: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar7 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px;
      order: unset;
      position: relative; }
      .header.header-topbar.header-topbar7 .navbar .navbar-nav > li#departments::before {
        content: unset; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar7 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar7 .module-holder .module-contact {
      height: 105px;
      padding-right: 25px;
      border-right: 1px solid #dadde2; } }
  .header.header-topbar.header-topbar7 .module-holder .module-search {
    padding-right: 0;
    margin-right: 0px;
    margin-left: 25px; }
    .header.header-topbar.header-topbar7 .module-holder .module-search::after {
      content: unset; }
    @media screen and (max-width: 1199.98px) {
      .header.header-topbar.header-topbar7 .module-holder .module-search {
        margin-right: 25px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar8 .navbar .navbar-brand {
      display: flex;
      align-items: center;
      height: 105px;
      padding-right: 40px;
      margin-right: 0;
      border-right: 1px solid #dadde2; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar8 .navbar .navbar-brand {
      padding-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar8 .navbar .navbar-nav {
      margin-left: 40px;
      margin-right: auto; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar8 .navbar .navbar-nav {
      margin-left: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar8 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px;
      order: unset;
      position: relative; }
      .header.header-topbar.header-topbar8 .navbar .navbar-nav > li#departments::before {
        content: unset; } }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .header.header-topbar.header-topbar8 .navbar .navbar-nav > li#departments {
      padding-right: 0px;
      margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar8 .module-holder .module-cart .cart-box {
      top: 100%; } }
  .header.header-topbar.header-topbar8 .module-holder .module-search {
    padding-right: 0;
    margin-right: 0px;
    margin-left: 40px; }
    .header.header-topbar.header-topbar8 .module-holder .module-search::after {
      content: unset; }
    @media screen and (max-width: 1199.98px) {
      .header.header-topbar.header-topbar8 .module-holder .module-search {
        margin-right: 25px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar8 .module-contact {
      margin-left: 30px; } }
  @media screen and (min-width: 1200px) {
    .header.header-topbar.header-topbar8 .module-cart {
      height: 105px;
      padding-right: 40px;
      border-right: 1px solid #dadde2;
      margin-right: 0;
      display: flex;
      align-items: center; } }

.header-topbar + .page-title,
.header-topbar + .hero,
.header-topbar + .slider {
  position: relative;
  margin-top: -155px; }

/*------------------------------------*\
    #Menu
\*------------------------------------*/
.header .navbar {
  margin-bottom: 0;
  border-radius: 0;
  border: none;
  z-index: 7777;
  padding: 0;
  transition: all 300ms ease-in-out; }
  .header .navbar.navbar-bordered {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  @media screen and (max-width: 991.98px) {
    .header .navbar.navbar-sticky, .header .navbar.navbar.navbar-fixed {
      border-color: rgba(0, 0, 0, 0.1); } }
  .header .navbar .navbar-nav > li {
    margin-right: 20px;
    position: relative; }
    .header .navbar .navbar-nav > li:last-child {
      margin-right: 0; }
    .header .navbar .navbar-nav > li > a {
      font-family: var(--global--font-body);
      font-size: 15px;
      text-transform: capitalize;
      font-weight: 700;
      line-height: 105px;
      color: var(--global--color-white);
      padding: 0;
      position: relative;
      display: flex;
      align-items: center; }
      .header .navbar .navbar-nav > li > a::before {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background: var(--global--color-white);
        content: "";
        transition: transform 1s cubic-bezier(0.2, 1, 0.3, 1);
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1); }
    .header .navbar .navbar-nav > li.has-dropdown > a {
      position: relative; }
      .header .navbar .navbar-nav > li.has-dropdown > a::after {
        border: 0;
        content: '\f078';
        position: relative;
        margin-left: 7px;
        font-size: 11px;
        font-family: "Font Awesome 5 Free";
        color: #97a0b5;
        transition: 0.3s ease-in-out; }
    @media screen and (min-width: 1200px) {
      .header .navbar .navbar-nav > li#departments {
        padding-right: 40px;
        margin-right: 40px;
        order: -1;
        position: relative; }
        .header .navbar .navbar-nav > li#departments::before {
          content: '';
          width: 2px;
          height: 27px;
          display: inline-flex;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background-color: #d9dce1; } }
    @media (min-width: 1200px) and (max-width: 1350px) {
      .header .navbar .navbar-nav > li#departments {
        padding-right: 20px;
        margin-right: 20px; } }
    .header .navbar .navbar-nav > li#contact.active a, .header .navbar .navbar-nav > li#contact:hover a {
      color: var(--global--color-primary); }
      @media screen and (min-width: 1200px) {
        .header .navbar .navbar-nav > li#contact.active a::before, .header .navbar .navbar-nav > li#contact:hover a::before {
          transform: scale3d(1, 1, 1);
          transform-origin: 0 50%;
          background-color: var(--global--color-primary); } }
    .header .navbar .navbar-nav > li.active > .dropdown-toggle, .header .navbar .navbar-nav > li.has-dropdown.active > .dropdown-toggle, .header .navbar .navbar-nav > li:hover .dropdown-toggle {
      color: var(--global--color-primary); }
      .header .navbar .navbar-nav > li.active > .dropdown-toggle::before, .header .navbar .navbar-nav > li.has-dropdown.active > .dropdown-toggle::before, .header .navbar .navbar-nav > li:hover .dropdown-toggle::before {
        transform: scale3d(1, 1, 1);
        transform-origin: 0 50%;
        background-color: var(--global--color-primary); }
      .header .navbar .navbar-nav > li.active > .dropdown-toggle::after, .header .navbar .navbar-nav > li.has-dropdown.active > .dropdown-toggle::after, .header .navbar .navbar-nav > li:hover .dropdown-toggle::after {
        color: var(--global--color-primary); }
  .header .navbar.navbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: var(--global--color-white);
    animation: fadeInDown 1s ease-in-out;
    -moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
    -webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
    box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1); }
    @media screen and (min-width: 992px) {
      .header .navbar.navbar-fixed .navbar-brand .logo-dark {
        display: inline-block; } }
    .header .navbar.navbar-fixed .navbar-brand .logo-light {
      display: none; }
    .header .navbar.navbar-fixed .navbar-nav > li > a {
      color: var(--global--color-heading); }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.navbar-nav > li.active > ul {
  margin-top: 0; }

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > a:before,
ul.dropdown-menu > li.has-dropdown > a:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f105";
  font-size: 13px;
  position: absolute;
  right: 25px; }

/* Menu Levels */
.dropdown-menu {
  background-color: transparent;
  position: relative;
  width: 100%;
  border: none;
  margin: 0;
  border-radius: 0;
  box-shadow: none; }

@media screen and (min-width: 992px) {
  .navbar.navbar-dark {
    background-color: var(--global--color-heading); } }

.navbar.navbar-dark.navbar-fixed .module-social a {
  color: var(--global--color-white); }
  .navbar.navbar-dark.navbar-fixed .module-social a:hover {
    color: var(--global--color-primary); }

.navbar.navbar-dark .module .module-icon i {
  color: var(--global--color-white); }

@media screen and (min-width: 992px) {
  .navbar.navbar-dark .navbar-nav > li > a {
    color: var(--global--color-white); }
    .navbar.navbar-dark .navbar-nav > li > a:hover {
      color: var(--global--color-primary); }
  .navbar.navbar-dark .navbar-nav > li.active > a, .navbar.navbar-dark .navbar-nav > li:hover > a {
    color: var(--global--color-white); } }

/* Small Devices, Tablets */
@media only screen and (max-width: 1198.98px) {
  .header .navbar {
    padding: 0 40px; }
    .header .navbar .navbar-brand {
      margin-right: 0;
      height: 80px;
      line-height: 80px; }
      .header .navbar .navbar-brand .logo-light {
        display: none; }
      .header .navbar .navbar-brand .logo-phone {
        width: auto; }
    .header .navbar .navbar-collapse {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      background-color: var(--global--color-white);
      padding: 15px 30px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
      transition: 0.3s linear; }
      .header .navbar .navbar-collapse.show {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px); }
    .header .navbar.navbar-bordered .navbar-collapse {
      top: calc(100% + 1px); }
  /* Navabar */
  .header .navbar {
    /* Mega Menu Content */ }
    .header .navbar .navbar-nav > li {
      margin-right: 0px; }
      .header .navbar .navbar-nav > li > a,
      .header .navbar .navbar-nav > li.active > a {
        line-height: 36px;
        color: var(--global--color-heading);
        display: block;
        z-index: 5; }
        .header .navbar .navbar-nav > li > a::before, .header .navbar .navbar-nav > li > a::after,
        .header .navbar .navbar-nav > li.active > a::before,
        .header .navbar .navbar-nav > li.active > a::after {
          display: none; }
    .header .navbar .menu-item::before {
      display: none; }
    .header .navbar .has-dropdown > .dropdown-menu {
      transition: 0.3s ease-in-out; }
    .header .navbar .has-dropdown::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      content: "\f107";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 5px;
      color: var(--global--color-heading);
      background-color: var(--global--color-primary-light);
      width: 25px;
      height: 25px;
      transition: 0.3s ease-in-out; }
    .header .navbar .has-dropdown:hover::after, .header .navbar .has-dropdown.active::after {
      color: var(--global--color-primary); }
    .header .navbar .has-dropdown.show::after {
      content: '\f106'; }
    .header .navbar .dropdown-menu.show {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 10px;
      border-radius: 0; }
    .header .navbar .dropdown-menu li {
      line-height: 26px; }
      .header .navbar .dropdown-menu li.current a {
        background-color: var(--global--color-primary-light);
        color: var(--global--color-primary); }
      .header .navbar .dropdown-menu li a {
        margin-left: 20px;
        color: var(--global--color-heading);
        font-size: 15px;
        font-weight: 500;
        display: block;
        padding: 4px 15px;
        margin-bottom: 5px;
        text-transform: capitalize;
        border-radius: 8px 8px 0 8px; }
        .header .navbar .dropdown-menu li a:hover {
          background-color: var(--global--color-primary-light);
          color: var(--global--color-primary); }
    .header .navbar .show > .dropdown-menu {
      display: block; }
    .header .navbar .dropdown-submenu.show .dropdown-menu {
      display: block; }
    .header .navbar .dropdown-submenu .dropdown-menu {
      padding-left: 20px; }
    .header .navbar li.has-dropdown.mega-dropdown > .dropdown-menu .row .menu-head {
      font-size: 14px;
      font-family: var(--global--font-heading);
      font-weight: 700;
      color: var(--global--font-heading);
      text-transform: capitalize;
      margin-bottom: 15px; }
  /* Header Modifers */
  .navbar.navbar-light .navbar-nav > li > a {
    color: var(--global--color-heading); }
  .navbar-light:not(.navbar-fixed) .navbar-toggler {
    color: var(--global--color-white);
    border-color: var(--global--color-white); }
  .navbar-light:not(.navbar-fixed) .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,225, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
  .navbar-dark .navbar-toggler,
  .navbar-light.navbar-fixed .navbar-toggler {
    color: #222;
    border-color: #222; }
  .navbar-dark .navbar-toggler-icon,
  .navbar-light.navbar-fixed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(34,34,34, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); } }

/* Large Devices, Wide Screens */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar-nav > li {
    margin-right: 16px; }
  .module .module-icon,
  .module-consultation .btn {
    margin-right: 0; } }

@media only screen and (min-width: 1200px) {
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0; }
  /* Menu with Dropdown */
  .navbar-nav {
    /* Mega Menu Content */ }
    .navbar-nav > li.has-dropdown > ul.dropdown-menu {
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
      transition: all 300ms linear;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      right: auto;
      transform-origin: left top;
      transform: translateY(20px);
      display: block;
      width: 210px;
      padding: 20px 20px;
      background-color: white;
      border-radius: 0px 0px 8px 8px; }
      .navbar-nav > li.has-dropdown > ul.dropdown-menu > li {
        padding-right: 0;
        position: relative;
        vertical-align: top; }
      .navbar-nav > li.has-dropdown > ul.dropdown-menu li a {
        transition: 0.3s ease-in-out;
        position: relative;
        font-family: var(--global--font-body);
        color: var(--global--color-secondary);
        padding: 8px 15px;
        margin-bottom: 3px;
        display: block;
        font-size: 14px;
        font-weight: 400;
        transition: all 300ms ease-in-out;
        text-transform: capitalize;
        border-radius: 8px 8px 0 8px; }
        .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:active, .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:focus, .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:hover {
          background-color: var(--global--color-primary-light);
          color: var(--global--color-primary); }
      .navbar-nav > li.has-dropdown > ul.dropdown-menu li.current > a {
        background-color: var(--global--color-primary-light);
        color: var(--global--color-primary); }
    .navbar-nav > li.has-dropdown:hover > ul.dropdown-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      z-index: 100;
      display: block; }
    .navbar-nav li ul.dropdown-menu.left {
      left: -650px !important;
      margin-left: 0 !important; }
    .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu {
      background-color: var(--global--color-white);
      padding: 0;
      width: 510px; }
      .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu li {
        padding-left: 0;
        padding-right: 0; }
      .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row {
        background-color: var(--global--color-white);
        margin-right: 0;
        margin-left: 0;
        padding: 35px 23px; }
        .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row .menu-head {
          font-size: 14px;
          font-family: var(--global--font-heading);
          font-weight: 700;
          color: var(--global--font-heading);
          text-transform: capitalize;
          margin-bottom: 15px; }
        .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul {
          margin: 0;
          padding: 0; }
          .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li {
            line-height: 2.5; }
            .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li.title {
              color: var(--global--color-heading);
              font-size: 13px;
              font-weight: 600;
              text-transform: capitalize; }
            .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a {
              margin: 0; }
    .navbar-nav.ml-auto.mr-auto li.has-dropdown.mega-dropdown {
      position: absolute; }
    .navbar-nav.ml-auto:not(.mr-auto) li.has-dropdown.mega-dropdown {
      position: relative; } }

/*------------------------------------*\
    #MODULE
\*------------------------------------*/
.module-holder {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 105px; }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .module-holder {
      margin-left: 20px; } }
  @media screen and (max-width: 1199.98px) {
    .module-holder {
      margin-top: 20px;
      height: auto; } }
  @media screen and (max-width: 1199.98px) {
    .module-holder .module-language,
    .module-holder .module-search,
    .module-holder .module-cart {
      display: none; } }
  .module-holder.module-holder-phone {
    display: none; }
    @media screen and (max-width: 1199.98px) {
      .module-holder.module-holder-phone {
        display: flex;
        height: 105px;
        margin-top: 0; } }
    .module-holder.module-holder-phone .navbar-toggler {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      transition: 0.3s ease-in-out;
      border: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
      border-radius: 0; }
      .module-holder.module-holder-phone .navbar-toggler:focus {
        box-shadow: none; }
      @media screen and (min-width: 1200px) {
        .module-holder.module-holder-phone .navbar-toggler {
          display: none; } }
      .module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
        background-color: transparent; }
        .module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before, .module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
          top: 0; }
        .module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
          transform: rotate(45deg); }
        .module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
          transform: rotate(-45deg); }
      .module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon {
        background-color: var(--global--color-heading);
        width: 100%;
        height: 2px;
        position: relative;
        transition: 0.3s ease-in-out; }
        .module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::before, .module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
          content: '';
          position: absolute;
          left: 0;
          height: 2px;
          width: 100%;
          background-color: var(--global--color-heading);
          transition: 0.3s ease-in-out; }
        .module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::before {
          top: -10px; }
        .module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
          top: 10px; }
    .module-holder.module-holder-phone .module-language {
      display: inline-flex;
      margin-right: 15px; }
      @media (min-width: 992px) and (max-width: 1200px) {
        .module-holder.module-holder-phone .module-language {
          top: 140%; } }
      @media screen and (min-width: 992px) {
        .module-holder.module-holder-phone .module-language {
          display: none; } }
      .module-holder.module-holder-phone .module-language .selected img {
        margin-right: 0; }
      .module-holder.module-holder-phone .module-language .selected span,
      .module-holder.module-holder-phone .module-language .selected i {
        display: none; }
      .module-holder.module-holder-phone .module-language .selected .lang-list {
        left: -45px; }
    .module-holder.module-holder-phone .module-search {
      display: inline-flex;
      border-bottom: 0;
      padding: 0;
      padding-right: 20px;
      margin-right: 20px; }
    .module-holder.module-holder-phone .module-cart {
      display: inline-flex; }

.module {
  display: inline-block;
  position: relative; }
  .module span.title {
    display: none; }
  .module .module-icon {
    cursor: pointer; }
    .module .module-icon i {
      font-size: 14px;
      line-height: 24px;
      color: var(--global--color-white); }
  .module:last-of-type .module-icon {
    margin-right: 0; }
  .module .menu-icon-double i {
    margin-left: 30px; }
  .module .module-label {
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    background-color: var(--global--color-primary);
    color: var(--global--color-white);
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    position: absolute;
    top: -4px;
    left: 10px; }
  .module .module-box {
    position: absolute;
    display: block;
    box-shadow: 0 2px 3px rgba(40, 40, 40, 0.1);
    background-color: #1b1a1a;
    opacity: 0;
    visibility: hidden;
    transition: all 100ms linear;
    right: 0;
    width: 290px;
    border-radius: 6px;
    padding: 30px;
    z-index: 99;
    transform: translateY(10%);
    height: 0;
    margin-top: 1px; }
  .module .widget-contact-info {
    padding-bottom: 18px; }
    .module .widget-contact-info ul {
      transform: translateY(-5px); }
      .module .widget-contact-info ul li {
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        font-family: var(--global--font-body);
        line-height: 23px;
        color: #a5a5a5; }
        .module .widget-contact-info ul li:first-of-type {
          margin-bottom: 12px; }

/* Module Fullscreen */
.module-fullscreen {
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: var(--global--color-white);
  position: fixed; }
  .module-fullscreen .module-cancel {
    top: 40px;
    right: 40px;
    border-radius: 8px 8px 0 8px;
    position: absolute;
    width: 50px;
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--global--color-white);
    background-color: var(--global--color-primary);
    display: flex;
    justify-content: center;
    align-items: center; }
  .module-fullscreen.module-active {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s ease-in-out; }

.module-fullscreen.fullscreen-light {
  background-color: var(--global--color-white); }

.module-active .module-box {
  opacity: 1;
  visibility: visible;
  display: block;
  transform: translateY(0px);
  height: inherit; }

/* Module Search */
.module-search {
  position: relative;
  padding-right: 30px;
  margin-right: 30px; }
  @media (min-width: 1200px) and (max-width: 1350px) {
    .module-search {
      padding-right: 20px;
      margin-right: 20px; } }
  .module-search::after {
    content: '';
    width: 2px;
    height: 27px;
    display: inline-flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: #d9dce1; }
  .module-search .module-icon i {
    transition: 0.3s ease-in-out; }
  .module-search.module-search-2 .form-search {
    margin-bottom: 0;
    display: flex; }
    .module-search.module-search-2 .form-search button::before {
      position: absolute;
      content: "\e906";
      top: 0;
      right: 0;
      color: var(--global--color-white);
      font-family: "icomoon";
      font-size: 24px;
      font-weight: 400;
      line-height: 70px;
      z-index: 999;
      cursor: pointer;
      font-size: 17px;
      transition: 0.3s ease-in-out; }
      .module-search.module-search-2 .form-search button::before:hover {
        opacity: 0.8; }
    .module-search.module-search-2 .form-search .form-control {
      height: 70px;
      border: 0;
      margin-bottom: 0;
      padding: 20px 30px;
      font-size: 13px;
      color: var(--global--color-body); }
      .module-search.module-search-2 .form-search .form-control::-webkit-input-placeholder {
        color: var(--global--color-body);
        font-family: var(--global--font-heading);
        font-size: 13px; }
      .module-search.module-search-2 .form-search .form-control::-moz-placeholder {
        color: var(--global--color-body);
        font-family: var(--global--font-heading);
        font-size: 13px; }

.module-search-box .module-cancel {
  color: var(--global--color-white); }

.module-search-box .form-search {
  margin-bottom: 0; }
  .module-search-box .form-search button::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "\e906";
    color: var(--global--color-heading);
    font-family: "icomoon";
    font-size: 24px;
    font-weight: 400;
    line-height: 70px;
    z-index: 999;
    cursor: pointer; }

.module-search-box .form-control {
  position: relative;
  font-family: var(--global--font-heading);
  font-size: 25px;
  font-weight: 400;
  line-height: 70px;
  height: 70px;
  color: #9b9b9b;
  border: none;
  background-color: transparent;
  width: 100%;
  border-bottom: 2px solid var(--global--color-gray-light);
  padding: 17px 30px 17px 46px;
  margin-bottom: 0;
  transition: 400ms linear;
  text-transform: capitalize;
  border-radius: 0; }
  .module-search-box .form-control:focus, .module-search-box .form-control:active {
    border-color: var(--global--color-primary); }
  .module-search-box .form-control::-webkit-input-placeholder {
    color: #9b9b9b;
    font-family: var(--global--font-heading);
    font-size: 35px; }
    @media screen and (max-width: 767.98px) {
      .module-search-box .form-control::-webkit-input-placeholder {
        font-size: 18px; } }
  .module-search-box .form-control::-moz-placeholder {
    color: #9b9b9b;
    font-family: var(--global--font-heading);
    font-size: 35px; }
    @media screen and (max-width: 767.98px) {
      .module-search-box .form-control::-moz-placeholder {
        font-size: 18px; } }
  .module-search-box .form-control::-moz-placeholder {
    color: #9b9b9b;
    font-family: var(--global--font-heading);
    font-size: 35px; }
    @media screen and (max-width: 767.98px) {
      .module-search-box .form-control::-moz-placeholder {
        font-size: 18px; } }
  .module-search-box .form-control:-ms-input-placeholder {
    color: #9b9b9b;
    font-family: var(--global--font-heading);
    font-size: 35px; }
    @media screen and (max-width: 767.98px) {
      .module-search-box .form-control:-ms-input-placeholder {
        font-size: 18px; } }

.module-search-box .btn {
  background-color: transparent;
  border-radius: 0;
  width: 35px;
  line-height: 57px;
  color: var(--global--color-white);
  font-size: 28px;
  font-weight: 400;
  text-align: right;
  margin-left: 0;
  padding: 0; }

/* Module Cart */
.module-cart {
  margin-right: 20px; }
  .module-cart .module-icon {
    position: relative; }
    .module-cart .module-icon i {
      transition: 0.3s ease-in-out;
      font-size: 16px;
      position: relative; }
  .module-cart .cart-box {
    position: absolute;
    box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.15);
    top: 64px;
    left: -150px;
    width: 290px;
    background-color: var(--global--color-white);
    padding: 30px;
    border-radius: 0 0 8px 8px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: 500ms; }
    @media (max-width: 1250px) {
      .module-cart .cart-box {
        left: -250px; } }
    @media screen and (max-width: 767.98px) {
      .module-cart .cart-box {
        left: -200px; } }
    @media screen and (min-width: 768px) {
      .module-cart .cart-box {
        width: 330px; } }
    .module-cart .cart-box.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0); }
    .module-cart .cart-box .cart-overview {
      margin-bottom: 0;
      width: 100%; }
      .module-cart .cart-box .cart-overview li {
        padding-bottom: 30px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .module-cart .cart-box .cart-overview li:last-child {
          margin-bottom: 0; }
        .module-cart .cart-box .cart-overview li .cart-cancel {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--global--color-white);
          font-size: 7px;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          background-color: var(--global--color-secondary);
          border-radius: 1px;
          margin-left: 15px; }
          .module-cart .cart-box .cart-overview li .cart-cancel:hover {
            background-color: var(--global--color-primary); }
        .module-cart .cart-box .cart-overview li img {
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          margin-right: 15px;
          border-radius: 8px 8px 0 8px; }
        .module-cart .cart-box .cart-overview li .product-meta h5 {
          color: var(--global--color-heading);
          font-family: var(--global--font-heading);
          font-size: 15px;
          text-transform: capitalize;
          line-height: 1;
          font-weight: 500;
          margin-bottom: 8px; }
        .module-cart .cart-box .cart-overview li .product-meta p {
          font-family: var(--global--font-body);
          color: var(--global--color-primary);
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          margin-bottom: 0; }
    .module-cart .cart-box span {
      font-family: var(--global--font-heading);
      color: var(--global--color-heading);
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
      display: inline-block;
      text-transform: capitalize;
      margin-bottom: 17px;
      transform: translateY(-7px); }
      .module-cart .cart-box span .total-price {
        font-style: normal; }
    .module-cart .cart-box .cart--control .btn {
      height: 60px;
      width: 100%;
      border-radius: 8px 8px 0 8px;
      background-color: var(--global--color-secondary);
      color: var(--global--color-white); }
      .module-cart .cart-box .cart--control .btn::before {
        background-color: var(--global--color-primary); }

/* Module Contact */
.module-contact {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 1199.98px) {
    .module-contact {
      flex-direction: column;
      width: 100%; } }
  .module-contact .btn {
    width: 190px;
    height: 50px;
    margin-right: 40px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px 8px 0 8px;
    cursor: pointer;
    transition: background 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9), color 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
    overflow: hidden; }
    @media (min-width: 1200px) and (max-width: 1350px) {
      .module-contact .btn {
        margin-right: 20px; } }
    .module-contact .btn:last-child {
      margin-right: 0; }
    .module-contact .btn:first-child {
      width: auto;
      height: auto;
      background-color: transparent;
      color: var(--global--color-secondary);
      border: 0; }
      @media screen and (min-width: 1200px) {
        .module-contact .btn:first-child::before {
          content: unset; } }
      .module-contact .btn:first-child:hover, .module-contact .btn:first-child:active, .module-contact .btn:first-child:focus {
        color: var(--global--color-primary); }
    @media screen and (max-width: 1199.98px) {
      .module-contact .btn:first-child, .module-contact .btn:last-child {
        display: flex;
        width: 100%; }
      .module-contact .btn:first-child {
        margin-bottom: 10px;
        width: 100%;
        color: var(--global--color-white);
        height: 50px;
        margin-right: 0;
        background-color: var(--global--color-secondary); }
        .module-contact .btn:first-child::before {
          background-color: var(--global--color-primary); }
        .module-contact .btn:first-child:hover, .module-contact .btn:first-child:active, .module-contact .btn:first-child:focus {
          color: var(--global--color-white); } }
  .module-contact.module-contact-2 .btn:first-child {
    width: 190px;
    height: 51px;
    background-color: var(--global--color-primary);
    color: var(--global--color-white);
    border: 0; }
    .module-contact.module-contact-2 .btn:first-child::before {
      background-color: var(--global--color-secondary);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    @media screen and (max-width: 1199.98px) {
      .module-contact.module-contact-2 .btn:first-child {
        width: 100%; } }
  .module-contact.module-contact-3 .btn:first-child {
    width: 140px;
    height: 40px; }
    @media screen and (max-width: 1199.98px) {
      .module-contact.module-contact-3 .btn:first-child {
        width: 100%; } }

/* Module Language */
.module-language {
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 100%; }
  .module-language.module-active .lang-list, .module-language:hover .lang-list {
    opacity: 1;
    visibility: visible; }
  .module-language .selected {
    display: flex;
    align-items: center; }
    .module-language .selected img {
      margin-right: 10px; }
    .module-language .selected span {
      font-family: var(--global--font-body);
      font-size: 13px;
      font-weight: 400;
      text-transform: capitalize;
      color: var(--global--color-white);
      margin-right: 5px;
      cursor: pointer; }
    .module-language .selected i {
      font-size: 11px;
      color: var(--global--color-white); }
  .module-language .lang-list {
    background-color: var(--global--color-white);
    color: var(--global--color-heading);
    position: absolute;
    top: 100%;
    padding: 6px 22px;
    box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
    z-index: 5555;
    left: -22px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
    border-radius: 0 0 8px 8px; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .module-language .lang-list {
        top: 140%; } }
    .module-language .lang-list ul {
      margin-bottom: 0; }
      .module-language .lang-list ul li {
        display: flex;
        align-items: center; }
        .module-language .lang-list ul li:last-of-type a {
          border-bottom: none; }
          .module-language .lang-list ul li:last-of-type a::after {
            content: none; }
        .module-language .lang-list ul li img {
          margin-right: 10px; }
        .module-language .lang-list ul li a {
          font-size: 14px;
          display: block;
          color: var(--global--color-body);
          text-transform: capitalize;
          border-bottom: 1px solid #eaeaea;
          padding: 8px 0px;
          cursor: pointer;
          transition: 0.3s ease;
          position: relative; }
          .module-language .lang-list ul li a::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 0px;
            transition: 0.3s ease;
            background-color: transparent; }
          .module-language .lang-list ul li a:hover {
            color: var(--global--color-primary); }
            .module-language .lang-list ul li a:hover::after {
              width: 100%;
              background-color: var(--global--color-primary); }

.navbar-fixed .module-language span,
.navbar-fixed .module-language i {
  color: var(--global--color-heading); }

/* Module Social */
.module-social {
  line-height: 100px;
  height: 100px;
  margin-right: 20px;
  margin-bottom: 0; }
  @media screen and (max-width: 991.98px) {
    .module-social {
      border-bottom: 0; } }
  .module-social:last-child {
    margin-right: 0; }
  .module-social a {
    color: var(--global--color-white);
    font-size: 17px;
    font-weight: 400;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    width: 35px;
    height: 35px;
    background-color: var(--global--color-primary);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: 300ms ease-in-out; }
    .module-social a:hover {
      transform: scale(0.9); }
  .module-social.module-social-2 {
    line-height: 70px;
    height: 70px;
    border-right: 2px solid #292929;
    margin-right: 0; }
    .module-social.module-social-2:last-child {
      margin-right: 0; }
    .module-social.module-social-2 a {
      color: var(--global--color-white);
      background-color: transparent;
      margin-right: 20px;
      width: auto;
      height: auto;
      border-radius: 0; }
      .module-social.module-social-2 a:hover {
        color: var(--global--color-primary);
        transform: scale(1); }

/* Navbar For header Top Bar */
/* Small Devices, Tablets */
@media only screen and (max-width: 991px) {
  .module-content {
    margin-bottom: 10px; }
  .cart-box .cart-overview h5 {
    color: var(--global--color-white); }
  .module-search .search-icon i {
    border-left: none;
    padding-left: 0;
    padding-right: 5px; } }

.module-cart.active .cart-box {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  opacity: 1; }

.module.module-active .cart-box,
.module.module-active .search-box {
  display: block !important; }

.module-search.module-active .search-box .search-form {
  margin-bottom: 10px; }

.module-search.module-active .search-box .search-form input {
  background-color: var(--global--color-white);
  margin-bottom: 0;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  max-width: 100%; }

.module-cart.module-active .cart-box .cart-control {
  margin-right: auto;
  margin-left: auto;
  text-align: center; }

.module-cart.module-active .cart-box .cart-control .pull-right {
  float: none !important; }

.module-consultation {
  padding: 10px 0; }
  .module-consultation .btn {
    border-color: var(--global--color-heading);
    color: var(--global--color-heading);
    margin: 0; }

.module-social a {
  color: var(--global--color-heading);
  line-height: 36px; }

.module-sidearea .module-sidearea-wrap {
  width: 320px;
  padding: 40px; }

/*------------------------------------*\
    #TopBar
\*------------------------------------*/
.top-bar {
  padding: 0 20px;
  display: flex;
  height: 50px;
  position: relative;
  z-index: 8888;
  background-color: var(--global--color-secondary); }
  @media (min-width: 992px) and (max-width: 1250px) {
    .top-bar {
      height: auto;
      padding: 10px 20px 10px; } }
  .top-bar .block-left {
    display: flex;
    align-items: center;
    flex-basis: 40%; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .top-bar .block-left {
        flex-direction: column;
        align-items: flex-start; } }
    .top-bar .block-left .headline {
      font-family: var(--global--font-body);
      font-size: 13px;
      line-height: 22px;
      color: var(--global--color-gray-darken);
      font-weight: 400;
      margin-bottom: 0;
      display: flex;
      align-items: center; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar .block-left .headline {
          margin-bottom: 10px; } }
      .top-bar .block-left .headline svg {
        fill: var(--global--color-gray-darken);
        margin-right: 9px; }
    .top-bar .block-left a {
      margin-left: 4px;
      font-family: var(--global--font-body);
      font-size: 13px;
      color: var(--global--color-white);
      font-weight: 400;
      transition: 0.3s ease-in-out;
      display: inline-flex;
      align-items: center;
      text-transform: capitalize; }
      .top-bar .block-left a:hover {
        color: var(--global--color-primary); }
        .top-bar .block-left a:hover svg {
          fill: var(--global--color-primary); }
      .top-bar .block-left a svg {
        fill: var(--global--color-white);
        transition: 0.3s ease-in-out;
        margin-left: 8px; }
    .top-bar .block-left .owl-carousel {
      width: 200px;
      display: inline-block; }
  .top-bar .block-right {
    display: flex;
    align-items: center;
    flex-basis: 60%; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .top-bar .block-right {
        flex-direction: column;
        align-items: flex-end; } }
    .top-bar .block-right .module-language {
      margin-left: 30px; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar .block-right .module-language {
          margin-top: 10px; } }
  .top-bar .top-contact {
    display: flex; }
    .top-bar .top-contact .contact-infos {
      display: flex;
      align-items: center;
      margin-right: 30px; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar .top-contact .contact-infos {
          margin-right: 15px; } }
      .top-bar .top-contact .contact-infos:last-child {
        margin-right: 0; }
      .top-bar .top-contact .contact-infos i {
        color: var(--global--color-gray-darken);
        font-size: 15px;
        margin-right: 10px; }
      .top-bar .top-contact .contact-infos a,
      .top-bar .top-contact .contact-infos p {
        font-family: var(--global--font-body);
        line-height: 22px;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
        color: var(--global--color-white);
        text-transform: capitalize; }
      .top-bar .top-contact .contact-infos a:hover {
        color: var(--global--color-primary); }
  .top-bar.top-bar-2 .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .top-bar.top-bar-2 .container p {
      margin-bottom: 0; }
  .top-bar.top-bar-2 .update svg {
    fill: var(--global--color-gray-darken);
    margin-right: 15px; }
  .top-bar.top-bar-2 .update a {
    font-family: var(--global--font-heading);
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: var(--global--color-white); }
    .top-bar.top-bar-2 .update a:hover {
      color: var(--global--color-primary); }
  .top-bar.top-bar-2 .more {
    font-family: var(--global--font-body);
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: var(--global--color-gray-darken); }
    .top-bar.top-bar-2 .more a {
      color: var(--global--color-white);
      display: inline-flex;
      align-items: center;
      text-transform: capitalize; }
      .top-bar.top-bar-2 .more a svg {
        fill: var(--global--color-white);
        transition: 0.3s ease-in-out;
        margin-left: 5px; }
      .top-bar.top-bar-2 .more a:hover {
        color: var(--global--color-primary); }
        .top-bar.top-bar-2 .more a:hover svg {
          fill: var(--global--color-primary); }
  @media (min-width: 992px) and (max-width: 1250px) {
    .top-bar.top-bar-3 {
      padding-top: 0;
      padding-bottom: 0; } }
  .top-bar.top-bar-3 .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .top-bar.top-bar-3 .container .top-contact {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px; } }
    .top-bar.top-bar-3 .container .block-right {
      display: inline-flex;
      flex-basis: unset;
      height: 100%; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar.top-bar-3 .container .block-right {
          flex-direction: row; } }
      .top-bar.top-bar-3 .container .block-right a {
        display: inline-flex;
        align-items: center;
        height: 100%;
        font-size: 13px;
        line-height: 21px;
        color: var(--global--color-white);
        font-weight: 400;
        font-family: var(--global--font-body);
        margin-right: 20px;
        text-transform: capitalize; }
        .top-bar.top-bar-3 .container .block-right a:last-child {
          margin-right: 0; }
        .top-bar.top-bar-3 .container .block-right a:hover {
          color: var(--global--color-primary); }
      .top-bar.top-bar-3 .container .block-right .widget-holder {
        padding: 0 20px;
        background-color: var(--global--color-heading-light);
        margin-left: 10px;
        height: 100%;
        position: relative; }
        .top-bar.top-bar-3 .container .block-right .widget-holder:hover .feature-panel {
          opacity: 1;
          visibility: visible;
          transform: translateY(0); }
        .top-bar.top-bar-3 .container .block-right .widget-holder a {
          margin-right: 0; }
          .top-bar.top-bar-3 .container .block-right .widget-holder a i {
            margin-left: 10px; }
        .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel {
          opacity: 0;
          visibility: hidden;
          transform: translateY(15px);
          transition: 0.3s ease-in-out;
          position: absolute;
          border-radius: 0 8px 8px 8px;
          width: 290px;
          background-color: var(--global--color-heading-light);
          top: 100%;
          left: 0;
          padding: 28px 40px 40px; }
          @media (min-width: 992px) and (max-width: 1250px) {
            .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel {
              left: auto;
              right: 0;
              border-radius: 8px 0 8px 8px; } }
          @media screen and (max-width: 991.98px) {
            .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel {
              max-width: 370px;
              margin: 0 auto; } }
          .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content {
            position: relative;
            z-index: 3; }
            @media screen and (max-width: 991.98px) {
              .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content {
                text-align: center; } }
            .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content i {
              display: block;
              margin-bottom: 11px; }
              .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content i:before {
                font-size: 50px;
                color: var(--global--color-white);
                margin-left: 0; }
            .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content h5 {
              text-transform: capitalize;
              font-family: var(--global--font-heading);
              font-weight: 500;
              font-size: 19px;
              line-height: 28px;
              color: var(--global--color-white);
              margin-bottom: 16px; }
            .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content p {
              font-family: var(--global--font-body);
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #f4f4f4;
              margin-bottom: 21px; }
            .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content a {
              font-family: var(--global--font-heading);
              font-weight: 400;
              color: var(--global--color-white);
              font-size: 24px;
              display: flex;
              align-items: center;
              transition: 0.3s ease-in-out; }
              @media screen and (max-width: 991.98px) {
                .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content a {
                  justify-content: center; } }
              .top-bar.top-bar-3 .container .block-right .widget-holder .feature-panel .feature-content a span {
                transition: 0.3s ease-in-out;
                display: inline-flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                width: 42px;
                height: 42px;
                background-color: #283b69;
                color: var(--global--color-white);
                border-radius: 8px 8px 0 8px;
                font-size: 15px;
                margin-right: 10px; }
  .top-bar.top-bar-4 {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .top-bar.top-bar-4 {
        padding-top: 0;
        padding-bottom: 0; } }
    @media screen and (max-width: 991.98px) {
      .top-bar.top-bar-4 {
        display: none; } }
    @media (min-width: 992px) and (max-width: 1250px) {
      .top-bar.top-bar-4 .top-contact {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px; } }
    .top-bar.top-bar-4 .block-right {
      display: inline-flex;
      flex-basis: unset;
      height: 100%; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar.top-bar-4 .block-right {
          flex-direction: row; } }
      .top-bar.top-bar-4 .block-right a {
        display: inline-flex;
        align-items: center;
        height: 100%;
        font-size: 13px;
        line-height: 21px;
        color: var(--global--color-white);
        font-weight: 400;
        font-family: var(--global--font-body);
        margin-right: 20px;
        text-transform: capitalize; }
        .top-bar.top-bar-4 .block-right a:last-child {
          margin-right: 0; }
        .top-bar.top-bar-4 .block-right a:hover {
          color: var(--global--color-primary); }
      .top-bar.top-bar-4 .block-right .module-language {
        margin-left: 10px; }
        @media (min-width: 992px) and (max-width: 1250px) {
          .top-bar.top-bar-4 .block-right .module-language {
            margin-top: 0; } }
        @media (min-width: 992px) and (max-width: 1250px) {
          .top-bar.top-bar-4 .block-right .module-language .lang-list {
            top: calc(100% + 15px); } }
        .top-bar.top-bar-4 .block-right .module-language .lang-list ul li a {
          color: var(--global--color-body); }
          .top-bar.top-bar-4 .block-right .module-language .lang-list ul li a:hover {
            color: var(--global--color-primary); }
  .top-bar.top-bar-5 {
    justify-content: space-between; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .top-bar.top-bar-5 .block-left {
        justify-content: center; } }
    .top-bar.top-bar-5 .block-left .headline {
      color: var(--global--color-white); }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar.top-bar-5 .block-left .headline {
          margin-bottom: 0; } }
    .top-bar.top-bar-5 .block-right {
      flex-basis: unset; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .top-bar.top-bar-5 .block-right .top-contact .contact-infos {
          margin-right: 10px; } }

/*------------------------------------*\
    #Page Title
\*------------------------------------*/
.page-title {
  padding: 0; }
  .page-title .title {
    padding: 342px 0 35px 0;
    text-align: center; }
    .page-title .title .title-sub-heading p {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 15px;
      line-height: 1;
      color: var(--global--color-gray);
      margin-bottom: 0; }
      @media screen and (max-width: 991.98px) {
        .page-title .title .title-sub-heading p {
          line-height: 1.4; } }
    .page-title .title .title-heading {
      margin-bottom: 150px; }
      .page-title .title .title-heading h1 {
        font-family: var(--global--font-heading);
        color: var(--global--color-white);
        font-size: 45px;
        font-weight: 500;
        line-height: 60px;
        margin-bottom: 0;
        text-transform: capitalize; }
        @media screen and (max-width: 767.98px) {
          .page-title .title .title-heading h1 {
            font-size: 32px;
            line-height: 1.4; } }
    .page-title .title .title-desc p {
      color: var(--global--color-gray);
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 300; }
    .page-title .title .breadcrumb {
      font-family: var(--global--font-body);
      font-size: 14px;
      font-weight: 400;
      line-height: 1px;
      background: transparent;
      color: var(--global--color-gray);
      display: flex;
      justify-content: center; }
      @media screen and (min-width: 992px) {
        .page-title .title .breadcrumb.breadcrumb-long {
          justify-content: start; } }
      @media screen and (max-width: 767.98px) {
        .page-title .title .breadcrumb.breadcrumb-long {
          line-height: normal;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          justify-content: start;
          flex-direction: row; } }
      .page-title .title .breadcrumb.breadcrumb-long .breadcrumb-item {
        margin-bottom: 5px; }
        .page-title .title .breadcrumb.breadcrumb-long .breadcrumb-item:last-child {
          margin-bottom: 0; }
      @media screen and (max-width: 767.98px) {
        .page-title .title .breadcrumb.breadcrumb-long .breadcrumb-item + .breadcrumb-item::before {
          line-height: 17px; } }
      .page-title .title .breadcrumb a {
        font-size: 14px;
        font-weight: 400;
        line-height: 1px;
        background: transparent;
        color: var(--global--color-gray); }
        .page-title .title .breadcrumb a:hover {
          color: var(--global--color-heading-darken); }
      .page-title .title .breadcrumb .active {
        color: var(--global--color-gray); }
      .page-title .title .breadcrumb .breadcrumb-item + .breadcrumb-item {
        padding-left: 0; }
        .page-title .title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
          font-family: "Font Awesome 5 Free";
          font-weight: 700;
          font-size: 11px;
          padding: 0 10px;
          color: var(--global--color-gray);
          content: "\f054"; }
    @media screen and (max-width: 767.98px) {
      .page-title .title {
        padding-top: 150px; }
        .page-title .title .title-heading {
          margin-bottom: 80px; } }
  .page-title.page-title-blank .title {
    padding: 205px 0 44px 0; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .page-title.page-title-blank .title {
        padding-top: 250px; } }
    .page-title.page-title-blank .title .breadcrumb a {
      color: var(--global--color-secondary); }
      .page-title.page-title-blank .title .breadcrumb a:hover {
        color: var(--global--color-primary); }
    .page-title.page-title-blank .title .breadcrumb .active {
      color: var(--global--color-heading-darken); }
    .page-title.page-title-blank .title .breadcrumb-item + .breadcrumb-item::before {
      color: var(--global--color-secondary); }
  .page-title.page-title-1 {
    background-position-y: 130px; }
    @media screen and (max-width: 991.98px) {
      .page-title.page-title-1 {
        background-position-y: 100px; } }
    .page-title.page-title-1 .breadcrumb {
      margin-bottom: 26px; }
    .page-title.page-title-1 .title {
      padding-top: 312px;
      padding-bottom: 135px; }
      @media screen and (max-width: 767.98px) {
        .page-title.page-title-1 .title {
          padding-top: 250px;
          padding-bottom: 110px; } }
      .page-title.page-title-1 .title .title-heading {
        margin-bottom: 0; }
  .page-title.page-title-2 {
    background-position-y: 1%; }
    .page-title.page-title-2 .breadcrumb {
      margin-bottom: 0px; }
    .page-title.page-title-2 .title {
      padding-top: 292px;
      padding-bottom: 155px; }
      @media screen and (max-width: 767.98px) {
        .page-title.page-title-2 .title {
          padding-top: 250px;
          padding-bottom: 120px; } }
      .page-title.page-title-2 .title .title-heading {
        margin-bottom: 20px; }
  .page-title.page-title-3 {
    overflow: visible;
    background-position-y: 1px; }
    .page-title.page-title-3 .title {
      text-align: left;
      padding-bottom: 0px;
      padding-top: 394px; }
      @media screen and (max-width: 991.98px) {
        .page-title.page-title-3 .title {
          text-align: center;
          padding-top: 300px; } }
      .page-title.page-title-3 .title .title-card {
        transform: translateY(50px);
        margin-top: -50px;
        padding: 45px 50px 54px;
        border-radius: 8px 8px 0 8px;
        background-color: var(--global--color-white);
        box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); }
        @media screen and (max-width: 767.98px) {
          .page-title.page-title-3 .title .title-card {
            padding-left: 25px;
            padding-right: 25px; } }
        .page-title.page-title-3 .title .title-card::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 100px);
          height: 2px;
          background-color: var(--global--color-primary); }
          @media screen and (max-width: 767.98px) {
            .page-title.page-title-3 .title .title-card::before {
              width: calc(100% - 50px); } }
        .page-title.page-title-3 .title .title-card .title-heading {
          margin-bottom: 17px; }
          .page-title.page-title-3 .title .title-card .title-heading h1 {
            font-size: 28px;
            line-height: 32px;
            color: var(--global--color-heading);
            font-weight: 500;
            margin-bottom: 0; }
        .page-title.page-title-3 .title .title-card .title-sub-heading {
          margin-bottom: 26px; }
          .page-title.page-title-3 .title .title-card .title-sub-heading p {
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;
            color: var(--global--color-body);
            margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .page-title.page-title-3 .title .title-card .breadcrumb {
            justify-content: flex-start; } }
        .page-title.page-title-3 .title .title-card .breadcrumb a {
          color: var(--global--color-secondary); }
          .page-title.page-title-3 .title .title-card .breadcrumb a:hover {
            color: var(--global--color-primary); }
        .page-title.page-title-3 .title .title-card .breadcrumb .active {
          color: var(--global--color-heading-darken); }
        .page-title.page-title-3 .title .title-card .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
          color: var(--global--color-secondary); }
  .page-title.page-title-4 {
    background-position-y: 110%;
    overflow: visible; }
    .page-title.page-title-4 .title {
      text-align: left;
      padding-bottom: 0px;
      padding-top: 304px; }
      @media screen and (max-width: 767.98px) {
        .page-title.page-title-4 .title {
          text-align: center; } }
      .page-title.page-title-4 .title .breadcrumb {
        margin-bottom: 33px; }
        @media screen and (min-width: 992px) {
          .page-title.page-title-4 .title .breadcrumb {
            justify-content: flex-start; } }
      .page-title.page-title-4 .title .title-card {
        transform: translateY(50px);
        margin-top: -50px;
        padding: 50px 50px 50px;
        border-radius: 8px 8px 0 8px;
        background-color: var(--global--color-white);
        box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
        display: flex; }
        @media screen and (max-width: 767.98px) {
          .page-title.page-title-4 .title .title-card {
            flex-direction: column;
            padding-left: 25px;
            padding-right: 25px;
            align-items: center; } }
        @media screen and (min-width: 992px) {
          .page-title.page-title-4 .title .title-card {
            max-width: 515px; } }
        .page-title.page-title-4 .title .title-card::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 100px);
          height: 2px;
          background-color: var(--global--color-primary); }
          @media screen and (max-width: 767.98px) {
            .page-title.page-title-4 .title .title-card::before {
              width: calc(100% - 50px); } }
        .page-title.page-title-4 .title .title-card .card-icon {
          margin-right: 30px;
          width: 94px;
          height: 94px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px 0 8px 8px;
          background-color: #e7f9fc; }
          @media screen and (max-width: 767.98px) {
            .page-title.page-title-4 .title .title-card .card-icon {
              margin-right: 0;
              margin-bottom: 25px; } }
          .page-title.page-title-4 .title .title-card .card-icon i::before {
            color: #304370;
            font-size: 64px;
            margin-left: 0; }
        @media screen and (max-width: 767.98px) {
          .page-title.page-title-4 .title .title-card .card-content {
            text-align: center; } }
        .page-title.page-title-4 .title .title-card .card-content .title-heading {
          margin-bottom: 17px; }
          .page-title.page-title-4 .title .title-card .card-content .title-heading h1 {
            font-size: 28px;
            line-height: 32px;
            color: var(--global--color-heading);
            font-weight: 500;
            margin-bottom: 0; }
        .page-title.page-title-4 .title .title-card .card-content .title-sub-heading {
          margin-bottom: 22px; }
          .page-title.page-title-4 .title .title-card .card-content .title-sub-heading p {
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;
            color: var(--global--color-body);
            margin-bottom: 0; }
        .page-title.page-title-4 .title .title-card .card-content .btn {
          width: 143px;
          height: 42px;
          border-color: #283b6a;
          border: 2px solid;
          transition-property: background, color, border-color; }
          .page-title.page-title-4 .title .title-card .card-content .btn::before {
            background-color: var(--global--color-secondary);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            top: -2px;
            left: -2px; }
          .page-title.page-title-4 .title .title-card .card-content .btn:hover, .page-title.page-title-4 .title .title-card .card-content .btn:active, .page-title.page-title-4 .title .title-card .card-content .btn:focus {
            border-color: var(--global--color-secondary); }
          @media screen and (max-width: 767.98px) {
            .page-title.page-title-4 .title .title-card .card-content .btn {
              display: inline-flex; } }
  .page-title.page-title-5 {
    background-position-y: 100%; }

/*------------------------------------*\
    #Carousel
\*------------------------------------*/
.owl-nav.disabled {
  display: none; }

.carousel-dots {
  position: relative; }
  .carousel-dots button.owl-dot,
  .carousel-dots button.owl-dot:focus {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none; }
  .carousel-dots .owl-dots {
    margin-top: 40px;
    display: flex;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .carousel-dots .owl-dots {
        margin-top: 0;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        bottom: 45px; } }
    .carousel-dots .owl-dots .owl-dot.active span {
      background-color: var(--global--color-primary); }
      .carousel-dots .owl-dots .owl-dot.active span::before {
        background-color: #cff3f7; }
    .carousel-dots .owl-dots .owl-dot span {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #687596;
      margin-right: 20px;
      transition: 0.3s ease-in-out;
      position: relative; }
      .carousel-dots .owl-dots .owl-dot span::before {
        content: '';
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        width: 25px;
        height: 25px;
        background-color: transparent;
        transition: 0.3s ease-in-out; }

.carousel-navs {
  position: relative; }
  .carousel-navs .owl-nav span {
    font-size: 0; }
  @media screen and (min-width: 992px) {
    .carousel-navs .owl-nav {
      position: absolute;
      top: -127px;
      right: 0; } }
  .carousel-navs .owl-next:hover::before,
  .carousel-navs .owl-prev:hover::before {
    color: var(--global--color-primary); }
  .carousel-navs .owl-next:before,
  .carousel-navs .owl-prev:before {
    font-family: 'carousel';
    font-size: 43px;
    font-weight: 600;
    color: #687596;
    transition: 0.3s ease-in-out; }
  .carousel-navs .owl-prev {
    margin-right: 50px; }
    .carousel-navs .owl-prev:before {
      content: "\e990"; }
  .carousel-navs .owl-next:before {
    content: "\e991"; }

.carousel-navs .owl-dots {
  position: absolute;
  bottom: 40px;
  width: 100%; }

/*------------------------------------*\
    #Heading
\*------------------------------------*/
.heading {
  margin-bottom: 48px; }
  @media screen and (max-width: 991.98px) {
    .heading {
      text-align: center; } }
  .heading .heading-subtitle {
    font-family: var(--global--font-body);
    text-transform: capitalize;
    color: var(--global--color-primary);
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 15px; }
    @media screen and (max-width: 991.98px) {
      .heading .heading-subtitle {
        line-height: 1.5; } }
  .heading .heading-title {
    font-family: var(--global--font-heading);
    text-transform: capitalize;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 25px;
    font-weight: 500;
    color: #0e204d; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .heading .heading-title {
        font-size: 35px;
        line-height: 1.4; } }
    @media screen and (max-width: 767.98px) {
      .heading .heading-title {
        font-size: 32px;
        line-height: 1.4; } }
  .heading .heading-desc {
    font-family: var(--global--font-body);
    color: var(--global--color-body);
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 0; }
  .heading .paragraph {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: var(--global--color-secondary);
    margin-bottom: 0px; }
  .heading.heading-light .heading-subtitle {
    color: var(--global--color-gray); }
  .heading.heading-light .heading-title {
    color: var(--global--color-white); }
  .heading.heading-light .heading-desc {
    color: var(--global--color-gray); }
  .heading.heading-light .paragraph {
    color: var(--global--color-gray); }
  .heading.heading-2 .heading-title {
    margin-bottom: 50px; }
  .heading.heading-2 .paragraph {
    margin-bottom: 25px; }
  .heading.heading-2 .heading-desc {
    margin-bottom: 40px; }
  .heading.heading-3 {
    margin-bottom: 45px; }
    .heading.heading-3 .heading-title {
      transform: translateY(-6px); }
    .heading.heading-3 .paragraph {
      margin-bottom: 26px; }
    .heading.heading-3 .heading-desc {
      margin-bottom: 42px; }
    .heading.heading-3 .actions-holder .btn:first-child {
      width: 190px;
      height: 60px;
      border-radius: 8px 8px 0 8px; }
    .heading.heading-3 .actions-holder .btn:last-child {
      border-radius: 8px 8px 8px 0;
      width: 170px;
      height: 60px; }
  .heading.heading-4 {
    margin-bottom: 0px; }
    .heading.heading-4 .heading-subtitle {
      margin-bottom: 20px; }
    .heading.heading-4 .heading-title {
      transform: translateY(-6px);
      margin-bottom: 70px; }
    .heading.heading-4 .paragraph {
      margin-bottom: 26px; }
    .heading.heading-4 .heading-desc {
      margin-bottom: 42px; }
  .heading.heading-5 {
    margin-bottom: 52px;
    position: relative; }
    .heading.heading-5 .heading-title {
      font-size: 37px;
      line-height: 54px;
      color: #0e204d;
      margin-bottom: 10px; }
      @media screen and (max-width: 767.98px) {
        .heading.heading-5 .heading-title {
          font-size: 32px;
          line-height: 1.4; } }
  .heading.heading-6 {
    margin-bottom: 64px; }
    .heading.heading-6 .heading-title {
      margin-bottom: 50px; }
  .heading.heading-7 .heading-subtitle {
    color: var(--global--color-heading-darken);
    font-size: 16px;
    margin-bottom: 13px; }
  .heading.heading-7 .heading-title {
    font-size: 37px;
    line-height: 54px;
    color: #0e204d; }
    @media screen and (max-width: 767.98px) {
      .heading.heading-7 .heading-title {
        font-size: 32px;
        line-height: 1.4; } }
  .heading.heading-8 {
    margin-bottom: 25px; }
    .heading.heading-8 .heading-title {
      margin-bottom: 39px; }
  .heading.heading-9 {
    margin-bottom: 53px; }
    .heading.heading-9 .heading-title {
      font-size: 37px;
      line-height: 54px;
      color: #0e204d;
      margin-bottom: 9px; }
      @media screen and (max-width: 767.98px) {
        .heading.heading-9 .heading-title {
          font-size: 32px;
          line-height: 1.4; } }
  .heading.heading-10 {
    margin-bottom: 58px; }
    .heading.heading-10 .heading-subtitle {
      color: var(--global--color-primary);
      font-size: 16px; }
  .heading.heading-11 {
    margin-bottom: 0px; }
    .heading.heading-11 .heading-title {
      transform: translateY(-6px);
      margin-bottom: 25px; }
    .heading.heading-11 .heading-desc {
      margin-bottom: 32px; }
  .heading.heading-12 .heading-subtitle {
    color: var(--global--color-heading-darken);
    font-size: 16px;
    margin-bottom: 13px; }
  .heading.heading-12 .heading-title {
    font-size: 37px;
    line-height: 54px;
    color: #0e204d; }
    @media screen and (max-width: 767.98px) {
      .heading.heading-12 .heading-title {
        font-size: 32px;
        line-height: 1.4; } }
  .heading.heading-13 {
    margin-bottom: 88px; }
  .heading.heading-14 {
    margin-bottom: 45px; }
    .heading.heading-14 .heading-subtitle {
      font-size: 16px;
      margin-bottom: 7px;
      color: var(--global--color-primary); }
    .heading.heading-14 .heading-title {
      font-size: 160px;
      line-height: 1; }
      @media screen and (max-width: 767.98px) {
        .heading.heading-14 .heading-title {
          font-size: 60px; } }
    .heading.heading-14 .paragraph {
      margin-bottom: 25px; }
      @media screen and (min-width: 992px) {
        .heading.heading-14 .paragraph {
          margin-top: 13px; } }
    .heading.heading-14 .heading-desc {
      color: var(--global--color-gray-darken);
      margin-bottom: 42px; }
  .heading.heading-15 {
    margin-top: 92px;
    margin-bottom: 50px; }
    .heading.heading-15 .heading-title {
      transform: translateY(-6px); }
    .heading.heading-15 .paragraph {
      margin-bottom: 26px; }
    .heading.heading-15 .heading-desc {
      margin-bottom: 42px; }
  .heading.heading-16 {
    margin-top: 93px;
    margin-bottom: 24px; }
  .heading.heading-17 {
    margin-bottom: 90px; }
    @media screen and (max-width: 991.98px) {
      .heading.heading-17 {
        margin-bottom: 50px; } }
    .heading.heading-17 .heading-title {
      transform: translateY(-6px); }
    .heading.heading-17 .paragraph {
      margin-bottom: 26px; }
    .heading.heading-17 .heading-desc {
      margin-bottom: 42px; }
  .heading.heading-18 {
    margin-bottom: 100px; }
    .heading.heading-18 .heading-subtitle {
      margin-bottom: 20px; }
    .heading.heading-18 .heading-title {
      transform: translateY(-6px);
      margin-bottom: 70px; }
    .heading.heading-18 .paragraph {
      margin-bottom: 26px; }
    .heading.heading-18 .heading-desc {
      margin-bottom: 34px; }
  .heading.heading-19 {
    margin-bottom: 38px; }
    .heading.heading-19 .heading-title {
      margin-bottom: 29px; }

/*------------------------------------*\
	#Slider
\*------------------------------------*/
.slider {
  padding-top: 0;
  padding-bottom: 0; }
  .slider .slide {
    padding-top: 0;
    height: 800px; }
    @media screen and (max-width: 767.98px) {
      .slider .slide {
        height: 700px; } }
    @media (min-width: 992px) and (max-width: 1250px) {
      .slider .slide {
        height: auto; } }
    .slider .slide .slide-content {
      padding-top: 321px;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .slider .slide .slide-content {
          padding-bottom: 100px; } }
      @media screen and (max-width: 991.98px) {
        .slider .slide .slide-content {
          align-items: center; } }
      @media screen and (max-width: 767.98px) {
        .slider .slide .slide-content {
          padding-top: 200px; } }
      .slider .slide .slide-content .slide-subheadline {
        font-family: var(--global--font-body);
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        color: var(--global--color-gray);
        margin-bottom: 20px;
        text-transform: capitalize; }
        @media screen and (max-width: 991.98px) {
          .slider .slide .slide-content .slide-subheadline {
            text-align: center;
            line-height: 1.5; } }
      .slider .slide .slide-content .slide-headline {
        font-family: var(--global--font-heading);
        font-weight: 500;
        font-size: 75px;
        line-height: 70px;
        color: var(--global--color-white);
        margin-bottom: 24px;
        text-transform: capitalize; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .slider .slide .slide-content .slide-headline {
            font-size: 35px;
            line-height: 1.4; } }
        @media screen and (max-width: 767.98px) {
          .slider .slide .slide-content .slide-headline {
            font-size: 32px;
            line-height: 1.4; } }
        @media screen and (max-width: 991.98px) {
          .slider .slide .slide-content .slide-headline {
            text-align: center; } }
      .slider .slide .slide-content .slide-desc {
        font-family: var(--global--font-body);
        font-weight: 700;
        font-size: 17px;
        line-height: 26px;
        color: var(--global--color-gray);
        margin-bottom: 33px; }
        @media screen and (max-width: 991.98px) {
          .slider .slide .slide-content .slide-desc {
            text-align: center; } }
      .slider .slide .slide-content .slide-action {
        margin-bottom: 0px; }
        @media screen and (max-width: 767.98px) {
          .slider .slide .slide-content .slide-action {
            display: flex;
            flex-direction: column;
            align-items: center; } }
        .slider .slide .slide-content .slide-action .btn {
          font-family: var(--global--font-body);
          font-size: 15px;
          font-weight: 700;
          display: inline-flex;
          width: 170px;
          height: 62px;
          border-radius: 8px 8px 0 8px;
          margin-right: 30px; }
          .slider .slide .slide-content .slide-action .btn:last-child {
            margin-right: 0;
            width: 182px; }
            @media screen and (max-width: 767.98px) {
              .slider .slide .slide-content .slide-action .btn:last-child {
                width: 190px; } }
          @media (min-width: 992px) and (max-width: 1199px) {
            .slider .slide .slide-content .slide-action .btn {
              width: 170px; } }
          @media screen and (max-width: 767.98px) {
            .slider .slide .slide-content .slide-action .btn {
              margin-right: 0;
              margin-bottom: 15px;
              height: 50px;
              width: 190px; } }
          .slider .slide .slide-content .slide-action .btn.btn--white {
            box-shadow: 2.121px 2.121px 3px rgba(3, 21, 50, 0.05); }
      @media screen and (max-width: 767.98px) {
        .slider .slide .slide-content .slide-list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center; } }
      .slider .slide .slide-content .slide-list .icon {
        margin-right: 30px;
        display: inline-block; }
        @media screen and (max-width: 767.98px) {
          .slider .slide .slide-content .slide-list .icon {
            margin-right: 0;
            margin: 0 15px 25px; } }
        .slider .slide .slide-content .slide-list .icon:last-child {
          margin-right: 0; }
        .slider .slide .slide-content .slide-list .icon i {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px 8px 0 8px;
          width: 90px;
          height: 90px;
          background-color: var(--global--color-white);
          margin-bottom: 9px; }
          .slider .slide .slide-content .slide-list .icon i::before {
            margin-left: 0;
            font-size: 50px;
            color: var(--global--color-heading); }
        .slider .slide .slide-content .slide-list .icon p {
          text-align: center;
          font-size: 15px;
          font-weight: 700;
          color: var(--global--color-white);
          line-height: 24px;
          margin-bottom: 0;
          text-transform: capitalize; }
  .slider .carousel-navs .owl-nav {
    right: auto;
    top: auto;
    position: initial; }
    @media screen and (max-width: 991.98px) {
      .slider .carousel-navs .owl-nav {
        display: none; } }
    .slider .carousel-navs .owl-nav span {
      font-size: 0; }
    .slider .carousel-navs .owl-nav .owl-next:before,
    .slider .carousel-navs .owl-nav .owl-prev:before {
      color: rgba(255, 255, 255, 0.5);
      font-family: "carousel" !important;
      font-size: 44px;
      transition: 0.3s ease-in-out; }
    .slider .carousel-navs .owl-nav .owl-prev:before {
      content: "\e990"; }
    .slider .carousel-navs .owl-nav .owl-next:before {
      content: "\e991"; }
    .slider .carousel-navs .owl-nav .owl-next,
    .slider .carousel-navs .owl-nav .owl-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      .slider .carousel-navs .owl-nav .owl-next:hover::before,
      .slider .carousel-navs .owl-nav .owl-prev:hover::before {
        color: white; }
    .slider .carousel-navs .owl-nav .owl-next {
      right: 50px; }
    .slider .carousel-navs .owl-nav .owl-prev {
      margin-right: 0;
      left: 50px; }
  .slider .carousel-dots {
    position: relative; }
    .slider .carousel-dots button.owl-dot,
    .slider .carousel-dots button.owl-dot:focus {
      padding: 0;
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none; }
    .slider .carousel-dots .owl-dots {
      display: flex;
      justify-content: center;
      z-index: 5; }
      @media screen and (min-width: 992px) {
        .slider .carousel-dots .owl-dots {
          right: auto; } }
      .slider .carousel-dots .owl-dots .owl-dot {
        margin-right: 20px;
        display: inline-flex; }
        .slider .carousel-dots .owl-dots .owl-dot:last-child {
          margin-bottom: 0; }
        .slider .carousel-dots .owl-dots .owl-dot.active span::before {
          background-color: rgba(var(--global--color-white), 0.3); }
        .slider .carousel-dots .owl-dots .owl-dot span {
          display: inline-block;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: var(--global--color-white);
          transition: 0.3s ease-in-out;
          margin-right: 0;
          position: relative; }
          .slider .carousel-dots .owl-dots .owl-dot span::before {
            content: '';
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);
            width: 25px;
            height: 25px;
            background-color: transparent;
            transition: 0.3s ease-in-out; }
  .slider.slider-2 .slide {
    height: 780px; }
    @media (min-width: 992px) and (max-width: 1250px) {
      .slider.slider-2 .slide {
        height: auto; } }
    @media screen and (max-width: 767.98px) {
      .slider.slider-2 .slide {
        height: auto;
        padding-bottom: 180px; } }
  .slider.slider-2 .slide-content {
    padding-top: 254px; }
    @media screen and (max-width: 767.98px) {
      .slider.slider-2 .slide-content {
        padding-top: 200px; } }
    @media (min-width: 992px) and (max-width: 1250px) {
      .slider.slider-2 .slide-content {
        padding-bottom: 80px; } }
  @media (min-width: 992px) and (max-width: 1250px) {
    .slider.slider-3 .slide {
      height: 850px; } }
  .slider.slider-3 .slide .slide-content {
    padding-top: 293px; }
    @media screen and (max-width: 767.98px) {
      .slider.slider-3 .slide .slide-content {
        padding-top: 200px; } }
    @media screen and (max-width: 991.98px) {
      .slider.slider-3 .slide .slide-content .slide-action {
        text-align: center; } }
    .slider.slider-3 .slide .slide-content .slide-action .btn-line:last-child .line span {
      background-color: var(--global--color-white); }
  .slider.slider-4 .slide {
    background-position: 50% -100%;
    height: 777px; }
    @media screen and (max-width: 767.98px) {
      .slider.slider-4 .slide {
        height: 730px; } }
    @media (min-width: 992px) and (max-width: 1250px) {
      .slider.slider-4 .slide {
        height: 850px; } }
    .slider.slider-4 .slide .slide-content {
      padding-top: 270px; }
      @media (min-width: 992px) and (max-width: 1250px) {
        .slider.slider-4 .slide .slide-content {
          padding-bottom: 100px; } }
      @media screen and (max-width: 767.98px) {
        .slider.slider-4 .slide .slide-content {
          padding-top: 210px; } }
      .slider.slider-4 .slide .slide-content .slide-action .btn:first-child {
        width: 180px; }
  .slider.slider-5 .slide {
    height: 784px;
    background-position: 50% -85%; }
    @media screen and (max-width: 767.98px) {
      .slider.slider-5 .slide {
        height: 680px; } }
    .slider.slider-5 .slide .slide-content {
      padding-top: 308px; }
      @media screen and (max-width: 767.98px) {
        .slider.slider-5 .slide .slide-content {
          padding-top: 200px; } }

.hero {
  padding-bottom: 0; }
  .hero .hero-content {
    padding-top: 185px;
    padding-bottom: 26px; }
    @media screen and (max-width: 991.98px) {
      .hero .hero-content {
        text-align: center;
        padding-top: 120px; } }
    .hero .hero-content .hero-subtitle {
      font-family: var(--global--font-body);
      color: var(--global--color-gray);
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 15px; }
      @media screen and (max-width: 991.98px) {
        .hero .hero-content .hero-subtitle {
          line-height: 1.5; } }
    .hero .hero-content .hero-title {
      font-family: var(--global--font-heading);
      color: var(--global--color-white);
      font-weight: 500;
      font-size: 40px;
      line-height: 55px;
      margin-bottom: 19px;
      text-transform: capitalize; }
      @media (min-width: 992px) and (max-width: 1199px) {
        .hero .hero-content .hero-title {
          font-size: 35px;
          line-height: 1.4; } }
      @media screen and (max-width: 767.98px) {
        .hero .hero-content .hero-title {
          font-size: 32px;
          line-height: 1.4; } }
    .hero .hero-content .hero-desc {
      font-family: var(--global--font-body);
      color: var(--global--color-gray);
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 31px; }
    .hero .hero-content .hero-action {
      margin-bottom: 105px; }
      @media screen and (max-width: 767.98px) {
        .hero .hero-content .hero-action {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 70px; } }
      .hero .hero-content .hero-action .btn {
        font-family: var(--global--font-body);
        font-size: 15px;
        font-weight: 700;
        display: inline-flex;
        width: 210px;
        height: 60px;
        margin-right: 30px; }
        @media screen and (max-width: 767.98px) {
          .hero .hero-content .hero-action .btn {
            margin-right: 0;
            margin-bottom: 15px;
            height: 50px;
            width: 210px; } }
        @media (min-width: 992px) and (max-width: 1250px) {
          .hero .hero-content .hero-action .btn {
            width: 170px;
            margin-bottom: 15px; } }
        .hero .hero-content .hero-action .btn:last-child {
          margin-right: 0; }
        .hero .hero-content .hero-action .btn.btn--white {
          box-shadow: 2.121px 2.121px 3px rgba(3, 21, 50, 0.05); }
    @media screen and (max-width: 991.98px) {
      .hero .hero-content .breadcrumb {
        justify-content: center; } }
    .hero .hero-content .breadcrumb .breadcrumb-item + .breadcrumb-item {
      padding-left: 0;
      display: flex;
      align-items: center; }
      .hero .hero-content .breadcrumb .breadcrumb-item + .breadcrumb-item a {
        font-size: 14px;
        line-height: 1; }
    .hero .hero-content .breadcrumb > li + li:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      font-size: 10px;
      padding: 0 10px;
      color: var(--global--color-white);
      content: "\f054"; }
  .hero .hero-panel-holder {
    display: flex;
    justify-content: flex-end; }
    @media screen and (max-width: 991.98px) {
      .hero .hero-panel-holder {
        justify-content: center;
        margin-bottom: 30px; } }
    .hero .hero-panel-holder .hero-panel {
      background-color: var(--global--color-white);
      border-radius: 8px 0 8px 8px;
      padding: 26px 40px 39px;
      max-width: 290px; }
      .hero .hero-panel-holder .hero-panel .panel-content {
        display: flex;
        flex-direction: column; }
        @media screen and (max-width: 991.98px) {
          .hero .hero-panel-holder .hero-panel .panel-content {
            align-items: center; } }
        .hero .hero-panel-holder .hero-panel .panel-content i {
          color: #51668a;
          margin-bottom: 12px;
          display: inline-block; }
          .hero .hero-panel-holder .hero-panel .panel-content i::before {
            margin-left: 0;
            font-size: 49px; }
        .hero .hero-panel-holder .hero-panel .panel-content h5 {
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-size: 19px;
          line-height: 28px;
          color: var(--global--color-heading);
          margin-bottom: 14px;
          text-transform: capitalize; }
        .hero .hero-panel-holder .hero-panel .panel-content p {
          font-family: var(--global--font-body);
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--global--color-body);
          margin-bottom: 21px; }
        .hero .hero-panel-holder .hero-panel .panel-content .btn {
          width: 155px;
          height: 42px;
          border-radius: 8px 8px 0 8px;
          border: 2px solid;
          transition-property: background, color, border-color;
          border-color: #687596; }
          .hero .hero-panel-holder .hero-panel .panel-content .btn::before {
            background-color: var(--global--color-secondary);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            top: -2px;
            left: -2px; }
          .hero .hero-panel-holder .hero-panel .panel-content .btn:hover {
            border-color: #213360; }
      .hero .hero-panel-holder .hero-panel.hero-panel-dark {
        background-color: var(--global--color-heading-darken); }
        .hero .hero-panel-holder .hero-panel.hero-panel-dark .panel-content i,
        .hero .hero-panel-holder .hero-panel.hero-panel-dark .panel-content h5,
        .hero .hero-panel-holder .hero-panel.hero-panel-dark .panel-content p {
          color: var(--global--color-white); }
        .hero .hero-panel-holder .hero-panel.hero-panel-dark .panel-content .btn {
          background-color: transparent;
          color: var(--global--color-white);
          border-color: #7284b9;
          border: 2px solid;
          transition-property: background, color, border-color; }
          .hero .hero-panel-holder .hero-panel.hero-panel-dark .panel-content .btn::before {
            background-color: var(--global--color-primary);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            top: -2px;
            left: -2px; }
          .hero .hero-panel-holder .hero-panel.hero-panel-dark .panel-content .btn:hover {
            border-color: var(--global--color-primary);
            color: var(--global--color-white); }
    .hero .hero-panel-holder .phone-card {
      display: flex;
      background-color: var(--global--color-heading-darken);
      padding: 35px 40px 31px;
      border-radius: 0 32px 0 0;
      max-width: 470px; }
      @media screen and (min-width: 992px) {
        .hero .hero-panel-holder .phone-card {
          transform: translateY(80px); } }
      @media screen and (max-width: 767.98px) {
        .hero .hero-panel-holder .phone-card {
          flex-direction: column;
          align-items: center;
          text-align: center; } }
      .hero .hero-panel-holder .phone-card .card-icon {
        flex-shrink: 0;
        margin-right: 30px; }
        @media screen and (max-width: 767.98px) {
          .hero .hero-panel-holder .phone-card .card-icon {
            margin-right: 0; } }
        .hero .hero-panel-holder .phone-card .card-icon i::before {
          display: flex;
          transform: translateY(-4px);
          font-size: 50px;
          margin-left: 0;
          color: var(--global--color-white); }
      .hero .hero-panel-holder .phone-card .card-content h5 {
        font-weight: 500;
        font-size: 19px;
        line-height: 28px;
        color: var(--global--color-white);
        text-transform: capitalize;
        margin-bottom: 16px; }
      .hero .hero-panel-holder .phone-card .card-content p {
        font-size: 14px;
        color: var(--global--color-gray);
        line-height: 24px;
        margin-bottom: 15px; }
      .hero .hero-panel-holder .phone-card .card-content a {
        display: flex;
        align-items: center;
        transition: 0.3s ease-in-out;
        color: var(--global--color-white); }
        @media screen and (max-width: 991.98px) {
          .hero .hero-panel-holder .phone-card .card-content a {
            justify-content: center; } }
        .hero .hero-panel-holder .phone-card .card-content a span {
          transition: 0.3s ease-in-out;
          font-family: var(--global--font-heading);
          font-weight: 400;
          font-size: 24px; }
        .hero .hero-panel-holder .phone-card .card-content a i {
          margin-bottom: 4px;
          transition: 0.3s ease-in-out;
          font-size: 19px;
          margin-right: 15px; }
    .hero .hero-panel-holder.hero-panel-holder-2 {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end; }
      @media screen and (max-width: 991.98px) {
        .hero .hero-panel-holder.hero-panel-holder-2 {
          align-items: center; } }
  .hero .hero-rating {
    display: flex;
    align-items: center; }
    .hero .hero-rating .num {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 37px;
      line-height: 22px;
      color: var(--global--color-white);
      margin-right: 18px; }
    .hero .hero-rating .rating-body p {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: var(--global--color-gray);
      margin-bottom: 0; }
      .hero .hero-rating .rating-body p a {
        font-family: var(--global--font-body);
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        color: var(--global--color-secondary);
        text-transform: capitalize; }
  .hero.hero-1 {
    background-position-y: 150px; }
    @media screen and (max-width: 991.98px) {
      .hero.hero-1 {
        background-position-y: 100px; } }
    .hero.hero-1 .hero-content .hero-action .btn:last-child {
      width: 180px; }
  .hero.hero-2 {
    background-position-y: 150px; }
    @media screen and (max-width: 991.98px) {
      .hero.hero-2 {
        background-position-y: 100px; } }
    .hero.hero-2 .hero-content .hero-action {
      margin-bottom: 35px; }
      .hero.hero-2 .hero-content .hero-action .btn:first-child {
        border-radius: 8px 8px 0 8px; }
      .hero.hero-2 .hero-content .hero-action .btn:last-child {
        width: 180px;
        border-radius: 8px 8px 0 8px; }
    .hero.hero-2 .hero-content .breadcrumb {
      margin-top: 70px;
      justify-content: center; }
  .hero.hero-3 {
    overflow: visible;
    background-position-y: 150px; }
    @media screen and (max-width: 991.98px) {
      .hero.hero-3 {
        background-position-y: 100px; } }
    .hero.hero-3 .hero-content .hero-action {
      margin-bottom: 35px; }
      .hero.hero-3 .hero-content .hero-action .btn:first-child {
        border-radius: 8px 8px 0 8px; }
      .hero.hero-3 .hero-content .hero-action .btn:last-child {
        width: 180px;
        border-radius: 8px 8px 0 8px; }
    .hero.hero-3 .hero-content .breadcrumb {
      margin-top: 70px;
      justify-content: center; }
    @media screen and (min-width: 992px) {
      .hero.hero-3 .hero-panel-holder .hero-panel {
        transform: translateY(180px); } }
  .hero.hero-4 {
    overflow: visible;
    background-position-y: 100px; }
    .hero.hero-4::before {
      background-image: linear-gradient(180deg, #13C1D9BF 18%, var(--global--color-primary) 70%); }
    .hero.hero-4 .hero-content {
      padding-top: 144px;
      padding-bottom: 0; }
      .hero.hero-4 .hero-content .hero-action {
        display: flex;
        margin-bottom: 40px; }
      .hero.hero-4 .hero-content .hero-rating {
        margin-bottom: 45px; }
      @media screen and (min-width: 992px) {
        .hero.hero-4 .hero-content .team-modern .team-member {
          max-width: 330px;
          transform: translateY(359px);
          margin-top: -359px;
          box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06); } }
      .hero.hero-4 .hero-content .content-merged {
        flex-direction: column;
        justify-content: flex-end;
        display: flex; }
        @media screen and (max-width: 991.98px) {
          .hero.hero-4 .hero-content .content-merged {
            align-items: center; } }
  .hero.hero-5 {
    background-position-y: 150px; }
    @media screen and (max-width: 991.98px) {
      .hero.hero-5 {
        background-position-y: 100px; } }
    .hero.hero-5 .hero-content {
      padding-top: 165px; }
      .hero.hero-5 .hero-content .hero-desc {
        font-weight: 700; }
    .hero.hero-5 .hero-action {
      margin-bottom: 100px; }
      @media screen and (max-width: 991.98px) {
        .hero.hero-5 .hero-action {
          margin-bottom: 70px; } }
      .hero.hero-5 .hero-action .btn {
        border-radius: 8px 8px 0 8px; }
        .hero.hero-5 .hero-action .btn:first-child {
          width: 170px; }
        .hero.hero-5 .hero-action .btn:last-child {
          width: 190px; }
          @media (min-width: 992px) and (max-width: 1250px) {
            .hero.hero-5 .hero-action .btn:last-child {
              width: 170px; } }
  .hero.hero-6 .hero-content .hero-title {
    margin-bottom: 36px; }
  .hero.hero-6 .hero-content .hero-action {
    display: flex;
    align-items: center; }
    .hero.hero-6 .hero-content .hero-action .btn {
      width: 180px;
      height: 60px;
      border-radius: 8px 8px 0 8px; }
    @media (min-width: 768px) and (max-width: 992px) {
      .hero.hero-6 .hero-content .hero-action {
        justify-content: center; } }
    @media screen and (max-width: 767.98px) {
      .hero.hero-6 .hero-content .hero-action {
        flex-direction: column;
        align-items: center; } }
  .hero.hero-7 .hero-content {
    text-align: center;
    padding-top: 175px; }
    .hero.hero-7 .hero-content .hero-title {
      margin-bottom: 27px; }
    .hero.hero-7 .hero-content .hero-action {
      margin-bottom: 245px; }
      .hero.hero-7 .hero-content .hero-action .btn {
        border-radius: 8px 8px 0 8px;
        width: 170px;
        height: 60px; }
  .hero.hero-8 {
    overflow: visible; }
    .hero.hero-8 .hero-content {
      padding-top: 165px; }
      .hero.hero-8 .hero-content .hero-action {
        margin-bottom: 90px; }
        @media screen and (max-width: 991.98px) {
          .hero.hero-8 .hero-content .hero-action {
            margin-bottom: 50px; } }
        .hero.hero-8 .hero-content .hero-action .btn {
          width: 180px;
          height: 60px;
          border-radius: 8px 8px 0 8px; }
  .hero.hero-9 .hero-content {
    padding-top: 147px; }
    .hero.hero-9 .hero-content .hero-title {
      font-size: 55px;
      margin-bottom: 24px; }
      @media screen and (max-width: 767.98px) {
        .hero.hero-9 .hero-content .hero-title {
          font-size: 32px;
          line-height: 1.4; } }
    .hero.hero-9 .hero-content .hero-desc {
      font-size: 17px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 32px; }
    .hero.hero-9 .hero-content .hero-action {
      display: flex;
      align-items: center;
      margin-bottom: 80px; }
      .hero.hero-9 .hero-content .hero-action .btn {
        width: 180px;
        height: 60px;
        border-radius: 8px 8px 0 8px; }
      @media (min-width: 768px) and (max-width: 992px) {
        .hero.hero-9 .hero-content .hero-action {
          justify-content: center; } }
      @media screen and (max-width: 767.98px) {
        .hero.hero-9 .hero-content .hero-action {
          flex-direction: column;
          align-items: center; } }

/*------------------------------------*\
    #Blog Section
\*------------------------------------*/
.blog {
  padding: 127px 0 70px; }

.blog-entry {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
  border-radius: 8px 8px 8px 0;
  background-color: var(--global--color-white); }
  @media screen and (max-width: 991.98px) {
    .blog-entry {
      max-width: 370px;
      margin: 40px auto 40px; } }
  .blog-entry:hover .entry-img img {
    transform: scale3d(1, 1, 1); }
  .blog-entry:hover .entry-img::before {
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }
  .blog-entry .entry-img {
    position: relative;
    overflow: hidden;
    margin-right: 40px;
    border-radius: 8px 8px 8px 0px;
    transform: translateY(-40px);
    margin-bottom: -40px; }
    .blog-entry .entry-img::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      content: '';
      z-index: 1;
      transition: transform 0.6s;
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }
    .blog-entry .entry-img img {
      transition: transform 0.35s;
      max-width: 100%;
      height: auto;
      transform: scale3d(1.05, 1.05, 1); }
    .blog-entry .entry-img .entry-date {
      position: absolute;
      z-index: 2;
      top: 30px;
      right: 30px;
      padding: 18px 0;
      background-color: var(--global--color-white);
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
      border-radius: 8px 8px 8px 0px;
      text-align: center; }
      .blog-entry .entry-img .entry-date .entry-content {
        color: var(--global--color-heading-darken);
        font-family: var(--global--font-body);
        padding: 0 15px;
        position: relative;
        display: inline-flex;
        flex-direction: column; }
        .blog-entry .entry-img .entry-date .entry-content::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 2px;
          top: 0;
          left: 0;
          background-color: var(--global--color-primary); }
        .blog-entry .entry-img .entry-date .entry-content .day {
          font-weight: 700;
          font-size: 26px;
          line-height: 22px;
          margin-bottom: 4px; }
        .blog-entry .entry-img .entry-date .entry-content .year,
        .blog-entry .entry-img .entry-date .entry-content .month {
          text-transform: capitalize;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px; }
  .blog-entry .entry-content {
    padding: 22px 40px 40px;
    position: relative; }
    @media screen and (max-width: 767.98px) {
      .blog-entry .entry-content {
        padding-left: 25px;
        padding-right: 25px; } }
  .blog-entry .entry-meta {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    .blog-entry .entry-meta .entry-category {
      margin-bottom: 0px;
      text-transform: capitalize; }
      .blog-entry .entry-meta .entry-category a {
        position: relative;
        color: var(--global--color-primary);
        font-family: var(--global--font-body);
        font-size: 13px;
        font-weight: 400;
        line-height: 26px; }
        .blog-entry .entry-meta .entry-category a::after {
          content: ", ";
          position: relative; }
        .blog-entry .entry-meta .entry-category a:last-of-type::after {
          content: ""; }
        .blog-entry .entry-meta .entry-category a:hover {
          color: var(--global--color-heading); }
    .blog-entry .entry-meta .divider {
      width: 5px;
      height: 5px;
      background-color: #dedfe3;
      border-radius: 50%;
      margin: 0 10px; }
    .blog-entry .entry-meta .entry-author {
      text-transform: capitalize; }
      .blog-entry .entry-meta .entry-author p {
        font-family: var(--global--font-body);
        font-size: 13px;
        font-weight: 400;
        line-height: 26px;
        color: var(--global--color-heading-darken);
        margin-bottom: 0; }
    .blog-entry .entry-meta .entry-comments {
      text-transform: capitalize;
      font-family: var(--global--font-body);
      font-size: 13px;
      font-weight: 400;
      line-height: 26px;
      color: var(--global--color-heading-darken); }
      .blog-entry .entry-meta .entry-comments .num {
        color: var(--global--color-body); }
  .blog-entry .entry-title {
    margin-bottom: 27px; }
    .blog-entry .entry-title h4 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 0;
      text-transform: capitalize; }
    .blog-entry .entry-title a {
      color: var(--global--color-heading); }
      .blog-entry .entry-title a:hover {
        color: var(--global--color-primary); }
  .blog-entry .entry-bio p {
    font-family: var(--global--font-body);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px; }
  .blog-entry .entry-more a {
    width: 124px;
    height: 42px;
    position: relative;
    transition: all 300ms ease-in-out;
    color: var(--global--color-secondary);
    border: 2px solid;
    border-color: #687696;
    transition-property: background, color, border-color; }
    .blog-entry .entry-more a::before {
      background-color: var(--global--color-secondary);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px; }
    .blog-entry .entry-more a:hover {
      color: var(--global--color-white);
      border-color: var(--global--color-secondary); }

/* Blog Grid */
.blog-grid {
  padding: 70px 0 110px; }
  .blog-grid .owl-carousel .owl-stage-outer {
    overflow: visible; }
    .blog-grid .owl-carousel .owl-stage-outer .owl-item {
      opacity: 0; }
      .blog-grid .owl-carousel .owl-stage-outer .owl-item.active {
        opacity: 1; }
  .blog-grid .owl-carousel .owl-dots {
    position: relative;
    right: auto;
    bottom: auto;
    justify-content: center; }
    @media screen and (max-width: 991.98px) {
      .blog-grid .owl-carousel .owl-dots {
        margin-top: 0; } }
    .blog-grid .owl-carousel .owl-dots .owl-dot {
      display: inline-flex; }
  .blog-grid.blog-grid-2 {
    padding-top: 510px; }
  .blog-grid.blog-grid-3 {
    padding-top: 130px; }
    @media screen and (max-width: 991.98px) {
      .blog-grid.blog-grid-3 {
        padding-top: 70px;
        padding-bottom: 70px; } }
  .blog-grid.blog-grid-4 {
    padding-top: 218px; }
    @media screen and (max-width: 991.98px) {
      .blog-grid.blog-grid-4 {
        padding-top: 70px;
        padding-bottom: 70px; } }

/* Blog Standard */
.blog-standard {
  padding-top: 0; }
  .blog-standard .blog-entry {
    margin-bottom: 80px; }

/* Blog Single */
.blog-single {
  padding-top: 0px;
  padding-bottom: 110px; }
  .blog-single .blog-entry {
    box-shadow: none;
    margin-top: 0; }
    @media screen and (max-width: 991.98px) {
      .blog-single .blog-entry {
        max-width: initial; } }
    .blog-single .blog-entry:last-of-type {
      margin-bottom: 0px; }
    .blog-single .blog-entry .entry-img {
      margin-bottom: 0;
      transform: translateY(0);
      margin-right: 0; }
      .blog-single .blog-entry .entry-img::before {
        content: unset; }
      .blog-single .blog-entry .entry-img .entry-date {
        right: auto;
        left: 40px; }
    .blog-single .blog-entry .entry-meta {
      margin-bottom: 21px; }
      @media screen and (max-width: 767.98px) {
        .blog-single .blog-entry .entry-meta {
          flex-direction: row; }
          .blog-single .blog-entry .entry-meta div {
            margin-right: 5px; } }
      @media screen and (max-width: 767.98px) {
        .blog-single .blog-entry .entry-meta .divider {
          display: none; } }
      .blog-single .blog-entry .entry-meta .entry-author a p {
        transition: 0.3s ease-in-out; }
      .blog-single .blog-entry .entry-meta .entry-author a:hover p {
        color: var(--global--color-primary); }
  .blog-single .entry-holder {
    padding-top: 9px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--global--color-gray-light);
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 767.98px) {
      .blog-single .entry-holder {
        flex-direction: column; } }
    .blog-single .entry-holder .entry-share {
      display: flex;
      align-items: center;
      flex-wrap: nowrap; }
      @media screen and (max-width: 991.98px) {
        .blog-single .entry-holder .entry-share {
          flex-wrap: wrap; } }
      .blog-single .entry-holder .entry-share span {
        font-family: var(--global--font-heading);
        font-weight: 500;
        font-size: 15px;
        color: var(--global--color-heading);
        margin-right: 20px;
        text-transform: capitalize; }
      .blog-single .entry-holder .entry-share a {
        color: var(--global--color-white);
        background-color: var(--global--color-heading);
        border-radius: 8px 8px 0 8px;
        margin-right: 15px;
        transition: 0.3s ease-in-out;
        width: 50px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-in-out; }
        @media screen and (min-width: 992px) {
          .blog-single .entry-holder .entry-share a {
            margin-right: 10px; } }
        .blog-single .entry-holder .entry-share a:last-child {
          margin-right: 0; }
        .blog-single .entry-holder .entry-share a:hover {
          background-color: var(--global--color-primary); }
        .blog-single .entry-holder .entry-share a.facebook-bg {
          background-color: #3b5999; }
          .blog-single .entry-holder .entry-share a.facebook-bg:hover {
            background-color: var(--global--color-heading); }
        .blog-single .entry-holder .entry-share a.twitter-bg {
          background-color: #55acee; }
          .blog-single .entry-holder .entry-share a.twitter-bg:hover {
            background-color: var(--global--color-heading); }
        .blog-single .entry-holder .entry-share a.instagram-bg {
          background-color: #0092ff; }
          .blog-single .entry-holder .entry-share a.instagram-bg:hover {
            background-color: var(--global--color-heading); }
    @media screen and (max-width: 767.98px) {
      .blog-single .entry-holder .entry-tags {
        margin-bottom: 30px; } }
    .blog-single .entry-holder .entry-tags a {
      margin-right: 10px;
      display: inline-block;
      font-family: var(--global--font-body);
      font-size: 12px;
      font-weight: 400;
      line-height: 28px;
      text-transform: capitalize;
      transition: 0.3s ease-in-out;
      color: var(--global--color-primary);
      background-color: var(--global--color-white);
      border: 1px solid var(--global--color-gray-light);
      border-radius: 8px 8px 0 8px;
      padding: 0 9px; }
      .blog-single .entry-holder .entry-tags a:hover {
        background-color: var(--global--color-primary);
        color: var(--global--color-white);
        border-color: var(--global--color-primary); }
  .blog-single .entry-content {
    padding: 32px 40px 34px; }
    @media screen and (max-width: 991.98px) {
      .blog-single .entry-content {
        padding: 32px 0; } }
    .blog-single .entry-content .entry-title {
      margin-bottom: 31px; }
      .blog-single .entry-content .entry-title h4 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700; }
        @media screen and (max-width: 767.98px) {
          .blog-single .entry-content .entry-title h4 {
            font-size: 32px;
            line-height: 1.4; } }
    .blog-single .entry-content .entry-bio p {
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 27px; }
      .blog-single .entry-content .entry-bio p:last-child {
        margin-bottom: 0; }
  .blog-single .nav-posts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    text-transform: capitalize;
    border-bottom: 1px solid var(--global--color-gray-light); }
    @media screen and (max-width: 991.98px) {
      .blog-single .nav-posts {
        flex-direction: column;
        justify-content: center; } }
    .blog-single .nav-posts .prev-post,
    .blog-single .nav-posts .next-post {
      display: flex;
      border-radius: 8px 8px 8px 0;
      padding: 20px;
      background-color: var(--global--color-white);
      transition: 0.3s ease-in-out; }
      .blog-single .nav-posts .prev-post:hover,
      .blog-single .nav-posts .next-post:hover {
        background-color: var(--global--color-primary-light); }
        .blog-single .nav-posts .prev-post:hover .post-img .overlay,
        .blog-single .nav-posts .next-post:hover .post-img .overlay {
          background-color: var(--global--color-primary);
          transform: scale(1); }
      .blog-single .nav-posts .prev-post .post-img,
      .blog-single .nav-posts .next-post .post-img {
        flex-basis: 90px / 67px;
        flex-shrink: 0;
        width: 90px;
        height: 67px;
        border-radius: 8px 8px 8px 0;
        margin-right: 20px;
        position: relative;
        overflow: hidden; }
        .blog-single .nav-posts .prev-post .post-img .overlay,
        .blog-single .nav-posts .next-post .post-img .overlay {
          content: '\e901';
          transition: 0.3s ease-in-out;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          font-family: 'icomoon';
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(var(--global--color-primary), 0.9);
          color: var(--global--color-white);
          transition: 0.3s ease-in-out;
          transform: scale(0); }
        .blog-single .nav-posts .prev-post .post-img img,
        .blog-single .nav-posts .next-post .post-img img {
          width: 100%;
          height: 1005; }
      .blog-single .nav-posts .prev-post .post-body span,
      .blog-single .nav-posts .next-post .post-body span {
        display: block;
        font-family: var(--global--font-body);
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: var(--global--color-body); }
      .blog-single .nav-posts .prev-post .post-body .post-link,
      .blog-single .nav-posts .next-post .post-body .post-link {
        font-family: var(--global--font-heading);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: var(--global--color-heading);
        transition: 300ms ease-in-out; }
        .blog-single .nav-posts .prev-post .post-body .post-link:hover,
        .blog-single .nav-posts .next-post .post-body .post-link:hover {
          color: var(--global--color-primary); }
      @media screen and (min-width: 992px) {
        .blog-single .nav-posts .prev-post,
        .blog-single .nav-posts .next-post {
          flex-basis: 50%; } }
    .blog-single .nav-posts .prev-post .post-img .overlay i {
      transform: rotate(180deg); }
    @media screen and (max-width: 991.98px) {
      .blog-single .nav-posts .prev-post {
        margin-bottom: 38px; } }
    .blog-single .nav-posts .next-post {
      text-align: right; }
      .blog-single .nav-posts .next-post .post-img {
        margin-right: 0;
        margin-left: 20px; }
  .blog-single .entry-widget {
    padding-top: 62px; }
    .blog-single .entry-widget .entry-widget-title {
      position: relative;
      margin-bottom: 21px; }
      .blog-single .entry-widget .entry-widget-title h4 {
        font-family: var(--global--font-heading);
        font-size: 21px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 0;
        position: relative;
        text-transform: capitalize;
        color: var(--global--color-heading); }
  .blog-single .entry-widget-bio {
    margin-top: 40px;
    padding: 40px 40px 35px;
    border-radius: 8px 8px 8px 0;
    background-color: var(--global--color-primary-light);
    position: relative; }
    @media screen and (max-width: 767.98px) {
      .blog-single .entry-widget-bio {
        padding-right: 20px;
        padding-left: 20px; } }
    .blog-single .entry-widget-bio::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: calc(100% - 80px);
      background-color: var(--global--color-primary); }
      @media screen and (max-width: 767.98px) {
        .blog-single .entry-widget-bio::before {
          width: calc(100% - 40px); } }
    .blog-single .entry-widget-bio .entry-widget-content {
      position: relative;
      display: flex; }
      @media screen and (max-width: 767.98px) {
        .blog-single .entry-widget-bio .entry-widget-content {
          flex-direction: column;
          align-items: center; } }
      .blog-single .entry-widget-bio .entry-widget-content img {
        width: 70px;
        height: 70px;
        border-radius: 8px 8px 8px 0;
        margin-right: 30px; }
        @media screen and (max-width: 767.98px) {
          .blog-single .entry-widget-bio .entry-widget-content img {
            margin-right: 0;
            margin-bottom: 25px; } }
      @media screen and (max-width: 767.98px) {
        .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc {
          text-align: center; } }
      .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc h4 {
        font-family: var(--global--font-heading);
        color: var(--global--color-heading);
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 10px; }
      .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc p {
        color: var(--global--color-body);
        font-size: 15px;
        font-family: var(--global--font-body);
        font-weight: 400;
        line-height: 23px;
        margin-bottom: 22px; }
      .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc a {
        color: var(--global--color-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        margin-right: 18px;
        transition: 0.3s ease-in-out; }
        .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc a:hover {
          color: var(--global--color-primary); }
      .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc a:last-child {
        margin-right: 0; }
  .blog-single .entry-comments .entry-widget-content .comments-list {
    width: 100%;
    list-style: none;
    overflow: hidden;
    padding-left: 0;
    margin-bottom: 0; }
    .blog-single .entry-comments .entry-widget-content .comments-list .comment-body {
      position: relative;
      margin-bottom: 30px;
      display: flex; }
      @media screen and (max-width: 767.98px) {
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body {
          flex-direction: column; } }
      .blog-single .entry-comments .entry-widget-content .comments-list .comment-body:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0; }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body:last-child .comment {
          border-bottom: none; }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body:last-child .replies-list {
          margin-top: 25px;
          padding-top: 30px;
          border-top: 2px solid var(--global--color-gray-light); }
      .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list {
        margin-top: 25px;
        padding-top: 30px;
        border-top: 2px solid var(--global--color-gray-light); }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list li {
          position: relative;
          margin-bottom: 30px; }
          .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list li:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0; }
            .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list li:last-child .comment {
              border-bottom: none; }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list .comment-body .comment {
          border-bottom: 2px solid var(--global--color-gray-light); }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list .comment-body:last-child .comment {
          border: 0; }
      .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .avatar {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        overflow: hidden;
        display: flex;
        border-radius: 8px 8px 8px 0; }
        @media screen and (max-width: 767.98px) {
          .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .avatar {
            margin-left: 20px;
            margin-bottom: 30px; } }
      .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment {
        margin-left: 30px;
        padding-bottom: 10px;
        border-bottom: 2px solid var(--global--color-gray-light); }
        @media screen and (max-width: 767.98px) {
          .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment {
            margin-left: 20px; } }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment h6 {
          font-family: var(--global--font-heading);
          color: var(--global--color-heading);
          font-size: 15px;
          font-weight: 500;
          text-transform: capitalize;
          margin-bottom: 5px;
          line-height: 16px; }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .date {
          color: var(--global--color-heading-darken);
          font-family: var(--global--font-body);
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 6px; }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment p {
          font-family: var(--global--font-body);
          color: var(--global--color-body);
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 3px; }
        .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment a.reply {
          font-family: var(--global--font-body);
          font-size: 14px;
          font-weight: 700;
          text-transform: capitalize;
          color: var(--global--color-primary);
          line-height: 20px;
          display: inline-flex;
          align-items: center;
          transition: 0.3s ease-in-out;
          position: relative; }
          .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment a.reply:before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 2px;
            background-color: var(--global--color-primary);
            margin-right: 10px;
            margin-bottom: 2px;
            transition: 0.3s ease-in-out; }
          .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment a.reply:hover {
            color: var(--global--color-heading); }
            .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment a.reply:hover::before {
              margin-right: 5px; }
  .blog-single .entry-add-comment {
    padding-top: 36px; }
    .blog-single .entry-add-comment .form-control {
      margin-bottom: 30px; }
    .blog-single .entry-add-comment .custom-radio-group .custom-control {
      margin-bottom: 20px; }
    .blog-single .entry-add-comment .btn {
      font-size: 15px;
      font-family: var(--global--font-body);
      font-weight: 700;
      text-transform: capitalize;
      width: 270px;
      height: 70px;
      border-radius: 0px 8px 8px 8px;
      background-color: var(--global--color-secondary); }
      .blog-single .entry-add-comment .btn::before {
        background-color: var(--global--color-heading-darken); }
      .blog-single .entry-add-comment .btn:active, .blog-single .entry-add-comment .btn:focus, .blog-single .entry-add-comment .btn:hover {
        border-color: var(--global--color-heading-darken);
        color: var(--global--color-white); }

/*------------------------------------*\
    #Shop
\*------------------------------------*/
.shop {
  padding-top: 110px;
  padding-bottom: 110px; }
  @media screen and (max-width: 991.98px) {
    .shop {
      padding-top: 70px;
      padding-bottom: 70px; } }
  .shop .sidebar-shop {
    transform: translateY(-5px); }
  .shop.shop-2 {
    padding-top: 47px;
    padding-bottom: 61px; }
    .shop.shop-2 h5 {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 21px;
      color: var(--global--color-heading);
      text-transform: capitalize; }
      @media screen and (max-width: 991.98px) {
        .shop.shop-2 h5 {
          text-align: center; } }
  .shop.shop-3 {
    padding-top: 130px;
    padding-bottom: 80px; }
    @media screen and (max-width: 991.98px) {
      .shop.shop-3 {
        padding-top: 70px;
        padding-bottom: 70px; } }

/* Product Item */
.product-item {
  margin-bottom: 40px; }
  .product-item .product-img {
    border-radius: 8px;
    position: relative;
    overflow: hidden; }
    .product-item .product-img:hover img {
      transform: scale(1.1); }
    .product-item .product-img:hover .add-to-cart {
      opacity: 1;
      transform: translateX(-50%) translateY(0px);
      visibility: visible; }
    .product-item .product-img img {
      border-radius: 8px;
      max-width: 100%;
      transition: 0.4s ease-in-out;
      transform: scale(1); }
    .product-item .product-img .badge {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: var(--global--color-primary);
      color: var(--global--color-white);
      border-radius: 4px 4px 0 4px;
      font-family: var(--global--font-body);
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      padding: 8px 15px;
      text-transform: capitalize; }
    .product-item .product-img .add-to-cart {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(20px);
      bottom: 40px;
      width: 190px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--global--color-white);
      background-color: var(--global--color-secondary);
      text-transform: capitalize;
      border-radius: 8px 8px 0 8px;
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 15px;
      transition: 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden; }
      .product-item .product-img .add-to-cart:hover {
        background-color: var(--global--color-heading); }
      .product-item .product-img .add-to-cart i {
        font-size: 15px;
        margin-right: 10px; }
  .product-item .product-content {
    padding-top: 21px;
    text-align: center; }
    .product-item .product-content .product-title a {
      text-transform: capitalize;
      font-family: var(--global--font-heading);
      font-size: 21px;
      line-height: 32px;
      font-weight: 500;
      color: var(--global--color-heading);
      margin-bottom: 5px; }
    .product-item .product-content .product-price span {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 19px;
      color: var(--global--color-primary);
      line-height: 28px; }

/* Shop Options */
.shop-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  @media screen and (max-width: 767.98px) {
    .shop-options {
      flex-direction: column;
      align-items: center; } }
  @media screen and (max-width: 767.98px) {
    .shop-options .products-show {
      margin-bottom: 25px; } }
  .shop-options .products-show p {
    font-family: var(--global--font-body);
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0; }
  .shop-options .products-sort .nice-select {
    padding-left: 23px;
    border-radius: 0 8px 8px 8px;
    border-color: var(--global--color-gray-light);
    height: 60px;
    width: 250px;
    display: flex;
    align-items: center; }
    .shop-options .products-sort .nice-select::after {
      width: 7px;
      height: 7px;
      border-color: var(--global--color-heading);
      transform: rotate(45deg) translateY(-50%);
      right: 24px; }
    .shop-options .products-sort .nice-select .current {
      font-family: var(--global--font-body);
      font-size: 14px;
      color: var(--global--color-heading); }
    .shop-options .products-sort .nice-select .list {
      width: 100%; }

/* Shop Single */
.single-product {
  padding-top: 0;
  padding-bottom: 0; }
  .single-product .checkout-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    background-color: var(--global--color-primary-light);
    border-radius: 8px 8px 0 8px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767.98px) {
      .single-product .checkout-banner {
        flex-direction: column;
        align-items: center; } }
    .single-product .checkout-banner p {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 19px;
      color: var(--global--color-heading);
      margin-bottom: 0px; }
      @media screen and (max-width: 767.98px) {
        .single-product .checkout-banner p {
          margin-bottom: 20px; } }
    .single-product .checkout-banner .btn {
      height: 60px;
      border-radius: 8px 8px 0 8px; }
  .single-product .product-img {
    border-radius: 8px;
    position: relative; }
    @media screen and (max-width: 991.98px) {
      .single-product .product-img {
        margin-bottom: 25px; } }
    .single-product .product-img a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: zoom-in; }
  .single-product .product-content {
    padding-top: 15px; }
    @media screen and (max-width: 991.98px) {
      .single-product .product-content {
        text-align: center; } }
    .single-product .product-content .product-title h3 {
      font-family: var(--global--font-heading);
      font-weight: 500;
      color: var(--global--color-heading);
      font-size: 29px;
      line-height: 1;
      text-transform: capitalize;
      margin-bottom: 12px; }
    .single-product .product-content .product-price {
      font-family: var(--global--font-body);
      color: var(--global--color-primary);
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 26px; }
    .single-product .product-content .product-review {
      margin-bottom: 21px; }
      .single-product .product-content .product-review span {
        color: var(--global--color-body);
        font-size: 13px;
        line-height: 1;
        font-weight: 400;
        font-family: var(--global--font-body);
        text-transform: capitalize; }
        .single-product .product-content .product-review span::after {
          content: '/';
          margin: 0 6px; }
        .single-product .product-content .product-review span:last-child::after {
          content: unset; }
        .single-product .product-content .product-review span a {
          color: var(--global--color-body); }
          .single-product .product-content .product-review span a:hover {
            color: var(--global--color-primary); }
      .single-product .product-content .product-review .product-rating {
        margin-right: 30px;
        display: inline-block; }
        .single-product .product-content .product-review .product-rating i {
          color: var(--global--color-body);
          font-size: 15px;
          line-height: 1;
          margin-right: 5px; }
          .single-product .product-content .product-review .product-rating i:last-child {
            margin-right: 0; }
        .single-product .product-content .product-review .product-rating i.active {
          color: var(--global--color-primary); }
    .single-product .product-content .product-desc p {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 32px;
      color: var(--global--color-body); }
    .single-product .product-content .product-details {
      margin-bottom: 20px; }
      .single-product .product-content .product-details .table {
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .single-product .product-content .product-details .table {
            width: 60%; } }
        .single-product .product-content .product-details .table td {
          border-top: 0;
          padding-bottom: 5px; }
        .single-product .product-content .product-details .table .name {
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-size: 16px;
          color: var(--global--color-heading);
          text-transform: capitalize; }
          @media screen and (min-width: 992px) {
            .single-product .product-content .product-details .table .name {
              padding-left: 0; } }
        .single-product .product-content .product-details .table .value {
          font-family: var(--global--font-body);
          font-weight: 400;
          font-size: 15px;
          color: var(--global--color-body);
          text-transform: capitalize; }
    .single-product .product-content .product-share {
      display: flex;
      align-items: center;
      flex-wrap: nowrap; }
      @media screen and (max-width: 991.98px) {
        .single-product .product-content .product-share {
          justify-content: center;
          flex-wrap: wrap; } }
      .single-product .product-content .product-share a {
        color: var(--global--color-white);
        background-color: var(--global--color-heading);
        border-radius: 8px 8px 0 8px;
        margin-right: 15px;
        transition: 0.3s ease-in-out;
        width: 52px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-in-out; }
        @media screen and (min-width: 992px) {
          .single-product .product-content .product-share a {
            margin-right: 10px; } }
        .single-product .product-content .product-share a:last-child {
          margin-right: 0; }
        .single-product .product-content .product-share a:hover {
          background-color: var(--global--color-primary);
          transform: translateY(-5px); }
        .single-product .product-content .product-share a.facebook-bg {
          background-color: #3b5999; }
          .single-product .product-content .product-share a.facebook-bg:hover {
            background-color: var(--global--color-heading); }
        .single-product .product-content .product-share a.twitter-bg {
          background-color: #55acee; }
          .single-product .product-content .product-share a.twitter-bg:hover {
            background-color: var(--global--color-heading); }
        .single-product .product-content .product-share a.instagram-bg {
          background-color: #0092ff; }
          .single-product .product-content .product-share a.instagram-bg:hover {
            background-color: var(--global--color-heading); }
        .single-product .product-content .product-share a.pinterest-bg {
          background-color: #ca1f27; }
          .single-product .product-content .product-share a.pinterest-bg:hover {
            background-color: var(--global--color-heading); }
    .single-product .product-content .product-action {
      display: flex;
      margin-bottom: 31px; }
      @media screen and (max-width: 991.98px) {
        .single-product .product-content .product-action {
          justify-content: center; } }
      .single-product .product-content .product-action .product-quantity {
        display: flex;
        border: 2px solid var(--global--color-gray-light);
        border-radius: 8px 8px 0 8px;
        width: 80px;
        margin-right: 15px; }
        .single-product .product-content .product-action .product-quantity span {
          display: flex;
          flex-direction: column; }
          .single-product .product-content .product-action .product-quantity span a {
            border-left: 2px solid var(--global--color-gray-light); }
            .single-product .product-content .product-action .product-quantity span a:first-child {
              border-bottom: 2px solid var(--global--color-gray-light); }
            .single-product .product-content .product-action .product-quantity span a i {
              width: 24px;
              height: 24px;
              font-size: 10px;
              line-height: 25px;
              color: var(--global--color-heading);
              text-align: center; }
        .single-product .product-content .product-action .product-quantity input {
          width: 50px;
          height: 50px;
          color: var(--global--color-heading);
          font-size: 20px;
          font-weight: 700;
          line-height: 22px;
          font-family: var(--global--font-body);
          text-align: center;
          background-color: transparent;
          border-color: transparent; }
      .single-product .product-content .product-action .btn {
        height: 54px;
        background-color: var(--global--color-secondary);
        color: var(--global--color-white); }
        .single-product .product-content .product-action .btn::before {
          background-color: var(--global--color-primary); }
        .single-product .product-content .product-action .btn:active, .single-product .product-content .product-action .btn:focus, .single-product .product-content .product-action .btn:hover {
          color: var(--global--color-white); }
  .single-product .product-tabs {
    background-color: var(--global--color-white);
    margin-top: 95px;
    margin-bottom: 40px; }
    .single-product .product-tabs .nav-tabs {
      background-color: var(--global--color-white);
      border: 0; }
      .single-product .product-tabs .nav-tabs li {
        margin-right: 30px; }
        .single-product .product-tabs .nav-tabs li:last-child {
          margin-right: 0; }
        .single-product .product-tabs .nav-tabs li a {
          color: #273a69;
          font-size: 16px;
          font-weight: 700;
          text-transform: capitalize;
          font-family: var(--global--font-body);
          text-transform: capitalize;
          border-radius: 0;
          margin-right: 0;
          padding-bottom: 14px;
          display: block;
          border-right: 0;
          position: relative; }
          .single-product .product-tabs .nav-tabs li a::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: transparent;
            transition: 0.3s ease-in-out; }
            @media (max-width: 420px) {
              .single-product .product-tabs .nav-tabs li a::after {
                content: unset; } }
          .single-product .product-tabs .nav-tabs li a:focus, .single-product .product-tabs .nav-tabs li a:hover {
            color: var(--global--color-primary); }
      .single-product .product-tabs .nav-tabs li > .active,
      .single-product .product-tabs .nav-tabs li > .active:focus,
      .single-product .product-tabs .nav-tabs li > .active:hover {
        color: var(--global--color-primary); }
        .single-product .product-tabs .nav-tabs li > .active::after,
        .single-product .product-tabs .nav-tabs li > .active:focus::after,
        .single-product .product-tabs .nav-tabs li > .active:hover::after {
          background-color: var(--global--color-primary); }
    .single-product .product-tabs .tab-content {
      border-top: 2px solid var(--global--color-gray-light);
      padding-top: 25px; }
      .single-product .product-tabs .tab-content p {
        font-family: var(--global--font-body);
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        color: var(--global--color-body); }
      .single-product .product-tabs .tab-content h5 {
        font-size: 13px; }
      .single-product .product-tabs .tab-content .review-comment .review-body {
        display: flex;
        align-items: center;
        margin-bottom: 25px; }
        .single-product .product-tabs .tab-content .review-comment .review-body img {
          width: 60px;
          height: 60px;
          border-radius: 8px 8px 0 8px;
          margin-right: 30px; }
        .single-product .product-tabs .tab-content .review-comment .review-body h6,
        .single-product .product-tabs .tab-content .review-comment .review-body p {
          margin-bottom: 0; }
        .single-product .product-tabs .tab-content .review-comment .review-body .product-rating i.active {
          color: var(--global--color-primary); }
      .single-product .product-tabs .tab-content .review-comment .product-comment {
        margin-bottom: 35px; }
        .single-product .product-tabs .tab-content .review-comment .product-comment p {
          margin-bottom: 0; }
      .single-product .product-tabs .tab-content .form-review .nice-select .list {
        width: 100%; }
  .single-product .form-review .form-control {
    margin-bottom: 30px; }

/*Shop Cart*/
.shop-cart {
  padding-top: 0; }
  .shop-cart .cart-table {
    margin-bottom: 30px; }
    .shop-cart .cart-table.table-responsive {
      min-height: 0.01%;
      overflow-x: auto; }
    .shop-cart .cart-table .table {
      background-color: var(--global--color-white);
      border-radius: 0;
      margin-bottom: 0;
      color: var(--global--color-body); }
      .shop-cart .cart-table .table tr:first-child th {
        border-top: 0; }
        .shop-cart .cart-table .table tr:first-child th:first-child {
          border-left: 0; }
        .shop-cart .cart-table .table tr:first-child th:last-child {
          border-right: 0; }
      .shop-cart .cart-table .table tr th {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
        color: var(--global--color-body);
        font-family: var(--global--font-body);
        padding: 10px 0;
        border-right: 1px solid #e5e8ed;
        border-bottom: 1px solid #e5e8ed; }
      .shop-cart .cart-table .table tbody tr td {
        padding: 20px;
        border-right: 1px solid #e5e8ed;
        border-bottom: 1px solid #e5e8ed;
        border-top: 0;
        border-left: 0; }
        .shop-cart .cart-table .table tbody tr td:last-child {
          border-right: 0; }
        .shop-cart .cart-table .table tbody tr td.cart-product-price, .shop-cart .cart-table .table tbody tr td.cart-product-total {
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          color: var(--global--color-primary);
          font-family: var(--global--font-body);
          vertical-align: middle; }
        .shop-cart .cart-table .table tbody tr td.cart-product-item {
          display: flex;
          justify-content: flex-start;
          align-items: center; }
          .shop-cart .cart-table .table tbody tr td.cart-product-item .cart-product-remove {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 19px;
            height: 19px;
            background-color: #283b6a;
            border-radius: 1px;
            text-align: center;
            cursor: pointer;
            margin-right: 20px;
            transition: 0.3s ease-in-out; }
            .shop-cart .cart-table .table tbody tr td.cart-product-item .cart-product-remove:hover {
              background-color: var(--global--color-primary); }
          .shop-cart .cart-table .table tbody tr td.cart-product-item .cart-product-img {
            width: 70px;
            height: 70px;
            margin-right: 20px; }
            .shop-cart .cart-table .table tbody tr td.cart-product-item .cart-product-img img {
              max-width: 100%; }
          .shop-cart .cart-table .table tbody tr td.cart-product-item .cart-product-name h6 {
            font-family: var(--global--font-heading);
            color: var(--global--color-heading);
            font-size: 16px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
            margin-bottom: 0px; }
        .shop-cart .cart-table .table tbody tr td.cart-product-quantity .product-quantity {
          display: flex;
          border: 2px solid var(--global--color-gray-light);
          border-radius: 8px 8px 0 8px;
          width: 80px;
          margin-right: 0px;
          margin: 0 auto; }
          .shop-cart .cart-table .table tbody tr td.cart-product-quantity .product-quantity span {
            display: flex;
            flex-direction: column; }
            .shop-cart .cart-table .table tbody tr td.cart-product-quantity .product-quantity span a {
              border-left: 2px solid var(--global--color-gray-light); }
              .shop-cart .cart-table .table tbody tr td.cart-product-quantity .product-quantity span a:first-child {
                border-bottom: 2px solid var(--global--color-gray-light); }
              .shop-cart .cart-table .table tbody tr td.cart-product-quantity .product-quantity span a i {
                width: 24px;
                height: 24px;
                font-size: 10px;
                line-height: 25px;
                color: var(--global--color-heading);
                text-align: center; }
          .shop-cart .cart-table .table tbody tr td.cart-product-quantity .product-quantity input {
            width: 50px;
            height: 50px;
            color: var(--global--color-heading);
            font-size: 20px;
            font-weight: 700;
            line-height: 22px;
            font-family: var(--global--font-body);
            text-align: center;
            background-color: transparent;
            border-color: transparent; }
  .shop-cart .cart-product-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 82px; }
    @media screen and (max-width: 767.98px) {
      .shop-cart .cart-product-action {
        flex-direction: column;
        align-items: center; } }
    .shop-cart .cart-product-action form {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      @media screen and (max-width: 767.98px) {
        .shop-cart .cart-product-action form {
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px; } }
      .shop-cart .cart-product-action form .form-control {
        border-radius: 8px 8px 0 8px;
        margin-bottom: 0;
        border: 1px solid var(--global--color-gray-light);
        background-color: var(--global--color-white);
        margin-right: 10px;
        height: 42px;
        width: 250px;
        line-height: 42px;
        margin-bottom: 0px; }
        @media screen and (max-width: 767.98px) {
          .shop-cart .cart-product-action form .form-control {
            margin-bottom: 15px;
            margin-right: 0; } }
      .shop-cart .cart-product-action form .btn {
        margin-top: 0;
        flex-shrink: 0; }
    .shop-cart .cart-product-action .btn {
      width: 134px;
      height: 42px;
      line-height: 39px;
      margin-right: 10px; }
      @media screen and (max-width: 575.98px) {
        .shop-cart .cart-product-action .btn {
          margin-top: 20px; } }
      .shop-cart .cart-product-action .btn:last-child {
        margin-right: 0; }
    .shop-cart .cart-product-action div {
      display: flex;
      align-items: center; }
  .shop-cart .cart-total-amount h5 {
    color: var(--global--color-heading);
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
    font-family: var(--global--font-heading);
    margin-bottom: 13px;
    position: relative; }
  .shop-cart .cart-total-amount .table {
    margin-bottom: 40px; }
    .shop-cart .cart-total-amount .table tbody .head {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 17px;
      color: var(--global--color-heading);
      text-transform: capitalize; }
    .shop-cart .cart-total-amount .table tbody td {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 17px;
      color: var(--global--color-body);
      border-top: 0;
      border-bottom: 1px solid #e5e8ed;
      padding-left: 0; }
      .shop-cart .cart-total-amount .table tbody td.amount {
        font-weight: 700; }
  .shop-cart .cart-total-amount .btn {
    width: 270px;
    height: 50px; }

/* Shop Checkout */
.shop-checkout {
  padding-bottom: 120px; }
  .shop-checkout .cart-empty,
  .shop-checkout .cart-coupon {
    padding: 30px;
    background-color: #f5f5f5;
    border-top: 3px solid #1e85be;
    font-size: 18px;
    font-weight: 400;
    font-family: var(--global--font-body);
    line-height: 1;
    color: #515151;
    margin-bottom: 35px; }
    .shop-checkout .cart-empty a,
    .shop-checkout .cart-coupon a {
      color: var(--global--color-primary); }

.checkout-widget {
  padding: 20px;
  margin-bottom: 45px; }
  .checkout-widget .widget-title {
    margin-bottom: 15px;
    text-transform: capitalize; }
  .checkout-widget label {
    font-family: var(--global--font-heading);
    font-weight: 500;
    font-size: 16px;
    line-height: 2;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: var(--global--color-heading);
    display: block; }
    .checkout-widget label .required {
      margin-left: 3px;
      color: red; }
  .checkout-widget .form-control {
    margin-bottom: 12px; }
  .checkout-widget .nice-select {
    display: flex;
    align-items: center;
    width: 100%; }
    .checkout-widget .nice-select .list {
      width: 100%; }

.your-order {
  margin-bottom: 53px;
  border-radius: 8px 8px 0 8px;
  overflow: hidden; }
  .your-order h5 {
    text-transform: capitalize; }
  .your-order .table-order {
    background-color: var(--global--color-gray);
    padding: 30px 30px 12px; }
    .your-order .table-order table {
      width: 100%; }
      .your-order .table-order table thead .order-heading {
        border-bottom: 1px solid #eaeaea; }
        .your-order .table-order table thead .order-heading th {
          font-family: var(--global--font-body);
          font-size: 16px;
          color: var(--global--color-heading);
          font-weight: 700;
          text-transform: capitalize;
          padding-bottom: 3px; }
      .your-order .table-order table tbody tr {
        border-bottom: 1px solid #eaeaea; }
        .your-order .table-order table tbody tr:last-child {
          border-bottom: 0; }
        .your-order .table-order table tbody tr td {
          font-family: var(--global--font-body);
          font-size: 14px;
          color: var(--global--color-heading);
          text-transform: capitalize;
          font-weight: 700;
          padding-top: 13px;
          padding-bottom: 13px; }
          .your-order .table-order table tbody tr td:last-of-type {
            font-weight: 400;
            color: var(--global--color-body); }
          .your-order .table-order table tbody tr td.product-name {
            padding-left: 10px; }
        .your-order .table-order table tbody tr.cart-total td:last-of-type {
          font-weight: 700;
          color: var(--global--color-heading); }

.payment {
  background-color: var(--global--color-gray);
  padding: 33px 15px;
  border-radius: 8px 8px 0 8px; }
  .payment .payment-method {
    margin: 0 15px; }
    .payment .payment-method .no-payment {
      border-top: 3px solid #1e85be;
      padding: 27px 0; }
      .payment .payment-method .no-payment p {
        font-family: var(--global--font-body);
        padding: 0 30px;
        font-size: 18px;
        line-height: 2;
        font-weight: 500;
        color: var(--global--color-heading); }
  .payment .payment-conditions {
    margin: 0 15px; }
    .payment .payment-conditions .message {
      padding-top: 10px;
      font-family: var(--global--font-body);
      font-size: 17px;
      line-height: 2;
      color: var(--global--color-body);
      font-weight: 400;
      margin-bottom: 30px; }
    .payment .payment-conditions label {
      text-transform: capitalize;
      font-weight: 500;
      color: var(--global--color-heading);
      font-size: 16px;
      margin-bottom: 30px; }
      .payment .payment-conditions label a {
        color: var(--global--color-primary); }

.login {
  padding-bottom: 140px; }
  .login .login-body {
    background-color: var(--global--color-primary-light);
    border-radius: 8px 8px 8px 0;
    padding: 40px; }
    .login .login-body label {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      margin-bottom: 5px;
      text-transform: capitalize;
      color: #222222; }
      .login .login-body label .required {
        margin-left: 3px;
        color: red; }
    .login .login-body .form-control {
      background-color: var(--global--color-white);
      margin-bottom: 8px; }
    .login .login-body .check {
      margin-top: 10px;
      margin-bottom: 5px; }
      .login .login-body .check label {
        margin-left: 5px; }
    .login .login-body .forget {
      margin-top: 13px;
      font-family: var(--global--font-body);
      font-size: 15px;
      font-weight: 700;
      color: var(--global--color-heading); }
      .login .login-body .forget:hover {
        color: var(--global--color-primary); }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .product-item {
    max-width: 270px;
    margin: 0 auto 30px; }
  .product-options2 span {
    display: inline-block;
    min-width: 50px;
    margin-bottom: 10px; } }

/*------------------------------------*\
    #Teams
\*------------------------------------*/
.team {
  padding-top: 95px; }

/* Team Filter */
.team-filter {
  text-align: center;
  margin-bottom: 9px; }
  .team-filter li {
    display: inline-block; }
    .team-filter li:last-child a {
      margin-right: 0; }
    .team-filter li a {
      color: var(--global--color-heading);
      font-family: var(--global--font-body);
      font-size: 14px;
      font-weight: 700;
      line-height: 49px;
      margin-right: 26px;
      text-transform: capitalize;
      transition: 0.3s ease-in-out; }
      .team-filter li a:hover {
        color: var(--global--color-primary); }
    .team-filter li a.active-filter {
      color: var(--global--color-primary); }

/* Team Member */
.team-member {
  margin-bottom: 40px; }
  @media screen and (max-width: 991.98px) {
    .team-member {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto; } }
  .team-member .team-member-holder {
    overflow: hidden; }
    .team-member .team-member-holder:hover .team-img img {
      transform: scale3d(1, 1, 1); }
    .team-member .team-member-holder:hover .team-img::before {
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }
  .team-member .team-img {
    position: relative;
    overflow: hidden;
    border-radius: 8px; }
    .team-member .team-img::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      content: '';
      z-index: 1;
      transition: transform 0.6s;
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }
    .team-member .team-img .link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .team-member .team-img img {
      transition: transform 0.35s;
      max-width: 100%;
      height: auto;
      transform: scale3d(1.05, 1.05, 1); }
    .team-member .team-img .team-social {
      position: absolute;
      left: 40px;
      bottom: 0;
      transition: 0.3s ease-in-out;
      background-color: var(--global--color-secondary);
      z-index: 4;
      border-radius: 8px 8px 0 0;
      display: flex;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transform: translateY(51px); }
      @media screen and (max-width: 991.98px) {
        .team-member .team-img .team-social {
          left: 50%;
          transform: translateX(-50%) translateY(51px); } }
      .team-member .team-img .team-social a {
        width: 50px;
        height: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--global--color-white);
        transition: 0.3s ease-in-out;
        position: relative; }
        .team-member .team-img .team-social a:last-child::after {
          content: unset; }
        .team-member .team-img .team-social a::after {
          content: '';
          height: 21px;
          width: 2px;
          background-color: #485880;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }
        .team-member .team-img .team-social a i {
          transition: 0.3s ease-in-out;
          font-size: 20px; }
          .team-member .team-img .team-social a i:hover {
            color: var(--global--color-primary);
            animation: bounce 1s ease-in-out; }
  .team-member .team-content {
    padding: 23px 30px 0;
    text-align: center;
    background-color: var(--global--color-white); }
    @media screen and (min-width: 992px) {
      .team-member .team-content {
        text-align: left; } }
    .team-member .team-content .team-title h4 {
      font-family: var(--global--font-heading);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 3px;
      text-transform: capitalize; }
      .team-member .team-content .team-title h4 a {
        color: var(--global--color-heading); }
        .team-member .team-content .team-title h4 a:hover {
          color: var(--global--color-primary); }
    .team-member .team-content .team-cat a {
      color: var(--global--color-primary);
      font-family: var(--global--font-body);
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      position: relative;
      text-transform: capitalize; }
      .team-member .team-content .team-cat a::before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: var(--global--color-primary);
        position: absolute;
        bottom: -2px;
        left: 0;
        opacity: 0;
        transition: 0.3s ease-in-out; }
      .team-member .team-content .team-cat a:hover {
        color: var(--global--color-primary); }
        .team-member .team-content .team-cat a:hover::before {
          opacity: 1; }
      .team-member .team-content .team-cat a::after {
        content: ",";
        margin-right: 5px; }
      .team-member .team-content .team-cat a:last-of-type::after {
        content: none; }
    .team-member .team-content .team-desc p {
      font-family: var(--global--font-body);
      color: var(--global--color-body);
      font-size: 15px;
      line-height: 26px;
      margin-bottom: 0; }
    .team-member .team-content .team-more a {
      height: 42px;
      width: 124px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      color: var(--global--color-secondary);
      font-family: var(--global--font-secondary);
      text-transform: capitalize;
      transition: 0.3s ease-in-out;
      border-radius: 8px 8px 0 8px; }

/* Team Standard */
.team-standard {
  padding: 40px 0 110px; }
  .team-standard .team-member {
    border-radius: 8px 8px 0 8px;
    margin-bottom: 30px;
    transition: 0.5s ease;
    box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06); }
    .team-standard .team-member:hover .team-social {
      opacity: 1;
      visibility: visible;
      transform: translateY(0); }
      @media screen and (max-width: 991.98px) {
        .team-standard .team-member:hover .team-social {
          transform: translate(-50%, 0); } }
    .team-standard .team-member:hover .team-content::before {
      width: 0%; }
    .team-standard .team-member:hover .team-content::after {
      width: calc(100% - 80px); }
    .team-standard .team-member .team-img {
      border-radius: 8px 8px 0px 0px; }
    .team-standard .team-member .team-content {
      padding-top: 33px;
      padding-bottom: 32px;
      padding-left: 40px;
      padding-right: 40px;
      position: relative; }
      .team-standard .team-member .team-content .team-title h4 {
        font-size: 21px;
        margin-bottom: 4px; }
      .team-standard .team-member .team-content .team-cat {
        margin-bottom: 13px; }
      .team-standard .team-member .team-content .team-desc {
        margin-bottom: 0px; }
      .team-standard .team-member .team-content::before, .team-standard .team-member .team-content::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        transition: 0.3s ease-in-out; }
      .team-standard .team-member .team-content::before {
        background-color: var(--global--color-primary);
        width: calc(100% - 80px); }
      .team-standard .team-member .team-content::after {
        background-color: var(--global--color-secondary);
        width: 0; }
  .team-standard .pagination {
    margin-top: 10px; }

/* Team Modern*/
.team-modern {
  padding-top: 130px;
  padding-bottom: 110px; }
  .team-modern .team-member {
    box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06);
    overflow: hidden;
    border-radius: 8px 8px 0 8px;
    margin-bottom: 30px; }
    .team-modern .team-member:hover .team-member-holder .team-content-holder .team-content {
      transform: translateY(0); }
      .team-modern .team-member:hover .team-member-holder .team-content-holder .team-content::after {
        width: calc(100% - 80px); }
    .team-modern .team-member .team-member-holder .team-img {
      border-radius: 8px 8px 0 0; }
    .team-modern .team-member .team-member-holder .team-content-holder {
      border-radius: 0 0 8px 8px; }
      .team-modern .team-member .team-member-holder .team-content-holder .team-content {
        padding: 33px 40px 40px;
        position: relative;
        transform: translateY(90px);
        margin-top: -90px;
        transition: 0.3s ease-in-out;
        z-index: 5; }
        .team-modern .team-member .team-member-holder .team-content-holder .team-content::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 3px;
          transition: 0.3s ease-in-out;
          transition-delay: 0.2s;
          background-color: var(--global--color-primary); }
        .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-title h4 {
          margin-bottom: 5px; }
        .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-cat {
          margin-bottom: 13px; }
        .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-desc p {
          margin-bottom: 32px; }
        .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-social {
          transition: 0.3s ease-in-out;
          background-color: var(--global--color-secondary);
          z-index: 4;
          border-radius: 8px 8px 0 0;
          display: inline-flex;
          align-items: center; }
          .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-social a {
            width: 50px;
            height: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: var(--global--color-white);
            transition: 0.3s ease-in-out;
            position: relative; }
            .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-social a:last-child::after {
              content: unset; }
            .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-social a::after {
              content: '';
              height: 21px;
              width: 2px;
              background-color: #485880;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%); }
            .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-social a i {
              transition: 0.3s ease-in-out;
              font-size: 20px; }
              .team-modern .team-member .team-member-holder .team-content-holder .team-content .team-social a i:hover {
                color: var(--global--color-primary);
                animation: bounce 1s ease-in-out; }
  .team-modern.team-modern-2 {
    padding-top: 0;
    padding-bottom: 0; }
    .team-modern.team-modern-2 .team-member {
      margin-bottom: 0;
      box-shadow: none; }
      .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content {
        transform: translateY(0);
        margin-top: 0; }
        .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content::after {
          width: calc(100% - 80px); }
          @media screen and (max-width: 991.98px) {
            .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content::after {
              content: unset; } }
        @media screen and (max-width: 991.98px) {
          .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content {
            background-color: transparent; } }
        @media screen and (max-width: 991.98px) {
          .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-title h4 {
            font-size: 24px; }
            .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-title h4 a {
              color: var(--global--color-white); }
              .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-title h4 a:hover {
                color: var(--global--color-secondary); } }
        @media screen and (max-width: 991.98px) {
          .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-cat a {
            color: var(--global--color-white); }
            .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-cat a:hover {
              color: var(--global--color-secondary); }
              .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-cat a:hover::before {
                background-color: var(--global--color-secondary); } }
        @media screen and (max-width: 991.98px) {
          .team-modern.team-modern-2 .team-member .team-member-holder .team-content-holder .team-content .team-desc p {
            color: var(--global--color-white); } }
  .team-modern.team-modern-3 {
    padding-top: 0;
    padding-bottom: 0; }
    .team-modern.team-modern-3 .team-member {
      margin-bottom: 0;
      box-shadow: none; }
      .team-modern.team-modern-3 .team-member .team-member-holder .team-content-holder .team-content {
        transform: translateY(0);
        margin-top: 0; }
        .team-modern.team-modern-3 .team-member .team-member-holder .team-content-holder .team-content::after {
          width: calc(100% - 80px); }
          @media screen and (max-width: 991.98px) {
            .team-modern.team-modern-3 .team-member .team-member-holder .team-content-holder .team-content::after {
              content: unset; } }
        @media screen and (max-width: 991.98px) {
          .team-modern.team-modern-3 .team-member .team-member-holder .team-content-holder .team-content .team-title h4 {
            font-size: 24px; } }

/* Team Grid */
.team-grid {
  padding: 130px 0 110px; }
  .team-grid .team-member {
    border-radius: 8px 8px 0 8px;
    margin-bottom: 25px;
    transition: 0.5s ease; }
    .team-grid .team-member:hover {
      box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06); }
      .team-grid .team-member:hover .team-social {
        opacity: 1;
        visibility: visible;
        transform: translateY(0); }
        @media screen and (max-width: 991.98px) {
          .team-grid .team-member:hover .team-social {
            transform: translate(-50%, 0); } }
      .team-grid .team-member:hover .team-content .team-more a {
        color: var(--global--color-white);
        background-color: var(--global--color-secondary);
        padding: 0 13px 0 15px; }
        .team-grid .team-member:hover .team-content .team-more a .line span {
          opacity: 0;
          visibility: hidden; }
        .team-grid .team-member:hover .team-content .team-more a .line::after {
          opacity: 1;
          visibility: visible; }
    .team-grid .team-member .team-img {
      border-radius: 8px 8px 0px 0px; }
    .team-grid .team-member .team-content {
      padding-top: 33px;
      padding-bottom: 40px;
      padding-left: 40px;
      padding-right: 40px;
      position: relative; }
      .team-grid .team-member .team-content .team-title h4 {
        font-size: 21px;
        margin-bottom: 4px; }
      .team-grid .team-member .team-content .team-cat {
        margin-bottom: 13px; }
      .team-grid .team-member .team-content .team-desc {
        margin-bottom: 18px; }
      @media screen and (max-width: 991.98px) {
        .team-grid .team-member .team-content .team-more a {
          display: inline-flex; } }
  .team-grid .pagination {
    margin-top: 0px; }
  .team-grid.team-grid-2 {
    padding-top: 275px;
    padding-bottom: 50px;
    background: url("../images/background/pattern.png") no-repeat;
    background-size: cover; }
    @media screen and (max-width: 991.98px) {
      .team-grid.team-grid-2 {
        padding-top: 170px; } }
    @media screen and (max-width: 991.98px) {
      .team-grid.team-grid-2 .doctor-action {
        margin-top: 30px; } }
    .team-grid.team-grid-2 .team-member:hover .team-social {
      opacity: 0;
      visibility: hidden; }
    .team-grid.team-grid-2 .team-member:hover .team-content {
      background-color: var(--global--color-white); }
    .team-grid.team-grid-2 .team-member .team-content {
      transition: 0.3s ease-in-out;
      background-color: transparent; }
  .team-grid.team-grid-3 {
    padding-top: 118px; }
  .team-grid.team-grid-4 {
    padding: 0; }
    @media screen and (max-width: 991.98px) {
      .team-grid.team-grid-4 .team-member {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto; } }
    .team-grid.team-grid-4 .team-member .team-content {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 32px; }

.doctor-action {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end; }
  @media screen and (max-width: 991.98px) {
    .doctor-action {
      justify-content: center; } }
  @media screen and (max-width: 767.98px) {
    .doctor-action {
      flex-direction: column; } }
  .doctor-action .btn {
    width: 210px;
    height: 60px;
    border-radius: 8px 8px 0 8px;
    margin-right: 70px; }
    @media screen and (max-width: 767.98px) {
      .doctor-action .btn {
        margin-right: 0;
        margin-bottom: 20px; } }
  .doctor-action .doctor-info {
    display: inline-flex;
    position: relative;
    align-items: center; }
    .doctor-action .doctor-info .doctor-img {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px; }
      .doctor-action .doctor-info .doctor-img img {
        max-width: 100%;
        border-radius: 50%; }
    .doctor-action .doctor-info i {
      width: 50px;
      height: 50px;
      color: var(--global--color-white);
      background-color: var(--global--color-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      left: -40px; }
    .doctor-action .doctor-info .doctor-body a {
      font-family: var(--global--font-heading);
      font-weight: 700;
      font-size: 19px;
      line-height: 29px;
      color: var(--global--color-secondary);
      margin-bottom: 0; }
    .doctor-action .doctor-info .doctor-body p {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 14px;
      color: var(--global--color-body);
      margin-bottom: 0;
      text-transform: capitalize; }

/* Member Single */
.team-single {
  padding-top: 107px;
  padding-bottom: 110px; }
  .team-single .entry-bio {
    margin-bottom: 42px; }
    @media screen and (max-width: 991.98px) {
      .team-single .entry-bio {
        text-align: center; } }
    .team-single .entry-bio .entry-heading {
      font-family: var(--global--font-heading);
      font-size: 24px;
      line-height: 1;
      font-weight: 500;
      margin-bottom: 19px;
      color: #0e204d;
      text-transform: capitalize; }
    .team-single .entry-bio .entry-desc {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      color: var(--global--color-body);
      margin-bottom: 25px; }
      .team-single .entry-bio .entry-desc:last-child {
        margin-bottom: 0; }
      .team-single .entry-bio .entry-desc.strong {
        font-weight: 700;
        color: var(--global--color-heading); }
  .team-single .entry-table {
    margin-bottom: 67px; }
    .team-single .entry-table .table {
      height: 1px;
      margin-bottom: 0; }
      .team-single .entry-table .table tbody tr {
        height: 100%; }
        .team-single .entry-table .table tbody tr:nth-of-type(odd) {
          background-color: transparent; }
          .team-single .entry-table .table tbody tr:nth-of-type(odd) .content {
            background-color: var(--global--color-primary-light); }
      .team-single .entry-table .table td {
        height: 100%;
        border-top: 0;
        border-bottom: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none; }
        .team-single .entry-table .table td .content {
          border-radius: 8px 0 0 8px;
          height: 100%;
          font-family: var(--global--font-body);
          font-size: 14px;
          font-weight: 400;
          color: var(--global--color-body);
          text-transform: capitalize;
          padding: 20px 30px 20px 50px; }
          @media screen and (max-width: 767.98px) {
            .team-single .entry-table .table td .content {
              padding: 20px; } }
          .team-single .entry-table .table td .content.head {
            border-radius: 0 8px 0 0;
            font-weight: 700;
            color: var(--global--color-heading); }
          .team-single .entry-table .table td .content .experties-list {
            margin-bottom: 0; }
            .team-single .entry-table .table td .content .experties-list li {
              margin-bottom: 14px; }
              .team-single .entry-table .table td .content .experties-list li:last-child {
                margin-bottom: 0; }
              @media screen and (max-width: 767.98px) {
                .team-single .entry-table .table td .content .experties-list li {
                  display: flex; } }
              .team-single .entry-table .table td .content .experties-list li i {
                font-size: 10px;
                margin-right: 10px;
                color: var(--global--color-heading-darken); }
                @media screen and (max-width: 767.98px) {
                  .team-single .entry-table .table td .content .experties-list li i {
                    margin-top: 5px; } }
        .team-single .entry-table .table td:first-child .content {
          border-radius: 8px 0 0 8px; }
        .team-single .entry-table .table td:last-child .content {
          border-radius: 0px 8px 0 0px; }
  .team-single .entry-services .prices-panel {
    border: 2px solid var(--global--color-gray-light);
    padding: 37px 40px 27px;
    border-radius: 8px 8px 0 8px; }
    .team-single .entry-services .prices-panel h6 {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 19px;
      line-height: 1;
      color: var(--global--color-heading);
      text-transform: capitalize; }
    .team-single .entry-services .prices-panel ul {
      margin-bottom: 0; }
      .team-single .entry-services .prices-panel ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 700;
        font-family: var(--global--font-body);
        color: var(--global--color-body);
        text-transform: capitalize;
        border-bottom: 1px solid var(--global--color-gray-light);
        margin-bottom: 14px;
        padding-bottom: 12px; }
        @media screen and (min-width: 768px) {
          .team-single .entry-services .prices-panel ul li:last-child {
            margin-bottom: 0px;
            border-bottom: 0; } }
        .team-single .entry-services .prices-panel ul li .price {
          font-family: var(--global--font-secondary);
          font-weight: 700;
          font-size: 15px;
          color: var(--global--color-white);
          background-color: var(--global--color-heading-darken);
          padding: 3px 10px;
          border-radius: 8px 8px 0 8px; }
  .team-single .entry-awards {
    margin-bottom: 37px; }
    .team-single .entry-awards .entry-desc {
      margin-bottom: 42px; }
    .team-single .entry-awards .entry-award {
      padding: 38px 40px 36px;
      display: flex;
      align-items: center;
      border: 2px solid var(--global--color-gray-light);
      border-radius: 8px 8px 0 8px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      margin-bottom: 30px; }
      @media screen and (max-width: 767.98px) {
        .team-single .entry-awards .entry-award {
          flex-direction: column;
          align-items: center;
          padding: 28px 25px 26px;
          text-align: center; } }
      .team-single .entry-awards .entry-award:hover {
        border-color: transparent;
        box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.15); }
      .team-single .entry-awards .entry-award svg {
        width: 48px;
        height: 48px;
        fill: #354f8e;
        margin-right: 20px; }

@media screen and (max-width:767.98px) {
  .team-single .entry-awards .entry-award svg {
    margin-right: 0;
    margin-bottom: 20px; } }
      .team-single .entry-awards .entry-award h5 {
        font-family: var(--global--font-heading);
        font-weight: 500;
        color: var(--global--color-heading);
        font-size: 19px;
        margin-bottom: 1px;
        text-transform: capitalize; }
      .team-single .entry-awards .entry-award p {
        font-family: var(--global--font-body);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        text-transform: capitalize; }
  .team-single .entry-education {
    margin-bottom: 70px; }
    .team-single .entry-education .entry-desc {
      margin-bottom: 42px; }
    .team-single .entry-education .education-panel {
      display: flex; }
      @media screen and (max-width: 767.98px) {
        .team-single .entry-education .education-panel {
          flex-direction: column; } }
      .team-single .entry-education .education-panel .education-year {
        margin-right: 40px; }
        @media screen and (max-width: 767.98px) {
          .team-single .entry-education .education-panel .education-year {
            margin-right: 0;
            margin-bottom: 15px; } }
        .team-single .entry-education .education-panel .education-year .year {
          background-color: var(--global--color-primary);
          padding-right: 12px;
          font-family: var(--global--font-body);
          font-size: 14px;
          font-weight: 700;
          color: var(--global--color-white);
          clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0 100%, 0 50%, 0 0);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 87px;
          height: 30px;
          border-radius: 4px; }
      .team-single .entry-education .education-panel .education-body {
        position: relative;
        padding-left: 40px;
        padding-bottom: 36px;
        margin-top: 3px; }
        @media screen and (max-width: 767.98px) {
          .team-single .entry-education .education-panel .education-body {
            padding-left: 20px; } }
        .team-single .entry-education .education-panel .education-body::before {
          content: '';
          width: 2px;
          position: absolute;
          height: calc(100% + 3px);
          top: 0;
          left: -1px;
          background-color: #dcdee2; }
          @media screen and (max-width: 767.98px) {
            .team-single .entry-education .education-panel .education-body::before {
              left: 0;
              height: calc(100% + 22px);
              top: -20px; } }
        .team-single .entry-education .education-panel .education-body .bullet {
          z-index: 4;
          position: absolute;
          top: 0px;
          left: -12px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: var(--global--color-white);
          border: 3px solid #dcdee2;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media screen and (max-width: 767.98px) {
            .team-single .entry-education .education-panel .education-body .bullet {
              display: none; } }
          .team-single .entry-education .education-panel .education-body .bullet::after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: transparent;
            border: 2px solid var(--global--color-primary); }
        .team-single .entry-education .education-panel .education-body h5 {
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-size: 19px;
          line-height: 28px;
          color: var(--global--color-heading);
          margin-bottom: 15px;
          transform: translateY(-3px); }
        .team-single .entry-education .education-panel .education-body p {
          font-family: var(--global--font-body);
          font-weight: 400;
          font-size: 15px;
          line-height: 26px;
          color: var(--global--color-body);
          margin-bottom: 0; }
  .team-single .entry-skills {
    margin-bottom: 70px; }
    .team-single .entry-skills .entry-desc {
      margin-bottom: 40px; }
  .team-single .entry-contact .contact-panel {
    border-radius: 8px 8px 0 8px; }
  .team-single .team-title h3 {
    color: #222222;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 30px; }
  .team-single .team-content {
    padding-bottom: 47px;
    text-align: left; }
    .team-single .team-content p {
      color: var(--global--color-body);
      font-family: var(--global--font-body);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 28px; }
  .team-single .team-list {
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 36px; }
    .team-single .team-list li {
      font-family: var(--global--font-body);
      font-size: 15px;
      font-weight: 400;
      line-height: 33px;
      color: #9e9e9e;
      text-transform: capitalize; }
      .team-single .team-list li span {
        font-family: var(--global--font-heading);
        font-size: 14px;
        color: #222222;
        font-weight: 700;
        width: 40%;
        display: inline-block; }
    .team-single .team-list li:last-child {
      border-bottom: none; }
  .team-single .team-share span {
    color: #222222;
    font-size: 13px;
    font-weight: 700;
    line-height: 30px;
    text-transform: capitalize;
    display: inline-block;
    width: 40%; }
  .team-single .team-share a {
    color: #333333;
    font-size: 17px;
    line-height: 1;
    margin-right: 3px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 25px;
    height: 25px; }
    .team-single .team-share a:last-of-type {
      margin-right: 0; }
    .team-single .team-share a:hover i:first-child {
      top: -101%; }
    .team-single .team-share a:hover i:last-child {
      top: 2px; }
    .team-single .team-share a i:first-child {
      position: absolute;
      top: 2px;
      left: 0;
      width: 25px;
      height: 25px;
      transition: 300ms ease-in-out; }
    .team-single .team-share a i:last-child {
      position: absolute;
      top: 103%;
      left: 0;
      width: 25px;
      height: 25px;
      color: var(--global--color-primary);
      transition: 300ms ease-in-out; }
  .team-single.team-single-standard {
    padding-top: 0; }

/* Team Carousel */
.team-carousel {
  position: relative;
  padding-bottom: 0px;
  overflow: visible;
  padding-top: 116px; }

@media screen and (max-width:991.98px) {
  .team-carousel {
    padding-top: 70px; } }
  .team-carousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 120px);
    height: 100%;
    background-color: #e5f7fa;
    border-radius: 32px 32px 0px 32px; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .team-carousel::before {
        width: calc(100% - 30px); } }
    @media screen and (max-width: 991.98px) {
      .team-carousel::before {
        width: calc(100% - 60px); } }

@media screen and (max-width:767.98px) {
  .team-carousel::before {
    width: calc(100% - 20px); } }
  .team-carousel .owl-carousel .owl-stage-outer {
    overflow: visible; }
    .team-carousel .owl-carousel .owl-stage-outer .owl-item {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out; }
      .team-carousel .owl-carousel .owl-stage-outer .owl-item.active {
        opacity: 1;
        visibility: visible; }
  .team-carousel .owl-carousel .owl-nav {
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 991.98px) {
      .team-carousel .owl-carousel .owl-nav .owl-next::before,
      .team-carousel .owl-carousel .owl-nav .owl-prev::before {
        font-size: 25px; } }
  .team-carousel .action-bar {
    margin-top: 7px;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 991.98px) {
      .team-carousel .action-bar {
        margin-top: 40px; } }
    .team-carousel .action-bar .note {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 24px;
      color: #85849e; }
      @media screen and (max-width: 991.98px) {
        .team-carousel .action-bar .note {
          text-align: center;
          line-height: 30px; } }
      .team-carousel .action-bar .note span {
        text-transform: capitalize;
        color: var(--global--color-heading-darken); }
        .team-carousel .action-bar .note span i {
          margin-right: 5px; }
    .team-carousel .action-bar .btn {
      border-radius: 8px 8px 0 8px;
      width: 210px;
      height: 60px;
      position: absolute;
      right: 0;
      bottom: -30px; }
      @media screen and (max-width: 991.98px) {
        .team-carousel .action-bar .btn {
          right: 50%;
          transform: translateX(50%); } }

/* Team Projects */
.team-projects {
  padding-top: 0;
  padding-bottom: 0; }
  .team-projects .project {
    position: relative;
    overflow: hidden; }
    .team-projects .project:hover img {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .team-projects .project a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .team-projects .project img {
      display: inline-flex;
      margin: 0 auto;
      max-width: none;
      width: calc(100% + 50px);
      opacity: 0.9;
      transition: opacity 0.35s, transform 0.35s;
      transform: translate3d(-40px, 0, 0); }
      @media screen and (max-width: 767.98px) {
        .team-projects .project img {
          max-width: 100%;
          transform: translate3d(0px, 0, 0); } }

/* Team Gallery */
.team-gallery {
  padding-top: 110px;
  padding-bottom: 110px; }
  .team-gallery .btn {
    margin-top: 20px;
    display: inline-flex;
    width: 170px;
    height: 60px;
    border-radius: 8px 8px 8px 0;
    border-color: #687596;
    border: 2px solid;
    transition-property: background, color, border-color; }
    .team-gallery .btn::before {
      background-color: var(--global--color-secondary);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px; }
    .team-gallery .btn:hover {
      border-color: #213360; }
  .team-gallery .team-member {
    margin-bottom: 30px; }
    .team-gallery .team-member .team-member-holder {
      position: relative; }
      .team-gallery .team-member .team-member-holder:hover .team-hover {
        opacity: 1;
        visibility: visible;
        transform: scale(1); }
      .team-gallery .team-member .team-member-holder .team-img {
        border-radius: 8px 8px 8px 0; }
        .team-gallery .team-member .team-member-holder .team-img::before {
          content: unset; }
      .team-gallery .team-member .team-member-holder .team-hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, rgba(19, 189, 213, 0) 0%, #1d294dc7 0%);
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
        transition: 0.4s ease-in-out; }
        .team-gallery .team-member .team-member-holder .team-hover .team-zoom {
          color: var(--global--color-white);
          font-size: 20px; }
        .team-gallery .team-member .team-member-holder .team-hover a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 2; }

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@-moz-keyframes bounce {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@-ms-keyframes bounce {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@-o-keyframes bounce {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@keyframes bounce {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .team-filter li a {
    margin-right: 10px;
    font-size: 13px; }
  .team-member .team--img img,
  .team .owl-carousel .owl-item img {
    width: 100%; }
  .team-grid .team-member-holder,
  .team-masonry .team-member-holder {
    max-width: 370px;
    margin-right: auto;
    margin-left: auto; }
  .team-slider .carousel-navs .owl-nav .owl-next:before,
  .team-slider .carousel-navs .owl-nav .owl-prev:before {
    font-size: 30px; } }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-masonry .team--img img {
    width: 100%; } }

/*------------------------------------*\
    #Timetable
\*------------------------------------*/
.timetable {
  padding-top: 60px;
  padding-bottom: 110px; }
  .timetable .table-sort {
    margin-bottom: 50px;
    height: 60px; }
    @media screen and (max-width: 991.98px) {
      .timetable .table-sort {
        display: flex;
        justify-content: center; } }
    .timetable .table-sort .nice-select {
      padding: 9px 25px;
      width: 250px;
      border-radius: 0 8px 8px 8px;
      border-color: #e5e7eb;
      height: 100%;
      font-size: var(--global--font-body);
      font-weight: 400;
      font-size: 14px;
      color: var(--global--color-heading);
      text-transform: capitalize; }
      .timetable .table-sort .nice-select::after {
        width: 7px;
        height: 7px;
        border-color: var(--global--color-heading);
        right: 25px; }
      .timetable .table-sort .nice-select .list {
        width: 100%; }
  @media screen and (min-width: 992px) {
    .timetable .table-holder {
      display: grid;
      grid-template-columns: repeat(8, 1fr); } }
  .timetable .table-holder .day-appointment {
    background-color: var(--global--color-primary-light);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--global--font-body);
    font-weight: 700;
    font-size: 14px;
    color: var(--global--color-body);
    text-transform: capitalize;
    margin: 2px; }
    @media screen and (max-width: 991.98px) {
      .timetable .table-holder .day-appointment {
        margin: 0;
        padding: 15px 0; } }
  .timetable .table-holder .appointment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: var(--global--font-body);
    font-weight: 700;
    font-size: 14px;
    color: var(--global--color-body);
    text-transform: capitalize;
    margin: 2px;
    border-radius: 4px;
    background-color: var(--global--color-primary-light); }
  .timetable .table-holder .time-table {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--global--color-heading-darken);
    margin: 2px;
    border-radius: 4px;
    transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden; }
    .timetable .table-holder .time-table.active {
      opacity: 1;
      visibility: visible; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .timetable .table-holder .time-table {
        padding: 0; } }
    .timetable .table-holder .time-table:hover {
      background-color: var(--global--color-primary); }
    @media screen and (max-width: 991.98px) {
      .timetable .table-holder .time-table {
        flex-direction: row;
        justify-content: space-between;
        margin: 15px 0;
        border-radius: 0;
        background-color: transparent;
        border-bottom: 1px solid #eaeaea;
        opacity: 1;
        visibility: visible;
        display: none; }
        .timetable .table-holder .time-table.active {
          display: flex; }
        .timetable .table-holder .time-table:hover {
          background-color: transparent; }
        .timetable .table-holder .time-table:last-child {
          border-bottom: 0; } }
    .timetable .table-holder .time-table .clinic {
      font-weight: 500;
      font-family: var(--global--font-heading);
      font-size: 17px;
      line-height: 28px;
      color: var(--global--color-white);
      text-transform: capitalize;
      margin-bottom: 5px; }
      .timetable .table-holder .time-table .clinic:hover {
        text-decoration: underline; }
      @media screen and (max-width: 991.98px) {
        .timetable .table-holder .time-table .clinic {
          color: var(--global--color-body); }
          .timetable .table-holder .time-table .clinic:hover {
            text-decoration: none; } }
    .timetable .table-holder .time-table .type {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: #f4f4f4;
      text-transform: capitalize;
      margin-bottom: 19px; }
      @media screen and (max-width: 991.98px) {
        .timetable .table-holder .time-table .type {
          display: none; } }
    .timetable .table-holder .time-table .hours {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #f4f4f4;
      margin-bottom: 14px; }
      .timetable .table-holder .time-table .hours span {
        display: block; }
      @media screen and (max-width: 991.98px) {
        .timetable .table-holder .time-table .hours {
          color: var(--global--color-body);
          margin-bottom: 0; }
          .timetable .table-holder .time-table .hours span {
            display: inline-flex;
            align-items: center; }
            .timetable .table-holder .time-table .hours span:last-child:after {
              content: unset; }
            .timetable .table-holder .time-table .hours span:after {
              content: '-';
              margin: 4px; } }
    .timetable .table-holder .time-table .doctor {
      font-family: var(--global--font-heading);
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #f4f4f4;
      margin-bottom: 0; }
      @media screen and (max-width: 991.98px) {
        .timetable .table-holder .time-table .doctor {
          display: none; } }
  @media screen and (min-width: 992px) {
    .timetable .table-holder .table-column {
      display: grid;
      grid-template-rows: 50px repeat(6, 200px); } }
  .timetable .table-holder .table-column .double {
    grid-row: span 2; }
  @media screen and (max-width: 991.98px) {
    .timetable .table-holder .table-column.first-column {
      display: none; } }
  .timetable .table-holder .day-appointment {
    width: auto;
    height: auto; }
  .timetable .table-holder .appointment {
    width: auto;
    height: auto; }
  .timetable .table-holder .time-table {
    width: auto;
    height: auto; }

/*------------------------------------*\
#Skills
\*------------------------------------*/
.skills .progressbar {
  margin-bottom: 29px; }
  .skills .progressbar:last-of-type {
    margin-bottom: 0; }
  .skills .progressbar .progress-title {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 7px;
    line-height: 1; }
    .skills .progressbar .progress-title .title {
      font-family: var(--global--font-body);
      color: var(--global--color-heading);
      font-size: 15px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 2px;
      text-transform: capitalize; }
    .skills .progressbar .progress-title .value {
      font-family: var(--global--font-body);
      color: var(--global--color-body);
      font-weight: 700;
      font-size: 15px;
      line-height: 1px;
      text-transform: uppercase;
      bottom: 7px;
      position: absolute;
      margin-left: -27px;
      opacity: 0;
      transition: opacity 300ms linear; }
  .skills .progressbar .progress {
    height: 12px;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    width: 100%;
    border-bottom: 2px solid #dcdee2;
    margin-bottom: 0;
    border-radius: 0; }
    .skills .progressbar .progress .progress-bar {
      height: 7px;
      background-color: var(--global--color-heading-darken);
      border-radius: 4px;
      width: 0; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .skills .heading {
    margin-bottom: 30px; } }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skills .heading {
    margin-bottom: 30px; } }

/*------------------------------------*\
	#Widgets
\*------------------------------------*/
@media screen and (min-width: 992px) {
  .sidebar {
    max-width: 330px;
    margin-left: auto; } }

/* Widget */
.widget {
  background-color: var(--global--color-primary-light);
  padding: 33px 40px 40px;
  margin-bottom: 40px;
  border-radius: 8px 8px 0 8px;
  position: relative; }
  .widget::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: calc(100% - 80px);
    background-color: var(--global--color-primary); }
  .widget .widget-title {
    text-align: center;
    margin-bottom: 22px; }
    @media screen and (min-width: 992px) {
      .widget .widget-title {
        text-align: left; } }
    .widget .widget-title h5 {
      font-size: 19px;
      line-height: 30px;
      font-weight: 500;
      font-family: var(--global--font-heading);
      color: var(--global--color-heading);
      text-transform: capitalize;
      margin-bottom: 0;
      position: relative; }

/* Category Widget */
.widget-categories {
  padding-bottom: 28px; }
  .widget-categories .widget-title {
    margin-bottom: 8px; }
  .widget-categories ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0; }
    .widget-categories ul li {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .widget-categories ul li:hover a {
        padding-left: 15px;
        color: var(--global--color-primary); }
      .widget-categories ul li:hover span {
        background-color: var(--global--color-primary);
        border-color: var(--global--color-primary);
        color: var(--global--color-white); }
      .widget-categories ul li a {
        color: var(--global--color-secondary);
        font-size: 15px;
        font-family: var(--global--font-body);
        font-weight: 700;
        line-height: 36px;
        text-transform: capitalize;
        position: relative;
        transition: 0.3s ease-in-out;
        display: inline-flex;
        align-items: center; }
        .widget-categories ul li a:before {
          display: inline-block;
          content: '';
          width: 12px;
          height: 2px;
          margin-right: 10px;
          background-color: var(--global--color-primary); }
      .widget-categories ul li span {
        font-family: var(--global--font-body);
        font-weight: 400;
        font-size: 13px;
        padding: 1px 7px;
        color: var(--global--color-primary);
        background-color: var(--global--color-white);
        border: 1px solid var(--global--color-gray-light);
        border-radius: 8px 8px 0 8px;
        transition: 0.3s ease-in-out; }

/* Tags Widget */
.widget-tags .widget-title {
  margin-bottom: 22px; }

.widget-tags .widget-content {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .widget-tags .widget-content {
      text-align: left; } }
  .widget-tags .widget-content a {
    display: inline-block;
    background-color: var(--global--color-white);
    font-family: var(--global--font-body);
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    color: var(--global--color-primary);
    margin-right: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
    padding: 0px 9px;
    border: 1px solid var(--global--color-gray-light);
    border-radius: 8px 8px 0 8px;
    transition: 0.3s ease-in-out; }
    .widget-tags .widget-content a:hover {
      color: var(--global--color-white);
      background-color: var(--global--color-primary);
      border-color: var(--global--color-primary); }

/* Recent Posts */
.widget-recent-posts {
  padding-bottom: 40px; }
  .widget-recent-posts .widget-title {
    margin-bottom: 20px; }
  .widget-recent-posts .post {
    position: relative;
    margin-bottom: 25px;
    display: flex; }
    .widget-recent-posts .post:last-child {
      margin-bottom: 0; }
    .widget-recent-posts .post .post-img {
      flex-shrink: 0;
      max-width: 100%;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 8px 8px 0 8px;
      width: 80px;
      height: 80px; }
      .widget-recent-posts .post .post-img img {
        width: 100%;
        height: 100%; }
    .widget-recent-posts .post .post-content {
      position: relative; }
      .widget-recent-posts .post .post-content .post-date {
        font-family: var(--global--font-body);
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--global--color-heading-darken);
        text-transform: capitalize; }
        .widget-recent-posts .post .post-content .post-date span:after {
          position: relative;
          content: ',';
          margin-right: 4px; }
        .widget-recent-posts .post .post-content .post-date span:last-child:after {
          content: unset; }
      .widget-recent-posts .post .post-content .post-title a {
        font-family: var(--global--font-heading);
        color: var(--global--color-heading);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize; }
        .widget-recent-posts .post .post-content .post-title a:hover {
          color: var(--global--color-primary); }
  .widget-recent-posts .carousel-dots .owl-dots {
    text-align: center;
    margin-top: 26px; }
    @media screen and (min-width: 992px) {
      .widget-recent-posts .carousel-dots .owl-dots {
        text-align: left; } }
    .widget-recent-posts .carousel-dots .owl-dots .owl-dot.active span {
      background-color: var(--global--color-primary);
      border-color: var(--global--color-primary); }
    .widget-recent-posts .carousel-dots .owl-dots .owl-dot span {
      width: 16px;
      height: 6px;
      border-radius: 2px;
      border-width: 3px;
      background-color: var(--global--color-heading);
      border-color: var(--global--color-heading);
      margin-right: 12px; }

/* Search Widget */
.widget-search .form-search {
  border-radius: 3px;
  border: 1px solid var(--global--color-gray-light);
  margin-bottom: 0;
  border-radius: 8px 8px 0 8px;
  background-color: var(--global--color-white); }
  .widget-search .form-search .form-control {
    border: none;
    box-shadow: none;
    color: var(--global--color-heading);
    font-size: 14px;
    font-family: var(--global--font-body);
    font-weight: 400;
    line-height: 5px;
    height: 55px;
    text-transform: capitalize;
    padding-left: 20px;
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 0; }
    .widget-search .form-search .form-control::-webkit-input-placeholder {
      font-size: 14px;
      font-family: var(--global--font-body);
      color: var(--global--color-heading);
      text-transform: capitalize; }
    .widget-search .form-search .form-control:-moz-placeholder {
      font-size: 14px;
      font-family: var(--global--font-body);
      color: var(--global--color-heading);
      text-transform: capitalize; }
  .widget-search .form-search .btn {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    width: 45px;
    height: 55px;
    line-height: 29px;
    font-size: 16px; }
    .widget-search .form-search .btn i {
      color: var(--global--color-heading);
      transition: 0.3s ease-in-out;
      margin-right: 0; }
    .widget-search .form-search .btn:hover i {
      color: var(--global--color-primary); }

/* Reservation Widget */
.widget-reservation {
  position: relative;
  overflow: hidden;
  padding: 50px 40px 60px;
  border-radius: 8px 8px 0 8px; }
  .widget-reservation:hover::before {
    opacity: 0;
    visibility: hidden; }
  .widget-reservation:hover::after {
    opacity: 0.9;
    visibility: visible; }
  .widget-reservation:hover .widget-content a span {
    background-color: var(--global--color-heading-darken); }
  .widget-reservation img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .widget-reservation::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(6, 30, 59, 0) 0%, var(--global--color-primary) 0%);
    transform: translateX(0);
    z-index: 2;
    transition: 0.5s ease-in-out;
    opacity: 0.9;
    visibility: visible; }
  .widget-reservation::after {
    content: '';
    background-image: linear-gradient(180deg, rgba(19, 189, 213, 0) 0%, #1d294d 0%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    z-index: 2;
    transition: 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden; }
  @media screen and (max-width: 991.98px) {
    .widget-reservation {
      text-align: center; } }
  .widget-reservation .widget-content {
    position: relative;
    z-index: 3; }
    .widget-reservation .widget-content i {
      display: block;
      margin-bottom: 11px; }
      .widget-reservation .widget-content i:before {
        font-size: 50px;
        color: var(--global--color-white);
        margin-left: 0; }
    .widget-reservation .widget-content h5 {
      text-transform: capitalize;
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 19px;
      line-height: 28px;
      color: var(--global--color-white);
      margin-bottom: 16px; }
    .widget-reservation .widget-content p {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #f4f4f4;
      margin-bottom: 21px; }
    .widget-reservation .widget-content a {
      font-family: var(--global--font-heading);
      font-weight: 400;
      color: var(--global--color-white);
      font-size: 24px;
      display: flex;
      align-items: center;
      transition: 0.3s ease-in-out; }
      @media screen and (max-width: 991.98px) {
        .widget-reservation .widget-content a {
          justify-content: center; } }
      .widget-reservation .widget-content a span {
        transition: 0.3s ease-in-out;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        background-color: #1aadcd;
        color: var(--global--color-white);
        border-radius: 8px 8px 0 8px;
        font-size: 16px;
        margin-right: 10px; }
  .widget-reservation.widget-reservation-2::before {
    content: unset; }
  .widget-reservation.widget-reservation-2:after {
    opacity: 0.9;
    visibility: visible; }
  .widget-reservation.widget-reservation-2 .widget-content a span {
    background-color: var(--global--color-heading-darken); }

/* Opening Hours Widget */
.widget-opening-hours {
  position: relative;
  overflow: hidden;
  padding: 50px 40px 60px;
  border-radius: 8px 8px 0 8px;
  background-color: #f6f6f6; }
  @media screen and (max-width: 991.98px) {
    .widget-opening-hours {
      text-align: center; } }
  .widget-opening-hours::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--global--color-heading-darken);
    height: 3px;
    width: calc(100% - 80px); }
  .widget-opening-hours .widget-content {
    position: relative;
    z-index: 3; }
    .widget-opening-hours .widget-content i {
      display: block;
      margin-bottom: 11px; }
      .widget-opening-hours .widget-content i:before {
        font-size: 50px;
        color: #51668a;
        margin-left: 0; }
    .widget-opening-hours .widget-content h5 {
      text-transform: capitalize;
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 19px;
      line-height: 28px;
      color: var(--global--color-heading);
      margin-bottom: 8px; }
    .widget-opening-hours .widget-content ul {
      margin-bottom: 0; }
      .widget-opening-hours .widget-content ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        font-family: var(--global--font-body);
        color: var(--global--color-body);
        text-transform: capitalize;
        border-bottom: 1px solid #dcdee2;
        padding: 9px 0; }

/* Widget Download */
.widget-download {
  padding: 0;
  background-color: transparent; }
  .widget-download::before {
    content: unset; }
  .widget-download ul {
    margin: 0; }
    .widget-download ul li:last-of-type a {
      margin-bottom: 0; }
    .widget-download ul li.inversed a {
      background-color: var(--global--color-primary); }
      .widget-download ul li.inversed a::before {
        background-color: var(--global--color-secondary); }
    .widget-download ul li a {
      display: flex;
      align-items: center;
      padding: 0 40px;
      height: 100px;
      background-color: var(--global--color-secondary);
      color: var(--global--color-white);
      transition: 0.3s ease-in-out;
      border-radius: 8px 8px 0 8px;
      margin-bottom: 20px;
      position: relative; }
      @media screen and (max-width: 767.98px) {
        .widget-download ul li a {
          padding: 0 20px;
          height: 80px; } }
      .widget-download ul li a::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        width: calc(100% - 80px);
        background-color: var(--global--color-primary); }
        @media screen and (max-width: 767.98px) {
          .widget-download ul li a::before {
            width: calc(100% - 40px); } }
      .widget-download ul li a svg {
        margin-right: 20px;
        fill: var(--global--color-white); }
      .widget-download ul li a span {
        font-family: var(--global--font-heading);
        font-size: 17px;
        font-weight: 500;
        text-transform: capitalize; }

/* Widget Recent Products */
.widget-recent-products .product {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .widget-recent-products .product .product-img {
    flex-shrink: 0;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 8px 8px 0 8px; }
    .widget-recent-products .product .product-img img {
      border-radius: 8px 8px 0 8px;
      max-width: 100%; }
  .widget-recent-products .product .product-desc .product-title a {
    text-transform: capitalize;
    font-family: var(--global--font-heading);
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: var(--global--color-heading);
    margin-bottom: 4px;
    display: inline-block; }
    .widget-recent-products .product .product-desc .product-title a:hover {
      color: var(--global--color-primary); }
  .widget-recent-products .product .product-desc .product-meta span {
    font-family: var(--global--font-body);
    font-size: 15px;
    font-weight: 700;
    color: var(--global--color-primary); }

/* Widget Services */
.widget-services .widget-content ul {
  margin-bottom: 0; }
  .widget-services .widget-content ul li {
    margin-bottom: 4px; }
    .widget-services .widget-content ul li:last-child {
      margin-bottom: 0; }
    .widget-services .widget-content ul li a {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      color: var(--global--color-secondary);
      text-transform: capitalize;
      background-color: var(--global--color-white);
      border-radius: 8px 8px 0 8px;
      transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.05);
      height: 65px;
      padding: 0 30px; }
      .widget-services .widget-content ul li a:hover {
        background-color: var(--global--color-secondary);
        color: var(--global--color-white); }
        .widget-services .widget-content ul li a:hover .line span {
          opacity: 0;
          visibility: hidden; }
        .widget-services .widget-content ul li a:hover .line::after {
          transform: translateY(-50%) translateX(0px);
          opacity: 1;
          visibility: visible; }
      .widget-services .widget-content ul li a .line {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        position: relative;
        margin-right: 10px; }
        .widget-services .widget-content ul li a .line span {
          display: block;
          width: 12px;
          height: 2px;
          background-color: var(--global--color-secondary);
          transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55); }
        .widget-services .widget-content ul li a .line::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-15px);
          left: 0;
          content: '\e901';
          font-family: 'icomoon';
          font-size: 10px;
          color: var(--global--color-white);
          display: inline-block;
          opacity: 0;
          visibility: hidden;
          transition: 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55); }

/* Widget Filter */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit; }

.ui-slider-horizontal .ui-slider-handle {
  position: absolute;
  top: -2px;
  margin-left: -0.6em; }

.ui-slider-horizontal .ui-slider-range {
  position: absolute;
  top: 0;
  height: 100%; }

.ui-slider-horizontal .ui-slider-range-min {
  left: 0; }

.ui-slider-horizontal .ui-slider-range-max {
  right: 0; }

.ui-slider-vertical {
  width: 0.8em;
  height: 100px; }
  .ui-slider-vertical .ui-slider-handle {
    left: -0.3em;
    margin-left: 0;
    margin-bottom: -0.6em; }
  .ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%; }
  .ui-slider-vertical .ui-slider-range-min {
    bottom: 0; }
  .ui-slider-vertical .ui-slider-vertical .ui-slider-range-max {
    top: 0; }

.widget-filter .ui-slider {
  position: relative;
  height: 4px;
  background-color: rgba(34, 34, 34, 0.06);
  border-radius: 0;
  margin-bottom: 20px;
  margin-top: 5px; }

.widget-filter .ui-slider-handle {
  /* Style for "Line" */
  width: 8px;
  height: 8px;
  margin-left: 0;
  background-color: var(--global--color-primary);
  border-radius: 50%; }

.widget-filter .ui-slider-range {
  height: 4px;
  background-color: var(--global--color-primary);
  margin-bottom: 20px; }

.widget-filter .slider-mount {
  display: flex;
  align-items: center;
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 400;
  line-height: 1; }

.widget-filter label {
  color: var(--global--color-body);
  margin: 0; }

.widget-filter input {
  color: var(--global--color-body);
  border: none;
  background-color: transparent; }

.widget-filter .btn-filter {
  float: right;
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--global--color-secondary);
  flex-grow: 2;
  text-align: right; }
  .widget-filter .btn-filter:hover {
    color: var(--global--color-primary); }

/* Doctors sidebar */
@media screen and (min-width: 992px) {
  .sidebar-doctors {
    margin-top: 292px;
    max-width: 330px;
    margin-left: 0;
    margin-right: auto; } }

@media screen and (min-width: 992px) {
  .sidebar-doctors.sidebar-doctors-2 {
    margin-top: 0px; } }

@media screen and (min-width: 992px) {
  .sidebar-doctors.sidebar-doctors-3 {
    margin-top: 0px; } }

.sidebar-doctors.sidebar-doctors-3 .team-modern .team-member {
  box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06);
  margin-bottom: 40px; }

/* blog sidebar */
.sidebar-blog .form-search {
  background-color: var(--global--color-white); }

.sidebar-blog .widget-recent-posts .entry {
  margin-bottom: 30px; }

.sidebar-blog .widget-recent-posts .entry:last-of-type {
  margin-bottom: 0; }

.sidebar-blog .widget-recent-posts .entry-title a {
  font-weight: 700; }

.sidebar-blog .widget-recent-posts .entry-date {
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 600;
  color: #616161; }

/* Shop Sidebar */
.sidebar-shop .widget {
  padding: 0;
  background-color: var(--global--color-white); }
  .sidebar-shop .widget::before {
    content: unset; }

.sidebar-shop .widget-categories {
  margin-bottom: 35px; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .sidebar {
    margin-top: 40px; }
  .widget {
    margin-bottom: 30px; } }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    margin-top: 40px; }
  .widget-recent .entry .entry-desc {
    margin-left: 70px; } }

/*------------------------------------*\
    #About
\*------------------------------------*/
.about {
  padding-top: 116px;
  padding-bottom: 130px;
  overflow: visible; }
  @media screen and (max-width: 991.98px) {
    .about {
      padding-top: 70px;
      padding-bottom: 70px; } }
  .about .about-img {
    position: relative;
    transform: translateY(12px); }
    @media screen and (max-width: 991.98px) {
      .about .about-img {
        transform: translateY(0px);
        width: max-content;
        margin: 0 auto 80px; } }
    .about .about-img img {
      border-radius: 32px 32px 0 32px;
      height: 677px; }
      @media screen and (max-width: 767.98px) {
        .about .about-img img {
          height: 450px; } }
    .about .about-img .btn-video {
      position: absolute;
      left: 0;
      bottom: 0; }
      @media screen and (max-width: 767.98px) {
        .about .about-img .btn-video {
          height: 75px; } }
  .about .about-image-bottom {
    position: relative;
    overflow: visible;
    margin-top: 12px; }
    .about .about-image-bottom img {
      position: absolute;
      top: -95px;
      left: 0;
      z-index: 5;
      display: block;
      width: 470px;
      height: 314px;
      border-radius: 32px 32px 0 32px; }
      @media screen and (max-width: 991.98px) {
        .about .about-image-bottom img {
          height: auto;
          top: -27px;
          left: 50%;
          transform: translateX(-50%); } }
      @media screen and (max-width: 767.98px) {
        .about .about-image-bottom img {
          width: auto;
          max-width: 100%; } }
      @media (min-width: 992px) and (max-width: 1200px) {
        .about .about-image-bottom img {
          top: -30px; } }
  .about .signature-block {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991.98px) {
      .about .signature-block {
        justify-content: center; } }
    .about .signature-block .signature-body {
      position: relative; }
      .about .signature-block .signature-body img {
        position: absolute;
        top: -18px;
        left: 2px;
        right: 0; }

@media screen and (max-width:767.98px) {
  .about .signature-block .signature-body img {
    top: 0;
    left: -20px;
    max-width: 130%; } }
      .about .signature-block .signature-body h6 {
        font-family: var(--global--font-heading);
        font-weight: 500;
        color: var(--global--color-secondary);
        font-size: 19px;
        line-height: 29px;
        text-transform: capitalize;
        margin-bottom: 0; }
      .about .signature-block .signature-body p {
        font-family: var(--global--font-body);
        font-weight: 400;
        color: var(--global--color-primary);
        font-size: 14px;
        line-height: 26px;
        text-transform: capitalize;
        margin-bottom: 0; }
    .about .signature-block .btn {
      border-radius: 8px 8px 0 8px;
      margin-right: 40px;
      width: 170px;
      height: 60px; }
  .about .advantages-list {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none; }
    @media screen and (max-width: 991.98px) {
      .about .advantages-list {
        text-align: left; } }
    .about .advantages-list li {
      display: block;
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 15px;
      line-height: 36px;
      color: var(--global--color-body);
      text-transform: capitalize; }
      .about .advantages-list li i {
        width: 22px;
        height: 22px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--global--color-heading-darken);
        color: var(--global--color-white);
        margin-right: 10px;
        border-radius: 50%;
        font-size: 10px; }
  .about.about-2 {
    padding-top: 0;
    padding-bottom: 0; }
    .about.about-2 .about-img {
      width: auto;
      transform: translateY(-130px);
      margin-bottom: -130px;
      z-index: 5; }
      .about.about-2 .about-img img {
        height: auto; }
      .about.about-2 .about-img .btn-video {
        left: auto;
        right: 30px;
        bottom: -30px; }
  .about.about-3 {
    padding-top: 122px;
    padding-bottom: 0;
    overflow: visible;
    z-index: 5; }
    @media screen and (max-width: 991.98px) {
      .about.about-3 {
        padding-top: 70px; } }
    .about.about-3 .about-img-holder {
      display: flex;
      flex-direction: column; }
      .about.about-3 .about-img-holder .about-img {
        transform: translateY(0);
        width: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1; }
        @media screen and (min-width: 992px) {
          .about.about-3 .about-img-holder .about-img {
            transform: translateY(-100px); } }
        @media screen and (max-width: 991.98px) {
          .about.about-3 .about-img-holder .about-img {
            margin-bottom: 0; } }
        .about.about-3 .about-img-holder .about-img img {
          height: 100%;
          border-radius: 0 32px 32px 0; }
          @media screen and (max-width: 991.98px) {
            .about.about-3 .about-img-holder .about-img img {
              border-radius: 0px 0px 32px 32px; } }
        .about.about-3 .about-img-holder .about-img .btn-video {
          bottom: 40px;
          left: 40px; }
          @media screen and (max-width: 767.98px) {
            .about.about-3 .about-img-holder .about-img .btn-video {
              height: 61px; } }
    .about.about-3 .video-card {
      transform: translateY(210px);
      margin-top: -210px;
      z-index: 6; }
      .about.about-3 .video-card .card-left {
        border-radius: 32px 0 0 32px;
        background-color: var(--global--color-white);
        padding: 53px 60px 60px;
        box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); }
        @media screen and (max-width: 991.98px) {
          .about.about-3 .video-card .card-left {
            border-radius: 32px 32px 0 0; } }
        @media screen and (max-width: 767.98px) {
          .about.about-3 .video-card .card-left {
            padding-left: 25px;
            padding-right: 25px; } }
        .about.about-3 .video-card .card-left .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 27px;
          color: var(--global--color-secondary);
          margin-bottom: 25px; }
        .about.about-3 .video-card .card-left .desc {
          font-size: 16px;
          line-height: 27px;
          color: var(--global--color-body);
          margin-bottom: 32px; }
        .about.about-3 .video-card .card-left .card-action {
          display: flex;
          align-items: center; }
          @media screen and (max-width: 991.98px) {
            .about.about-3 .video-card .card-left .card-action {
              justify-content: center; } }
          @media screen and (max-width: 767.98px) {
            .about.about-3 .video-card .card-left .card-action {
              flex-direction: column; } }
          .about.about-3 .video-card .card-left .card-action .btn {
            width: 170px;
            height: 60px;
            border-radius: 8px 8px 0 8px;
            margin-right: 30px; }
            @media screen and (max-width: 767.98px) {
              .about.about-3 .video-card .card-left .card-action .btn {
                margin-right: 0;
                margin-bottom: 20px; } }
            .about.about-3 .video-card .card-left .card-action .btn:last-child {
              border-radius: 8px 8px 8px 0;
              border: 2px solid;
              border-color: #687696;
              transition-property: background, color, border-color; }
              .about.about-3 .video-card .card-left .card-action .btn:last-child::before {
                background-color: var(--global--color-secondary);
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                top: -2px;
                left: -2px; }
              .about.about-3 .video-card .card-left .card-action .btn:last-child:hover {
                border-color: var(--global--color-secondary); }
  .about.about-4 {
    padding-top: 109px;
    padding-bottom: 0; }
    @media screen and (max-width: 991.98px) {
      .about.about-4 {
        padding-top: 70px; } }
    .about.about-4 .avatars-holder {
      position: relative;
      z-index: 5; }
      .about.about-4 .avatars-holder .avatar {
        display: inline-flex; }
        @media screen and (max-width: 991.98px) {
          .about.about-4 .avatars-holder .avatar {
            align-items: center;
            margin-bottom: 30px; } }
        .about.about-4 .avatars-holder .avatar img {
          width: 100%;
          height: 100%; }
          @media screen and (max-width: 991.98px) {
            .about.about-4 .avatars-holder .avatar img {
              width: auto;
              margin: 0 auto;
              max-width: 100%; } }
      .about.about-4 .avatars-holder .block-top {
        margin-bottom: 40px; }
        .about.about-4 .avatars-holder .block-top .col-12 {
          display: flex;
          flex-direction: column;
          justify-content: flex-end; }
          .about.about-4 .avatars-holder .block-top .col-12 .avatar {
            width: 100%; }
      @media screen and (min-width: 992px) {
        .about.about-4 .avatars-holder .block-bottom {
          margin-bottom: -313px; } }
      .about.about-4 .avatars-holder .block-bottom .col-12 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        @media screen and (max-width: 991.98px) {
          .about.about-4 .avatars-holder .block-bottom .col-12 {
            align-items: center; } }
      .about.about-4 .avatars-holder .avatar-1 {
        height: 170px; }
        .about.about-4 .avatars-holder .avatar-1 img {
          border-radius: 32px 0 32px 32px; }
      .about.about-4 .avatars-holder .avatar-2 {
        height: 307px;
        border-radius: 0px 32px 32px 32px;
        padding: 71px 50px 50px; }
        .about.about-4 .avatars-holder .avatar-2::before {
          background-image: linear-gradient(180deg, #13C1D9BF 18%, var(--global--color-primary) 70%); }
        @media screen and (max-width: 767.98px) {
          .about.about-4 .avatars-holder .avatar-2 {
            height: auto; } }
        .about.about-4 .avatars-holder .avatar-2 .avatar-content p {
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-style: italic;
          font-size: 18px;
          line-height: 29px;
          color: #f4f4f4;
          margin-bottom: 20px; }
        .about.about-4 .avatars-holder .avatar-2 .avatar-content .btn {
          width: 143px;
          height: 42px;
          border: 2px solid;
          border-color: #4ed4e6;
          transition-property: background, color, border-color; }
          .about.about-4 .avatars-holder .avatar-2 .avatar-content .btn::before {
            background-color: var(--global--color-secondary);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            top: -2px;
            left: -2px; }
          .about.about-4 .avatars-holder .avatar-2 .avatar-content .btn:hover, .about.about-4 .avatars-holder .avatar-2 .avatar-content .btn:active, .about.about-4 .avatars-holder .avatar-2 .avatar-content .btn:focus {
            border-color: var(--global--color-secondary); }
      .about.about-4 .avatars-holder .avatar-3 {
        height: 225px; }
        .about.about-4 .avatars-holder .avatar-3 img {
          border-radius: 32px 32px 32px 0px; }
      .about.about-4 .avatars-holder .avatar-4 {
        height: 405px; }
        @media screen and (min-width: 992px) {
          .about.about-4 .avatars-holder .avatar-4 {
            transform: translateY(-210px); } }
        .about.about-4 .avatars-holder .avatar-4 img {
          border-radius: 0px 32px 32px 32px; }
      .about.about-4 .avatars-holder .avatar-5 {
        height: 180px; }
        .about.about-4 .avatars-holder .avatar-5 img {
          border-radius: 32px 32px 32px 0px; }
      .about.about-4 .avatars-holder .avatar-6 {
        height: 307px; }
        .about.about-4 .avatars-holder .avatar-6 img {
          border-radius: 0px 32px 32px 32px; }

/*------------------------------------*\
    #Features
\*------------------------------------*/
.features {
  padding-top: 163px;
  padding-bottom: 100px; }
  @media screen and (max-width: 767.98px) {
    .features {
      padding-top: 200px;
      padding-bottom: 70px; } }
  @media (max-width: 424px) {
    .features {
      padding-top: 150px; } }
  .features .features-holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 25px; }
    @media screen and (max-width: 991.98px) {
      .features .features-holder {
        grid-template-columns: 1fr 1fr; } }
    @media screen and (max-width: 767.98px) {
      .features .features-holder {
        grid-template-columns: 1fr; } }
  .features .feature-panel-holder {
    padding-top: 45px;
    transition: 0.3s ease-in-out; }
    .features .feature-panel-holder:hover {
      padding-top: 0; }
      .features .feature-panel-holder:hover .feature-panel {
        background-color: var(--global--color-white); }
        .features .feature-panel-holder:hover .feature-panel .feature-icon i::before {
          color: var(--global--color-primary); }
        .features .feature-panel-holder:hover .feature-panel .feature-content h4 {
          color: var(--global--color-heading); }
        .features .feature-panel-holder:hover .feature-panel .feature-content p {
          visibility: visible;
          opacity: 1; }
        .features .feature-panel-holder:hover .feature-panel a {
          border-color: var(--global--color-primary);
          margin-top: 12px;
          color: var(--global--color-primary); }
        .features .feature-panel-holder:hover .feature-panel.feature-panel-dark {
          background-color: #354f8e; }
          .features .feature-panel-holder:hover .feature-panel.feature-panel-dark .feature-icon i::before {
            color: var(--global--color-white); }
          .features .feature-panel-holder:hover .feature-panel.feature-panel-dark .feature-content h4 {
            color: var(--global--color-white); }
          .features .feature-panel-holder:hover .feature-panel.feature-panel-dark a {
            border-color: var(--global--color-primary);
            margin-top: 12px;
            color: var(--global--color-primary); }
    .features .feature-panel-holder .feature-panel {
      position: relative;
      padding: 23px 30px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 33px;
      border-radius: 8px 0px 8px 8px;
      border: 1px solid #87dfeb;
      transition: 0.3s ease-in-out; }
      .features .feature-panel-holder .feature-panel .feature-icon {
        margin-bottom: 5px; }
        .features .feature-panel-holder .feature-panel .feature-icon i::before {
          color: var(--global--color-white);
          margin-left: 0;
          font-size: 70px;
          transition: 0.3s ease-in-out; }
      .features .feature-panel-holder .feature-panel .feature-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; }
        .features .feature-panel-holder .feature-panel .feature-content h4 {
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          text-transform: capitalize;
          margin-bottom: 6px;
          color: var(--global--color-white);
          transition: 0.3s ease-in-out; }
        .features .feature-panel-holder .feature-panel .feature-content p {
          text-align: center;
          font-family: var(--global--font-body);
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 0px;
          color: var(--global--color-body);
          opacity: 0;
          visibility: hidden;
          transition: 0.3s ease-in-out; }
      .features .feature-panel-holder .feature-panel a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid rgba(var(--global--color-white), 0.5);
        font-size: 13px;
        color: var(--global--color-white);
        margin-top: -32px;
        transition: 0.3s ease-in-out; }
        @media screen and (max-width: 991.98px) {
          .features .feature-panel-holder .feature-panel a {
            margin-top: -12px; } }
      .features .feature-panel-holder .feature-panel.feature-panel-dark {
        border-color: rgba(249, 249, 249, 0.15); }
        .features .feature-panel-holder .feature-panel.feature-panel-dark .feature-icon i::before {
          color: #788fc8; }
        .features .feature-panel-holder .feature-panel.feature-panel-dark .feature-content p {
          color: var(--global--color-gray); }
        .features .feature-panel-holder .feature-panel.feature-panel-dark a {
          border-color: #545e79; }
  .features .actions-holder {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991.98px) {
      .features .actions-holder {
        justify-content: center; } }
    @media screen and (max-width: 767.98px) {
      .features .actions-holder {
        flex-direction: column; } }
    .features .actions-holder .btn {
      margin-right: 30px; }
      @media screen and (max-width: 767.98px) {
        .features .actions-holder .btn {
          margin-right: 0;
          margin-bottom: 20px; } }
    .features .actions-holder .employee-info {
      display: inline-flex; }
      .features .actions-holder .employee-info .employee-img {
        width: 55px;
        height: 55px;
        padding: 3px;
        border: 3px solid var(--global--color-primary);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px; }
        .features .actions-holder .employee-info .employee-img img {
          max-width: 100%;
          border-radius: 50%; }
      .features .actions-holder .employee-info .employee-body h6 {
        font-family: var(--global--font-heading);
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #222222;
        margin-bottom: 0; }
      .features .actions-holder .employee-info .employee-body p {
        font-family: var(--global--font-body);
        font-weight: 400;
        font-size: 13px;
        color: var(--global--color-body);
        margin-bottom: 0; }
  .features .signature-block {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991.98px) {
      .features .signature-block {
        justify-content: center; } }
    .features .signature-block .signature-body {
      position: relative; }
      .features .signature-block .signature-body img {
        position: absolute;
        top: -18px;
        left: 2px;
        right: 0; }

@media screen and (max-width:767.98px) {
  .features .signature-block .signature-body img {
    top: 0;
    left: -20px;
    max-width: 130%; } }
      .features .signature-block .signature-body h6 {
        font-family: var(--global--font-heading);
        font-weight: 500;
        color: var(--global--color-white);
        font-size: 19px;
        line-height: 29px;
        text-transform: capitalize;
        margin-bottom: 0; }
      .features .signature-block .signature-body p {
        font-family: var(--global--font-body);
        font-weight: 400;
        color: var(--global--color-gray);
        font-size: 14px;
        line-height: 26px;
        text-transform: capitalize;
        margin-bottom: 0; }
    .features .signature-block .btn {
      border-radius: 8px 8px 0 8px;
      margin-right: 40px;
      width: 190px;
      height: 60px; }
  .features .more-features {
    text-align: center; }
    .features .more-features p {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 14px;
      color: #f4f4f4;
      margin-bottom: 0; }
      .features .more-features p a {
        position: relative;
        color: var(--global--color-secondary);
        transition: 0.3s ease-in-out;
        text-transform: capitalize;
        padding-bottom: 8px; }
        .features .more-features p a:hover::before {
          width: 70%; }
        .features .more-features p a::before {
          content: '';
          transition: 0.3s ease-in-out;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background-color: var(--global--color-secondary); }
    .features .more-features.more-features-2 {
      margin-bottom: 80px; }
      .features .more-features.more-features-2 p a {
        color: var(--global--color-primary); }
        .features .more-features.more-features-2 p a::before {
          background-color: var(--global--color-primary); }
  .features .features-card {
    display: flex;
    transform: translateY(100px);
    margin-top: -100px; }
    @media screen and (min-width: 992px) {
      .features .features-card {
        justify-content: space-between; } }
    @media screen and (max-width: 991.98px) {
      .features .features-card {
        flex-direction: column; } }
    .features .features-card .card-panel {
      display: flex;
      background: var(--global--color-white);
      border-radius: 8px 0px 8px 8px;
      padding: 30px 40px 34px;
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
      position: relative; }
      @media screen and (max-width: 767.98px) {
        .features .features-card .card-panel {
          flex-direction: column;
          align-items: center;
          padding-left: 25px;
          padding-right: 25px; } }
      @media screen and (max-width: 991.98px) {
        .features .features-card .card-panel {
          margin-bottom: 40px; } }
      @media screen and (min-width: 992px) {
        .features .features-card .card-panel {
          flex-basis: 48%; } }
      .features .features-card .card-panel:hover .panel-icon i::before {
        transform: translateY(-15px); }
      .features .features-card .card-panel::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 80px);
        background-color: var(--global--color-heading-darken);
        height: 1px; }
        @media screen and (max-width: 767.98px) {
          .features .features-card .card-panel::after {
            width: calc(100% - 50px); } }
      .features .features-card .card-panel.reversed {
        flex-direction: row-reverse; }
        @media screen and (max-width: 767.98px) {
          .features .features-card .card-panel.reversed {
            flex-direction: column; } }
        .features .features-card .card-panel.reversed::after {
          background-color: var(--global--color-primary); }
        .features .features-card .card-panel.reversed .panel-icon {
          margin-right: 0;
          margin-left: 40px; }
          @media screen and (max-width: 767.98px) {
            .features .features-card .card-panel.reversed .panel-icon {
              margin-left: 0;
              margin-bottom: 25px; } }
          .features .features-card .card-panel.reversed .panel-icon i::before {
            color: var(--global--color-primary); }
        .features .features-card .card-panel.reversed .panel-body {
          text-align: right; }
          @media screen and (max-width: 767.98px) {
            .features .features-card .card-panel.reversed .panel-body {
              text-align: center; } }
          .features .features-card .card-panel.reversed .panel-body .btn {
            color: var(--global--color-primary); }
            .features .features-card .card-panel.reversed .panel-body .btn:hover {
              color: var(--global--color-heading-darken); }
            .features .features-card .card-panel.reversed .panel-body .btn .line span {
              background-color: var(--global--color-primary); }
            .features .features-card .card-panel.reversed .panel-body .btn .line::after {
              color: var(--global--color-heading-darken); }
      .features .features-card .card-panel .panel-icon {
        margin-right: 40px; }
        @media screen and (min-width: 768px) {
          .features .features-card .card-panel .panel-icon {
            transform: translateY(-7px); } }
        @media screen and (max-width: 767.98px) {
          .features .features-card .card-panel .panel-icon {
            margin-bottom: 25px;
            margin-right: 0; } }
        .features .features-card .card-panel .panel-icon i::before {
          margin-left: 0;
          color: #435ba1;
          font-size: 65px;
          display: inline-flex;
          transition: 0.3s ease-in-out; }
      @media screen and (max-width: 767.98px) {
        .features .features-card .card-panel .panel-body {
          text-align: center; } }
      .features .features-card .card-panel .panel-body h4 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        color: var(--global--color-heading);
        margin-bottom: 15px;
        text-transform: capitalize; }
      .features .features-card .card-panel .panel-body p {
        font-size: 15px;
        line-height: 25px;
        color: var(--global--color-body);
        margin-bottom: 16px; }
      .features .features-card .card-panel .panel-body .btn {
        width: auto;
        height: auto;
        display: inline-flex;
        color: var(--global--color-heading-darken);
        border: 0;
        background-color: transparent; }
        .features .features-card .card-panel .panel-body .btn:hover {
          color: var(--global--color-primary); }
        .features .features-card .card-panel .panel-body .btn .line span {
          background-color: var(--global--color-heading-darken); }
        .features .features-card .card-panel .panel-body .btn .line::after {
          color: var(--global--color-primary); }
  .features.features-2 {
    padding-top: 130px;
    padding-bottom: 0;
    overflow: visible; }

@media screen and (max-width:991.98px) {
  .features.features-2 {
    padding-top: 70px; } }
    .features.features-2 .signature-block .line::after {
      color: var(--global--color-heading); }
  .features.features-3 {
    padding-top: 295px;
    padding-bottom: 102px;
    overflow: visible; }
    @media screen and (max-width: 991.98px) {
      .features.features-3 {
        padding-top: 240px;
        padding-bottom: 70px; } }
    .features.features-3 .feature-panel-holder .feature-panel .feature-icon {
      margin-bottom: 23px;
      width: 65px;
      height: 65px; }
      .features.features-3 .feature-panel-holder .feature-panel .feature-icon img {
        max-width: 100%; }
    .features.features-3 .more-features-2 {
      margin-bottom: 0; }
  .features.features-4 {
    padding-bottom: 0;
    overflow: visible; }
    @media (min-width: 992px) and (max-width: 1280px) {
      .features.features-4 {
        padding-bottom: 90px; } }
    @media screen and (max-width: 991.98px) {
      .features.features-4 {
        padding-top: 70px; } }
    .features.features-4 .features-holder {
      display: block; }
      @media screen and (max-width: 991.98px) {
        .features.features-4 .features-holder {
          max-width: 90%;
          text-align: center;
          margin: 0 auto;
          padding-bottom: 40px; } }
    @media screen and (min-width: 992px) {
      .features.features-4 .features-wrapper {
        display: grid;
        grid-template-columns: 45% 50%;
        grid-column-gap: 5%;
        transform: translateY(110px);
        margin-top: -110px; } }
    .features.features-4 .feature-panel-holder .feature-panel {
      margin-bottom: 43px; }
      @media screen and (max-width: 991.98px) {
        .features.features-4 .feature-panel-holder .feature-panel {
          margin-bottom: 20px; } }
    @media screen and (min-width: 992px) {
      .features.features-4 .owl-dots {
        right: 102px;
        bottom: -23px; } }
    @media screen and (max-width: 991.98px) {
      .features.features-4 .owl-dots {
        margin-top: 20px;
        margin-bottom: 30px; } }
    .features.features-4 .owl-dots .owl-dot {
      display: inline-flex; }
      .features.features-4 .owl-dots .owl-dot.active span::before {
        background-color: rgba(var(--global--color-white), 0.3); }
      .features.features-4 .owl-dots .owl-dot span {
        background-color: var(--global--color-white); }
    @media screen and (min-width: 992px) {
      .features.features-4 .more-features {
        text-align: left; } }
    .features.features-4 .more-features p a {
      color: var(--global--color-white); }
      .features.features-4 .more-features p a::before {
        background-color: var(--global--color-white); }

.features-bar {
  padding-top: 0;
  padding-bottom: 170px;
  overflow: visible; }

@media screen and (max-width:991.98px) {
  .features-bar {
    padding-bottom: 100px; } }
  .features-bar .features-holder {
    position: relative;
    z-index: 55;
    transform: translateY(-90px);
    margin-bottom: -90px; }
    @media screen and (min-width: 992px) {
      .features-bar .features-holder {
        box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); } }
    .features-bar .features-holder .feature-panel {
      padding: 28px 40px 40px; }
      @media screen and (max-width: 991.98px) {
        .features-bar .features-holder .feature-panel {
          max-width: 370px;
          margin: 0 auto; } }
      .features-bar .features-holder .feature-panel .feature-content {
        position: relative;
        z-index: 3; }
        @media screen and (max-width: 991.98px) {
          .features-bar .features-holder .feature-panel .feature-content {
            text-align: center; } }
        .features-bar .features-holder .feature-panel .feature-content i {
          display: block;
          margin-bottom: 11px; }
          .features-bar .features-holder .feature-panel .feature-content i:before {
            font-size: 50px;
            color: var(--global--color-white);
            margin-left: 0; }
        .features-bar .features-holder .feature-panel .feature-content h5 {
          text-transform: capitalize;
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-size: 19px;
          line-height: 28px;
          color: var(--global--color-white);
          margin-bottom: 16px; }
        .features-bar .features-holder .feature-panel .feature-content p {
          font-family: var(--global--font-body);
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #f4f4f4;
          margin-bottom: 21px; }
      .features-bar .features-holder .feature-panel.feature-reservation {
        background-color: var(--global--color-primary); }
        @media screen and (min-width: 992px) {
          .features-bar .features-holder .feature-panel.feature-reservation {
            border-radius: 8px 0 0 8px; } }
        .features-bar .features-holder .feature-panel.feature-reservation a {
          font-family: var(--global--font-heading);
          font-weight: 400;
          color: var(--global--color-white);
          font-size: 24px;
          display: flex;
          align-items: center;
          transition: 0.3s ease-in-out; }
          @media screen and (max-width: 991.98px) {
            .features-bar .features-holder .feature-panel.feature-reservation a {
              justify-content: center; } }
          .features-bar .features-holder .feature-panel.feature-reservation a span {
            transition: 0.3s ease-in-out;
            display: inline-flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
            background-color: #1aadcd;
            color: var(--global--color-white);
            border-radius: 8px 8px 0 8px;
            font-size: 16px;
            margin-right: 10px; }
      .features-bar .features-holder .feature-panel.feature-timetable {
        background-color: var(--global--color-heading-darken); }
        .features-bar .features-holder .feature-panel.feature-timetable .btn {
          width: 156px;
          height: 42px;
          border-radius: 8px 8px 0 8px;
          border-color: rgba(255, 255, 255, 0.25); }
          @media screen and (max-width: 991.98px) {
            .features-bar .features-holder .feature-panel.feature-timetable .btn {
              display: inline-flex; } }
          .features-bar .features-holder .feature-panel.feature-timetable .btn:hover {
            border-color: var(--global--color-primary); }
      .features-bar .features-holder .feature-panel.feature-opening-hours {
        background-color: #354f8e;
        padding-bottom: 36px; }
        .features-bar .features-holder .feature-panel.feature-opening-hours h5 {
          margin-bottom: 8px; }
        .features-bar .features-holder .feature-panel.feature-opening-hours ul {
          margin-bottom: 0; }
          .features-bar .features-holder .feature-panel.feature-opening-hours ul li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            font-family: var(--global--font-body);
            color: var(--global--color-white);
            text-transform: capitalize;
            border-bottom: 1px solid rgba(var(--global--color-white), 0.25);
            padding: 9px 0; }
            .features-bar .features-holder .feature-panel.feature-opening-hours ul li:last-child {
              border-bottom: 0;
              padding-bottom: 0; }
      .features-bar .features-holder .feature-panel.feature-location {
        padding-bottom: 45px;
        background-color: var(--global--color-secondary); }
        @media screen and (min-width: 992px) {
          .features-bar .features-holder .feature-panel.feature-location {
            border-radius: 0 8px 0 0; } }
        .features-bar .features-holder .feature-panel.feature-location p {
          margin-bottom: 11px; }
        .features-bar .features-holder .feature-panel.feature-location .map-img {
          position: relative; }
          .features-bar .features-holder .feature-panel.feature-location .map-img a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 1; }
          .features-bar .features-holder .feature-panel.feature-location .map-img img {
            max-width: 100%; }
  .features-bar .actions-holder {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991.98px) {
      .features-bar .actions-holder {
        justify-content: center; } }
    @media screen and (max-width: 767.98px) {
      .features-bar .actions-holder {
        flex-direction: column; } }
    .features-bar .actions-holder .btn {
      width: 170px;
      height: 60px;
      margin-right: 30px; }
      @media screen and (max-width: 767.98px) {
        .features-bar .actions-holder .btn {
          margin-right: 0;
          margin-bottom: 20px; } }
      .features-bar .actions-holder .btn:last-child {
        border-color: #687696;
        border: 2px solid;
        transition-property: background, color, border-color; }
        .features-bar .actions-holder .btn:last-child::before {
          background-color: var(--global--color-primary);
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          top: -2px;
          left: -2px; }
        .features-bar .actions-holder .btn:last-child:hover {
          border-color: var(--global--color-secondary); }

/* Small Devices, Tablets */
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .feature-panel {
    max-width: 370px;
    margin-right: auto;
    margin-left: auto; } }

/* Custom, iPhone Retina */
/* Custom, iPhone Retina */
/*--------------------------------
    #Pricing
---------------------------------*/
.pricing {
  padding-top: 0;
  padding-bottom: 98px;
  background-image: url("../images/background/pattern.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: visible; }
  .pricing .pricing-holder {
    position: relative;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    transform: translateY(-130px);
    margin-bottom: -130px;
    z-index: 20; }
    @media screen and (max-width: 991.98px) {
      .pricing .pricing-holder {
        grid-template-columns: 1fr;
        grid-row-gap: 40px; } }
    .pricing .pricing-holder::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--global--color-primary);
      height: 2px;
      width: calc(100% - 100px); }
      @media screen and (max-width: 991.98px) {
        .pricing .pricing-holder::after {
          content: unset; } }
  .pricing .price-table {
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 991.98px) {
      .pricing .price-table {
        max-width: 370px;
        margin: 0 auto; } }
    @media screen and (min-width: 992px) {
      .pricing .price-table:first-child .pricing-panel .pricing-price p::before {
        width: calc(100% + 50px); } }
    @media screen and (min-width: 992px) {
      .pricing .price-table:nth-child(2) .pricing-panel .pricing-price p::before {
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 100px); } }
    @media screen and (min-width: 992px) {
      .pricing .price-table:last-child .pricing-panel .pricing-price p::before {
        left: auto;
        right: 0;
        width: calc(100% + 50px); } }
    .pricing .price-table .pricing-panel {
      background-color: var(--global--color-white);
      padding: 44px 50px 50px;
      overflow: hidden;
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1; }
      @media screen and (max-width: 991.98px) {
        .pricing .price-table .pricing-panel {
          text-align: center; } }
      @media screen and (max-width: 767.98px) {
        .pricing .price-table .pricing-panel {
          padding-left: 25px;
          padding-right: 25px; } }
      .pricing .price-table .pricing-panel.active .pricing-price .btn {
        background-color: var(--global--color-primary);
        border-color: var(--global--color-primary); }
        .pricing .price-table .pricing-panel.active .pricing-price .btn::before {
          background-color: var(--global--color-secondary); }
        .pricing .price-table .pricing-panel.active .pricing-price .btn:hover, .pricing .price-table .pricing-panel.active .pricing-price .btn:active, .pricing .price-table .pricing-panel.active .pricing-price .btn:focus {
          border-color: var(--global--color-secondary); }
      .pricing .price-table .pricing-panel .pricing-body .pricing-heading {
        margin-bottom: 55px; }
        .pricing .price-table .pricing-panel .pricing-body .pricing-heading .pricing-title {
          font-family: var(--global--font-body);
          font-size: 16px;
          font-weight: 700px;
          line-height: 26px;
          color: var(--global--color-primary);
          margin-bottom: 16px;
          position: relative;
          text-transform: capitalize;
          transition: 300ms ease-in-out; }
        .pricing .price-table .pricing-panel .pricing-body .pricing-heading .pricing-desc {
          font-family: var(--global--font-body);
          font-size: 14px;
          color: var(--global--color-body);
          line-height: 24px;
          margin-bottom: 0px; }
      .pricing .price-table .pricing-panel .pricing-body .pricing-list li {
        font-family: var(--global--font-body);
        font-size: 15px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 0px;
        color: var(--global--color-body);
        display: flex;
        align-items: center; }
        .pricing .price-table .pricing-panel .pricing-body .pricing-list li i {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: var(--global--color-heading-darken);
          color: var(--global--color-white);
          font-size: 10px;
          margin-right: 15px; }
      .pricing .price-table .pricing-panel .pricing-price p {
        padding-top: 44px;
        margin-bottom: 29px;
        font-family: var(--global--font-heading);
        font-weight: 400;
        color: var(--global--color-heading-darken);
        display: flex;
        align-items: baseline;
        line-height: 30px;
        position: relative; }
        .pricing .price-table .pricing-panel .pricing-price p::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          width: 100%;
          background-color: var(--global--color-gray-light); }
        @media screen and (max-width: 991.98px) {
          .pricing .price-table .pricing-panel .pricing-price p {
            justify-content: center; } }
        .pricing .price-table .pricing-panel .pricing-price p span::before {
          content: '/'; }
        .pricing .price-table .pricing-panel .pricing-price p span:first-of-type::before {
          content: unset; }
        .pricing .price-table .pricing-panel .pricing-price p .currency {
          font-size: 50px; }
        .pricing .price-table .pricing-panel .pricing-price p .time {
          font-size: 14px; }
      .pricing .price-table .pricing-panel .pricing-price .btn {
        background-color: var(--global--color-secondary);
        border-radius: 8px 8px 0 8px;
        height: 60px; }
        .pricing .price-table .pricing-panel .pricing-price .btn::before {
          background-color: var(--global--color-primary); }
  .pricing .more-prices {
    text-align: center;
    margin-top: 50px; }
    @media screen and (min-width: 992px) {
      .pricing .more-prices {
        margin-top: 32px; } }
    .pricing .more-prices p {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 14px;
      color: var(--global--color-body);
      margin-bottom: 0; }
      .pricing .more-prices p a {
        position: relative;
        color: var(--global--color-primary);
        transition: 0.3s ease-in-out;
        text-transform: capitalize;
        padding-bottom: 8px; }
        .pricing .more-prices p a:hover::before {
          width: 70%; }
        .pricing .more-prices p a::before {
          content: '';
          transition: 0.3s ease-in-out;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background-color: var(--global--color-primary); }

/*------------------------------------*\
    #Donations
\*------------------------------------*/
.donations {
  padding-top: 122px;
  padding-bottom: 110px; }
  @media screen and (max-width: 991.98px) {
    .donations {
      padding-top: 70px;
      padding-bottom: 70px; } }
  .donations .donations-map {
    position: relative;
    max-width: 470px;
    margin: 0 auto; }
    @media screen and (max-width: 991.98px) {
      .donations .donations-map {
        margin: 0 auto 40px; } }
    .donations .donations-map img {
      max-width: 100%; }
    .donations .donations-map .map-pointer {
      position: absolute;
      width: 28px;
      height: 28px;
      cursor: pointer; }
      .donations .donations-map .map-pointer:hover .info {
        opacity: 1;
        visibility: visible;
        transform: translateY(0); }
      .donations .donations-map .map-pointer:first-of-type {
        top: 29%;
        left: 15%; }
      .donations .donations-map .map-pointer:nth-of-type(2) {
        top: 48%;
        left: 48%; }
      .donations .donations-map .map-pointer:nth-of-type(3) {
        top: 23%;
        left: 68%; }
      .donations .donations-map .map-pointer .info {
        background-color: var(--global--color-white);
        box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
        border-radius: 10px 10px 10px 0px;
        position: relative;
        width: max-content;
        position: absolute;
        max-width: 170px;
        z-index: 2;
        transition: 0.3s ease-in-out;
        left: 18px;
        top: -97px;
        padding: 15px 20px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px); }
        .donations .donations-map .map-pointer .info.left {
          left: -160px;
          border-radius: 10px 10px 0 10px; }
          .donations .donations-map .map-pointer .info.left::before {
            clip-path: polygon(100% 0, 0 0, 100% 100%);
            left: auto;
            right: 0; }
        .donations .donations-map .map-pointer .info::before {
          content: '';
          width: 10px;
          height: 10px;
          background-color: var(--global--color-white);
          clip-path: polygon(100% 0, 0 0, 0 100%);
          position: absolute;
          bottom: -9px;
          left: 0; }
        .donations .donations-map .map-pointer .info span {
          font-family: var(--global--font-body);
          font-size: 14px;
          line-height: 24px;
          color: var(--global--color-body);
          text-transform: capitalize; }
  .donations .img-hotspot .img-hotspot-wrap {
    position: relative;
    max-width: 470px;
    margin: 0 auto; }
    @media screen and (max-width: 991.98px) {
      .donations .img-hotspot .img-hotspot-wrap {
        margin: 0 auto 40px; } }
    .donations .img-hotspot .img-hotspot-wrap .img-hotspot-bg img {
      max-width: 100%; }
    .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0; }
      .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer {
        position: absolute;
        width: 28px;
        height: 28px;
        cursor: pointer; }
        .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer:hover .info {
          opacity: 1;
          visibility: visible;
          transform: translateY(0); }
        .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info {
          background-color: var(--global--color-white);
          box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
          border-radius: 10px 10px 10px 0px;
          position: relative;
          width: max-content;
          position: absolute;
          max-width: 170px;
          z-index: 2;
          transition: 0.3s ease-in-out;
          padding: 15px 20px;
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px); }
          .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info::before {
            content: '';
            bottom: -9px;
            width: 10px;
            height: 10px;
            background-color: var(--global--color-white);
            position: absolute; }
          .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info.left {
            border-radius: 10px 10px 0 10px; }
            .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info.left::before {
              clip-path: polygon(100% 0, 0 0, 100% 100%);
              left: auto;
              right: 0; }
          .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info.right {
            border-radius: 10px 10px 10px 0px; }
            .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info.right::before {
              clip-path: polygon(100% 0, 0 0, 0 100%);
              left: 0; }
          .donations .img-hotspot .img-hotspot-wrap .img-hotspot-pointers .img-hotspot-pointer .info span {
            font-family: var(--global--font-body);
            font-size: 14px;
            line-height: 24px;
            color: var(--global--color-body);
            text-transform: capitalize; }
  .donations .btn {
    width: 170px;
    height: 60px;
    border-radius: 8px 8px 0 8px; }
    @media screen and (max-width: 991.98px) {
      .donations .btn {
        margin: 0 auto;
        display: inline-flex; } }
    .donations .btn i {
      transition: 0.3s ease-in-out;
      margin-left: 10px; }
  .donations .donations-action {
    margin-bottom: 70px; }
    @media screen and (max-width: 767.98px) {
      .donations .donations-action {
        display: flex;
        flex-direction: column;
        align-items: center; } }
    .donations .donations-action .btn {
      font-family: var(--global--font-body);
      font-size: 15px;
      font-weight: 700;
      display: inline-flex;
      width: 170px;
      height: 60px;
      margin-right: 30px; }
      @media screen and (max-width: 991.98px) {
        .donations .donations-action .btn {
          margin: 0 30px 0 0; } }
      @media screen and (max-width: 767.98px) {
        .donations .donations-action .btn {
          margin-right: 0;
          margin-bottom: 15px;
          height: 50px; } }
      .donations .donations-action .btn:first-child i {
        color: #213360; }
      .donations .donations-action .btn:first-child:hover i {
        color: var(--global--color-white); }
      .donations .donations-action .btn:last-child {
        border-radius: 8px 8px 8px 0;
        width: 150px;
        margin-right: 0; }
  .donations.donations-2 {
    padding-top: 501px; }
    @media screen and (max-width: 767.98px) {
      .donations.donations-2 {
        padding-top: 450px; } }
  .donations.donations-3 {
    padding-top: 110px;
    padding-bottom: 85px; }
    @media screen and (max-width: 991.98px) {
      .donations.donations-3 {
        padding-top: 70px;
        padding-bottom: 70px; } }

/*------------------------------------*\
    #Processes
\*------------------------------------*/
.processes .process-panel {
  padding: 56px 50px 54px;
  border-top: 1px solid var(--global--color-gray-light);
  border-right: 1px solid var(--global--color-gray-light);
  position: relative;
  transition: 0.3s ease-in-out; }
  .processes .process-panel:hover {
    box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); }
    .processes .process-panel:hover::before {
      width: 100%; }
    .processes .process-panel:hover .process-body .process-icon i::before {
      color: var(--global--color-primary); }
    .processes .process-panel:hover .process-body .process-content a {
      color: var(--global--color-primary); }
      .processes .process-panel:hover .process-body .process-content a .line span {
        opacity: 0;
        visibility: hidden; }
      .processes .process-panel:hover .process-body .process-content a .line::after {
        opacity: 1;
        visibility: visible;
        color: var(--global--color-primary); }
  .processes .process-panel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 0;
    transition: 0.3s ease-in-out;
    background-color: var(--global--color-primary); }
  .processes .process-panel .process-number {
    font-family: var(--global--font-heading);
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    font-style: italic;
    color: var(--global--color-gray-light);
    margin-bottom: 49px; }
    @media screen and (max-width: 767.98px) {
      .processes .process-panel .process-number {
        text-align: center;
        margin-bottom: 36px; } }
  .processes .process-panel .process-body {
    display: flex; }
    @media screen and (max-width: 767.98px) {
      .processes .process-panel .process-body {
        flex-direction: column;
        align-items: center;
        text-align: center; } }
    .processes .process-panel .process-body .process-icon {
      margin-right: 30px; }
      @media screen and (max-width: 767.98px) {
        .processes .process-panel .process-body .process-icon {
          margin-right: 0;
          margin-bottom: 25px; } }
      .processes .process-panel .process-body .process-icon i::before {
        display: flex;
        transform: translateY(-3px);
        font-size: 47px;
        margin-left: 0;
        transition: 0.3s ease-in-out;
        color: #354f8e; }
    .processes .process-panel .process-body .process-content h5 {
      font-weight: 500;
      font-size: 19px;
      color: var(--global--color-heading);
      line-height: 28px;
      margin-bottom: 16px;
      text-transform: capitalize; }
    .processes .process-panel .process-body .process-content p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 27px; }
    .processes .process-panel .process-body .process-content a {
      display: flex;
      font-family: var(--global--font-secondary);
      font-weight: 700;
      font-size: 15px;
      text-transform: capitalize;
      color: var(--global--color-secondary); }
      @media screen and (max-width: 767.98px) {
        .processes .process-panel .process-body .process-content a {
          justify-content: center; } }

/*------------------------------------*\
    #Contacts
\*------------------------------------*/
.contact-panel {
  padding: 50px 55px; }
  @media screen and (max-width: 767.98px) {
    .contact-panel {
      padding: 40px 25px; } }
  .contact-panel.contact-panel-2 {
    display: flex;
    padding: 0;
    transform: translateY(398px);
    margin-top: -398px; }
    @media screen and (max-width: 991.98px) {
      .contact-panel.contact-panel-2 {
        flex-direction: column; } }
    .contact-panel.contact-panel-2 .contact-card {
      padding: 57px 50px 60px;
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
      border-radius: 0 0 0px 8px; }
      @media screen and (min-width: 992px) {
        .contact-panel.contact-panel-2 .contact-card {
          border-radius: 0 0 8px 0; } }
      @media screen and (max-width: 767.98px) {
        .contact-panel.contact-panel-2 .contact-card {
          padding-left: 25px;
          padding-right: 25px; } }
    .contact-panel.contact-panel-2 .img-card {
      border-radius: 8px 8px 0 0px; }
      @media screen and (min-width: 992px) {
        .contact-panel.contact-panel-2 .img-card {
          border-radius: 8px 0 0 8px; } }
  .contact-panel.contact-panel-3 {
    display: flex;
    padding: 0; }
    @media screen and (max-width: 991.98px) {
      .contact-panel.contact-panel-3 {
        flex-direction: column; } }
    @media screen and (min-width: 992px) {
      .contact-panel.contact-panel-3 {
        justify-content: space-between; } }

@media screen and (min-width:992px) {
  .contact-panel.contact-panel-3 .heading {
    flex-basis: 40%;
    flex-shrink: 0; } }
    .contact-panel.contact-panel-3 .contact-card {
      padding: 57px 50px 60px;
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
      border-radius: 0 8px 8px 8px;
      height: max-content; }
      @media screen and (min-width: 992px) {
        .contact-panel.contact-panel-3 .contact-card {
          flex-basis: 54%;
          flex-shrink: 0; } }
      @media screen and (max-width: 767.98px) {
        .contact-panel.contact-panel-3 .contact-card {
          padding-left: 25px;
          padding-right: 25px; } }
      .contact-panel.contact-panel-3 .contact-card .btn {
        width: 100%; }
  .contact-panel.contact-panel-4 {
    padding: 60px 60px 60px;
    background-color: var(--global--color-white);
    box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
    border-radius: 8px 0 8px 8px;
    transform: translateY(-130px);
    margin-bottom: -130px;
    z-index: 5; }
    @media screen and (max-width: 767.98px) {
      .contact-panel.contact-panel-4 {
        padding-left: 25px;
        padding-right: 25px; } }
    .contact-panel.contact-panel-4 .contact-card {
      padding: 0; }
      .contact-panel.contact-panel-4 .contact-card .contact-body .form-control {
        margin-bottom: 25px; }
  .contact-panel.contact-panel-5 {
    padding: 60px 60px 60px;
    background-color: var(--global--color-white);
    box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
    border-radius: 8px 0 8px 8px;
    transform: translateY(400px);
    margin-top: -400px;
    z-index: 5; }
    @media screen and (max-width: 767.98px) {
      .contact-panel.contact-panel-5 {
        padding-left: 25px;
        padding-right: 25px; } }
    .contact-panel.contact-panel-5 .contact-card {
      padding: 0; }

.contact-card {
  background-color: var(--global--color-white);
  border-radius: 8px 8px 0 8px;
  padding: 47px 60px 50px;
  position: relative; }
  @media screen and (max-width: 767.98px) {
    .contact-card {
      padding: 37px 25px 40px; } }
  @media screen and (max-width: 991.98px) {
    .contact-card .contact-body {
      text-align: center; } }
  .contact-card .contact-body .card-heading {
    color: var(--global--color-heading);
    font-family: var(--global--font-heading);
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 20px; }
  .contact-card .contact-body .card-desc {
    font-family: var(--global--font-body);
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: var(--global--color-body);
    margin-bottom: 40px; }
  .contact-card .contact-body form {
    margin-bottom: 0; }
  .contact-card .contact-body .form-control {
    border-radius: 0 8px 8px 8px;
    border: 2px solid transparent;
    transition: 0.3s ease-in-out;
    margin-bottom: 30px; }
    .contact-card .contact-body .form-control:hover, .contact-card .contact-body .form-control:active, .contact-card .contact-body .form-control:focus {
      background-color: var(--global--color-white);
      border-color: var(--global--color-primary); }
    .contact-card .contact-body .form-control.nice-select {
      width: 100%;
      display: flex;
      align-items: center; }
      .contact-card .contact-body .form-control.nice-select::after {
        width: 7px;
        height: 7px;
        border-color: var(--global--color-heading);
        right: 25px; }
      .contact-card .contact-body .form-control.nice-select.open .list {
        width: 100%; }
      .contact-card .contact-body .form-control.nice-select .current {
        text-transform: capitalize; }
      .contact-card .contact-body .form-control.nice-select .list li {
        text-transform: capitalize; }
  .contact-card .contact-body .btn {
    height: 70px;
    border-radius: 0 8px 8px 8px; }
  .contact-card .contact-body .select-holder {
    position: relative; }
    .contact-card .contact-body .select-holder .badge {
      transition: 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      transform: translateX(-15px);
      height: 26px;
      padding: 0 10px;
      background-color: var(--global--color-primary);
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 14px;
      color: var(--global--color-white);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0px;
      top: -24px;
      border-radius: 8px 8px 0 0;
      text-transform: capitalize; }
    .contact-card .contact-body .select-holder .form-control:hover + .badge {
      transform: translateX(0);
      opacity: 1;
      visibility: visible; }
  .contact-card .contact-body .time-select,
  .contact-card .contact-body .date-select {
    position: relative;
    background-color: var(--global--color-primary-light);
    height: 60px;
    border-radius: 0 8px 8px 8px;
    border: 2px solid transparent;
    transition: 0.3s ease-in-out;
    margin-bottom: 30px;
    display: flex;
    align-items: center; }
    .contact-card .contact-body .time-select:hover, .contact-card .contact-body .time-select:active, .contact-card .contact-body .time-select:focus,
    .contact-card .contact-body .date-select:hover,
    .contact-card .contact-body .date-select:active,
    .contact-card .contact-body .date-select:focus {
      background-color: var(--global--color-white);
      border-color: var(--global--color-primary); }
    .contact-card .contact-body .time-select input,
    .contact-card .contact-body .date-select input {
      position: relative;
      background-color: transparent;
      margin-bottom: 0;
      z-index: 1; }
      .contact-card .contact-body .time-select input:hover, .contact-card .contact-body .time-select input:active, .contact-card .contact-body .time-select input:focus,
      .contact-card .contact-body .date-select input:hover,
      .contact-card .contact-body .date-select input:active,
      .contact-card .contact-body .date-select input:focus {
        background-color: transparent;
        border-color: transparent; }
      .contact-card .contact-body .time-select input::-webkit-calendar-picker-indicator, .contact-card .contact-body .time-select input::-webkit-inner-spin-button,
      .contact-card .contact-body .date-select input::-webkit-calendar-picker-indicator,
      .contact-card .contact-body .date-select input::-webkit-inner-spin-button {
        display: block;
        -webkit-appearance: block;
        opacity: 0; }
    .contact-card .contact-body .time-select i,
    .contact-card .contact-body .date-select i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      right: 20px;
      color: var(--global--color-heading); }
    .contact-card .contact-body .time-select label.error,
    .contact-card .contact-body .date-select label.error {
      bottom: -23px;
      left: 0px; }
  .contact-card .contact-body .contact-result {
    margin-top: 10px;
    text-align: center; }

.img-card {
  flex-shrink: 0;
  height: auto;
  padding: 65px 60px 60px;
  display: flex;
  align-items: flex-end;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .img-card {
      text-align: left;
      padding: 55px 60px 60px;
      flex-basis: 46%; } }
  @media screen and (max-width: 767.98px) {
    .img-card {
      padding-left: 25px;
      padding-right: 25px; } }
  .img-card::before {
    background: linear-gradient(180deg, #00000000 19%, var(--global--color-primary) 71%); }
  .img-card .card-content {
    max-width: 100%; }
    .img-card .card-content h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 55px;
      color: var(--global--color-white);
      text-transform: capitalize;
      margin-bottom: 10px; }
      @media screen and (max-width: 767.98px) {
        .img-card .card-content h3 {
          font-size: 30px;
          line-height: 1.4; } }
    .img-card .card-content p {
      font-size: 17px;
      color: var(--global--color-gray);
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 33px; }
  .img-card.img-card-2 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 8px 0 8px 8px;
    padding-top: 50px;
    background-position-x: 81%; }
    @media screen and (max-width: 991.98px) {
      .img-card.img-card-2 {
        margin-top: 50px; } }
    .img-card.img-card-2::before {
      background: unset;
      background-color: #14c5dddb; }
    .img-card.img-card-2 .card-content {
      flex-direction: column;
      display: flex;
      flex-grow: 1;
      justify-content: space-between; }
      .img-card.img-card-2 .card-content h3 {
        font-family: var(--global--font-heading);
        font-size: 30px;
        line-height: 45px;
        font-weight: 500;
        color: var(--global--color-white); }
        @media screen and (max-width: 991.98px) {
          .img-card.img-card-2 .card-content h3 {
            margin-bottom: 30px; } }
      .img-card.img-card-2 .card-content p {
        font-family: var(--global--font-heading);
        font-size: 18px;
        line-height: 29px;
        font-weight: 500;
        color: #f4f4f4;
        font-style: italic; }
      .img-card.img-card-2 .card-content .btn {
        width: 170px;
        height: 60px;
        border-radius: 8px 8px 0 8px; }
        @media screen and (max-width: 991.98px) {
          .img-card.img-card-2 .card-content .btn {
            display: inline-flex; } }
        .img-card.img-card-2 .card-content .btn .line span {
          background-color: var(--global--color-secondary); }

.img-card-holder {
  display: flex;
  flex-direction: column; }

.contact-1 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 991.98px) {
    .contact-1 iframe {
      position: relative;
      width: 100%;
      height: 500px; } }

.map {
  position: relative;
  height: 720px; }
  .map iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%; }
    .map iframe.map-gray {
      filter: brightness(104%) contrast(99%) saturate(0%) blur(0px) hue-rotate(0deg); }
  .map.map-2 {
    padding: 0;
    height: 620px; }
  .map.map-3 {
    height: auto;
    padding: 90px 0; }

.company-data {
  padding-top: 45px;
  padding-bottom: 45px;
  border-bottom: 1px solid #eaeaea;
  overflow: visible; }
  .company-data .data {
    display: flex;
    align-items: center; }
    .company-data .data .data-icon {
      margin-right: 20px; }
      .company-data .data .data-icon i::before {
        margin-left: 0;
        color: var(--global--color-primary);
        font-size: 50px; }
    .company-data .data .data-body h6 {
      font-family: var(--global--font-heading);
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #282828;
      text-transform: capitalize;
      margin-bottom: 7px; }
    .company-data .data .data-body a {
      color: var(--global--color-body);
      transition: 0.3s ease-in-out;
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: block; }
      .company-data .data .data-body a:hover {
        color: var(--global--color-primary); }
  .company-data .panels-holder {
    display: flex;
    margin-top: 40px; }
    @media screen and (min-width: 992px) {
      .company-data .panels-holder {
        margin-top: 0px;
        justify-content: flex-end;
        margin-bottom: -136px;
        transform: translateY(-136px);
        position: relative;
        z-index: 1000; } }
    .company-data .panels-holder .panel-action {
      background-color: var(--global--color-primary);
      transition: 0.3s ease-in-out;
      padding: 28px 0px 32px 40px;
      display: flex;
      flex-direction: column;
      max-width: 230px;
      cursor: pointer; }
      @media (max-width: 375px) {
        .company-data .panels-holder .panel-action {
          padding-left: 20px; } }
      @media screen and (max-width: 991.98px) {
        .company-data .panels-holder .panel-action {
          max-width: none; } }
      .company-data .panels-holder .panel-action:first-child {
        border-radius: 4px 0 0 4px; }
      .company-data .panels-holder .panel-action:last-child {
        border-radius: 0 4px 4px 0; }
        .company-data .panels-holder .panel-action:last-child .panel-content {
          border-right: 0; }
      .company-data .panels-holder .panel-action .panel-content {
        border-right: 1px solid #e74a39;
        padding-right: 40px; }
        @media (max-width: 375px) {
          .company-data .panels-holder .panel-action .panel-content {
            padding-right: 20px; } }
        .company-data .panels-holder .panel-action .panel-content .panel-icon {
          margin-bottom: 10px; }
          .company-data .panels-holder .panel-action .panel-content .panel-icon i::before {
            color: var(--global--color-white);
            font-size: 50px;
            margin-left: 0; }
        .company-data .panels-holder .panel-action .panel-content .panel-heading h6 {
          font-family: var(--global--font-body);
          font-weight: 700;
          font-size: 16px;
          line-height: 29px;
          margin-bottom: 0;
          transition: 0.3s ease-in-out;
          color: var(--global--color-white); }

.contact-info {
  padding: 0;
  overflow: visible; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contact .contact-card {
    padding: 70px 20px; }
  .contact-3 .contact-card {
    padding: 0 50px;
    margin-bottom: 30px; } }

/* Tablets  */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-3 .contact-card {
    margin-bottom: 30px;
    padding: 0; } }

/*------------------------------------*\
    #Client
\*------------------------------------*/
.clients.bg-grey {
  color: var(--global--color-gray); }

.clients .client {
  position: relative;
  line-height: 50px;
  transition: 0.3s ease; }
  .clients .client img {
    max-width: 100%;
    width: auto !important;
    display: block;
    margin-right: auto;
    margin-left: auto; }

.certificate img {
  display: block;
  margin: 0 auto; }

/* Client #1 */
.clients-1 {
  padding-top: 48px;
  padding-bottom: 48px; }

/* Client #2 */
.clients-2 {
  border-top: 1px solid #eaeaea; }

/* Cases Clients */
.cases-clients {
  padding-top: 343px;
  padding-bottom: 25px;
  background-image: url("../images/background/pattern.png");
  background-attachment: fixed; }
  .cases-clients .team-standard {
    padding-bottom: 0;
    padding-top: 0; }
  .cases-clients.cases-clients {
    background-image: unset;
    background-attachment: unset;
    background-color: var(--global--color-white); }

@media only screen and (max-width: 991px) {
  .client {
    margin-bottom: 20px; } }

/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
.testimonial {
  padding-top: 130px;
  padding-bottom: 0;
  overflow: visible; }
  @media screen and (max-width: 767.98px) {
    .testimonial {
      padding-top: 70px; } }
  .testimonial .testimonials-holder {
    transform: translateY(-8px);
    margin-bottom: 92px;
    position: relative; }
    @media screen and (max-width: 767.98px) {
      .testimonial .testimonials-holder {
        margin-bottom: 70px; } }
    .testimonial .testimonials-holder .custom-navs {
      display: inline-flex;
      align-items: center;
      right: 0;
      bottom: 14px;
      position: absolute;
      z-index: 500; }
      @media screen and (max-width: 991.98px) {
        .testimonial .testimonials-holder .custom-navs {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          position: relative;
          bottom: auto;
          right: auto; } }
      .testimonial .testimonials-holder .custom-navs a {
        display: inline-flex;
        font-size: 15px;
        font-family: var(--global--font-secondary);
        font-weight: 700;
        color: var(--global--color-body);
        transition: 0.3s ease-in-out;
        text-transform: capitalize;
        cursor: pointer; }
        .testimonial .testimonials-holder .custom-navs a:hover {
          color: var(--global--color-primary); }
          .testimonial .testimonials-holder .custom-navs a:hover .line span {
            opacity: 0;
            visibility: hidden; }
          .testimonial .testimonials-holder .custom-navs a:hover .line::after {
            opacity: 1;
            visibility: visible; }
        .testimonial .testimonials-holder .custom-navs a .line {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          position: relative; }
          .testimonial .testimonials-holder .custom-navs a .line span {
            display: block;
            width: 12px;
            height: 2px;
            background-color: var(--global--color-body);
            opacity: 1;
            visibility: visible;
            transition: 0.3s ease-in-out; }
          .testimonial .testimonials-holder .custom-navs a .line::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            content: '\e901';
            font-family: 'icomoon';
            font-size: 10px;
            color: var(--global--color-primary);
            display: inline-block;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-in-out; }
        .testimonial .testimonials-holder .custom-navs a.prev {
          margin-right: 20px; }
          .testimonial .testimonials-holder .custom-navs a.prev .line {
            margin-right: 8px; }
            .testimonial .testimonials-holder .custom-navs a.prev .line::after {
              top: 3px;
              left: 0;
              transform: rotate(-180deg); }
        .testimonial .testimonials-holder .custom-navs a.next .line {
          margin-left: 8px; }
  .testimonial .testimonial-panel .testimonial-body {
    padding-left: 40px;
    padding-top: 40px;
    position: relative; }
    @media screen and (max-width: 767.98px) {
      .testimonial .testimonial-panel .testimonial-body {
        padding-left: 30px;
        padding-top: 30px; } }
    .testimonial .testimonial-panel .testimonial-body .testimonial-content {
      position: relative; }
      .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-icon {
        position: absolute;
        z-index: -1;
        top: -66px;
        left: -40px; }
        @media screen and (max-width: 767.98px) {
          .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-icon {
            top: -56px;
            left: -30px; } }
        .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-icon::before {
          font-size: 95px;
          font-family: "Font Awesome 5 Free";
          content: "\f10d";
          font-weight: 900;
          color: #293967; }
      .testimonial .testimonial-panel .testimonial-body .testimonial-content p {
        font-family: var(--global--font-heading);
        color: var(--global--color-white);
        font-size: 25px;
        font-weight: 400;
        line-height: 43px;
        margin-bottom: 45px; }
        @media screen and (max-width: 767.98px) {
          .testimonial .testimonial-panel .testimonial-body .testimonial-content p {
            font-size: 19px;
            line-height: 1.5; } }
      .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-rating {
        display: flex;
        align-items: center; }
        @media screen and (max-width: 991.98px) {
          .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-rating {
            justify-content: center; } }
        .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-rating .num {
          font-family: var(--global--font-heading);
          font-weight: 500;
          font-size: 37px;
          color: var(--global--color-white);
          flex-shrink: 0;
          margin-right: 20px; }
        .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-rating .rating-body p {
          font-size: 13px;
          line-height: 23px;
          color: var(--global--color-gray);
          margin-bottom: 0; }
          .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-rating .rating-body p a {
            color: var(--global--color-gray);
            text-transform: capitalize;
            border-bottom: 2px solid var(--global--color-primary);
            padding-bottom: 2px; }
            .testimonial .testimonial-panel .testimonial-body .testimonial-content .testimonial-rating .rating-body p a span {
              font-size: 14px;
              font-weight: 700;
              color: var(--global--color-primary);
              font-family: var(--global--font-body); }
  .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body {
    padding-left: 0;
    padding-right: 15px;
    padding-top: 18px; }
    .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-icon {
      top: -23px;
      right: -15px;
      left: auto; }
      .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-icon::before {
        color: #e8ebf2; }
    .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content p {
      font-size: 20px;
      font-weight: 500;
      line-height: 38px;
      color: var(--global--color-heading);
      margin-bottom: 48px; }
    .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb {
      display: flex;
      align-items: center;
      transition: 0.5s ease-in-out;
      cursor: pointer; }
      @media screen and (max-width: 991.98px) {
        .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb {
          justify-content: center; } }
      .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb .thumb-img {
        width: 80px;
        height: 80px;
        border-radius: 8px 0 8px 8px;
        position: relative;
        margin-right: 40px;
        box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); }
        .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb .thumb-img img {
          max-width: 100%;
          border-radius: 8px 0 8px 8px; }
        .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb .thumb-img i {
          transition: 0.5s ease-in-out;
          transform: translateY(-50%);
          font-size: 11px;
          position: absolute;
          top: 50%;
          right: -16px;
          width: 33px;
          height: 33px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: var(--global--color-primary);
          background-color: var(--global--color-white); }
      .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb .thumb-body {
        text-transform: capitalize; }
        .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb .thumb-body h6 {
          font-family: var(--global--font-body);
          font-size: 16px;
          font-weight: 700;
          line-height: 1.2;
          margin-bottom: 4px;
          color: var(--global--color-heading); }
        .testimonial .testimonial-panel.testimonial-panel-2 .testimonial-body .testimonial-content .testimonial-thumb .thumb-body p {
          font-size: 13px;
          font-weight: 400;
          line-height: 1.2;
          color: var(--global--color-body);
          margin-bottom: 0; }
  .testimonial .testimonial-thumbs {
    display: flex; }
    @media screen and (max-width: 991.98px) {
      .testimonial .testimonial-thumbs {
        justify-content: center;
        margin-bottom: 40px; } }
    .testimonial .testimonial-thumbs .testimonial-thumb {
      flex-shrink: 0;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      text-align: center;
      transition: 0.5s ease-in-out;
      cursor: pointer; }
      .testimonial .testimonial-thumbs .testimonial-thumb:last-child {
        margin-right: 0; }
      .testimonial .testimonial-thumbs .testimonial-thumb:hover, .testimonial .testimonial-thumbs .testimonial-thumb.active {
        transform: translateY(-10px); }
        .testimonial .testimonial-thumbs .testimonial-thumb:hover .thumb-img i, .testimonial .testimonial-thumbs .testimonial-thumb.active .thumb-img i {
          transform: translateY(-50%) scale(1);
          opacity: 1;
          visibility: visible; }
      @media screen and (max-width: 767.98px) {
        .testimonial .testimonial-thumbs .testimonial-thumb {
          margin-right: 25px; } }
      @media screen and (min-width: 992px) {
        .testimonial .testimonial-thumbs .testimonial-thumb {
          text-align: left; } }
      .testimonial .testimonial-thumbs .testimonial-thumb .thumb-img {
        width: 80px;
        height: 80px;
        border-radius: 8px 0 8px 8px;
        position: relative;
        margin-bottom: 16px; }
        .testimonial .testimonial-thumbs .testimonial-thumb .thumb-img img {
          max-width: 100%;
          border-radius: 8px 0 8px 8px; }
        .testimonial .testimonial-thumbs .testimonial-thumb .thumb-img i {
          transition: 0.5s ease-in-out;
          transform: translateY(-50%) scale(0);
          opacity: 0;
          visibility: hidden;
          font-size: 11px;
          position: absolute;
          top: 50%;
          right: -16px;
          width: 33px;
          height: 33px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: var(--global--color-primary);
          background-color: var(--global--color-white); }
      .testimonial .testimonial-thumbs .testimonial-thumb .thumb-body {
        text-transform: capitalize; }
        .testimonial .testimonial-thumbs .testimonial-thumb .thumb-body h6 {
          font-family: var(--global--font-body);
          font-size: 16px;
          font-weight: 700;
          line-height: 1.2;
          margin-bottom: 4px;
          color: var(--global--color-white); }
        .testimonial .testimonial-thumbs .testimonial-thumb .thumb-body p {
          font-size: 13px;
          line-height: 1.2;
          color: var(--global--color-body);
          margin-bottom: 0; }
  .testimonial .carousel-dots {
    position: relative; }
    .testimonial .carousel-dots .owl-dots {
      margin-top: 40px;
      display: flex;
      justify-content: center; }
      @media screen and (min-width: 992px) {
        .testimonial .carousel-dots .owl-dots {
          margin-top: 0;
          justify-content: flex-end;
          position: absolute;
          right: 0;
          bottom: 45px; } }
      .testimonial .carousel-dots .owl-dots .owl-dot {
        display: inline-flex; }
        .testimonial .carousel-dots .owl-dots .owl-dot.active span {
          background-color: var(--global--color-primary); }
          .testimonial .carousel-dots .owl-dots .owl-dot.active span::before {
            background-color: #cff3f7; }
        .testimonial .carousel-dots .owl-dots .owl-dot span {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: #687596;
          margin-right: 20px;
          transition: 0.3s ease-in-out;
          position: relative; }
          .testimonial .carousel-dots .owl-dots .owl-dot span::before {
            content: '';
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);
            width: 25px;
            height: 25px;
            background-color: transparent;
            transition: 0.3s ease-in-out; }
  .testimonial.testimonial-1 {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 84%, rgba(255, 255, 255, 0) 100%);
    padding-top: 128px;
    padding-bottom: 130px; }
    @media screen and (max-width: 767.98px) {
      .testimonial.testimonial-1 {
        padding-top: 70px;
        padding-bottom: 70px; } }
    .testimonial.testimonial-1 .section-head {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 16;
      line-height: 1;
      color: var(--global--color-primary);
      text-transform: capitalize;
      margin-bottom: 7px; }
      @media screen and (max-width: 991.98px) {
        .testimonial.testimonial-1 .section-head {
          text-align: center;
          margin-bottom: 25px; } }
    @media screen and (min-width: 992px) {
      .testimonial.testimonial-1 .clients-img {
        transform: translateY(-19px);
        margin-bottom: -19px; } }
    .testimonial.testimonial-1 .clients-img img {
      max-width: 100%; }
      @media screen and (max-width: 991.98px) {
        .testimonial.testimonial-1 .clients-img img {
          display: block;
          margin: 0 auto 60px; } }
    .testimonial.testimonial-1 .owl-stage-outer {
      overflow: visible; }
      .testimonial.testimonial-1 .owl-stage-outer .owl-item {
        opacity: 0; }
        .testimonial.testimonial-1 .owl-stage-outer .owl-item.active {
          opacity: 1; }
  .testimonial.testimonial-2 {
    padding-top: 200px; }

@media screen and (max-width:991.98px) {
  .testimonial.testimonial-2 {
    padding-top: 120px;
    padding-bottom: 70px; } }

/*------------------------------------*\
    #Video
\*------------------------------------*/
.video {
  position: relative;
  border-radius: 4px; }
  @media screen and (min-width: 992px) {
    .video {
      height: 528px; } }
  @media screen and (max-width: 991.98px) {
    .video {
      height: 400px; } }
  .video .player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 600; }
    .video .player a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background-color: var(--global--color-white);
      color: var(--global--color-primary);
      transition: 300ms ease-in-out;
      cursor: pointer; }
      .video .player a::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border: 3px solid rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        transform: scale(1);
        opacity: 1;
        animation: pulse 1s cubic-bezier(0, 0.51, 0.45, 1.49) 0s infinite; }
      .video .player a i {
        z-index: 5;
        color: var(--global--color-primary);
        font-size: 15px;
        transition: 300ms ease-in-out; }
  .video.video-1 {
    height: 880px;
    overflow: visible;
    border-radius: 32px 0 0 0;
    margin-top: -98px;
    transform: translateY(110px); }
    @media screen and (max-width: 991.98px) {
      .video.video-1 {
        height: 500px; } }
  .video.video-2 {
    position: relative;
    border-radius: 8px 8px 0 8px;
    margin-bottom: 43px; }
    .video.video-2 .btn-video {
      position: absolute;
      bottom: 37px;
      left: 40px;
      background-color: transparent;
      height: auto;
      padding: 0; }
  .video.video-3 {
    margin-bottom: 65px; }
  .video.video-4 {
    height: 880px;
    border-radius: 0px 32px 0px 0; }
    @media screen and (max-width: 991.98px) {
      .video.video-4 {
        height: 500px;
        max-width: 90%;
        margin: 0 auto 50px; } }
    .video.video-4 .btn-video {
      width: 90px;
      height: 90px;
      position: absolute;
      bottom: 70px;
      right: 70px; }
      @media screen and (max-width: 767.98px) {
        .video.video-4 .btn-video {
          width: 70px;
          height: 70px; } }

.mfp-bg {
  z-index: 1543; }

.mfp-wrap {
  z-index: 1642; }

@-webkit-keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.4);
    opacity: 0; } }

@-moz-keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.4);
    opacity: 0; } }

@-ms-keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.4);
    opacity: 0; } }

@-o-keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.4);
    opacity: 0; } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.4);
    opacity: 0; } }

/* Custom, iPhone Retina */
/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
.cta {
  overflow: visible;
  padding-top: 147px;
  padding-bottom: 0; }
  .cta .icon-set {
    display: flex;
    justify-content: space-around;
    margin-top: 47px; }
    @media screen and (max-width: 991.98px) {
      .cta .icon-set {
        margin-bottom: 30px; } }
    .cta .icon-set .icon-panel {
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media screen and (min-width: 992px) {
        .cta .icon-set .icon-panel {
          align-items: flex-start;
          margin-left: 50px; } }
      .cta .icon-set .icon-panel .icon {
        margin-bottom: 20px; }
        .cta .icon-set .icon-panel .icon::before {
          color: var(--global--color-white);
          font-size: 70px;
          margin-left: 0; }
      .cta .icon-set .icon-panel span {
        font-family: var(--global--font-heading);
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: var(--global--color-white);
        text-transform: capitalize;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .cta .icon-set .icon-panel span {
            text-align: left; } }
    .cta .icon-set.icon-set2 {
      margin-top: 44px;
      margin-bottom: 37px;
      justify-content: flex-start; }
      @media screen and (max-width: 991.98px) {
        .cta .icon-set.icon-set2 {
          justify-content: space-between;
          margin-bottom: 40px; } }
      .cta .icon-set.icon-set2 .icon-panel {
        max-width: 130px;
        margin-right: 80px; }
        @media screen and (max-width: 991.98px) {
          .cta .icon-set.icon-set2 .icon-panel {
            margin-right: 0; } }
        @media screen and (min-width: 992px) {
          .cta .icon-set.icon-set2 .icon-panel {
            margin-left: 0px; } }
  @media screen and (min-width: 992px) {
    .cta .prief-set {
      margin-top: 6px; } }
  .cta .prief-set p {
    font-family: var(--global--font-body);
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: var(--global--color-body);
    margin-bottom: 32px; }
    @media screen and (max-width: 991.98px) {
      .cta .prief-set p {
        text-align: center; } }
  .cta .prief-set .advantages-list {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none; }
    @media screen and (min-width: 992px) {
      .cta .prief-set .advantages-list {
        text-align: left; } }
    .cta .prief-set .advantages-list li {
      display: block;
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 15px;
      line-height: 36px;
      color: var(--global--color-body);
      text-transform: capitalize; }
      .cta .prief-set .advantages-list li i {
        width: 22px;
        height: 22px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--global--color-primary);
        color: var(--global--color-white);
        margin-right: 10px;
        border-radius: 50%;
        font-size: 10px; }
  .cta .prief-set.prief-set-2 {
    margin-bottom: 77px; }
    .cta .prief-set.prief-set-2 p {
      margin-bottom: 24px;
      color: var(--global--color-gray-darken); }
    .cta .prief-set.prief-set-2 .advantages-list-holder {
      display: flex; }
      @media screen and (max-width: 991.98px) {
        .cta .prief-set.prief-set-2 .advantages-list-holder {
          flex-direction: column;
          align-items: flex-start; } }
      .cta .prief-set.prief-set-2 .advantages-list-holder .advantages-list {
        margin-bottom: 0;
        text-align: left; }
        @media screen and (min-width: 992px) {
          .cta .prief-set.prief-set-2 .advantages-list-holder .advantages-list {
            margin-right: 40px; } }
        .cta .prief-set.prief-set-2 .advantages-list-holder .advantages-list li {
          color: var(--global--color-gray); }
  .cta .phone-card {
    display: flex;
    background-color: var(--global--color-heading-darken);
    padding: 35px 40px 31px;
    border-radius: 0 32px 0 0; }
    @media screen and (max-width: 991.98px) {
      .cta .phone-card {
        margin-bottom: 30px; } }
    @media screen and (max-width: 767.98px) {
      .cta .phone-card {
        flex-direction: column;
        align-items: center;
        text-align: center; } }
    .cta .phone-card .card-icon {
      flex-shrink: 0;
      margin-right: 30px; }
      @media screen and (max-width: 767.98px) {
        .cta .phone-card .card-icon {
          margin-right: 0; } }
      .cta .phone-card .card-icon i::before {
        display: flex;
        transform: translateY(-4px);
        font-size: 50px;
        margin-left: 0;
        color: var(--global--color-white); }
    .cta .phone-card .card-content h5 {
      font-weight: 500;
      font-size: 19px;
      line-height: 28px;
      color: var(--global--color-white);
      text-transform: capitalize;
      margin-bottom: 16px; }
    .cta .phone-card .card-content p {
      font-size: 14px;
      color: var(--global--color-gray);
      line-height: 24px;
      margin-bottom: 15px; }
    .cta .phone-card .card-content a {
      display: flex;
      align-items: center;
      transition: 0.3s ease-in-out;
      color: var(--global--color-white); }
      @media screen and (max-width: 991.98px) {
        .cta .phone-card .card-content a {
          justify-content: center; } }
      .cta .phone-card .card-content a span {
        transition: 0.3s ease-in-out;
        font-family: var(--global--font-heading);
        font-weight: 400;
        font-size: 24px; }
      .cta .phone-card .card-content a i {
        margin-bottom: 4px;
        transition: 0.3s ease-in-out;
        font-size: 19px;
        margin-right: 15px; }
  .cta .contact-panel {
    box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
    transform: translateY(340px);
    margin-top: -340px;
    padding-top: 90px; }
  .cta .panel {
    position: relative; }
    .cta .panel .panel-img {
      position: relative; }
      .cta .panel .panel-img::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(27, 26, 26, 0) 0%, rgba(27, 26, 26, 0) 0%, #1b1a1a 105%); }
      .cta .panel .panel-img img {
        height: 460px;
        max-width: 100%;
        border-radius: 4px; }
    .cta .panel .panel-action {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--global--color-primary);
      transition: 0.3s ease-in-out;
      padding: 35px 40px;
      border-radius: 0 4px 0 4px;
      display: flex;
      flex-direction: column;
      max-width: 230px;
      cursor: pointer; }
      .cta .panel .panel-action:hover {
        background-color: var(--global--color-heading); }
        .cta .panel .panel-action:hover a i {
          transform: translateX(10px); }
      .cta .panel .panel-action .panel-icon {
        margin-bottom: 10px; }
        .cta .panel .panel-action .panel-icon i::before {
          color: var(--global--color-white);
          font-size: 50px;
          margin-left: 0; }
      .cta .panel .panel-action .panel-heading {
        margin-bottom: 13px; }
        .cta .panel .panel-action .panel-heading h6 {
          font-family: var(--global--font-body);
          font-weight: 700;
          font-size: 16px;
          line-height: 29px;
          margin-bottom: 0;
          transition: 0.3s ease-in-out;
          color: var(--global--color-white); }
      .cta .panel .panel-action a i {
        display: inline-block;
        color: var(--global--color-white);
        transition: 0.3s ease-in-out; }
      .cta .panel .panel-action.inverted {
        background-color: var(--global--color-heading); }
        .cta .panel .panel-action.inverted:hover {
          background-color: var(--global--color-primary); }
  .cta.cta-2 {
    padding-top: 130px;
    padding-bottom: 110px;
    position: relative; }
    @media screen and (max-width: 991.98px) {
      .cta.cta-2 {
        padding-top: 70px;
        padding-bottom: 70px; } }
  .cta.cta-3 {
    padding-top: 129px;
    padding-bottom: 0; }

@media screen and (max-width:991.98px) {
  .cta.cta-3 {
    padding-top: 90px; } }
  .cta.cta-4 {
    padding-top: 130px;
    padding-bottom: 70px;
    position: relative; }
    @media screen and (max-width: 991.98px) {
      .cta.cta-4 {
        padding-top: 70px; } }
  .cta.cta-5 {
    padding-top: 117px;
    padding-bottom: 0px;
    background-color: var(--global--color-heading-light); }
    @media screen and (max-width: 991.98px) {
      .cta.cta-5 {
        padding-top: 70px; } }
    @media screen and (min-width: 992px) {
      .cta.cta-5 {
        padding-left: 118px;
        margin-bottom: -119px; } }
    @media screen and (max-width: 991.98px) {
      .cta.cta-5 {
        padding-left: 70px;
        padding-right: 70px; } }
    @media screen and (max-width: 767.98px) {
      .cta.cta-5 {
        padding-left: 30px;
        padding-right: 30px; } }
  .cta.cta-6 {
    padding-left: 130px;
    padding-right: 135px;
    overflow: visible; }
    @media screen and (max-width: 991.98px) {
      .cta.cta-6 {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 70px;
        padding-bottom: 70px; } }
    @media screen and (max-width: 767.98px) {
      .cta.cta-6 {
        padding-left: 20px;
        padding-right: 20px; } }
    .cta.cta-6 .video-btn {
      margin-bottom: 59px; }
      @media screen and (max-width: 991.98px) {
        .cta.cta-6 .video-btn {
          display: flex;
          justify-content: center; } }
    .cta.cta-6 .contact-panel {
      position: relative;
      z-index: 8;
      transform: translateY(100px);
      margin-top: -100px;
      width: 700px;
      padding-top: 0; }
      @media (min-width: 992px) and (max-width: 1275px) {
        .cta.cta-6 .contact-panel {
          width: 550px; } }
      @media screen and (max-width: 991.98px) {
        .cta.cta-6 .contact-panel {
          width: 100%; } }
      .cta.cta-6 .contact-panel .contact-types {
        padding: 50px 50px 10px;
        background-color: var(--global--color-white); }
        @media screen and (max-width: 767.98px) {
          .cta.cta-6 .contact-panel .contact-types {
            padding: 50px 20px 10px; } }
        .cta.cta-6 .contact-panel .contact-types .button {
          height: 71px;
          color: var(--global--color-heading);
          background-color: #eaeaea;
          border-radius: 4px 0 0 4px; }
          .cta.cta-6 .contact-panel .contact-types .button:last-child {
            border-radius: 0 4px 4px 0; }
          @media screen and (max-width: 767.98px) {
            .cta.cta-6 .contact-panel .contact-types .button {
              border-radius: 4px 4px 0 0; }
              .cta.cta-6 .contact-panel .contact-types .button:last-child {
                border-radius: 0px 0px 4px 4px; } }
          .cta.cta-6 .contact-panel .contact-types .button.active {
            background-color: var(--global--color-primary);
            color: var(--global--color-white); }
      @media screen and (max-width: 767.98px) {
        .cta.cta-6 .contact-panel .contact-card {
          padding-left: 20px;
          padding-right: 20px; } }

/*------------------------------------*\
    #404
\*------------------------------------*/
.page-404 h3 {
  font-size: 280px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0; }

.page-404 p {
  color: #a5a5a5;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 20px; }

@media only screen and (max-width: 767px) {
  .page-404 h3 {
    font-size: 150px; }
  .page-404 .btn {
    width: 125px; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .page-404 h3 {
    font-size: 180px; } }

/*--------------------------------
    #Services
---------------------------------*/
.services {
  padding-top: 130px;
  padding-bottom: 90px; }
  @media screen and (max-width: 991.98px) {
    .services {
      padding-top: 70px;
      padding-bottom: 70px; } }
  .services .service-panel {
    margin-bottom: 80px;
    box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.08);
    background-color: var(--global--color-white);
    transition: 0.3s ease-in-out;
    border-radius: 8px 0 8px 8px; }
    @media screen and (max-width: 991.98px) {
      .services .service-panel {
        max-width: 370px;
        margin: 0 auto 80px; } }
    .services .service-panel:hover {
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); }
      .services .service-panel:hover .service-panel-holder .service-img img {
        transform: scale3d(1, 1, 1); }
      .services .service-panel:hover .service-panel-holder .service-img::before {
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }
      .services .service-panel:hover .service-panel-holder .service-content .service-more a {
        background-color: var(--global--color-primary);
        border-color: var(--global--color-primary);
        color: var(--global--color-white); }
        .services .service-panel:hover .service-panel-holder .service-content .service-more a .line span {
          opacity: 0;
          visibility: hidden; }
        .services .service-panel:hover .service-panel-holder .service-content .service-more a .line::after {
          opacity: 1;
          visibility: visible;
          color: var(--global--color-white); }
    .services .service-panel .service-panel-holder {
      overflow: visible;
      position: relative;
      padding: 0 40px 182px; }
      @media screen and (max-width: 767.98px) {
        .services .service-panel .service-panel-holder {
          padding: 0 30px 182px; } }
      .services .service-panel .service-panel-holder .service-img {
        position: relative;
        overflow: hidden;
        border-radius: 8px 0 8px 8px;
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 80px); }
        @media screen and (max-width: 767.98px) {
          .services .service-panel .service-panel-holder .service-img {
            width: calc(100% - 60px); } }
        .services .service-panel .service-panel-holder .service-img a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 2; }
        .services .service-panel .service-panel-holder .service-img::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.5);
          content: '';
          z-index: 1;
          transition: transform 0.6s;
          transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }
        .services .service-panel .service-panel-holder .service-img img {
          max-width: 100%;
          height: auto;
          transition: 0.5s ease-in-out; }
      .services .service-panel .service-panel-holder .service-content {
        text-align: center; }
        @media screen and (min-width: 992px) {
          .services .service-panel .service-panel-holder .service-content {
            text-align: left; } }
        .services .service-panel .service-panel-holder .service-content .service-title h4 {
          font-family: var(--global--font-heading);
          font-size: 23px;
          font-weight: 500;
          line-height: 32px;
          margin-bottom: 16px;
          text-transform: capitalize; }
          .services .service-panel .service-panel-holder .service-content .service-title h4 a {
            color: var(--global--color-heading); }
            .services .service-panel .service-panel-holder .service-content .service-title h4 a:hover {
              color: var(--global--color-primary); }
        .services .service-panel .service-panel-holder .service-content .service-desc {
          margin-bottom: 22px; }
          .services .service-panel .service-panel-holder .service-content .service-desc p {
            font-family: var(--global--font-body);
            color: var(--global--color-body);
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            margin-bottom: 0; }
        .services .service-panel .service-panel-holder .service-content .service-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 94px;
          height: 94px;
          border-radius: 0 0 8px 8px;
          background-color: #e7f9fc;
          margin-bottom: 32px; }
          @media screen and (max-width: 991.98px) {
            .services .service-panel .service-panel-holder .service-content .service-icon {
              display: inline-flex; } }
          .services .service-panel .service-panel-holder .service-content .service-icon i::before {
            color: var(--global--color-heading);
            font-size: 64px;
            margin-left: 0; }
        @media screen and (max-width: 991.98px) {
          .services .service-panel .service-panel-holder .service-content .service-more {
            display: inline-flex; } }
        .services .service-panel .service-panel-holder .service-content .service-more a {
          font-size: 15px;
          font-weight: 700;
          font-family: var(--global--font-secondary);
          text-transform: capitalize;
          transition: 0.3s ease-in-out;
          width: 124px;
          border-radius: 8px 8px 0 8px;
          height: 42px;
          border: 2px solid;
          border-color: #687596;
          transition-property: background, color, border-color; }
          .services .service-panel .service-panel-holder .service-content .service-more a::before {
            background-color: var(--global--color-primary);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            top: -2px;
            left: -2px; }
          .services .service-panel .service-panel-holder .service-content .service-more a:active, .services .service-panel .service-panel-holder .service-content .service-more a:focus, .services .service-panel .service-panel-holder .service-content .service-more a:hover {
            color: var(--global--color-white);
            border-color: var(--global--color-primary); }
    .services .service-panel.service-panel-2 {
      margin-bottom: 30px;
      border-radius: 8px 0 8px 8px; }
      .services .service-panel.service-panel-2:hover .service-panel-holder .service-content .service-icon {
        background-color: #e7f9fc; }
      .services .service-panel.service-panel-2 .service-panel-holder {
        padding: 50px 40px; }
        @media screen and (min-width: 992px) {
          .services .service-panel.service-panel-2 .service-panel-holder .service-content {
            text-align: center; } }
        .services .service-panel.service-panel-2 .service-panel-holder .service-content .service-icon {
          width: 114px;
          height: 114px;
          border-radius: 50%;
          border: 5px solid #e7f9fc;
          background-color: transparent;
          transition: 0.3s ease-in-out; }
          @media screen and (min-width: 992px) {
            .services .service-panel.service-panel-2 .service-panel-holder .service-content .service-icon {
              display: inline-flex; } }
        @media screen and (min-width: 992px) {
          .services .service-panel.service-panel-2 .service-panel-holder .service-content .service-more a {
            display: inline-flex; } }
    .services .service-panel.service-panel-3 {
      margin-bottom: 30px;
      border-radius: 8px 0 8px 8px; }
      .services .service-panel.service-panel-3 .service-panel-holder {
        padding: 50px 40px; }
        @media screen and (min-width: 992px) {
          .services .service-panel.service-panel-3 .service-panel-holder .service-content {
            text-align: center; } }
        .services .service-panel.service-panel-3 .service-panel-holder .service-content .service-icon {
          width: 92px;
          height: 92px;
          background-color: transparent;
          transition: 0.3s ease-in-out; }
          .services .service-panel.service-panel-3 .service-panel-holder .service-content .service-icon img {
            max-width: 100%; }
          @media screen and (min-width: 992px) {
            .services .service-panel.service-panel-3 .service-panel-holder .service-content .service-icon {
              display: inline-flex; } }
        @media screen and (min-width: 992px) {
          .services .service-panel.service-panel-3 .service-panel-holder .service-content .service-more a {
            display: inline-flex; } }
  .services .more-services {
    text-align: center; }
    .services .more-services p {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 14px;
      color: var(--global--color-body);
      margin-bottom: 0; }
      .services .more-services p a {
        position: relative;
        color: var(--global--color-primary);
        transition: 0.3s ease-in-out;
        text-transform: capitalize;
        padding-bottom: 8px; }
        .services .more-services p a:hover::before {
          width: 70%; }
        .services .more-services p a::before {
          content: '';
          transition: 0.3s ease-in-out;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background-color: var(--global--color-primary); }
  .services.services-2 {
    padding-bottom: 100px; }
    @media screen and (max-width: 991.98px) {
      .services.services-2 {
        padding-bottom: 70px; } }
  .services.services-3 {
    padding-bottom: 100px; }
    @media screen and (max-width: 991.98px) {
      .services.services-3 {
        padding-bottom: 70px; } }
  .services.services-4 {
    padding-top: 324px;
    padding-bottom: 100px; }
    @media screen and (max-width: 991.98px) {
      .services.services-4 {
        padding-top: 80px;
        padding-bottom: 70px; } }
    .services.services-4 .service-panel .service-img img {
      width: auto; }
    .services.services-4 .owl-stage-outer {
      overflow: visible; }
      .services.services-4 .owl-stage-outer .owl-item {
        opacity: 0; }
        .services.services-4 .owl-stage-outer .owl-item.active {
          opacity: 1; }
    .services.services-4 .owl-dots {
      margin-bottom: 40px;
      margin-top: 0; }
      @media screen and (min-width: 992px) {
        .services.services-4 .owl-dots {
          right: 23px;
          bottom: -18px;
          margin-bottom: 0; } }
      .services.services-4 .owl-dots .owl-dot {
        display: inline-flex; }
    @media screen and (min-width: 992px) {
      .services.services-4 .more-services {
        text-align: left;
        padding-left: 40px; } }

.single-service {
  padding-bottom: 30px;
  padding-top: 120px; }
  @media screen and (max-width: 991.98px) {
    .single-service {
      padding-top: 70px; } }
  @media screen and (min-width: 992px) {
    .single-service .sidebar-service {
      margin-top: 8px; } }
  .single-service .service-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    color: #0e204d;
    margin-bottom: 40px; }
  .single-service .entry-introduction {
    margin-bottom: 70px; }
    .single-service .entry-introduction .head {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 16px;
      line-height: 27px;
      color: var(--global--color-secondary);
      margin-bottom: 25px; }
    .single-service .entry-introduction .desc {
      font-size: 16px;
      line-height: 27px;
      color: var(--global--color-body);
      margin-bottom: 25px; }
    .single-service .entry-introduction .signature-block {
      display: flex;
      align-items: center; }
      @media screen and (max-width: 991.98px) {
        .single-service .entry-introduction .signature-block {
          justify-content: center; } }
      @media screen and (min-width: 992px) {
        .single-service .entry-introduction .signature-block {
          margin-top: 7px; } }
      .single-service .entry-introduction .signature-block .signature-body {
        position: relative; }
        .single-service .entry-introduction .signature-block .signature-body img {
          position: absolute;
          top: -18px;
          left: 2px;
          right: 0; }
        .single-service .entry-introduction .signature-block .signature-body h6 {
          font-family: var(--global--font-heading);
          font-weight: 500;
          color: var(--global--color-secondary);
          font-size: 19px;
          line-height: 29px;
          text-transform: capitalize;
          margin-bottom: 0; }
        .single-service .entry-introduction .signature-block .signature-body p {
          font-family: var(--global--font-body);
          font-weight: 400;
          color: var(--global--color-primary);
          font-size: 14px;
          line-height: 26px;
          text-transform: capitalize;
          margin-bottom: 0; }
      .single-service .entry-introduction .signature-block .btn {
        border-radius: 8px 8px 0 8px;
        margin-right: 40px;
        width: 170px;
        height: 60px; }
    .single-service .entry-introduction .advantages {
      margin-bottom: 0; }
      @media screen and (max-width: 991.98px) {
        .single-service .entry-introduction .advantages {
          margin-bottom: 30px;
          text-align: left; } }
      .single-service .entry-introduction .advantages li {
        display: block;
        font-family: var(--global--font-body);
        font-weight: 700;
        font-size: 15px;
        line-height: 36px;
        color: var(--global--color-body);
        text-transform: capitalize; }
        .single-service .entry-introduction .advantages li i {
          width: 22px;
          height: 22px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: var(--global--color-heading-darken);
          color: var(--global--color-white);
          margin-right: 10px;
          border-radius: 50%;
          font-size: 10px; }
  @media screen and (max-width: 991.98px) {
    .single-service .entry-infos {
      text-align: center; } }
  .single-service .entry-infos .entry-heading {
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 19px;
    color: #0e204d;
    text-transform: capitalize; }
  .single-service .entry-infos .entry-desc {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 32px; }
  .single-service .health-plans {
    padding: 45px 50px 50px;
    border: 2px solid #e6e8eb;
    border-radius: 8px 8px 0 8px;
    margin-bottom: 67px; }
    @media screen and (max-width: 767.98px) {
      .single-service .health-plans {
        padding-left: 25px;
        padding-right: 25px; } }
    .single-service .health-plans .advantages-box {
      background-color: var(--global--color-primary-light);
      padding: 32px 40px;
      border-radius: 8px 8px 0 8px;
      margin-bottom: 40px; }
      @media screen and (max-width: 767.98px) {
        .single-service .health-plans .advantages-box {
          padding-left: 20px;
          padding-right: 20px; } }
      @media screen and (min-width: 992px) {
        .single-service .health-plans .advantages-box {
          display: flex;
          justify-content: space-between; } }
      .single-service .health-plans .advantages-box .advantages-list {
        margin-bottom: 0;
        margin: 0;
        padding: 0;
        list-style: none; }
        @media screen and (max-width: 991.98px) {
          .single-service .health-plans .advantages-box .advantages-list {
            margin-bottom: 0px; } }
        .single-service .health-plans .advantages-box .advantages-list li {
          display: block;
          font-family: var(--global--font-body);
          font-weight: 700;
          font-size: 15px;
          line-height: 36px;
          color: var(--global--color-heading); }
          .single-service .health-plans .advantages-box .advantages-list li i {
            width: 22px;
            height: 22px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: var(--global--color-primary);
            color: var(--global--color-white);
            margin-right: 10px;
            border-radius: 50%;
            font-size: 10px; }
    .single-service .health-plans .entry-action {
      display: flex;
      align-items: center; }
      @media screen and (max-width: 991.98px) {
        .single-service .health-plans .entry-action {
          flex-direction: column;
          align-items: center; } }
      .single-service .health-plans .entry-action p {
        margin-bottom: 0px;
        font-family: var(--global--font-heading);
        font-weight: 400;
        color: var(--global--color-heading-darken);
        display: flex;
        align-items: baseline; }
        @media screen and (min-width: 992px) {
          .single-service .health-plans .entry-action p {
            margin-right: 30px; } }
        @media screen and (max-width: 991.98px) {
          .single-service .health-plans .entry-action p {
            justify-content: center;
            margin-bottom: 20px; } }
        .single-service .health-plans .entry-action p span::before {
          content: '/'; }
        .single-service .health-plans .entry-action p span:first-of-type::before {
          content: unset; }
        .single-service .health-plans .entry-action p .currency {
          font-size: 50px; }
        .single-service .health-plans .entry-action p .time {
          font-size: 14px; }
      .single-service .health-plans .entry-action .btn {
        width: 214px;
        height: 60px;
        border-radius: 8px 8px 0 8px; }
  .single-service .core-values {
    margin-bottom: 27px; }
    .single-service .core-values .entry-desc {
      margin-bottom: 42px; }
    .single-service .core-values .entry-value {
      padding: 34px 38px 32px;
      display: flex;
      border: 2px solid var(--global--color-gray-light);
      border-radius: 8px 8px 0 8px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      margin-bottom: 40px; }
      @media screen and (max-width: 767.98px) {
        .single-service .core-values .entry-value {
          flex-direction: column;
          align-items: center;
          padding: 28px 25px 26px;
          text-align: center; } }
      .single-service .core-values .entry-value:hover {
        border-color: transparent;
        box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.15); }
      .single-service .core-values .entry-value i {
        margin-right: 30px; }
        .single-service .core-values .entry-value i::before {
          margin-left: 0;
          font-size: 49px;
          color: #354f8e; }
        @media screen and (min-width: 768px) {
          .single-service .core-values .entry-value i {
            transform: translateY(-6px); } }

@media screen and (max-width:767.98px) {
  .single-service .core-values .entry-value i {
    margin-right: 0;
    margin-bottom: 20px; } }
      .single-service .core-values .entry-value h5 {
        font-family: var(--global--font-heading);
        font-weight: 500;
        color: var(--global--color-heading);
        font-size: 19px;
        line-height: 28px;
        margin-bottom: 11px;
        text-transform: capitalize; }
      .single-service .core-values .entry-value p {
        font-family: var(--global--font-body);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        text-transform: capitalize; }
  .single-service .tips-info {
    margin-bottom: 67px; }
    .single-service .tips-info .entry-desc {
      margin-bottom: 42px; }
    .single-service .tips-info .prices-list {
      padding: 37px 40px 28px;
      border-radius: 8px 8px 0 8px;
      background-color: #f6f6f6; }
      .single-service .tips-info .prices-list h6 {
        font-family: var(--global--font-heading);
        font-weight: 500;
        font-size: 19px;
        line-height: 1;
        margin-bottom: 26px;
        color: var(--global--color-heading);
        text-transform: capitalize; }
      .single-service .tips-info .prices-list ul {
        margin-bottom: 0; }
        .single-service .tips-info .prices-list ul li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: capitalize;
          font-size: 15px;
          font-weight: 700;
          font-family: var(--global--font-body);
          color: var(--global--color-body);
          text-transform: capitalize;
          border-bottom: 1px solid var(--global--color-gray-light);
          margin-bottom: 14px;
          padding-bottom: 12px; }
          @media screen and (min-width: 768px) {
            .single-service .tips-info .prices-list ul li:last-child {
              margin-bottom: 0px;
              border-bottom: 0; } }
          .single-service .tips-info .prices-list ul li .price {
            font-family: var(--global--font-secondary);
            font-weight: 700;
            font-size: 15px;
            color: var(--global--color-white);
            background-color: var(--global--color-heading-darken);
            padding: 3px 10px;
            border-radius: 8px 8px 0 8px; }
    .single-service .tips-info .infos h6 {
      font-family: var(--global--font-body);
      font-weight: 700;
      font-size: 16px;
      line-height: 27px;
      color: var(--global--color-secondary);
      margin-bottom: 25px; }
    .single-service .tips-info .infos p {
      font-size: 16px;
      line-height: 27px;
      color: var(--global--color-body);
      margin-bottom: 25px; }
  .single-service .doctors-prief .entry-desc {
    margin-bottom: 42px; }

/*------------------------------------*\
    #Departments
\*------------------------------------*/
.departments {
  padding-top: 130px;
  padding-bottom: 50px; }
  @media screen and (max-width: 991.98px) {
    .departments {
      padding-top: 70px; } }
  .departments .department-panel {
    margin-bottom: 80px;
    box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.08);
    background-color: var(--global--color-white);
    transition: 0.3s ease-in-out;
    border-radius: 8px 0 8px 8px; }
    @media screen and (max-width: 991.98px) {
      .departments .department-panel {
        max-width: 370px;
        margin: 0 auto 80px; } }
    .departments .department-panel:hover {
      box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15); }
      .departments .department-panel:hover .department-panel-container .department-img img {
        transform: scale3d(1, 1, 1); }
      .departments .department-panel:hover .department-panel-container .department-img::before {
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }
      .departments .department-panel:hover .department-panel-container .department-content .department-more a {
        background-color: var(--global--color-primary);
        border-color: var(--global--color-primary);
        color: var(--global--color-white); }
        .departments .department-panel:hover .department-panel-container .department-content .department-more a .line span {
          opacity: 0;
          visibility: hidden; }
        .departments .department-panel:hover .department-panel-container .department-content .department-more a .line::after {
          opacity: 1;
          visibility: visible;
          color: var(--global--color-white); }
    .departments .department-panel .department-panel-holder {
      overflow: visible;
      position: relative;
      padding: 172px 40px 40px; }
      @media screen and (max-width: 767.98px) {
        .departments .department-panel .department-panel-holder {
          padding: 172px 30px 40px; } }
      @media (min-width: 1440px) {
        .departments .department-panel .department-panel-holder {
          padding-top: 200px; } }
      .departments .department-panel .department-panel-holder .department-img {
        position: relative;
        overflow: hidden;
        border-radius: 8px 0 8px 8px;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 80px); }
        @media screen and (max-width: 767.98px) {
          .departments .department-panel .department-panel-holder .department-img {
            width: calc(100% - 60px); } }
        .departments .department-panel .department-panel-holder .department-img a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 2; }
        .departments .department-panel .department-panel-holder .department-img img {
          transition: transform 0.35s;
          max-width: 100%;
          height: auto;
          transform: scale3d(1.05, 1.05, 1); }
        .departments .department-panel .department-panel-holder .department-img::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.5);
          content: '';
          z-index: 1;
          transition: transform 0.6s;
          transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }
      .departments .department-panel .department-panel-holder .department-content {
        text-align: center; }
        @media screen and (min-width: 992px) {
          .departments .department-panel .department-panel-holder .department-content {
            text-align: left; } }
        .departments .department-panel .department-panel-holder .department-content .department-title h4 {
          font-family: var(--global--font-heading);
          font-size: 23px;
          font-weight: 500;
          line-height: 32px;
          margin-bottom: 16px;
          text-transform: capitalize; }
          .departments .department-panel .department-panel-holder .department-content .department-title h4 a {
            color: var(--global--color-heading); }
            .departments .department-panel .department-panel-holder .department-content .department-title h4 a:hover {
              color: var(--global--color-primary); }
        .departments .department-panel .department-panel-holder .department-content .department-list {
          margin-bottom: 23px; }
          .departments .department-panel .department-panel-holder .department-content .department-list li {
            display: flex;
            align-items: center; }
            .departments .department-panel .department-panel-holder .department-content .department-list li:last-child span {
              border-bottom: 0; }
            .departments .department-panel .department-panel-holder .department-content .department-list li i {
              font-size: 10px;
              color: var(--global--color-heading-darken);
              margin-bottom: 5px;
              margin-right: 15px; }
            .departments .department-panel .department-panel-holder .department-content .department-list li span {
              flex-grow: 1;
              padding-top: 8px;
              padding-bottom: 11px;
              text-transform: capitalize;
              font-size: 15px;
              font-family: var(--global--font-body);
              font-weight: 700;
              color: var(--global--color-body);
              border-bottom: 1px solid var(--global--color-gray-light);
              text-align: left; }
        .departments .department-panel .department-panel-holder .department-content .department-desc {
          margin-bottom: 19px; }
          .departments .department-panel .department-panel-holder .department-content .department-desc p {
            font-family: var(--global--font-body);
            color: var(--global--color-body);
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            margin-bottom: 0; }
        @media screen and (max-width: 991.98px) {
          .departments .department-panel .department-panel-holder .department-content .department-more {
            display: inline-flex; } }
        .departments .department-panel .department-panel-holder .department-content .department-more .btn {
          font-size: 15px;
          font-weight: 700;
          border: 2px solid;
          border-color: #687596;
          font-family: var(--global--font-secondary);
          text-transform: capitalize;
          transition: 0.3s ease-in-out;
          width: 124px;
          border-radius: 8px 8px 0 8px;
          height: 42px;
          transition-property: background, color, border-color; }
          .departments .department-panel .department-panel-holder .department-content .department-more .btn::before {
            background-color: var(--global--color-primary);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            top: -2px;
            left: -2px; }
          .departments .department-panel .department-panel-holder .department-content .department-more .btn:active, .departments .department-panel .department-panel-holder .department-content .department-more .btn:focus, .departments .department-panel .department-panel-holder .department-content .department-more .btn:hover {
            color: var(--global--color-white);
            border-color: var(--global--color-primary); }
  @media screen and (max-width: 991.98px) {
    .departments .departments-action {
      text-align: center; } }
  @media screen and (min-width: 992px) {
    .departments .departments-action p {
      margin-bottom: 0; } }
  .departments.departments-2 {
    padding-top: 116px;
    padding-bottom: 98px; }

@media screen and (max-width:991.98px) {
  .departments.departments-2 {
    padding-top: 70px;
    padding-bottom: 70px; } }
    .departments.departments-2 .department-panel {
      margin-bottom: 44px; }
      @media screen and (max-width: 991.98px) {
        .departments.departments-2 .department-panel {
          margin: 0 auto 80px; } }

.single-department {
  padding-top: 127px;
  padding-bottom: 110px; }
  @media screen and (max-width: 991.98px) {
    .single-department {
      padding-top: 70px;
      padding-bottom: 70px; } }
  .single-department .sidebar-department {
    margin-top: 3px; }
  .single-department .entry-infos .entry-desc.strong {
    font-weight: 700;
    color: var(--global--color-secondary);
    margin-bottom: 25px; }
  .single-department .entry-infos.entry-infos-2 .entry-desc:last-child {
    margin-bottom: 42px; }
  .single-department .entry-introduction {
    margin-bottom: 60px; }
    @media screen and (max-width: 991.98px) {
      .single-department .entry-introduction {
        text-align: left; } }
    .single-department .entry-introduction .entry-desc:first-of-type {
      margin-bottom: 25px; }
    .single-department .entry-introduction .entry-desc:last-of-type {
      margin-bottom: 25px; }
  .single-department .services-price {
    margin-bottom: 68px; }
    .single-department .services-price .entry-desc {
      margin-bottom: 42px; }
    .single-department .services-price .prices-list {
      padding: 37px 40px 28px;
      border-radius: 8px 8px 0 8px;
      background-color: #f6f6f6; }
      @media screen and (max-width: 991.98px) {
        .single-department .services-price .prices-list {
          margin-bottom: 30px; } }
      @media screen and (max-width: 767.98px) {
        .single-department .services-price .prices-list {
          padding-right: 25px;
          padding-left: 25px; } }
      .single-department .services-price .prices-list h6 {
        font-family: var(--global--font-heading);
        font-weight: 500;
        font-size: 19px;
        line-height: 1;
        margin-bottom: 26px;
        color: var(--global--color-heading);
        text-transform: capitalize; }
      .single-department .services-price .prices-list ul {
        margin-bottom: 0; }
        .single-department .services-price .prices-list ul li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: capitalize;
          font-size: 15px;
          font-weight: 700;
          font-family: var(--global--font-body);
          color: var(--global--color-body);
          text-transform: capitalize;
          border-bottom: 1px solid var(--global--color-gray-light);
          margin-bottom: 14px;
          padding-bottom: 12px; }
          @media screen and (min-width: 768px) {
            .single-department .services-price .prices-list ul li:last-child {
              margin-bottom: 0px;
              border-bottom: 0; } }
          .single-department .services-price .prices-list ul li .price {
            font-family: var(--global--font-secondary);
            font-weight: 700;
            font-size: 15px;
            color: var(--global--color-white);
            background-color: var(--global--color-heading-darken);
            padding: 3px 10px;
            border-radius: 8px 8px 0 8px; }
      .single-department .services-price .prices-list.prices-list-2 ul li .price {
        background-color: var(--global--color-primary); }
  .single-department .doctors-prief .team-grid .team-member .team-content {
    padding-bottom: 20px; }
  .single-department .entry-contact .contact-panel {
    border-radius: 8px 8px 0 8px; }

/*------------------------------------*\
    #Footer
\*------------------------------------*/
/* Parallax Effect */
#wrapperParallax {
  position: relative;
  z-index: 1060;
  min-height: 100vh; }

#footerParallax {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0; }

@media only screen and (max-width: 991px) {
  #wrapperParallax {
    margin-bottom: 0 !important; }
  #footerParallax {
    position: static;
    text-align: center; } }

.footer {
  background-color: var(--global--color-heading-light);
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  /* Footer Widgets */ }
  .footer::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--global--color-primary);
    width: calc(100% - 120px);
    height: 4px; }
    @media screen and (max-width: 991.98px) {
      .footer::after {
        width: calc(100% - 60px); } }
    @media screen and (max-width: 767.98px) {
      .footer::after {
        width: calc(100% - 40px); } }
  .footer .footer-top {
    padding-top: 120px;
    padding-bottom: 84px; }
    @media screen and (max-width: 991.98px) {
      .footer .footer-top {
        padding-top: 40px;
        padding-bottom: 50px; } }
  .footer .footer-widget {
    text-align: center; }
    @media screen and (min-width: 768px) {
      .footer .footer-widget {
        text-align: left; } }
    .footer .footer-widget .footer-widget-title h5 {
      font-family: var(--global--font-heading);
      color: var(--global--color-white);
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      text-transform: capitalize;
      margin-bottom: 23px; }
    .footer .footer-widget .widget-content p {
      color: var(--global--color-body);
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      margin-bottom: 25px; }
    .footer .footer-widget .widget-content .module-social {
      height: auto;
      line-height: normal; }
  .footer .widget-newsletter .widget-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .footer .widget-newsletter .widget-content .form-newsletter {
      overflow: hidden;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      position: relative; }
      @media screen and (max-width: 991.98px) {
        .footer .widget-newsletter .widget-content .form-newsletter {
          width: 100%; } }
      .footer .widget-newsletter .widget-content .form-newsletter .form-group {
        position: relative;
        margin-bottom: 15px; }
        .footer .widget-newsletter .widget-content .form-newsletter .form-group .form-control {
          background-color: #283b69;
          color: var(--global--color-gray-darken);
          font-family: var(--global--font-body);
          font-size: 14px;
          font-weight: 400;
          height: 60px;
          line-height: 60px;
          margin-bottom: 0px;
          border-radius: 8px 8px 0 8px;
          padding-left: 20px;
          padding-right: 50px;
          width: 100%;
          outline: none;
          text-transform: capitalize; }
          @media screen and (min-width: 992px) {
            .footer .widget-newsletter .widget-content .form-newsletter .form-group .form-control {
              margin-bottom: 0;
              margin-right: 30px; } }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .form-control::-webkit-input-placeholder {
            color: var(--global--color-gray-darken); }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .form-control::-moz-placeholder {
            color: var(--global--color-gray-darken); }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .form-control:-ms-input-placeholder {
            color: var(--global--color-gray-darken); }
        .footer .widget-newsletter .widget-content .form-newsletter .form-group .submit {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: var(--global--color-primary);
          display: flex;
          align-items: center;
          justify-content: center; }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .submit:hover::before {
            opacity: 0;
            visibility: hidden; }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .submit:hover::after {
            opacity: 1;
            visibility: visible; }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .submit input {
            border: 0;
            outline: 0;
            background: transparent;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .submit::before {
            content: '\e901';
            font-family: 'icomoon';
            font-size: 11px;
            color: var(--global--color-white);
            transform: translateY(1px);
            transition: 0.3s ease-in-out; }
          .footer .widget-newsletter .widget-content .form-newsletter .form-group .submit:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 2px;
            background-color: var(--global--color-white);
            transition: 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden; }
      @media screen and (max-width: 991.98px) {
        .footer .widget-newsletter .widget-content .form-newsletter .custom-radio-group {
          justify-content: center; } }
      .footer .widget-newsletter .widget-content .form-newsletter .custom-radio-group .custom-control label {
        font-size: 14px;
        color: var(--global--color-gray);
        text-transform: lowercase; }
        .footer .widget-newsletter .widget-content .form-newsletter .custom-radio-group .custom-control label::before {
          border-color: #304377; }
  .footer .widget-links ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    overflow: hidden;
    transform: translateY(-5px); }
    .footer .widget-links ul li {
      display: block; }
      .footer .widget-links ul li a {
        color: var(--global--color-gray-darken);
        font-size: 14px;
        font-weight: 400;
        line-height: 33px;
        text-transform: capitalize;
        font-family: var(--global--font-body); }
        .footer .widget-links ul li a:hover {
          color: var(--global--color-primary); }
  .footer .widget-contact .widget-content p {
    color: var(--global--color-white);
    margin-bottom: 15px; }
  .footer .widget-contact .widget-content .phone {
    margin-bottom: 18px; }
    .footer .widget-contact .widget-content .phone a {
      color: var(--global--color-primary);
      font-family: var(--global--font-heading);
      font-size: 22px;
      transition: 0.3s ease-in-out; }
      .footer .widget-contact .widget-content .phone a:hover {
        color: var(--global--color-white); }
        .footer .widget-contact .widget-content .phone a:hover i {
          color: var(--global--color-white); }
      .footer .widget-contact .widget-content .phone a i {
        color: var(--global--color-primary);
        transition: 0.3s ease-in-out;
        display: inline-block;
        font-size: 18px;
        margin-right: 9px; }
  .footer .widget-contact .widget-content .address a {
    color: var(--global--color-body);
    font-size: 14px; }
    .footer .widget-contact .widget-content .address a:hover {
      color: var(--global--color-primary); }
  .footer .widget-contact .widget-content .directions {
    font-family: var(--global--font-secondary);
    font-weight: 700;
    font-size: 15px;
    color: var(--global--color-primary);
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
    transition: 0.3s ease-in-out;
    position: relative;
    padding-left: 22px; }
    .footer .widget-contact .widget-content .directions::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 12px;
      height: 2px;
      background-color: var(--global--color-primary);
      transition: 0.3s ease-in-out; }
    .footer .widget-contact .widget-content .directions i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      font-size: 12px;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out;
      color: var(--global--color-white); }
    .footer .widget-contact .widget-content .directions:hover {
      color: var(--global--color-white); }
      .footer .widget-contact .widget-content .directions:hover::before {
        opacity: 0;
        visibility: hidden; }
      .footer .widget-contact .widget-content .directions:hover i {
        opacity: 1;
        visibility: visible; }

.footer-copyright {
  padding: 50px 0 50px;
  border-top: 2px solid #2f3e66; }
  .footer-copyright .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    @media screen and (max-width: 991.98px) {
      .footer-copyright .copyright {
        flex-direction: column; } }
    .footer-copyright .copyright span {
      font-family: var(--global--font-body);
      font-weight: 400;
      font-size: 14px;
      color: var(--global--color-white);
      text-transform: capitalize; }
      @media screen and (max-width: 991.98px) {
        .footer-copyright .copyright span {
          margin-bottom: 40px;
          text-align: center; } }
      .footer-copyright .copyright span a {
        color: var(--global--color-primary); }
        .footer-copyright .copyright span a:hover {
          color: var(--global--color-white); }
    .footer-copyright .copyright .social-icons {
      margin-bottom: 0; }
      .footer-copyright .copyright .social-icons li {
        display: inline-block;
        margin-right: 10px; }
        .footer-copyright .copyright .social-icons li a {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #304377;
          font-size: 16px;
          color: var(--global--color-white);
          transition: 0.3s ease-in-out;
          border-radius: 8px 8px 0 8px; }
          .footer-copyright .copyright .social-icons li a:hover {
            background-color: var(--global--color-primary);
            transform: translateY(-10px); }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer .footer-widget {
    padding-top: 60px;
    padding-bottom: 0; }
  .footer .footer-widget-title h5 {
    margin-bottom: 15px; } }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-widget {
    padding-bottom: 40px;
    padding-top: 60px; }
    .footer .footer-widget .footer-widget-title h5 {
      margin-bottom: 15px; } }
