/*------------------------------------*\
    #Donations
\*------------------------------------*/
.donations {
    padding-top   : 122px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .donations-map {
        position : relative;
        max-width: 470px;
        margin   : 0 auto;

        @media #{$maxTablet} {
            margin: 0 auto 40px;
        }

        img {
            max-width: 100%;
        }

        .map-pointer {
            position: absolute;
            width   : 28px;
            height  : 28px;
            cursor  : pointer;

            &:hover {
                .info {
                    opacity   : 1;
                    visibility: visible;
                    transform : translateY(0);
                }
            }

            &:first-of-type {
                top : 29%;
                left: 15%;
            }

            &:nth-of-type(2) {
                top : 48%;
                left: 48%;
            }

            &:nth-of-type(3) {
                top : 23%;
                left: 68%;
            }

            .info {
                background-color: var(--global--color-white);
                box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
                border-radius   : 10px 10px 10px 0px;
                position        : relative;
                width           : max-content;
                position        : absolute;
                max-width       : 170px;
                z-index         : 2;
                transition      : 0.3s ease-in-out;
                left            : 18px;
                top             : -97px;
                padding         : 15px 20px;
                opacity         : 0;
                visibility      : hidden;
                transform       : translateY(10px);

                &.left {
                    left         : -160px;
                    border-radius: 10px 10px 0 10px;

                    &::before {
                        clip-path: polygon(100% 0, 0 0, 100% 100%);
                        left     : auto;
                        right    : 0;
                    }
                }

                &::before {
                    content         : '';
                    width           : 10px;
                    height          : 10px;
                    background-color: var(--global--color-white);
                    clip-path       : polygon(100% 0, 0 0, 0 100%);
                    position        : absolute;
                    bottom          : -9px;
                    left            : 0;
                }

                span {
                    font-family   : var(--global--font-body);
                    font-size     : 14px;
                    line-height   : 24px;
                    color         : var(--global--color-body);
                    text-transform: capitalize;
                }
            }
        }
    }

    .img-hotspot {
        .img-hotspot-wrap {
            position : relative;
            max-width: 470px;
            margin   : 0 auto;

            @media #{$maxTablet} {
                margin: 0 auto 40px;
            }

            .img-hotspot-bg {
                img {
                    max-width: 100%;
                }
            }

            .img-hotspot-pointers {
                position: absolute;
                width   : 100%;
                height  : 100%;
                top     : 0;
                left    : 0;
                right   : 0;

                .img-hotspot-pointer {
                    position: absolute;
                    width   : 28px;
                    height  : 28px;
                    cursor  : pointer;

                    &:hover {
                        .info {
                            opacity   : 1;
                            visibility: visible;
                            transform : translateY(0);
                        }
                    }

                    .info {
                        background-color: var(--global--color-white);
                        box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
                        border-radius   : 10px 10px 10px 0px;
                        position        : relative;
                        width           : max-content;
                        position        : absolute;
                        max-width       : 170px;
                        z-index         : 2;
                        transition      : 0.3s ease-in-out;
                        padding         : 15px 20px;
                        opacity         : 0;
                        visibility      : hidden;
                        transform       : translateY(10px);

                        &::before {
                            content         : '';
                            bottom          : -9px;
                            width           : 10px;
                            height          : 10px;
                            background-color: var(--global--color-white);
                            position        : absolute;
                        }

                        &.left {
                            border-radius: 10px 10px 0 10px;

                            &::before {
                                clip-path: polygon(100% 0, 0 0, 100% 100%);
                                left     : auto;
                                right    : 0;
                            }
                        }

                        &.right {
                            border-radius: 10px 10px 10px 0px;

                            &::before {
                                clip-path: polygon(100% 0, 0 0, 0 100%);
                                ;
                                left: 0;
                            }
                        }

                        span {
                            font-family   : var(--global--font-body);
                            font-size     : 14px;
                            line-height   : 24px;
                            color         : var(--global--color-body);
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }

    .btn {
        width        : 170px;
        height       : 60px;
        border-radius: 8px 8px 0 8px;

        @media #{$maxTablet} {
            margin : 0 auto;
            display: inline-flex;
        }

        i {
            transition : 0.3s ease-in-out;
            margin-left: 10px;
        }
    }

    .donations-action {
        margin-bottom: 70px;

        @media #{$maxSmall} {
            display       : flex;
            flex-direction: column;
            align-items   : center;
        }

        .btn {
            font-family : var(--global--font-body);
            font-size   : 15px;
            font-weight : 700;
            display     : inline-flex;
            width       : 170px;
            height      : 60px;
            margin-right: 30px;

            @media #{$maxTablet} {
                margin: 0 30px 0 0;
            }

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 15px;
                height       : 50px;
            }

            &:first-child {
                i {
                    color: #213360;
                }

                &:hover {
                    i {
                        color: var(--global--color-white);
                    }
                }
            }

            &:last-child {
                border-radius: 8px 8px 8px 0;
                width        : 150px;
                margin-right : 0;
            }
        }
    }

    &.donations-2 {
        padding-top: 501px;

        @media #{$maxSmall} {
            padding-top: 450px;
        }
    }

    &.donations-3 {
        padding-top   : 110px;
        padding-bottom: 85px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }
    }
}