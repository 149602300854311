/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color      : var(--global--color-heading);
	font-family: var(--global--font-heading);
	font-weight: 700;
	margin     : 0 0 28px;
	line-height: 1;
}

.h1,
h1 {
	font-size: $font-size-h1;
}

.h2,
h2 {
	font-size: $font-size-h2;
}

.h3,
h3 {
	font-size: $font-size-h3;
}

.h4,
h4 {
	font-size: $font-size-h4;
}

.h5,
h5 {
	font-size: $font-size-h5;
}

.h6,
h6 {
	font-size: $font-size-h6;
}

p {
	font-family: var(--global--font-body);
	color      : var(--global--color-body);
	font-size  : 16px;
	font-weight: 400;
	line-height: 28px;
}

.lead {
	font-size  : 16px;
	line-height: $line-height-content;
}

/* Aligning Text */
.text--left {
	text-align: left !important;
}

.text--right {
	text-align: right !important;
}

.text--center {
	text-align: center !important;
}

.text--just {
	text-align: justify !important;
}

.align--top {
	vertical-align: top;
}

.align--bottom {
	vertical-align: bottom;
}

.align--middle {
	vertical-align: middle;
}

.align--baseline {
	vertical-align: baseline;
}

/* Weight Text */
.bold {
	font-weight: bold !important;
}

.regular {
	font-weight: normal !important;
}

.medium {
	font-weight: 500 !important;
}

.italic {
	font-style: normal;
}

.break-word {
	word-wrap: break-word;
}

.no-wrap {
	white-space: nowrap;
}

/* Text Color */
.text-white {
	color: var(--global--color-white) !important;
}

.text-gray {
	color: var(--global--color-gray) !important;
}

.text-black {
	color: var(--global--color-heading);
}

.text-theme {
	color: var(--global--color-primary) !important;
}

.text--capitalize {
	text-transform: capitalize !important;
}

.text--uppercase {
	text-transform: uppercase !important;
}

.font-heading {
	font-family: var(--global--font-heading);
}

.font-heading {
	font-family: var(--global--font-heading);
}

.font-18 {
	font-size: $font-size-h5;
}

.font-16 {
	font-size: 16px;
}

.font-20 {
	font-size: 20px;
}

.font-40 {
	font-size: 40px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) and (max-width : 767px) {
	text-center-xs {
		text-align: center !important;
	}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {
	text-center-sm {
		text-align: center !important;
	}
}

.higlighted-style1 {
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
	padding         : 5px 0;
}

.higlighted-style2 {
	background-color: var(--global--color-heading);
	color           : var(--global--color-white);
	padding         : 5px 0;
}

.higlighted-style3 {
	background-color: var(--global--color-gray);
	color           : var(--global--color-primary);
	padding         : 5px 0;
}