/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/

.bg-theme {
    background-color: var(--global--color-primary) !important;
}

.bg-gray {
    background-color: var(--global--color-gray) !important;
}

.bg-dark-gray {
    background-color: #3a3a3a;
}

.bg-dark {
    background-color: #222222 !important;
}

/* Background Image */

.bg-section {
    position           : relative;
    overflow           : hidden;
    z-index            : 1;
    background-size    : cover;
    background-repeat  : no-repeat;
    background-position: center center;
    width              : 100%;
    height             : 100%;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-overlay {
    &:before {
        content : "";
        display : inline-block;
        height  : 100%;
        left    : 0;
        position: absolute;
        top     : 0;
        width   : 100%;
        z-index : -1;
    }
}

.bg-overlay-2 {
    &:before {
        content : "";
        display : inline-block;
        height  : 100%;
        left    : 0;
        position: absolute;
        top     : 0;
        width   : 100%;
        z-index : 0;
    }
}

.bg-overlay-light {
    &:before {
        background-color: var(--global--color-white);
        opacity         : 0.8;
    }
}

.bg-overlay-dark {
    &:before {
        background-color: rgba(29, 42, 77, 0.45)
    }
}

.bg-overlay-dark2 {
    &:before {
        background-color: #1B1A1A5e;
    }
}

.bg-overlay-dark-slider {
    background-position-y: 150px;

    @media #{$maxTablet} {
        background-position-y: 100px;
    }

    &:before {
        background-image: linear-gradient(180deg, var(--global--color-heading-light) 34%, #1B1A1A 84%);
        opacity         : 0.15
    }
}

.bg-overlay-theme {
    &:before {
        background-image: linear-gradient(-90deg, #13C1D9BF 18%, var(--global--color-primary) 70%);
    }
}

.bg-overlay-theme2 {
    &:before {
        background-image: linear-gradient(0deg, rgba(6, 22, 51, 0) 0%, #1d294d 0%);
    }
}

.bg-overlay-theme3 {
    &:before {
        background: var(--global--color-heading-light)F0;
    }
}

.bg-overlay-video {
    &:before {
        background-image: linear-gradient(180deg, rgba(6, 23, 51, 0) 0%, rgba(29, 42, 77, 0) 62%, rgba(29, 42, 77, 1) 97%);
    }
}

/* Background Video */
.bg-video {
    position: relative;

    iframe {
        position  : absolute;
        top       : 0;
        left      : 0;
        right     : 0;
        object-fit: cover;
        width     : 100%;
        height    : 100%;
        transform : rotateZ(0);
    }

    h2 {
        text-transform: capitalize;
        position      : absolute;
        top           : 50%;
        left          : 50%;
        transform     : translate(-50%);
        z-index       : 5;
    }
}


.no-shadow {
    box-shadow: none !important;
}

.bg-animate {
    background-size    : cover;
    background-position: 0px 0px;
    background-repeat  : repeat-x;
    animation          : animatedBackground 30s linear infinite;
}

@-webkit-keyframes animatedBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 0;
    }
}

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 0;
    }
}

/* Elements Background */
.el-bg {
    height         : 550px;
    display        : flex;
    justify-content: center;
    align-items    : center;

    h2 {
        color: var(--global--color-white);
    }
}