/*------------------------------------*\
    #Teams
\*------------------------------------*/
.team {
    padding-top: 95px
}

/* Team Filter */
.team-filter {
    text-align   : center;
    margin-bottom: 9px;

    li {
        display: inline-block;

        &:last-child {
            a {
                margin-right: 0;
            }
        }

        a {
            color         : var(--global--color-heading);
            font-family   : var(--global--font-body);
            font-size     : 14px;
            font-weight   : 700;
            line-height   : 49px;
            margin-right  : 26px;
            text-transform: capitalize;
            transition    : 0.3s ease-in-out;

            &:hover {
                color: var(--global--color-primary);
            }
        }

        a.active-filter {
            color: var(--global--color-primary);
        }
    }

}

/* Team Member */

.team-member {
    margin-bottom: 40px;

    @media #{$maxTablet} {
        max-width   : 370px;
        margin-left : auto;
        margin-right: auto;
    }

    .team-member-holder {
        overflow: hidden;

        &:hover {
            .team-img {
                img {
                    transform: scale3d(1, 1, 1);
                }

                &::before {
                    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
                }
            }
        }
    }

    .team-img {
        position     : relative;
        overflow     : hidden;
        border-radius: 8px;

        &::before {
            position  : absolute;
            top       : 0;
            left      : 0;
            width     : 100%;
            height    : 100%;
            background: rgba(255, 255, 255, 0.5);
            content   : '';
            z-index   : 1;
            transition: transform 0.6s;
            transform : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
        }

        .link {
            position: absolute;
            top     : 0;
            left    : 0;
            right   : 0;
            width   : 100%;
            height  : 100%;
            z-index : 2;
        }

        img {
            transition: transform 0.35s;
            max-width : 100%;
            height    : auto;
            transform : scale3d(1.05, 1.05, 1);
        }

        .team-social {
            position        : absolute;
            left            : 40px;
            bottom          : 0;
            transition      : 0.3s ease-in-out;
            background-color: var(--global--color-secondary);
            z-index         : 4;
            border-radius   : 8px 8px 0 0;
            display         : flex;
            align-items     : center;
            opacity         : 0;
            visibility      : hidden;
            transform       : translateY(51px);

            @media #{$maxTablet} {
                left     : 50%;
                transform: translateX(-50%) translateY(51px);
            }

            a {
                width          : 50px;
                height         : 50px;
                display        : inline-flex;
                justify-content: center;
                align-items    : center;
                color          : var(--global--color-white);
                transition     : 0.3s ease-in-out;
                position       : relative;

                &:last-child {
                    &::after {
                        content: unset;
                    }
                }

                &::after {
                    content         : '';
                    height          : 21px;
                    width           : 2px;
                    background-color: #485880;
                    position        : absolute;
                    right           : 0;
                    top             : 50%;
                    transform       : translateY(-50%);
                }

                i {
                    transition: 0.3s ease-in-out;
                    font-size : 20px;

                    &:hover {
                        color    : var(--global--color-primary);
                        animation: bounce 1s ease-in-out;
                    }
                }
            }
        }
    }

    .team-content {
        padding         : 23px 30px 0;
        text-align      : center;
        background-color: var(--global--color-white);

        @media #{$minLarge} {
            text-align: left;
        }

        .team-title {
            h4 {
                font-family   : var(--global--font-heading);
                font-size     : 20px;
                font-weight   : 500;
                line-height   : 28px;
                margin-bottom : 3px;
                text-transform: capitalize;

                a {
                    color: var(--global--color-heading);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }
        }

        .team-cat {
            a {
                color         : var(--global--color-primary);
                font-family   : var(--global--font-body);
                font-size     : 14px;
                font-weight   : 400;
                line-height   : 28px;
                position      : relative;
                text-transform: capitalize;

                &::before {
                    content         : '';
                    height          : 1px;
                    width           : 100%;
                    background-color: var(--global--color-primary);
                    position        : absolute;
                    bottom          : -2px;
                    left            : 0;
                    opacity         : 0;
                    transition      : 0.3s ease-in-out;
                }

                &:hover {
                    color: var(--global--color-primary);

                    &::before {
                        opacity: 1;
                    }
                }

                &::after {
                    content     : ",";
                    margin-right: 5px;
                }

                &:last-of-type {
                    &::after {
                        content: none;
                    }
                }
            }
        }

        .team-desc {
            p {
                font-family  : var(--global--font-body);
                color        : var(--global--color-body);
                font-size    : 15px;
                line-height  : 26px;
                margin-bottom: 0;
            }
        }

        .team-more {
            a {
                height        : 42px;
                width         : 124px;
                display       : flex;
                align-items   : center;
                font-size     : 15px;
                font-weight   : 700;
                color         : var(--global--color-secondary);
                font-family   : var(--global--font-secondary);
                text-transform: capitalize;
                transition    : 0.3s ease-in-out;
                border-radius : 8px 8px 0 8px;
            }
        }
    }

}

/* Team Standard */
.team-standard {
    padding: 40px 0 110px;

    .team-member {
        border-radius: 8px 8px 0 8px;
        margin-bottom: 30px;
        transition   : 0.5s ease;
        box-shadow   : 0px 5px 83px 0px rgba(40, 40, 40, 0.06);

        &:hover {
            .team-social {
                opacity   : 1;
                visibility: visible;
                transform : translateY(0);

                @media #{$maxTablet} {
                    transform: translate(-50%, 0);
                }
            }

            .team-content {
                &::before {
                    width: 0%;
                }

                &::after {
                    width: calc(100% - 80px)
                }
            }
        }

        .team-img {
            border-radius: 8px 8px 0px 0px;
        }

        .team-content {
            padding-top   : 33px;
            padding-bottom: 32px;
            padding-left  : 40px;
            padding-right : 40px;
            position      : relative;

            .team-title {
                h4 {
                    font-size    : 21px;
                    margin-bottom: 4px;
                }
            }

            .team-cat {
                margin-bottom: 13px;
            }

            .team-desc {
                margin-bottom: 0px;
            }

            &::before,
            &::after {
                content   : '';
                position  : absolute;
                bottom    : 0;
                left      : 50%;
                transform : translateX(-50%);
                height    : 3px;
                transition: 0.3s ease-in-out;
            }

            &::before {
                background-color: var(--global--color-primary);
                width           : calc(100% - 80px);
            }

            &::after {
                background-color: var(--global--color-secondary);
                width           : 0;
            }
        }
    }

    .pagination {
        margin-top: 10px;
    }
}

/* Team Modern*/
.team-modern {
    padding-top   : 130px;
    padding-bottom: 110px;

    .team-member {
        box-shadow   : 0px 5px 83px 0px rgba(40, 40, 40, 0.06);
        overflow     : hidden;
        border-radius: 8px 8px 0 8px;
        margin-bottom: 30px;

        &:hover {
            .team-member-holder {
                .team-content-holder {
                    .team-content {
                        transform: translateY(0);

                        &::after {
                            width: calc(100% - 80px);
                        }
                    }
                }
            }
        }

        .team-member-holder {
            .team-img {
                border-radius: 8px 8px 0 0;
            }

            .team-content-holder {
                border-radius: 0 0 8px 8px;

                .team-content {
                    padding   : 33px 40px 40px;
                    position  : relative;
                    transform : translateY(90px);
                    margin-top: -90px;
                    transition: 0.3s ease-in-out;
                    z-index   : 5;

                    &::after {
                        content         : '';
                        position        : absolute;
                        bottom          : 0;
                        left            : 50%;
                        transform       : translateX(-50%);
                        width           : 0;
                        height          : 3px;
                        transition      : 0.3s ease-in-out;
                        transition-delay: 0.2s;
                        background-color: var(--global--color-primary);
                    }

                    .team-title {
                        h4 {
                            margin-bottom: 5px;
                        }
                    }

                    .team-cat {
                        margin-bottom: 13px;
                    }

                    .team-desc {
                        p {
                            margin-bottom: 32px;
                        }
                    }

                    .team-social {
                        transition      : 0.3s ease-in-out;
                        background-color: var(--global--color-secondary);
                        z-index         : 4;
                        border-radius   : 8px 8px 0 0;
                        display         : inline-flex;
                        align-items     : center;

                        a {
                            width          : 50px;
                            height         : 50px;
                            display        : inline-flex;
                            justify-content: center;
                            align-items    : center;
                            color          : var(--global--color-white);
                            transition     : 0.3s ease-in-out;
                            position       : relative;

                            &:last-child {
                                &::after {
                                    content: unset;
                                }
                            }

                            &::after {
                                content         : '';
                                height          : 21px;
                                width           : 2px;
                                background-color: #485880;
                                position        : absolute;
                                right           : 0;
                                top             : 50%;
                                transform       : translateY(-50%);
                            }

                            i {
                                transition: 0.3s ease-in-out;
                                font-size : 20px;

                                &:hover {
                                    color    : var(--global--color-primary);
                                    animation: bounce 1s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.team-modern-2 {
        padding-top   : 0;
        padding-bottom: 0;

        .team-member {
            margin-bottom: 0;
            box-shadow   : none;

            .team-member-holder {
                .team-content-holder {
                    .team-content {
                        transform : translateY(0);
                        margin-top: 0;

                        &::after {
                            width: calc(100% - 80px);

                            @media #{$maxTablet} {
                                content: unset;
                            }
                        }

                        @media #{$maxTablet} {
                            background-color: transparent;
                        }

                        .team-title {
                            @media #{$maxTablet} {
                                h4 {
                                    font-size: 24px;

                                    a {
                                        color: var(--global--color-white);

                                        &:hover {
                                            color: var(--global--color-secondary);
                                        }
                                    }
                                }
                            }
                        }

                        .team-cat {
                            @media #{$maxTablet} {
                                a {
                                    color: var(--global--color-white);

                                    &:hover {
                                        color: var(--global--color-secondary);

                                        &::before {
                                            background-color: var(--global--color-secondary);
                                        }
                                    }
                                }
                            }
                        }

                        .team-desc {
                            @media #{$maxTablet} {
                                p {
                                    color: var(--global--color-white);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.team-modern-3 {
        padding-top   : 0;
        padding-bottom: 0;

        .team-member {
            margin-bottom: 0;
            box-shadow   : none;

            .team-member-holder {
                .team-content-holder {
                    .team-content {
                        transform : translateY(0);
                        margin-top: 0;

                        &::after {
                            width: calc(100% - 80px);

                            @media #{$maxTablet} {
                                content: unset;
                            }
                        }

                        .team-title {
                            @media #{$maxTablet} {
                                h4 {
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Team Grid */
.team-grid {
    padding: 130px 0 110px;

    .team-member {
        border-radius: 8px 8px 0 8px;
        margin-bottom: 25px;
        transition   : 0.5s ease;

        &:hover {
            box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.06);

            .team-social {
                opacity   : 1;
                visibility: visible;
                transform : translateY(0);

                @media #{$maxTablet} {
                    transform: translate(-50%, 0);
                }
            }

            .team-content {
                .team-more {
                    a {
                        color           : var(--global--color-white);
                        background-color: var(--global--color-secondary);
                        padding         : 0 13px 0 15px;

                        .line {
                            span {
                                opacity   : 0;
                                visibility: hidden;
                            }

                            &::after {
                                opacity   : 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }

        .team-img {
            border-radius: 8px 8px 0px 0px;
        }

        .team-content {
            padding-top   : 33px;
            padding-bottom: 40px;
            padding-left  : 40px;
            padding-right : 40px;
            position      : relative;

            .team-title {
                h4 {
                    font-size    : 21px;
                    margin-bottom: 4px;
                }
            }

            .team-cat {
                margin-bottom: 13px;
            }

            .team-desc {
                margin-bottom: 18px;
            }

            .team-more {
                a {
                    @media #{$maxTablet} {
                        display: inline-flex;
                    }
                }
            }
        }
    }

    .pagination {
        margin-top: 0px;
    }

    &.team-grid-2 {
        padding-top    : 275px;
        padding-bottom : 50px;
        background     : url('../images/background/pattern.png') no-repeat;
        background-size: cover;

        @media #{$maxTablet} {
            padding-top: 170px;
        }

        .doctor-action {
            @media #{$maxTablet} {
                margin-top: 30px;
            }
        }

        .team-member {
            &:hover {
                .team-social {
                    opacity   : 0;
                    visibility: hidden;
                }

                .team-content {
                    background-color: var(--global--color-white);
                }
            }

            .team-content {
                transition      : 0.3s ease-in-out;
                background-color: transparent;
            }
        }
    }

    &.team-grid-3 {
        padding-top: 118px;
    }

    &.team-grid-4 {
        padding: 0;

        .team-member {
            @media #{$maxTablet} {
                max-width   : 370px;
                margin-left : auto;
                margin-right: auto;
            }

            .team-content {
                padding-left  : 30px;
                padding-right : 30px;
                padding-bottom: 32px;
            }
        }
    }
}

.doctor-action {
    display        : flex;
    align-items    : center;
    height         : 100%;
    justify-content: flex-end;

    @media #{$maxTablet} {
        justify-content: center;
    }

    @media #{$maxSmall} {
        flex-direction: column;
    }

    .btn {
        width        : 210px;
        height       : 60px;
        border-radius: 8px 8px 0 8px;
        margin-right : 70px;

        @media #{$maxSmall} {
            margin-right : 0;
            margin-bottom: 20px;
        }
    }

    .doctor-info {
        display    : inline-flex;
        position   : relative;
        align-items: center;

        .doctor-img {
            flex-shrink    : 0;
            width          : 50px;
            height         : 50px;
            border-radius  : 50%;
            overflow       : hidden;
            display        : flex;
            justify-content: center;
            align-items    : center;
            margin-right   : 20px;

            img {
                max-width    : 100%;
                border-radius: 50%;
            }
        }

        i {
            width           : 50px;
            height          : 50px;
            color           : var(--global--color-white);
            background-color: var(--global--color-primary);
            display         : flex;
            justify-content : center;
            align-items     : center;
            position        : absolute;
            top             : 50%;
            transform       : translateY(-50%);
            border-radius   : 50%;
            left            : -40px;
        }

        .doctor-body {
            a {
                font-family  : var(--global--font-heading);
                font-weight  : 700;
                font-size    : 19px;
                line-height  : 29px;
                color        : var(--global--color-secondary);
                margin-bottom: 0;
            }

            p {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                font-size     : 14px;
                color         : var(--global--color-body);
                margin-bottom : 0;
                text-transform: capitalize;
            }
        }
    }
}

/* Member Single */

.team-single {
    padding-top   : 107px;
    padding-bottom: 110px;

    .entry-bio {
        margin-bottom: 42px;

        @media #{$maxTablet} {
            text-align: center;
        }

        .entry-heading {
            font-family   : var(--global--font-heading);
            font-size     : 24px;
            line-height   : 1;
            font-weight   : 500;
            margin-bottom : 19px;
            color         : #0e204d;
            text-transform: capitalize;
        }

        .entry-desc {
            font-family  : var(--global--font-body);
            font-weight  : 400;
            font-size    : 16px;
            line-height  : 27px;
            color        : var(--global--color-body);
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }

            &.strong {
                font-weight: 700;
                color      : var(--global--color-heading);
            }
        }
    }

    .entry-table {
        margin-bottom: 67px;

        .table {
            height       : 1px;
            margin-bottom: 0;

            tbody {
                tr {
                    height: 100%;

                    &:nth-of-type(odd) {
                        background-color: transparent;

                        .content {
                            background-color: var(--global--color-primary-light);
                        }
                    }
                }
            }

            td {
                height          : 100%;
                border-top      : 0;
                border-bottom   : 0;
                padding         : 0;
                background-color: transparent;
                box-shadow      : none;

                .content {
                    border-radius : 8px 0 0 8px;
                    height        : 100%;
                    font-family   : var(--global--font-body);
                    font-size     : 14px;
                    font-weight   : 400;
                    color         : var(--global--color-body);
                    text-transform: capitalize;
                    padding       : 20px 30px 20px 50px;

                    @media #{$maxSmall} {
                        padding: 20px;
                    }

                    &.head {
                        border-radius: 0 8px 0 0;
                        font-weight  : 700;
                        color        : var(--global--color-heading);
                    }

                    .experties-list {
                        margin-bottom: 0;

                        li {
                            margin-bottom: 14px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            @media #{$maxSmall} {
                                display: flex;
                            }

                            i {
                                font-size   : 10px;
                                margin-right: 10px;
                                color       : var(--global--color-heading-darken);

                                @media #{$maxSmall} {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }

                &:first-child {
                    .content {
                        border-radius: 8px 0 0 8px;
                    }
                }

                &:last-child {
                    .content {
                        border-radius: 0px 8px 0 0px;
                    }
                }
            }
        }
    }

    .entry-services {
        .prices-panel {
            border       : 2px solid var(--global--color-gray-light);
            padding      : 37px 40px 27px;
            border-radius: 8px 8px 0 8px;

            h6 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                font-size     : 19px;
                line-height   : 1;
                color         : var(--global--color-heading);
                text-transform: capitalize;
            }

            ul {
                margin-bottom: 0;

                li {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    text-transform : capitalize;
                    font-size      : 15px;
                    font-weight    : 700;
                    font-family    : var(--global--font-body);
                    color          : var(--global--color-body);
                    text-transform : capitalize;
                    border-bottom  : 1px solid var(--global--color-gray-light);
                    margin-bottom  : 14px;
                    padding-bottom : 12px;

                    &:last-child {
                        @media #{$minTablet} {
                            margin-bottom: 0px;
                            border-bottom: 0;
                        }
                    }

                    .price {
                        font-family     : var(--global--font-secondary);
                        font-weight     : 700;
                        font-size       : 15px;
                        color           : var(--global--color-white);
                        background-color: var(--global--color-heading-darken);
                        padding         : 3px 10px;
                        border-radius   : 8px 8px 0 8px;
                    }
                }
            }
        }
    }

    .entry-awards {
        margin-bottom: 37px;

        .entry-desc {
            margin-bottom: 42px;
        }

        .entry-award {
            padding      : 38px 40px 36px;
            display      : flex;
            align-items  : center;
            border       : 2px solid var(--global--color-gray-light);
            border-radius: 8px 8px 0 8px;
            transition   : 0.3s ease-in-out;
            cursor       : pointer;
            margin-bottom: 30px;

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
                padding       : 28px 25px 26px;
                text-align    : center;
            }

            &:hover {
                border-color: transparent;
                box-shadow  : 0px 5px 41.5px rgba(9, 29, 62, 0.15);
            }

            svg {
                width       : 48px;
                height      : 48px;
                fill        : #354f8e;
                margin-right: 20px;

                @media#{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 20px;
                }
            }

            h5 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                color         : var(--global--color-heading);
                font-size     : 19px;
                margin-bottom : 1px;
                text-transform: capitalize;
            }

            p {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                font-size     : 14px;
                line-height   : 24px;
                margin-bottom : 0;
                text-transform: capitalize;
            }
        }
    }

    .entry-education {
        margin-bottom: 70px;

        .entry-desc {
            margin-bottom: 42px;
        }

        .education-panel {
            display: flex;

            @media #{$maxSmall} {
                flex-direction: column;
            }

            .education-year {
                margin-right: 40px;

                @media #{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 15px;
                }

                .year {
                    background-color: var(--global--color-primary);
                    padding-right   : 12px;
                    font-family     : var(--global--font-body);
                    font-size       : 14px;
                    font-weight     : 700;
                    color           : var(--global--color-white);
                    clip-path       : polygon(75% 0%, 100% 50%, 75% 100%, 0 100%, 0 50%, 0 0);
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    width           : 87px;
                    height          : 30px;
                    border-radius   : 4px;
                }
            }

            .education-body {
                position      : relative;
                padding-left  : 40px;
                padding-bottom: 36px;
                margin-top    : 3px;

                @media #{$maxSmall} {
                    padding-left: 20px;
                }

                &::before {
                    content         : '';
                    width           : 2px;
                    position        : absolute;
                    height          : calc(100% + 3px);
                    top             : 0;
                    left            : -1px;
                    background-color: #dcdee2;

                    @media #{$maxSmall} {
                        left  : 0;
                        height: calc(100% + 22px);
                        top   : -20px;
                    }
                }

                .bullet {
                    z-index : 4;
                    position: absolute;
                    top     : 0px;
                    left    : -12px;
                    ;
                    width           : 24px;
                    height          : 24px;
                    border-radius   : 50%;
                    background-color: var(--global--color-white);
                    border          : 3px solid #dcdee2;
                    display         : flex;
                    align-items     : center;
                    justify-content : center;

                    @media #{$maxSmall} {
                        display: none;
                    }

                    &::after {
                        content         : '';
                        width           : 8px;
                        height          : 8px;
                        border-radius   : 50%;
                        background-color: transparent;
                        border          : 2px solid var(--global--color-primary);
                    }
                }

                h5 {
                    font-family  : var(--global--font-heading);
                    font-weight  : 500;
                    font-size    : 19px;
                    line-height  : 28px;
                    color        : var(--global--color-heading);
                    margin-bottom: 15px;
                    transform    : translateY(-3px);
                }

                p {
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    font-size    : 15px;
                    line-height  : 26px;
                    color        : var(--global--color-body);
                    margin-bottom: 0;
                }
            }
        }
    }

    .entry-skills {
        margin-bottom: 70px;

        .entry-desc {
            margin-bottom: 40px;
        }
    }

    .entry-contact {
        .contact-panel {
            border-radius: 8px 8px 0 8px;
        }
    }

    .team-title {
        h3 {
            color        : #222222;
            font-size    : 30px;
            font-weight  : 700;
            line-height  : 1;
            margin-bottom: 30px;
        }
    }

    .team-content {
        padding-bottom: 47px;
        text-align    : left;

        p {
            color        : var(--global--color-body);
            font-family  : var(--global--font-body);
            font-size    : 16px;
            font-weight  : 400;
            line-height  : 24px;
            margin-bottom: 28px;
        }

    }

    .team-list {
        background-color: transparent;
        border-radius   : 0;
        margin-bottom   : 36px;


        li {
            font-family   : var(--global--font-body);
            font-size     : 15px;
            font-weight   : 400;
            line-height   : 33px;
            color         : #9e9e9e;
            text-transform: capitalize;



            span {
                font-family: var(--global--font-heading);
                font-size  : 14px;
                color      : #222222;
                font-weight: 700;
                width      : 40%;
                display    : inline-block;
            }
        }

        li:last-child {
            border-bottom: none;
        }
    }

    .team-share {
        span {
            color         : #222222;
            font-size     : 13px;
            font-weight   : 700;
            line-height   : 30px;
            text-transform: capitalize;
            display       : inline-block;
            width         : 40%;
        }

        a {
            color       : #333333;
            font-size   : 17px;
            line-height : 1;
            margin-right: 3px;
            display     : inline-block;
            position    : relative;
            overflow    : hidden;
            width       : 25px;
            height      : 25px;

            &:last-of-type {
                margin-right: 0;
            }

            &:hover {
                i {
                    &:first-child {
                        top: -101%;
                    }

                    &:last-child {
                        top: 2px;
                    }
                }
            }

            i {
                &:first-child {
                    position  : absolute;
                    top       : 2px;
                    left      : 0;
                    width     : 25px;
                    height    : 25px;
                    transition: 300ms ease-in-out;
                }

                &:last-child {
                    position  : absolute;
                    top       : 103%;
                    left      : 0;
                    width     : 25px;
                    height    : 25px;
                    color     : var(--global--color-primary);
                    transition: 300ms ease-in-out;
                }
            }
        }


    }

    &.team-single-standard {
        padding-top: 0;
    }
}

/* Team Carousel */
.team-carousel {
    position      : relative;
    padding-bottom: 0px;
    overflow      : visible;
    padding-top   : 116px;

    @media#{$maxTablet} {
        padding-top: 70px;
    }

    &::before {
        content         : '';
        position        : absolute;
        top             : 0;
        left            : 50%;
        transform       : translateX(-50%);
        width           : calc(100% - 120px);
        height          : 100%;
        background-color: #e5f7fa;
        border-radius   : 32px 32px 0px 32px;

        @media (min-width:992px) and (max-width: 1200px) {
            width: calc(100% - 30px);
        }

        @media #{$maxTablet} {
            width: calc(100% - 60px);
        }

        @media#{$maxSmall} {
            width: calc(100% - 20px);
        }
    }

    .owl-carousel {
        .owl-stage-outer {
            overflow: visible;

            .owl-item {
                opacity   : 0;
                visibility: hidden;
                transition: 0.3s ease-in-out;

                &.active {
                    opacity   : 1;
                    visibility: visible;
                }
            }
        }

        .owl-nav {
            display        : flex;
            justify-content: center;

            .owl-next,
            .owl-prev {
                &::before {
                    @media #{$maxTablet} {
                        font-size: 25px;
                    }
                }
            }
        }
    }

    .action-bar {
        margin-top     : 7px;
        padding-bottom : 70px;
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        position       : relative;

        @media #{$maxTablet} {
            margin-top: 40px;
        }

        .note {
            margin-bottom: 0;
            font-weight  : 700;
            line-height  : 24px;
            color        : #85849e;

            @media #{$maxTablet} {
                text-align : center;
                line-height: 30px;
            }

            span {
                text-transform: capitalize;
                color         : var(--global--color-heading-darken);

                i {
                    margin-right: 5px;
                }
            }
        }

        .btn {
            border-radius: 8px 8px 0 8px;
            width        : 210px;
            height       : 60px;
            position     : absolute;
            right        : 0;
            bottom       : -30px;

            @media #{$maxTablet} {
                right    : 50%;
                transform: translateX(50%);
            }
        }
    }
}

/* Team Projects */
.team-projects {
    padding-top   : 0;
    padding-bottom: 0;

    .project {
        position: relative;
        overflow: hidden;

        &:hover {
            img {
                opacity  : 1;
                transform: translate3d(0, 0, 0);
            }
        }

        a {
            position: absolute;
            top     : 0;
            left    : 0;
            right   : 0;
            width   : 100%;
            height  : 100%;
            z-index : 2;
        }

        img {
            display   : inline-flex;
            margin    : 0 auto;
            max-width : none;
            width     : calc(100% + 50px);
            opacity   : 0.9;
            transition: opacity 0.35s, transform 0.35s;
            transform : translate3d(-40px, 0, 0);

            @media #{$maxSmall} {
                max-width: 100%;
                transform: translate3d(0px, 0, 0);
            }
        }
    }
}

/* Team Gallery */
.team-gallery {
    padding-top   : 110px;
    padding-bottom: 110px;

    .btn {
        margin-top         : 20px;
        display            : inline-flex;
        width              : 170px;
        height             : 60px;
        border-radius      : 8px 8px 8px 0;
        border-color       : #687596;
        border             : 2px solid;
        transition-property: background, color, border-color;

        &::before {
            background-color: var(--global--color-secondary);
            width           : calc(100% + 4px);
            height          : calc(100% + 4px);
            top             : -2px;
            left            : -2px;
        }

        &:hover {
            border-color: #213360;
        }
    }

    .team-member {
        margin-bottom: 30px;

        .team-member-holder {
            position: relative;

            &:hover {
                .team-hover {
                    opacity   : 1;
                    visibility: visible;
                    transform : scale(1);
                }
            }

            .team-img {
                border-radius: 8px 8px 8px 0;

                &::before {
                    content: unset;
                }
            }

            .team-hover {
                position       : absolute;
                top            : 0;
                left           : 0;
                right          : 0;
                width          : 100%;
                height         : 100%;
                z-index        : 2;
                display        : flex;
                justify-content: center;
                align-items    : center;
                background     : linear-gradient(180deg, rgba(19, 189, 213, 0) 0%, #1d294dc7 0%);
                opacity        : 0;
                visibility     : hidden;
                transform      : scale(0);
                transition     : 0.4s ease-in-out;

                .team-zoom {
                    color    : var(--global--color-white);
                    font-size: 20px;
                }

                a {
                    position: absolute;
                    top     : 0;
                    left    : 0;
                    right   : 0;
                    width   : 100%;
                    height  : 100%;
                    z-index : 2;
                }
            }
        }
    }
}


@include keyframes(bounce) {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) and (max-width: 767px) {

    .team-filter li a {
        margin-right: 10px;
        font-size   : 13px;
    }

    .team-member .team--img img,
    .team .owl-carousel .owl-item img {
        width: 100%;
    }

    .team-grid,
    .team-masonry {
        .team-member-holder {
            max-width   : 370px;
            margin-right: auto;
            margin-left : auto;
        }
    }

    .team-slider {
        .carousel-navs {
            .owl-nav {

                .owl-next:before,
                .owl-prev:before {
                    font-size: 30px;
                }

            }
        }

    }

}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-masonry {
        .team--img {
            img {
                width: 100%;
            }
        }
    }
}