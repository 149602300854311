/* Small Devices, Tablets */
@media only screen and (max-width: 1198.98px) {
    .header {
        .navbar {
            padding: 0 40px;

            .navbar-brand {
                margin-right: 0;
                height      : 80px;
                line-height : 80px;

                .logo-light {
                    display: none;
                }

                .logo-phone {
                    width: auto;
                }
            }

            .navbar-collapse {
                position        : absolute;
                left            : 0;
                top             : 100%;
                width           : 100%;
                background-color: var(--global--color-white);
                padding         : 15px 30px;
                opacity         : 0;
                visibility      : hidden;
                transform       : translateY(20px);
                transition      : 0.3s linear;

                &.show {
                    visibility: visible;
                    opacity   : 1;
                    transform : translateY(0px);
                }
            }

            &.navbar-bordered {
                .navbar-collapse {
                    top: calc(100% + 1px);
                }
            }
        }
    }

    /* Navabar */
    .header {
        .navbar {
            .navbar-nav {
                >li {
                    margin-right: 0px;

                    >a,
                    &.active>a {
                        line-height: 36px;
                        color      : var(--global--color-heading);
                        display    : block;
                        z-index    : 5;

                        &::before,
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .menu-item::before {
                display: none;
            }

            .has-dropdown {
                >.dropdown-menu {
                    transition: 0.3s ease-in-out;
                }

                &::after {
                    font-family     : "Font Awesome 5 Free";
                    font-weight     : 700;
                    content         : "\f107";
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    font-size       : 14px;
                    position        : absolute;
                    right           : 0;
                    top             : 5px;
                    color           : var(--global--color-heading);
                    background-color: var(--global--color-primary-light);
                    width           : 25px;
                    height          : 25px;
                    transition      : 0.3s ease-in-out;
                }

                &:hover,
                &.active {
                    &::after {
                        color: var(--global--color-primary);
                    }
                }

                &.show {
                    &::after {
                        content: '\f106';
                    }
                }
            }

            .dropdown-menu {
                &.show {
                    border-top   : 1px solid rgba(0, 0, 0, 0.1);
                    padding-top  : 10px;
                    border-radius: 0;
                }

                li {
                    line-height: 26px;

                    &.current {
                        a {
                            background-color: var(--global--color-primary-light);
                            color           : var(--global--color-primary);
                        }
                    }

                    a {
                        margin-left   : 20px;
                        color         : var(--global--color-heading);
                        font-size     : 15px;
                        font-weight   : 500;
                        display       : block;
                        padding       : 4px 15px;
                        margin-bottom : 5px;
                        text-transform: capitalize;
                        border-radius : 8px 8px 0 8px;

                        &:hover {
                            background-color: var(--global--color-primary-light);
                            color           : var(--global--color-primary);
                        }
                    }
                }
            }

            .show {
                >.dropdown-menu {
                    display: block;
                }
            }

            .dropdown-submenu {
                &.show {
                    .dropdown-menu {
                        display: block;
                    }
                }

                .dropdown-menu {
                    padding-left: 20px;
                }
            }

            /* Mega Menu Content */
            li.has-dropdown {
                &.mega-dropdown {
                    >.dropdown-menu {
                        .row {
                            .menu-head {
                                font-size     : 14px;
                                font-family   : var(--global--font-heading);
                                font-weight   : 700;
                                color         : var(--global--font-heading);
                                text-transform: capitalize;
                                margin-bottom : 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Header Modifers */

    .navbar.navbar-light .navbar-nav>li>a {
        color: var(--global--color-heading);
    }

    .navbar-light:not(.navbar-fixed) {
        .navbar-toggler {
            color       : var(--global--color-white);
            border-color: var(--global--color-white);
        }

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,225, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }

    .navbar-dark,
    .navbar-light.navbar-fixed {
        .navbar-toggler {
            color       : #222;
            border-color: #222;
        }

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(34,34,34, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }
}