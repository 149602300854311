/*------------------------------------*\
    #ALERT MESSAGE
\*------------------------------------*/
.alerts {
    background-color: var(--global--color-gray);
    border-radius   : 2px;
    padding         : 20px;
    margin-bottom   : 30px;

    .alert-icon {
        width           : 46px;
        height          : 46px;
        line-height     : 46px;
        background-color: var(--global--color-primary);
        border-radius   : 2px 1px 1px 2px;
        text-align      : center;
        position        : absolute;

        i {
            color      : var(--global--color-white);
            font-size  : 15px;
            font-weight: 400;
            line-height: 28px;
        }
    }

    .alert-content {
        padding-left: 68px;

        h4 {
            color         : var(--global--color-primary);
            font-size     : 15px;
            font-weight   : 800;
            line-height   : 32px;
            text-transform: capitalize;
            margin-bottom : 0;
            margin-top    : -6px;
        }

        p {
            font-family  : var(--global--font-body);
            color        : var(--global--color-body);
            font-size    : 13px;
            font-weight  : 400;
            line-height  : 22px;
            margin-bottom: 0;
        }
    }

    &.alert-warning {
        h4 {
            color: #8a6d3b;
        }

        .alert-icon {
            background-color: #fcf8e3;

            i {
                color: #8a6d3b;
            }
        }
    }

    &.alert-danger {
        h4 {
            color: #a94442;
        }

        .alert-icon {
            background-color: #f2dede;

            i {
                color: #a94442;
            }
        }
    }
}

.alert {
    font-size  : 16px;
    font-family: var(--global--font-body);
}

/* Subscribe Alert */
.subscribe-alert {
    position : fixed;
    top      : auto;
    right    : 0;
    bottom   : 0;
    left     : 0;
    z-index  : 99999;
    margin   : 0;
    cursor   : pointer;
    transform: translate3d(0, 200%, 0);
    animation: alertfadein .35s ease .5s both;

    .alert {
        margin       : 0 auto;
        font-size    : 18px;
        height       : 60px;
        line-height  : 60px;
        border       : none;
        border-radius: 0;
        padding      : 0 30px;
        color        : var(--global--color-white);

        &:after {
            position        : absolute;
            top             : 0;
            right           : 0;
            bottom          : 0;
            display         : flex;
            align-items     : center;
            justify-content : center;
            width           : 60px;
            background-color: rgba(0, 0, 0, .1);
            font-weight     : 600;
            font-size       : 18px;
            cursor          : pointer;
            transition      : background-color .25s ease;
            content         : "\f00d";
            font-family     : $fontawesome;
        }

        &.alert-danger {
            background-color: #cc0000;
        }

        &.alert-success {
            background-color: #198c19;
        }
    }
}

@keyframes alertfadein {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    100% {
        transform: none;
    }
}

/* Contact Alert */
#contact-result {
    .alert {
        margin   : 10px auto;
        font-size: 12px;
    }
}