/*------------------------------------*\
    #Processes
\*------------------------------------*/
.processes {
    .process-panel {
        padding     : 56px 50px 54px;
        border-top  : 1px solid var(--global--color-gray-light);
        border-right: 1px solid var(--global--color-gray-light);
        position    : relative;
        transition  : 0.3s ease-in-out;

        &:hover {
            box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);

            &::before {
                width: 100%;
            }

            .process-body {
                .process-icon {
                    i {
                        &::before {
                            color: var(--global--color-primary);
                        }
                    }
                }

                .process-content {
                    a {
                        color: var(--global--color-primary);

                        .line {
                            span {
                                opacity   : 0;
                                visibility: hidden;
                            }

                            &::after {
                                opacity   : 1;
                                visibility: visible;
                                color     : var(--global--color-primary);
                            }
                        }
                    }
                }
            }
        }

        &::before {
            content         : '';
            position        : absolute;
            top             : 0;
            left            : 50%;
            transform       : translateX(-50%);
            height          : 4px;
            width           : 0;
            transition      : 0.3s ease-in-out;
            background-color: var(--global--color-primary);
        }

        .process-number {
            font-family  : var(--global--font-heading);
            font-size    : 24px;
            font-weight  : 400;
            line-height  : 1;
            font-style   : italic;
            color        : var(--global--color-gray-light);
            margin-bottom: 49px;

            @media #{$maxSmall} {
                text-align   : center;
                margin-bottom: 36px;
            }
        }

        .process-body {
            display: flex;

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
                text-align    : center;
            }

            .process-icon {
                margin-right: 30px;

                @media #{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 25px;
                }

                i {
                    &::before {
                        display    : flex;
                        transform  : translateY(-3px);
                        font-size  : 47px;
                        margin-left: 0;
                        transition : 0.3s ease-in-out;
                        color      : rgb(53, 79, 142);
                    }
                }
            }

            .process-content {
                h5 {
                    font-weight   : 500;
                    font-size     : 19px;
                    color         : var(--global--color-heading);
                    line-height   : 28px;
                    margin-bottom : 16px;
                    text-transform: capitalize;
                }

                p {
                    font-size    : 14px;
                    line-height  : 24px;
                    margin-bottom: 27px;
                }

                a {
                    display       : flex;
                    font-family   : var(--global--font-secondary);
                    font-weight   : 700;
                    font-size     : 15px;
                    text-transform: capitalize;
                    color         : var(--global--color-secondary);

                    @media #{$maxSmall} {
                        justify-content: center;
                    }
                }
            }
        }
    }
}