/*------------------------------------*\
    #BREADCRUMB
\*------------------------------------*/
/* Breadcrumb Style 1 */
.breadcrumb {
    background-color: transparent;
    margin-bottom   : 0;
    padding         : 0;
    border-radius   : 0;
    color           : #e5e4e4;
    font-size       : 14px;
    font-weight     : 400;
    line-height     : 1;
    text-transform  : capitalize;
    display         : block;

    >li {
        display: inline-block;

        +li:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 700;
            padding    : 0 10px;
            color      : var(--global--color-gray);
            content    : "\f054";
        }

        a {
            color: var(--global--color-gray);

            &:hover {
                color: var(--global--color-primary);
            }
        }
    }

    >.active {
        color: var(--global--color-heading-darken);
    }
}