/*------------------------------------*\
    #Video
\*------------------------------------*/
.video {
    position     : relative;
    border-radius: 4px;

    @media screen and (min-width:992px) {
        height: 528px;
    }

    @media screen and (max-width:991.98px) {
        height: 400px;
    }

    .player {
        position : absolute;
        top      : 50%;
        left     : 50%;
        transform: translate(-50%, -50%);
        z-index  : 600;

        a {
            display         : flex;
            justify-content : center;
            align-items     : center;
            width           : 66px;
            height          : 66px;
            border-radius   : 50%;
            background-color: var(--global--color-white);
            color           : var(--global--color-primary);
            transition      : 300ms ease-in-out;
            cursor          : pointer;

            &::before {
                content      : '';
                height       : 100%;
                width        : 100%;
                position     : absolute;
                top          : 0;
                left         : 0;
                right        : 0;
                border       : 3px solid rgba(255, 255, 255, 0.5);
                border-radius: 100%;
                transform    : scale(1);
                opacity      : 1;
                animation    : pulse 1s cubic-bezier(0, 0.51, 0.45, 1.49) 0s infinite;
            }

            i {
                z-index   : 5;
                color     : var(--global--color-primary);
                font-size : 15px;
                transition: 300ms ease-in-out;
            }
        }
    }

    &.video-1 {
        height       : 880px;
        overflow     : visible;
        border-radius: 32px 0 0 0;
        margin-top   : -98px;
        transform    : translateY(110px);

        @media #{$maxTablet} {
            height: 500px;
        }
    }

    &.video-2 {
        position     : relative;
        border-radius: 8px 8px 0 8px;
        margin-bottom: 43px;

        .btn-video {
            position        : absolute;
            bottom          : 37px;
            left            : 40px;
            background-color: transparent;
            height          : auto;
            padding         : 0;
        }
    }

    &.video-3 {
        margin-bottom: 65px;
    }

    &.video-4 {
        height       : 880px;
        border-radius: 0px 32px 0px 0;

        @media #{$maxTablet} {
            height   : 500px;
            max-width: 90%;
            margin   : 0 auto 50px;
        }

        .btn-video {
            width   : 90px;
            height  : 90px;
            position: absolute;
            bottom  : 70px;
            right   : 70px;

            @media #{$maxSmall} {
                width : 70px;
                height: 70px;
            }
        }
    }
}

.mfp-bg {
    z-index: 1543;
}

.mfp-wrap {
    z-index: 1642;
}

@include keyframes(pulse) {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.4);
        opacity  : 0;
    }
}


/* Custom, iPhone Retina */