/*--------------------------------
    #Pricing
---------------------------------*/
.pricing {
    padding-top      : 0;
    padding-bottom   : 98px;
    background-image : url('../images/background/pattern.png');
    background-repeat: no-repeat;
    background-size  : cover;
    overflow         : visible;

    .pricing-holder {
        position             : relative;
        border-radius        : 8px;
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        transform            : translateY(-130px);
        margin-bottom        : -130px;
        z-index              : 20;

        @media #{$maxTablet} {
            grid-template-columns: 1fr;
            grid-row-gap         : 40px;
        }

        &::after {
            content         : '';
            position        : absolute;
            top             : 0;
            left            : 50%;
            transform       : translateX(-50%);
            background-color: var(--global--color-primary);
            height          : 2px;
            width           : calc(100% - 100px);

            @media #{$maxTablet} {
                content: unset;
            }
        }
    }

    .price-table {
        display       : flex;
        flex-direction: column;

        @media #{$maxTablet} {
            max-width: 370px;
            margin   : 0 auto;
        }

        &:first-child {
            .pricing-panel {
                .pricing-price {
                    p {
                        &::before {
                            @media #{$minLarge} {
                                width: calc(100% + 50px);
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(2) {
            .pricing-panel {
                .pricing-price {
                    p {
                        &::before {
                            @media #{$minLarge} {
                                left     : 50%;
                                transform: translateX(-50%);
                                width    : calc(100% + 100px);
                            }
                        }
                    }
                }
            }
        }

        &:last-child {
            .pricing-panel {
                .pricing-price {
                    p {
                        &::before {
                            @media #{$minLarge} {
                                left : auto;
                                right: 0;
                                width: calc(100% + 50px);
                            }
                        }
                    }
                }
            }
        }

        .pricing-panel {
            background-color: var(--global--color-white);
            padding         : 44px 50px 50px;
            overflow        : hidden;
            box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
            display         : flex;
            flex-direction  : column;
            justify-content : space-between;
            flex-grow       : 1;

            @media #{$maxTablet} {
                text-align: center;
            }

            @media #{$maxSmall} {
                padding-left : 25px;
                padding-right: 25px;
            }

            &.active {
                .pricing-price {
                    .btn {
                        background-color: var(--global--color-primary);
                        border-color    : var(--global--color-primary);

                        &::before {
                            background-color: var(--global--color-secondary);
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            border-color: var(--global--color-secondary);
                        }
                    }
                }
            }

            .pricing-body {
                .pricing-heading {
                    margin-bottom: 55px;

                    .pricing-title {
                        font-family   : var(--global--font-body);
                        font-size     : 16px;
                        font-weight   : 700px;
                        line-height   : 26px;
                        color         : var(--global--color-primary);
                        margin-bottom : 16px;
                        position      : relative;
                        text-transform: capitalize;
                        transition    : 300ms ease-in-out;
                    }

                    .pricing-desc {
                        font-family  : var(--global--font-body);
                        font-size    : 14px;
                        color        : var(--global--color-body);
                        line-height  : 24px;
                        margin-bottom: 0px;
                    }
                }

                .pricing-list {
                    li {
                        font-family  : var(--global--font-body);
                        font-size    : 15px;
                        font-weight  : 700;
                        line-height  : 36px;
                        margin-bottom: 0px;
                        color        : var(--global--color-body);
                        display      : flex;
                        align-items  : center;

                        i {
                            display         : inline-flex;
                            align-items     : center;
                            justify-content : center;
                            width           : 22px;
                            height          : 22px;
                            border-radius   : 50%;
                            background-color: var(--global--color-heading-darken);
                            color           : var(--global--color-white);
                            font-size       : 10px;
                            margin-right    : 15px;
                        }
                    }
                }
            }

            .pricing-price {
                p {
                    //border-top: 2px solid var(--global--color-gray-light);
                    padding-top  : 44px;
                    margin-bottom: 29px;
                    font-family  : var(--global--font-heading);
                    font-weight  : 400;
                    color        : var(--global--color-heading-darken);
                    display      : flex;
                    align-items  : baseline;
                    line-height  : 30px;
                    position     : relative;

                    &::before {
                        content         : '';
                        position        : absolute;
                        top             : 0;
                        left            : 0;
                        height          : 2px;
                        width           : 100%;
                        background-color: var(--global--color-gray-light);
                    }

                    @media screen and(max-width:991.98px) {
                        justify-content: center;
                    }

                    span {
                        &::before {
                            content: '/';
                        }

                        &:first-of-type {
                            &::before {
                                content: unset;
                            }
                        }
                    }

                    .currency {
                        font-size: 50px;
                    }

                    .time {
                        font-size: 14px;
                    }
                }

                .btn {
                    background-color: var(--global--color-secondary);
                    border-radius   : 8px 8px 0 8px;
                    height          : 60px;

                    &::before {
                        background-color: var(--global--color-primary);
                    }
                }
            }
        }
    }

    .more-prices {
        text-align: center;
        margin-top: 50px;

        @media #{$minLarge} {
            margin-top: 32px;
        }

        p {
            font-family  : var(--global--font-body);
            font-weight  : 700;
            font-size    : 14px;
            color        : var(--global--color-body);
            margin-bottom: 0;

            a {
                position      : relative;
                color         : var(--global--color-primary);
                transition    : 0.3s ease-in-out;
                text-transform: capitalize;
                padding-bottom: 8px;

                &:hover {
                    &::before {
                        width: 70%;
                    }
                }

                &::before {
                    content         : '';
                    transition      : 0.3s ease-in-out;
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    width           : 100%;
                    height          : 2px;
                    background-color: var(--global--color-primary);
                }
            }
        }
    }
}