/*------------------------------------*\
    #Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
    margin-bottom: $baseline;
}

/* All margin */
.m-0 {
    margin: 0 !important;
}

.m-xs {
    margin: ($baseline/2);
}

.m-sm {
    margin: $baseline;
}

.m-md {
    margin: $baseline * 2;
}

.m-lg {
    margin: $baseline * 4;
}

/* top margin */
.mt-0 {
    margin-top: 0;
}

.mt-xs {
    margin-top: ($baseline/2);
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mt-150 {
    margin-top: 150px;
}

/* bottom margin */
.mb-0 {
    margin-bottom: 0 !important;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 50px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mb-120 {
    margin-bottom: 120px !important;
}

.mb-150 {
    margin-bottom: 150px !important;
}

/* right margin */
.mr-0 {
    margin-right: 0;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-50 {
    margin-right: 50px;
}

.mr-60 {
    margin-right: 60px;
}

.mr-150 {
    margin-right: 150px;
}

/* left margin */
.ml-0 {
    margin-left: 0;
}

.ml-xs {
    margin-left: ($baseline/2);
}

.ml-sm {
    margin-left: $baseline;
}

.ml-md {
    margin-left: $baseline * 2;
}

.ml-lg {
    margin-left: $baseline * 4;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-50 {
    margin-left: 50px;
}

.ml-60 {
    margin-left: 60px;
}

.ml-150 {
    margin-left: 150px;
}

/* All padding */
.p-0 {
    padding: 0 !important;
}

.p-xs {
    padding: ($baseline/2);
}

.p-sm {
    padding: $baseline;
}

.p-md {
    padding: $baseline * 2;
}

.p-lg {
    padding: $baseline * 4;
}

/* top padding */
.pt-0 {
    padding-top: 0 !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pt-130 {
    padding-top: 130px !important;
}

.pt-150 {
    padding-top: 150px !important;
}

.pt-180 {
    padding-top: 180px !important;
}

/* bottom padding */
.pb-0 {
    padding-bottom: 0 !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-85 {
    padding-bottom: 85px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.pb-130 {
    padding-bottom: 130px !important;
}

.pb-150 {
    padding-bottom: 150px !important;
}

/* right padding */
.pr-0 {
    padding-right: 0;
}

.pr-xs {
    padding-right: ($baseline/2);
}

.pr-sm {
    padding-right: $baseline;
}

.pr-md {
    padding-right: $baseline * 2;
}

.pr-lg {
    padding-right: $baseline * 4;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-50 {
    padding-right: 50px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-100 {
    padding-right: 100px !important;
}

.pr-150 {
    padding-right: 150px;
}

/* left padding */
.pl-0 {
    padding-left: 0;
}

.pl-30 {
    padding-left: 30px;
}

.pl-50 {
    padding-left: 50px;
}

.pl-60 {
    padding-left: 60px;
}

.pl-70 {
    padding-left: 70px;
}

.pl-100 {
    padding-left: 100px !important;
}

.pl-150 {
    padding-left: 150px;
}

/* Postions */
.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.static {
    position: static;
}

/* Zindex*/
.zindex-1 {
    z-index: 1;
}

.zindex-2 {
    z-index: 2;
}

.zindex-3 {
    z-index: 3;
}

/* Borders */
.border-all {
    border: 1px solid var(--global--color-primary);
}

.border-top {
    border-top: 1px solid var(--global--color-primary);
}

.border-bottom {
    border-bottom: 1px solid var(--global--color-primary);
}

.border-right {
    border-right: 1px solid var(--global--color-primary);
}

.border-left {
    border-left: 1px solid var(--global--color-primary);
}

/* Display */
.inline {
    display: inline;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.hide {
    display: none;
}

.flex {
    display: flex;
}

@media only screen and (max-width: 767px) {

    .text-center-xs {
        text-align: center !important;
    }

    .pull-none-xs {
        float     : none !important;
        text-align: center !important;
    }

    .mb-15-xs {
        margin-bottom: 15px;
    }

    .mb-30-xs {
        margin-bottom: 30px !important;
    }

    .mb-40-xs {
        margin-bottom: 40px !important;
    }

    .mb-50-xs {
        margin-bottom: 50px;
    }

    .mb-60-xs {
        margin-bottom: 60px !important;
    }

    .p-none-xs {
        padding-right: 0;
        padding-left : 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .text-center-sm {
        text-align: center !important;
    }

    .mb-0-sm {
        margin-bottom: 0;
    }

    .mb-15-sm {
        margin-bottom: 15px;
    }

    .mb-30-sm {
        margin-bottom: 30px !important;
    }

    .mb-40-sm {
        margin-bottom: 40px;
    }

    .mb-50-sm {
        margin-bottom: 50px;
    }

    .mb-60-sm {
        margin-bottom: 60px;
    }

    .pb-15-sm {
        padding-bottom: 15px;
    }

    .pb-30-sm {
        padding-bottom: 30px;
    }

    .pb-50-sm {
        padding-bottom: 50px;
    }

    .pb-60-sm {
        padding-bottom: 60px;
    }

    .p-none-sm {
        padding-right: 0;
        padding-left : 0;
    }

    .pull-none-sm {
        float     : none !important;
        text-align: center !important;
    }
}

.border-b {
    border-bottom: 1px solid var(--global--color-gray);
}

.mt--80 {
    margin-top: -80px;
}

.p-0 {
    padding: 0;
}

.pt-200 {
    padding-top: 200px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-55 {
    padding-top: 55px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-145 {
    padding-top: 145px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-210 {
    padding-top: 210px;
}

.pt-225 {
    padding-top: 225px;
}

.pt-240 {
    padding-top: 240px;
}

.pt-250 {
    padding-top: 250px;
}

.pt-260 {
    padding-top: 260px;
}

.pt-265 {
    padding-top: 265px;
}

.pt-300 {
    padding-top: 300px;
}

.pt-355 {
    padding-top: 355px;
}

.pt-370 {
    padding-top: 370px;
}

.pt-380 {
    padding-top: 380px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pb-200 {
    padding-bottom: 200px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-35 {
    margin-top: 35px !important;
}

.mb-32 {
    margin-bottom: 32px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mt--7 {
    margin-top: -7px;
}

.overflow-visible {
    overflow: visible;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px !important;
}

.ml-20 {
    margin-left: 20px !important;
}