/*------------------------------------*\
    #Features
\*------------------------------------*/
.features {
    padding-top   : 163px;
    padding-bottom: 100px;

    @media #{$maxSmall} {
        padding-top   : 200px;
        padding-bottom: 70px;
    }

    @media (max-width:424px) {
        padding-top: 150px;
    }

    .features-holder {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap      : 25px;

        @media #{$maxTablet} {
            grid-template-columns: 1fr 1fr;
        }

        @media #{$maxSmall} {
            grid-template-columns: 1fr;
        }
    }

    .feature-panel-holder {
        padding-top: 45px;
        transition : 0.3s ease-in-out;

        &:hover {
            padding-top: 0;

            .feature-panel {
                background-color: var(--global--color-white);

                .feature-icon {
                    i {
                        &::before {
                            color: var(--global--color-primary);
                        }
                    }
                }

                .feature-content {
                    h4 {
                        color: var(--global--color-heading);
                    }

                    p {
                        visibility: visible;
                        opacity   : 1;
                    }
                }

                a {
                    border-color: var(--global--color-primary);
                    margin-top  : 12px;
                    color       : var(--global--color-primary);
                }

                &.feature-panel-dark {
                    background-color: #354f8e;

                    .feature-icon {
                        i {
                            &::before {
                                color: var(--global--color-white);
                            }
                        }
                    }

                    .feature-content {
                        h4 {
                            color: var(--global--color-white);
                        }
                    }

                    a {
                        border-color: var(--global--color-primary);
                        margin-top  : 12px;
                        color       : var(--global--color-primary);
                    }
                }
            }
        }

        .feature-panel {
            position      : relative;
            padding       : 23px 30px 40px;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            text-align    : center;
            overflow      : hidden;
            cursor        : pointer;
            margin-bottom : 33px;
            border-radius : 8px 0px 8px 8px;
            border        : 1px solid #87dfeb;
            transition    : 0.3s ease-in-out;

            .feature-icon {
                margin-bottom: 5px;

                i {
                    &::before {
                        color      : var(--global--color-white);
                        margin-left: 0;
                        font-size  : 70px;
                        transition : 0.3s ease-in-out;
                    }
                }
            }

            .feature-content {
                display       : flex;
                flex-direction: column;
                align-items   : center;
                text-align    : center;

                h4 {
                    font-family   : var(--global--font-heading);
                    font-weight   : 500;
                    font-size     : 18px;
                    line-height   : 28px;
                    text-transform: capitalize;
                    margin-bottom : 6px;
                    color         : var(--global--color-white);
                    transition    : 0.3s ease-in-out;
                }

                p {
                    text-align   : center;
                    font-family  : var(--global--font-body);
                    font-size    : 14px;
                    line-height  : 24px;
                    margin-bottom: 0px;
                    color        : var(--global--color-body);
                    opacity      : 0;
                    visibility   : hidden;
                    transition   : 0.3s ease-in-out;
                }
            }

            a {
                display        : flex;
                justify-content: center;
                align-items    : center;
                width          : 30px;
                height         : 30px;
                border-radius  : 50%;
                border         : 2px solid rgba(var(--global--color-white), 0.5);
                font-size      : 13px;
                color          : var(--global--color-white);
                margin-top     : -32px;
                transition     : 0.3s ease-in-out;

                @media #{$maxTablet} {
                    margin-top: -12px;
                }
            }

            &.feature-panel-dark {
                border-color: rgba(249, 249, 249, 0.15);

                .feature-icon {
                    i {
                        &::before {
                            color: rgb(120, 143, 200);
                        }
                    }
                }

                .feature-content {
                    p {
                        color: var(--global--color-gray);
                    }
                }

                a {
                    border-color: #545e79;
                }
            }
        }
    }

    .actions-holder {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            flex-direction: column;
        }

        .btn {
            margin-right: 30px;

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 20px;
            }
        }

        .employee-info {
            display: inline-flex;

            .employee-img {
                width          : 55px;
                height         : 55px;
                padding        : 3px;
                border         : 3px solid var(--global--color-primary);
                border-radius  : 50%;
                overflow       : hidden;
                display        : flex;
                justify-content: center;
                align-items    : center;
                margin-right   : 15px;

                img {
                    max-width    : 100%;
                    border-radius: 50%;
                }
            }

            .employee-body {
                h6 {
                    font-family  : var(--global--font-heading);
                    font-weight  : 700;
                    font-size    : 14px;
                    line-height  : 28px;
                    color        : #222222;
                    margin-bottom: 0;
                }

                p {
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    font-size    : 13px;
                    color        : var(--global--color-body);
                    margin-bottom: 0;
                }
            }
        }
    }

    .signature-block {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        .signature-body {
            position: relative;

            img {
                position: absolute;
                top     : -18px;
                left    : 2px;
                right   : 0;

                @media#{$maxSmall} {
                    top      : 0;
                    left     : -20px;
                    max-width: 130%;
                }
            }

            h6 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                color         : var(--global--color-white);
                font-size     : 19px;
                line-height   : 29px;
                text-transform: capitalize;
                margin-bottom : 0;
            }

            p {
                font-family   : var(--global--font-body);
                font-weight   : 400;
                color         : var(--global--color-gray);
                font-size     : 14px;
                line-height   : 26px;
                text-transform: capitalize;
                margin-bottom : 0;
            }
        }

        .btn {
            border-radius: 8px 8px 0 8px;
            margin-right : 40px;
            width        : 190px;
            height       : 60px;
        }
    }

    .more-features {
        text-align: center;

        p {
            font-family  : var(--global--font-body);
            font-weight  : 700;
            font-size    : 14px;
            color        : #f4f4f4;
            margin-bottom: 0;

            a {
                position      : relative;
                color         : var(--global--color-secondary);
                transition    : 0.3s ease-in-out;
                text-transform: capitalize;
                padding-bottom: 8px;

                &:hover {
                    &::before {
                        width: 70%;
                    }
                }

                &::before {
                    content         : '';
                    transition      : 0.3s ease-in-out;
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    width           : 100%;
                    height          : 2px;
                    background-color: var(--global--color-secondary);
                }
            }
        }

        &.more-features-2 {
            margin-bottom: 80px;

            p {
                a {
                    color: var(--global--color-primary);

                    &::before {
                        background-color: var(--global--color-primary);
                    }
                }
            }
        }
    }

    .features-card {
        display   : flex;
        transform : translateY(100px);
        margin-top: -100px;

        @media #{$minLarge} {
            justify-content: space-between;
        }

        @media #{$maxTablet} {
            flex-direction: column;
        }

        .card-panel {
            display      : flex;
            background   : var(--global--color-white);
            border-radius: 8px 0px 8px 8px;
            padding      : 30px 40px 34px;
            box-shadow   : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
                padding-left  : 25px;
                padding-right : 25px;
            }

            @media #{$maxTablet} {
                margin-bottom: 40px;
            }

            @media #{$minLarge} {
                flex-basis: 48%;
            }

            position: relative;

            &:hover {
                .panel-icon {
                    i {
                        &::before {
                            transform: translateY(-15px);
                        }
                    }
                }
            }

            &::after {
                content         : '';
                position        : absolute;
                bottom          : 0;
                left            : 50%;
                transform       : translateX(-50%);
                width           : calc(100% - 80px);
                background-color: var(--global--color-heading-darken);
                height          : 1px;

                @media #{$maxSmall} {
                    width: calc(100% - 50px);
                }
            }

            &.reversed {
                flex-direction: row-reverse;

                @media #{$maxSmall} {
                    flex-direction: column;
                }

                &::after {
                    background-color: var(--global--color-primary);
                }

                .panel-icon {
                    margin-right: 0;
                    margin-left : 40px;

                    @media #{$maxSmall} {
                        margin-left  : 0;
                        margin-bottom: 25px;
                    }

                    i {
                        &::before {
                            color: var(--global--color-primary);
                        }
                    }
                }

                .panel-body {
                    text-align: right;

                    @media #{$maxSmall} {
                        text-align: center;
                    }

                    .btn {
                        color: var(--global--color-primary);

                        &:hover {
                            color: var(--global--color-heading-darken);
                        }

                        .line {
                            span {
                                background-color: var(--global--color-primary);
                            }

                            &::after {
                                color: var(--global--color-heading-darken);
                            }
                        }
                    }
                }
            }

            .panel-icon {
                margin-right: 40px;

                @media #{$minTablet} {
                    transform: translateY(-7px);
                }

                @media #{$maxSmall} {
                    margin-bottom: 25px;
                    margin-right : 0;
                }

                i {
                    &::before {
                        margin-left: 0;
                        color      : rgb(67, 91, 161);
                        font-size  : 65px;
                        display    : inline-flex;
                        transition : 0.3s ease-in-out;
                    }
                }
            }

            .panel-body {
                @media #{$maxSmall} {
                    text-align: center;
                }

                h4 {
                    font-size     : 18px;
                    line-height   : 32px;
                    font-weight   : 500;
                    color         : var(--global--color-heading);
                    margin-bottom : 15px;
                    text-transform: capitalize;
                }

                p {
                    font-size    : 15px;
                    line-height  : 25px;
                    color        : var(--global--color-body);
                    margin-bottom: 16px;
                }

                .btn {
                    width           : auto;
                    height          : auto;
                    display         : inline-flex;
                    color           : var(--global--color-heading-darken);
                    border          : 0;
                    background-color: transparent;

                    &:hover {
                        color: var(--global--color-primary);
                    }

                    .line {
                        span {
                            background-color: var(--global--color-heading-darken);
                        }

                        &::after {
                            color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.features-2 {
        padding-top   : 130px;
        padding-bottom: 0;
        overflow      : visible;

        @media#{$maxTablet} {
            padding-top: 70px;
        }

        .signature-block {
            .line {
                &::after {
                    color: var(--global--color-heading);
                }
            }
        }
    }

    &.features-3 {
        padding-top   : 295px;
        padding-bottom: 102px;
        overflow      : visible;

        @media #{$maxTablet} {
            padding-top   : 240px;
            padding-bottom: 70px;
        }

        .feature-panel-holder {
            .feature-panel {
                .feature-icon {
                    margin-bottom: 23px;
                    width        : 65px;
                    height       : 65px;

                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        .more-features-2 {
            margin-bottom: 0;
        }
    }

    &.features-4 {
        padding-bottom: 0;
        overflow      : visible;

        @media (min-width:992px) and (max-width:1280px) {
            padding-bottom: 90px;
        }

        @media #{$maxTablet} {
            padding-top: 70px;
        }

        .features-holder {
            display: block;

            @media #{$maxTablet} {
                max-width     : 90%;
                text-align    : center;
                margin        : 0 auto;
                padding-bottom: 40px;
            }
        }

        .features-wrapper {
            @media #{$minLarge} {
                display              : grid;
                grid-template-columns: 45% 50%;
                grid-column-gap      : 5%;
                transform            : translateY(110px);
                margin-top           : -110px;
            }
        }

        .feature-panel-holder {
            .feature-panel {
                margin-bottom: 43px;

                @media #{$maxTablet} {
                    margin-bottom: 20px;
                }
            }
        }

        .owl-dots {
            @media #{$minLarge} {
                right : 102px;
                bottom: -23px;
            }

            @media #{$maxTablet} {
                margin-top   : 20px;
                margin-bottom: 30px;
            }

            .owl-dot {
                display: inline-flex;

                &.active {
                    span {
                        &::before {
                            background-color: rgba(var(--global--color-white), 0.3);
                        }
                    }
                }

                span {
                    background-color: var(--global--color-white);
                }
            }
        }

        .more-features {
            @media #{$minLarge} {
                text-align: left;
            }

            p {
                a {
                    color: var(--global--color-white);

                    &::before {
                        background-color: var(--global--color-white);
                    }
                }
            }
        }
    }
}

.features-bar {
    padding-top   : 0;
    padding-bottom: 170px;
    overflow      : visible;

    @media#{$maxTablet} {
        padding-bottom: 100px;
    }

    .features-holder {
        position     : relative;
        z-index      : 55;
        transform    : translateY(-90px);
        margin-bottom: -90px;

        @media #{$minLarge} {
            box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
        }

        .feature-panel {
            padding: 28px 40px 40px;

            @media #{$maxTablet} {
                max-width: 370px;
                margin   : 0 auto;
            }

            .feature-content {
                position: relative;
                z-index : 3;

                @media #{$maxTablet} {
                    text-align: center;
                }

                i {
                    display      : block;
                    margin-bottom: 11px;

                    &:before {
                        font-size  : 50px;
                        color      : var(--global--color-white);
                        margin-left: 0;
                    }
                }

                h5 {
                    text-transform: capitalize;
                    font-family   : var(--global--font-heading);
                    font-weight   : 500;
                    font-size     : 19px;
                    line-height   : 28px;
                    color         : var(--global--color-white);
                    margin-bottom : 16px;
                }

                p {
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    font-size    : 14px;
                    line-height  : 24px;
                    color        : #f4f4f4;
                    margin-bottom: 21px;
                }

            }

            &.feature-reservation {
                background-color: var(--global--color-primary);

                @media #{$minLarge} {
                    border-radius: 8px 0 0 8px;
                }

                a {
                    font-family: var(--global--font-heading);
                    font-weight: 400;
                    color      : var(--global--color-white);
                    font-size  : 24px;
                    display    : flex;
                    align-items: center;
                    transition : 0.3s ease-in-out;

                    @media #{$maxTablet} {
                        justify-content: center;
                    }

                    span {
                        transition      : 0.3s ease-in-out;
                        display         : inline-flex;
                        flex-shrink     : 0;
                        justify-content : center;
                        align-items     : center;
                        width           : 42px;
                        height          : 42px;
                        background-color: #1aadcd;
                        color           : var(--global--color-white);
                        border-radius   : 8px 8px 0 8px;
                        font-size       : 16px;
                        margin-right    : 10px;
                    }
                }
            }

            &.feature-timetable {
                background-color: var(--global--color-heading-darken);

                .btn {
                    width        : 156px;
                    height       : 42px;
                    border-radius: 8px 8px 0 8px;
                    border-color : rgba(255, 255, 255, 0.25);

                    @media #{$maxTablet} {
                        display: inline-flex;
                    }

                    &:hover {
                        border-color: var(--global--color-primary);
                    }
                }
            }

            &.feature-opening-hours {
                background-color: #354f8e;
                padding-bottom  : 36px;

                h5 {
                    margin-bottom: 8px;
                }

                ul {
                    margin-bottom: 0;

                    li {
                        display        : flex;
                        align-items    : center;
                        justify-content: space-between;
                        font-size      : 14px;
                        font-weight    : 400;
                        font-family    : var(--global--font-body);
                        color          : var(--global--color-white);
                        text-transform : capitalize;
                        border-bottom  : 1px solid rgba(var(--global--color-white), 0.25);
                        padding        : 9px 0;

                        &:last-child {
                            border-bottom : 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }

            &.feature-location {
                padding-bottom  : 45px;
                background-color: var(--global--color-secondary);

                @media #{$minLarge} {
                    border-radius: 0 8px 0 0;
                }

                p {
                    margin-bottom: 11px;
                }

                .map-img {
                    position: relative;

                    a {
                        position: absolute;
                        top     : 0;
                        left    : 0;
                        right   : 0;
                        width   : 100%;
                        height  : 100%;
                        z-index : 1;
                    }

                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .actions-holder {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            flex-direction: column;
        }

        .btn {
            width       : 170px;
            height      : 60px;
            margin-right: 30px;

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 20px;
            }

            &:last-child {
                border-color       : #687696;
                border             : 2px solid;
                transition-property: background, color, border-color;

                &::before {
                    background-color: var(--global--color-primary);
                    width           : calc(100% + 4px);
                    height          : calc(100% + 4px);
                    top             : -2px;
                    left            : -2px;
                }

                &:hover {
                    border-color: var(--global--color-secondary);
                }
            }
        }
    }
}




/* Small Devices, Tablets */
@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 767px) {}






/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .feature-panel {
        max-width   : 370px;
        margin-right: auto;
        margin-left : auto;
    }
}

/* Custom, iPhone Retina */

@media only screen and (min-width: 768px) and (max-width: 991px) {}

/* Custom, iPhone Retina */

@media only screen and (min-width: 992px) and (max-width: 1200px) {}