/*------------------------------------*\
    #Blog Section
\*------------------------------------*/
.blog {
    padding: 127px 0 70px;
}

.blog-entry {
    margin-top      : 40px;
    margin-bottom   : 40px;
    position        : relative;
    box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
    border-radius   : 8px 8px 8px 0;
    background-color: var(--global--color-white);

    @media #{$maxTablet} {
        max-width: 370px;
        margin   : 40px auto 40px;
    }

    &:hover {
        .entry-img {
            img {
                transform: scale3d(1, 1, 1);
            }

            &::before {
                transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
            }
        }
    }

    .entry-img {
        position     : relative;
        overflow     : hidden;
        margin-right : 40px;
        border-radius: 8px 8px 8px 0px;
        transform    : translateY(-40px);
        margin-bottom: -40px;

        &::before {
            position  : absolute;
            top       : 0;
            left      : 0;
            width     : 100%;
            height    : 100%;
            background: rgba(255, 255, 255, 0.5);
            content   : '';
            z-index   : 1;
            transition: transform 0.6s;
            transform : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
        }

        img {
            transition: transform 0.35s;
            max-width : 100%;
            height    : auto;
            transform : scale3d(1.05, 1.05, 1);
        }

        .entry-date {
            position        : absolute;
            z-index         : 2;
            top             : 30px;
            right           : 30px;
            padding         : 18px 0;
            background-color: var(--global--color-white);
            box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
            border-radius   : 8px 8px 8px 0px;
            text-align      : center;

            .entry-content {
                color         : var(--global--color-heading-darken);
                font-family   : var(--global--font-body);
                padding       : 0 15px;
                position      : relative;
                display       : inline-flex;
                flex-direction: column;

                &::before {
                    content         : '';
                    position        : absolute;
                    height          : 100%;
                    width           : 2px;
                    top             : 0;
                    left            : 0;
                    background-color: var(--global--color-primary);
                }

                .day {
                    font-weight  : 700;
                    font-size    : 26px;
                    line-height  : 22px;
                    margin-bottom: 4px;
                }

                .year,
                .month {
                    text-transform: capitalize;
                    font-weight   : 400;
                    font-size     : 13px;
                    line-height   : 16px;
                }
            }
        }
    }

    .entry-content {
        padding : 22px 40px 40px;
        position: relative;

        @media #{$maxSmall} {
            padding-left : 25px;
            padding-right: 25px;
        }
    }

    .entry-meta {
        display      : flex;
        align-items  : center;
        margin-bottom: 15px;

        .entry-category {
            margin-bottom : 0px;
            text-transform: capitalize;

            a {
                position   : relative;
                color      : var(--global--color-primary);
                font-family: var(--global--font-body);
                font-size  : 13px;
                font-weight: 400;
                line-height: 26px;

                &::after {
                    content : ", ";
                    position: relative;
                }

                &:last-of-type {
                    &::after {
                        content: "";
                    }
                }

                &:hover {
                    color: var(--global--color-heading);
                }
            }
        }

        .divider {
            width           : 5px;
            height          : 5px;
            background-color: rgb(222, 223, 227);
            border-radius   : 50%;
            margin          : 0 10px;
        }

        .entry-author {
            text-transform: capitalize;

            p {
                font-family  : var(--global--font-body);
                font-size    : 13px;
                font-weight  : 400;
                line-height  : 26px;
                color        : var(--global--color-heading-darken);
                margin-bottom: 0;
            }
        }

        .entry-comments {
            text-transform: capitalize;
            font-family   : var(--global--font-body);
            font-size     : 13px;
            font-weight   : 400;
            line-height   : 26px;
            color         : var(--global--color-heading-darken);

            .num {
                color: var(--global--color-body);
            }
        }
    }

    .entry-title {
        margin-bottom: 27px;

        h4 {
            font-size     : 20px;
            font-weight   : 500;
            line-height   : 28px;
            margin-bottom : 0;
            text-transform: capitalize;
        }

        a {
            color: var(--global--color-heading);

            &:hover {
                color: var(--global--color-primary);
            }
        }
    }

    .entry-bio {
        p {
            font-family  : var(--global--font-body);
            font-size    : 14px;
            font-weight  : 400;
            line-height  : 24px;
            margin-bottom: 32px;
        }
    }

    .entry-more {
        a {
            width              : 124px;
            height             : 42px;
            position           : relative;
            transition         : all 300ms ease-in-out;
            color              : var(--global--color-secondary);
            border             : 2px solid;
            border-color       : #687696;
            transition-property: background, color, border-color;

            &::before {
                background-color: var(--global--color-secondary);
                width           : calc(100% + 4px);
                height          : calc(100% + 4px);
                top             : -2px;
                left            : -2px;
            }

            &:hover {
                color       : var(--global--color-white);
                border-color: var(--global--color-secondary);
            }
        }
    }
}

/* Blog Grid */
.blog-grid {
    padding: 70px 0 110px;

    .owl-carousel {
        .owl-stage-outer {
            overflow: visible;

            .owl-item {
                opacity: 0;

                &.active {
                    opacity: 1;
                }
            }
        }

        .owl-dots {
            position       : relative;
            right          : auto;
            bottom         : auto;
            justify-content: center;

            @media #{$maxTablet} {
                margin-top: 0;
            }

            .owl-dot {
                display: inline-flex;
            }
        }
    }

    &.blog-grid-2 {
        padding-top: 510px;
    }

    &.blog-grid-3 {
        padding-top: 130px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }
    }

    &.blog-grid-4 {
        padding-top: 218px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }
    }
}

/* Blog Standard */
.blog-standard {
    padding-top: 0;

    .blog-entry {
        margin-bottom: 80px;
    }
}

/* Blog Single */
.blog-single {
    padding-top   : 0px;
    padding-bottom: 110px;

    .blog-entry {
        box-shadow: none;
        margin-top: 0;

        @media #{$maxTablet} {
            max-width: initial;
        }

        &:last-of-type {
            margin-bottom: 0px;
        }

        .entry-img {
            margin-bottom: 0;
            transform    : translateY(0);
            margin-right : 0;

            &::before {
                content: unset;
            }

            .entry-date {
                right: auto;
                left : 40px;
            }
        }

        .entry-meta {
            margin-bottom: 21px;

            @media #{$maxSmall} {
                flex-direction: row;

                div {
                    margin-right: 5px;
                }
            }

            .divider {
                @media #{$maxSmall} {
                    display: none;
                }
            }

            .entry-author {
                a {
                    p {
                        transition: 0.3s ease-in-out;
                    }

                    &:hover {
                        p {
                            color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }

    .entry-holder {
        padding-top    : 9px;
        padding-bottom : 40px;
        border-bottom  : 1px solid var(--global--color-gray-light);
        display        : flex;
        justify-content: space-between;
        align-items    : center;

        @media #{$maxSmall} {
            flex-direction: column;
        }

        .entry-share {
            display    : flex;
            align-items: center;
            flex-wrap  : nowrap;

            @media #{$maxTablet} {
                flex-wrap: wrap;
            }

            span {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                font-size     : 15px;
                color         : var(--global--color-heading);
                margin-right  : 20px;
                text-transform: capitalize;
            }

            a {
                color           : var(--global--color-white);
                background-color: var(--global--color-heading);
                border-radius   : 8px 8px 0 8px;
                margin-right    : 15px;
                transition      : 0.3s ease-in-out;
                width           : 50px;
                height          : 30px;
                display         : flex;
                justify-content : center;
                align-items     : center;
                transition      : 0.3s ease-in-out;

                @media #{$minLarge} {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    background-color: var(--global--color-primary);
                }

                &.facebook-bg {
                    background-color: #3b5999;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.twitter-bg {
                    background-color: #55acee;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.instagram-bg {
                    background-color: #0092ff;

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }
            }
        }

        .entry-tags {
            @media #{$maxSmall} {
                margin-bottom: 30px;
            }

            a {
                margin-right    : 10px;
                display         : inline-block;
                font-family     : var(--global--font-body);
                font-size       : 12px;
                font-weight     : 400;
                line-height     : 28px;
                text-transform  : capitalize;
                transition      : 0.3s ease-in-out;
                color           : var(--global--color-primary);
                background-color: var(--global--color-white);
                border          : 1px solid var(--global--color-gray-light);
                border-radius   : 8px 8px 0 8px;
                padding         : 0 9px;

                &:hover {
                    background-color: var(--global--color-primary);
                    color           : var(--global--color-white);
                    border-color    : var(--global--color-primary);
                }
            }
        }
    }

    .entry-content {
        padding: 32px 40px 34px;

        @media #{$maxTablet} {
            padding: 32px 0;
        }

        .entry-title {
            margin-bottom: 31px;

            h4 {
                font-size  : 40px;
                line-height: 50px;
                font-weight: 700;

                @media #{$maxSmall} {
                    font-size  : 32px;
                    line-height: 1.4;
                }
            }
        }

        .entry-bio {
            p {
                font-size    : 16px;
                line-height  : 27px;
                margin-bottom: 27px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .nav-posts {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding-top    : 30px;
        padding-bottom : 30px;
        text-transform : capitalize;
        border-bottom  : 1px solid var(--global--color-gray-light);

        @media screen and (max-width: 991.98px) {
            flex-direction : column;
            justify-content: center;
        }

        .prev-post,
        .next-post {
            display         : flex;
            border-radius   : 8px 8px 8px 0;
            padding         : 20px;
            background-color: var(--global--color-white);
            transition      : 0.3s ease-in-out;

            &:hover {
                background-color: var(--global--color-primary-light);

                .post-img {
                    .overlay {
                        background-color: var(--global--color-primary);
                        transform       : scale(1);
                    }
                }
            }

            .post-img {
                flex-basis   : 90px / 67px;
                flex-shrink  : 0;
                width        : 90px;
                height       : 67px;
                border-radius: 8px 8px 8px 0;
                margin-right : 20px;
                position     : relative;
                overflow     : hidden;

                .overlay {
                    content         : '\e901';
                    transition      : 0.3s ease-in-out;
                    position        : absolute;
                    top             : 0;
                    left            : 0;
                    right           : 0;
                    width           : 100%;
                    height          : 100%;
                    font-family     : 'icomoon';
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    background-color: rgba(var(--global--color-primary), 0.9);
                    color           : var(--global--color-white);
                    transition      : 0.3s ease-in-out;
                    transform       : scale(0);
                }

                img {
                    width : 100%;
                    height: 1005;
                }
            }

            .post-body {
                span {
                    display    : block;
                    font-family: var(--global--font-body);
                    font-size  : 13px;
                    font-weight: 400;
                    line-height: 16px;
                    color      : var(--global--color-body);
                }

                .post-link {
                    font-family: var(--global--font-heading);
                    font-weight: 500;
                    font-size  : 16px;
                    line-height: 22px;
                    color      : var(--global--color-heading);
                    transition : 300ms ease-in-out;

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            @media #{$minLarge} {
                flex-basis: 50%;
            }
        }

        .prev-post {
            .post-img {
                .overlay {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            @media #{$maxTablet} {
                margin-bottom: 38px;
            }
        }

        .next-post {
            text-align: right;

            .post-img {
                margin-right: 0;
                margin-left : 20px;
            }
        }
    }

    .entry-widget {
        padding-top: 62px;

        .entry-widget-title {
            position     : relative;
            margin-bottom: 21px;

            h4 {
                font-family   : var(--global--font-heading);
                font-size     : 21px;
                font-weight   : 500;
                line-height   : 30px;
                margin-bottom : 0;
                position      : relative;
                text-transform: capitalize;
                color         : var(--global--color-heading);
            }
        }
    }

    .entry-widget-bio {
        margin-top      : 40px;
        padding         : 40px 40px 35px;
        border-radius   : 8px 8px 8px 0;
        background-color: var(--global--color-primary-light);
        position        : relative;

        @media #{$maxSmall} {
            padding-right: 20px;
            padding-left : 20px;
        }

        &::before {
            content         : '';
            position        : absolute;
            bottom          : 0;
            left            : 50%;
            transform       : translateX(-50%);
            height          : 3px;
            width           : calc(100% - 80px);
            background-color: var(--global--color-primary);

            @media #{$maxSmall} {
                width: calc(100% - 40px);
            }
        }

        .entry-widget-content {
            position: relative;
            display : flex;

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
            }

            img {
                width        : 70px;
                height       : 70px;
                border-radius: 8px 8px 8px 0;
                margin-right : 30px;

                @media #{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 25px;
                }
            }

            .entry-bio-desc {
                @media #{$maxSmall} {
                    text-align: center;
                }

                h4 {
                    font-family  : var(--global--font-heading);
                    color        : var(--global--color-heading);
                    font-size    : 18px;
                    font-weight  : 500;
                    line-height  : 30px;
                    margin-bottom: 10px;
                }

                p {
                    color        : var(--global--color-body);
                    font-size    : 15px;
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    line-height  : 23px;
                    margin-bottom: 22px;
                }

                a {
                    color       : var(--global--color-secondary);
                    font-size   : 16px;
                    font-weight : 400;
                    line-height : 1;
                    margin-right: 18px;
                    transition  : 0.3s ease-in-out;

                    &:hover {
                        color: var(--global--color-primary)
                    }
                }

                a:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .entry-comments {
        .entry-widget-content {
            .comments-list {
                width        : 100%;
                list-style   : none;
                overflow     : hidden;
                padding-left : 0;
                margin-bottom: 0;

                .comment-body {
                    position     : relative;
                    margin-bottom: 30px;
                    display      : flex;

                    @media #{$maxSmall} {
                        flex-direction: column;
                    }

                    &:last-child {
                        border-bottom : none;
                        padding-bottom: 0;
                        margin-bottom : 0;

                        .comment {
                            border-bottom: none;
                        }

                        .replies-list {
                            margin-top : 25px;
                            padding-top: 30px;
                            border-top : 2px solid var(--global--color-gray-light);
                        }
                    }

                    .replies-list {
                        margin-top : 25px;
                        padding-top: 30px;
                        border-top : 2px solid var(--global--color-gray-light);

                        li {
                            position     : relative;
                            margin-bottom: 30px;

                            &:last-child {
                                border-bottom : none;
                                padding-bottom: 0;
                                margin-bottom : 0;

                                .comment {
                                    border-bottom: none;
                                }
                            }
                        }

                        .comment-body {
                            .comment {
                                border-bottom: 2px solid var(--global--color-gray-light);
                            }

                            &:last-child {
                                .comment {
                                    border: 0;
                                }
                            }
                        }
                    }

                    .avatar {
                        flex-shrink  : 0;
                        width        : 60px;
                        height       : 60px;
                        overflow     : hidden;
                        display      : flex;
                        border-radius: 8px 8px 8px 0;

                        @media #{$maxSmall} {
                            margin-left  : 20px;
                            margin-bottom: 30px;
                        }
                    }

                    .comment {
                        margin-left   : 30px;
                        padding-bottom: 10px;
                        border-bottom : 2px solid var(--global--color-gray-light);

                        @media #{$maxSmall} {
                            margin-left: 20px;
                        }

                        h6 {
                            font-family   : var(--global--font-heading);
                            color         : var(--global--color-heading);
                            font-size     : 15px;
                            font-weight   : 500;
                            text-transform: capitalize;
                            margin-bottom : 5px;
                            line-height   : 16px;
                        }

                        .date {
                            color        : var(--global--color-heading-darken);
                            font-family  : var(--global--font-body);
                            font-size    : 12px;
                            font-weight  : 400;
                            line-height  : 24px;
                            margin-bottom: 6px;
                        }

                        p {
                            font-family  : var(--global--font-body);
                            color        : var(--global--color-body);
                            font-size    : 15px;
                            font-weight  : 400;
                            line-height  : 24px;
                            margin-bottom: 3px;
                        }

                        a.reply {
                            font-family   : var(--global--font-body);
                            font-size     : 14px;
                            font-weight   : 700;
                            text-transform: capitalize;
                            color         : var(--global--color-primary);
                            line-height   : 20px;
                            display       : inline-flex;
                            align-items   : center;
                            transition    : 0.3s ease-in-out;
                            position      : relative;

                            &:before {
                                content         : '';
                                display         : inline-block;
                                width           : 12px;
                                height          : 2px;
                                background-color: var(--global--color-primary);
                                margin-right    : 10px;
                                margin-bottom   : 2px;
                                transition      : 0.3s ease-in-out;
                            }

                            &:hover {
                                color: var(--global--color-heading);

                                &::before {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .entry-add-comment {
        padding-top: 36px;

        .form-control {
            margin-bottom: 30px;
        }

        .custom-radio-group {
            .custom-control {
                margin-bottom: 20px;
            }
        }

        .btn {
            font-size       : 15px;
            font-family     : var(--global--font-body);
            font-weight     : 700;
            text-transform  : capitalize;
            width           : 270px;
            height          : 70px;
            border-radius   : 0px 8px 8px 8px;
            background-color: var(--global--color-secondary);

            &::before {
                background-color: var(--global--color-heading-darken);
            }

            &:active,
            &:focus,
            &:hover {
                border-color: var(--global--color-heading-darken);
                color       : var(--global--color-white);
            }
        }
    }

}