/*------------------------------------*\
    #Departments
\*------------------------------------*/
.departments {
    padding-top   : 130px;
    padding-bottom: 50px;

    @media #{$maxTablet} {
        padding-top: 70px;
    }

    .department-panel {
        margin-bottom   : 80px;
        box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.08);
        background-color: var(--global--color-white);
        transition      : 0.3s ease-in-out;
        border-radius   : 8px 0 8px 8px;

        @media #{$maxTablet} {
            max-width: 370px;
            margin   : 0 auto 80px;
        }

        &:hover {
            box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);

            .department-panel-container {
                .department-img {
                    img {
                        transform: scale3d(1, 1, 1);
                    }

                    &::before {
                        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
                    }
                }

                .department-content {
                    .department-more {
                        a {
                            background-color: var(--global--color-primary);
                            border-color    : var(--global--color-primary);
                            color           : var(--global--color-white);

                            .line {
                                span {
                                    opacity   : 0;
                                    visibility: hidden;
                                }

                                &::after {
                                    opacity   : 1;
                                    visibility: visible;
                                    color     : var(--global--color-white);
                                }
                            }
                        }
                    }
                }
            }
        }

        .department-panel-holder {
            overflow: visible;
            position: relative;
            padding : 172px 40px 40px;

            @media #{$maxSmall} {
                padding: 172px 30px 40px;
            }

            @media (min-width:1440px) {
                padding-top: 200px;
            }

            .department-img {
                position     : relative;
                overflow     : hidden;
                border-radius: 8px 0 8px 8px;
                position     : absolute;
                top          : -40px;
                left         : 50%;
                transform    : translateX(-50%);
                width        : calc(100% - 80px);

                @media #{$maxSmall} {
                    width: calc(100% - 60px);
                }

                a {
                    position: absolute;
                    top     : 0;
                    left    : 0;
                    right   : 0;
                    width   : 100%;
                    height  : 100%;
                    z-index : 2;
                }

                img {
                    transition: transform 0.35s;
                    max-width : 100%;
                    height    : auto;
                    transform : scale3d(1.05, 1.05, 1);
                }

                &::before {
                    position  : absolute;
                    top       : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 100%;
                    background: rgba(255, 255, 255, 0.5);
                    content   : '';
                    z-index   : 1;
                    transition: transform 0.6s;
                    transform : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
                }
            }

            .department-content {
                text-align: center;

                @media #{$minLarge} {
                    text-align: left;
                }

                .department-title {
                    h4 {
                        font-family   : var(--global--font-heading);
                        font-size     : 23px;
                        font-weight   : 500;
                        line-height   : 32px;
                        margin-bottom : 16px;
                        text-transform: capitalize;

                        a {
                            color: var(--global--color-heading);

                            &:hover {
                                color: var(--global--color-primary);
                            }
                        }
                    }

                }

                .department-list {
                    margin-bottom: 23px;

                    li {
                        display    : flex;
                        align-items: center;

                        &:last-child {
                            span {
                                border-bottom: 0;
                            }
                        }

                        i {
                            font-size    : 10px;
                            color        : var(--global--color-heading-darken);
                            margin-bottom: 5px;
                            margin-right : 15px;
                        }

                        span {
                            flex-grow     : 1;
                            padding-top   : 8px;
                            padding-bottom: 11px;
                            text-transform: capitalize;
                            font-size     : 15px;
                            font-family   : var(--global--font-body);
                            font-weight   : 700;
                            color         : var(--global--color-body);
                            border-bottom : 1px solid var(--global--color-gray-light);
                            text-align    : left;
                        }
                    }
                }

                .department-desc {
                    margin-bottom: 19px;

                    p {
                        font-family  : var(--global--font-body);
                        color        : var(--global--color-body);
                        font-size    : 15px;
                        font-weight  : 400;
                        line-height  : 25px;
                        margin-bottom: 0;
                    }
                }

                .department-more {
                    @media #{$maxTablet} {
                        display: inline-flex;
                    }

                    .btn {
                        font-size          : 15px;
                        font-weight        : 700;
                        border             : 2px solid;
                        border-color       : #687596;
                        font-family        : var(--global--font-secondary);
                        text-transform     : capitalize;
                        transition         : 0.3s ease-in-out;
                        width              : 124px;
                        border-radius      : 8px 8px 0 8px;
                        height             : 42px;
                        transition-property: background, color, border-color;

                        &::before {
                            background-color: var(--global--color-primary);
                            width           : calc(100% + 4px);
                            height          : calc(100% + 4px);
                            top             : -2px;
                            left            : -2px;
                        }

                        &:active,
                        &:focus,
                        &:hover {
                            color       : var(--global--color-white);
                            border-color: var(--global--color-primary);
                        }
                    }
                }
            }
        }

    }

    .departments-action {
        @media #{$maxTablet} {
            text-align: center;
        }

        p {
            @media #{$minLarge} {
                margin-bottom: 0;
            }
        }
    }

    &.departments-2 {
        padding-top   : 116px;
        padding-bottom: 98px;

        @media#{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }

        .department-panel {
            margin-bottom: 44px;

            @media #{$maxTablet} {
                margin: 0 auto 80px;
            }
        }
    }
}

.single-department {
    padding-top   : 127px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .sidebar-department {
        margin-top: 3px
    }

    .entry-infos {
        .entry-desc {
            &.strong {
                font-weight  : 700;
                color        : var(--global--color-secondary);
                margin-bottom: 25px;
            }
        }

        &.entry-infos-2 {
            .entry-desc {
                &:last-child {
                    margin-bottom: 42px;
                }
            }
        }
    }

    .entry-introduction {
        margin-bottom: 60px;

        @media #{$maxTablet} {
            text-align: left;
        }

        .entry-desc {
            &:first-of-type {
                margin-bottom: 25px;
            }

            &:last-of-type {
                margin-bottom: 25px;
            }
        }
    }

    .services-price {
        margin-bottom: 68px;

        .entry-desc {
            margin-bottom: 42px;
        }

        .prices-list {
            padding         : 37px 40px 28px;
            border-radius   : 8px 8px 0 8px;
            background-color: #f6f6f6;

            @media #{$maxTablet} {
                margin-bottom: 30px
            }

            @media #{$maxSmall} {
                padding-right: 25px;
                padding-left : 25px;
            }

            h6 {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                font-size     : 19px;
                line-height   : 1;
                margin-bottom : 26px;
                color         : var(--global--color-heading);
                text-transform: capitalize;
            }

            ul {
                margin-bottom: 0;

                li {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    text-transform : capitalize;
                    font-size      : 15px;
                    font-weight    : 700;
                    font-family    : var(--global--font-body);
                    color          : var(--global--color-body);
                    text-transform : capitalize;
                    border-bottom  : 1px solid var(--global--color-gray-light);
                    margin-bottom  : 14px;
                    padding-bottom : 12px;

                    &:last-child {
                        @media #{$minTablet} {
                            margin-bottom: 0px;
                            border-bottom: 0;
                        }
                    }

                    .price {
                        font-family     : var(--global--font-secondary);
                        font-weight     : 700;
                        font-size       : 15px;
                        color           : var(--global--color-white);
                        background-color: var(--global--color-heading-darken);
                        padding         : 3px 10px;
                        border-radius   : 8px 8px 0 8px;
                    }
                }
            }

            &.prices-list-2 {
                ul {
                    li {
                        .price {
                            background-color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }

    .doctors-prief {
        .team-grid {
            .team-member {
                .team-content {
                    padding-bottom: 20px;
                }
            }
        }
    }

    .entry-contact {
        .contact-panel {
            border-radius: 8px 8px 0 8px;
        }
    }
}