/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
.cta {
    overflow      : visible;
    padding-top   : 147px;
    padding-bottom: 0;

    .icon-set {
        display        : flex;
        justify-content: space-around;
        margin-top     : 47px;

        @media #{$maxTablet} {
            margin-bottom: 30px;
        }

        .icon-panel {
            display       : flex;
            flex-direction: column;
            align-items   : center;

            @media #{$minLarge} {
                align-items: flex-start;
                margin-left: 50px;
            }

            .icon {
                margin-bottom: 20px;

                &::before {
                    color      : var(--global--color-white);
                    font-size  : 70px;
                    margin-left: 0;
                }
            }

            span {
                font-family   : var(--global--font-heading);
                font-weight   : 500;
                font-size     : 18px;
                line-height   : 27px;
                color         : var(--global--color-white);
                text-transform: capitalize;
                text-align    : center;

                @media #{$minLarge} {
                    text-align: left;
                }
            }
        }

        &.icon-set2 {
            margin-top     : 44px;
            margin-bottom  : 37px;
            justify-content: flex-start;

            @media #{$maxTablet} {
                justify-content: space-between;
                margin-bottom  : 40px;
            }

            .icon-panel {
                max-width   : 130px;
                margin-right: 80px;

                @media #{$maxTablet} {
                    margin-right: 0;
                }

                @media #{$minLarge} {
                    margin-left: 0px;
                }
            }
        }
    }

    .prief-set {
        @media #{$minLarge} {
            margin-top: 6px;
        }

        p {
            font-family  : var(--global--font-body);
            font-weight  : 400;
            font-size    : 16px;
            line-height  : 27px;
            color        : var(--global--color-body);
            margin-bottom: 32px;

            @media #{$maxTablet} {
                text-align: center;
            }
        }

        .advantages-list {
            text-align: left;
            margin    : 0;
            padding   : 0;
            list-style: none;

            @media #{$minLarge} {
                text-align: left;
            }

            li {
                display       : block;
                font-family   : var(--global--font-body);
                font-weight   : 700;
                font-size     : 15px;
                line-height   : 36px;
                color         : var(--global--color-body);
                text-transform: capitalize;

                i {
                    width           : 22px;
                    height          : 22px;
                    display         : inline-flex;
                    justify-content : center;
                    align-items     : center;
                    background-color: var(--global--color-primary);
                    color           : var(--global--color-white);
                    margin-right    : 10px;
                    border-radius   : 50%;
                    font-size       : 10px;
                }
            }
        }

        &.prief-set-2 {
            margin-bottom: 77px;

            p {
                margin-bottom: 24px;
                color        : var(--global--color-gray-darken);
            }

            .advantages-list-holder {
                display: flex;

                @media #{$maxTablet} {
                    flex-direction: column;
                    align-items   : flex-start;
                }

                .advantages-list {
                    margin-bottom: 0;
                    text-align   : left;

                    @media #{$minLarge} {
                        margin-right: 40px;
                    }

                    li {
                        color: var(--global--color-gray);
                    }
                }
            }
        }
    }

    .phone-card {
        display         : flex;
        background-color: var(--global--color-heading-darken);
        padding         : 35px 40px 31px;
        border-radius   : 0 32px 0 0;

        @media #{$maxTablet} {
            margin-bottom: 30px;
        }

        @media #{$maxSmall} {
            flex-direction: column;
            align-items   : center;
            text-align    : center;
        }

        .card-icon {
            flex-shrink : 0;
            margin-right: 30px;

            @media #{$maxSmall} {
                margin-right: 0;
            }

            i {
                &::before {
                    display    : flex;
                    transform  : translateY(-4px);
                    font-size  : 50px;
                    margin-left: 0;
                    color      : var(--global--color-white);
                }
            }
        }

        .card-content {
            h5 {
                font-weight   : 500;
                font-size     : 19px;
                line-height   : 28px;
                color         : var(--global--color-white);
                text-transform: capitalize;
                margin-bottom : 16px;
            }

            p {
                font-size    : 14px;
                color        : var(--global--color-gray);
                line-height  : 24px;
                margin-bottom: 15px;
            }

            a {
                display    : flex;
                align-items: center;
                transition : 0.3s ease-in-out;
                color      : var(--global--color-white);

                @media #{$maxTablet} {
                    justify-content: center;
                }

                span {
                    transition : 0.3s ease-in-out;
                    font-family: var(--global--font-heading);
                    font-weight: 400;
                    font-size  : 24px;
                }

                i {
                    margin-bottom: 4px;
                    transition   : 0.3s ease-in-out;
                    font-size    : 19px;
                    margin-right : 15px;
                }
            }
        }
    }

    .contact-panel {
        box-shadow : 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
        transform  : translateY(340px);
        margin-top : -340px;
        padding-top: 90px;
    }

    .panel {
        position: relative;

        .panel-img {
            position: relative;

            &::before {
                content   : '';
                position  : absolute;
                top       : 0;
                left      : 0;
                bottom    : 0;
                width     : 100%;
                height    : 100%;
                background: linear-gradient(180deg, rgba(27, 26, 26, 0) 0%, rgba(27, 26, 26, 0) 0%, rgb(27, 26, 26) 105%);
            }

            img {
                height       : 460px;
                max-width    : 100%;
                border-radius: 4px;
            }
        }

        .panel-action {
            position        : absolute;
            bottom          : 0;
            left            : 0;
            background-color: var(--global--color-primary);
            transition      : 0.3s ease-in-out;
            padding         : 35px 40px;
            border-radius   : 0 4px 0 4px;
            display         : flex;
            flex-direction  : column;
            max-width       : 230px;
            cursor          : pointer;

            &:hover {
                background-color: var(--global--color-heading);

                a {
                    i {
                        transform: translateX(10px);
                    }
                }
            }

            .panel-icon {
                margin-bottom: 10px;

                i {
                    &::before {
                        color      : var(--global--color-white);
                        font-size  : 50px;
                        margin-left: 0;
                    }
                }
            }

            .panel-heading {
                margin-bottom: 13px;

                h6 {
                    font-family  : var(--global--font-body);
                    font-weight  : 700;
                    font-size    : 16px;
                    line-height  : 29px;
                    margin-bottom: 0;
                    transition   : 0.3s ease-in-out;
                    color        : var(--global--color-white);
                }
            }

            a {
                i {
                    display   : inline-block;
                    color     : var(--global--color-white);
                    transition: 0.3s ease-in-out;
                }
            }

            &.inverted {
                background-color: var(--global--color-heading);

                &:hover {
                    background-color: var(--global--color-primary);
                }
            }
        }
    }

    &.cta-2 {
        padding-top   : 130px;
        padding-bottom: 110px;
        position      : relative;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }
    }

    &.cta-3 {
        padding-top   : 129px;
        padding-bottom: 0;

        @media#{$maxTablet} {
            padding-top: 90px;
        }
    }

    &.cta-4 {
        padding-top   : 130px;
        padding-bottom: 70px;
        position      : relative;

        @media #{$maxTablet} {
            padding-top: 70px;
        }
    }

    &.cta-5 {
        padding-top     : 117px;
        padding-bottom  : 0px;
        background-color: var(--global--color-heading-light);

        @media #{$maxTablet} {
            padding-top: 70px;
        }

        @media #{$minLarge} {
            padding-left : 118px;
            margin-bottom: -119px;
        }

        @media #{$maxTablet} {
            padding-left : 70px;
            padding-right: 70px;
        }

        @media #{$maxSmall} {
            padding-left : 30px;
            padding-right: 30px;
        }
    }

    &.cta-6 {
        padding-left : 130px;
        padding-right: 135px;
        overflow     : visible;

        @media #{$maxTablet} {
            padding-left  : 50px;
            padding-right : 50px;
            padding-top   : 70px;
            padding-bottom: 70px;
        }

        @media #{$maxSmall} {
            padding-left : 20px;
            padding-right: 20px;
        }

        .video-btn {
            @media #{$maxTablet} {
                display        : flex;
                justify-content: center;
            }

            margin-bottom: 59px;
        }

        .contact-panel {
            position  : relative;
            z-index   : 8;
            transform : translateY(100px);
            margin-top: -100px;
            width     : 700px;

            @media (min-width: 992px) and (max-width: 1275px) {
                width: 550px;
            }

            padding-top: 0;

            @media #{$maxTablet} {
                width: 100%;
            }

            .contact-types {
                padding         : 50px 50px 10px;
                background-color: var(--global--color-white);

                @media #{$maxSmall} {
                    padding: 50px 20px 10px;
                }

                .button {
                    height          : 71px;
                    color           : var(--global--color-heading);
                    background-color: #eaeaea;
                    border-radius   : 4px 0 0 4px;

                    &:last-child {
                        border-radius: 0 4px 4px 0;
                    }

                    @media #{$maxSmall} {
                        border-radius: 4px 4px 0 0;

                        &:last-child {
                            border-radius: 0px 0px 4px 4px;
                        }
                    }

                    &.active {
                        background-color: var(--global--color-primary);
                        color           : var(--global--color-white);
                    }
                }
            }

            .contact-card {
                @media #{$maxSmall} {
                    padding-left : 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}