/*------------------------------------*\
    #Carousel
\*------------------------------------*/
.owl-nav.disabled {
    display: none;
}

.carousel-dots {
    position: relative;

    button.owl-dot,
    button.owl-dot:focus {
        padding         : 0;
        background-color: transparent;
        border          : none;
        outline         : none;
        box-shadow      : none;
    }

    .owl-dots {
        margin-top     : 40px;
        display        : flex;
        justify-content: center;

        @media #{$minLarge} {
            margin-top     : 0;
            justify-content: flex-end;
            position       : absolute;
            right          : 0;
            bottom         : 45px;
        }

        .owl-dot {
            &.active {
                span {
                    background-color: var(--global--color-primary);

                    &::before {
                        background-color: #cff3f7;
                    }
                }

            }

            span {
                width           : 9px;
                height          : 9px;
                border-radius   : 50%;
                background-color: #687596;
                margin-right    : 20px;
                transition      : 0.3s ease-in-out;
                position        : relative;

                &::before {
                    content         : '';
                    border-radius   : 50%;
                    position        : absolute;
                    top             : 50%;
                    left            : 50%;
                    z-index         : -1;
                    transform       : translate(-50%, -50%);
                    width           : 25px;
                    height          : 25px;
                    background-color: transparent;
                    transition      : 0.3s ease-in-out;
                }
            }
        }
    }
}



.carousel-navs {
    position: relative;

    .owl-nav {
        span {
            font-size: 0;
        }

        @media #{$minLarge} {
            position: absolute;
            top     : -127px;
            right   : 0;
        }
    }

    .owl-next,
    .owl-prev {
        &:hover {
            &::before {
                color: var(--global--color-primary);
            }
        }

        &:before {
            font-family: 'carousel';
            font-size  : 43px;
            font-weight: 600;
            color      : #687596;
            ;
            transition: 0.3s ease-in-out;
        }
    }

    .owl-prev {
        margin-right: 50px;

        &:before {
            content: "\e990";
        }
    }

    .owl-next {
        &:before {
            content: "\e991";
        }
    }
}

.carousel-navs {
    .owl-dots {
        position: absolute;
        bottom  : 40px;
        width   : 100%;
    }
}