/*------------------------------------*\
    #Page Title
\*------------------------------------*/
.page-title {
    padding: 0;

    .title {
        padding   : 342px 0 35px 0;
        text-align: center;

        .title-sub-heading {
            p {
                font-family  : var(--global--font-body);
                font-weight  : 700;
                font-size    : 15px;
                line-height  : 1;
                color        : var(--global--color-gray);
                margin-bottom: 0;

                @media #{$maxTablet} {
                    line-height: 1.4;
                }
            }
        }

        .title-heading {
            margin-bottom: 150px;

            h1 {
                font-family   : var(--global--font-heading);
                color         : var(--global--color-white);
                font-size     : 45px;
                font-weight   : 500;
                line-height   : 60px;
                margin-bottom : 0;
                text-transform: capitalize;

                @media #{$maxSmall} {
                    font-size  : 32px;
                    line-height: 1.4;
                }
            }
        }

        .title-desc {
            p {
                color        : var(--global--color-gray);
                font-size    : 16px;
                margin-bottom: 0;
                font-weight  : 300;
            }
        }

        .breadcrumb {
            font-family    : var(--global--font-body);
            font-size      : 14px;
            font-weight    : 400;
            line-height    : 1px;
            background     : transparent;
            color          : var(--global--color-gray);
            display        : flex;
            justify-content: center;

            &.breadcrumb-long {
                @media #{$minLarge} {
                    justify-content: start;
                }

                @media #{$maxSmall} {
                    line-height    : normal;
                    flex-direction : column;
                    align-items    : flex-start;
                    text-align     : left;
                    justify-content: start;
                    flex-direction : row;
                }

                .breadcrumb-item {
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .breadcrumb-item+.breadcrumb-item {
                    &::before {
                        @media #{$maxSmall} {
                            line-height: 17px;
                        }
                    }
                }
            }

            a {
                font-size  : 14px;
                font-weight: 400;
                line-height: 1px;
                background : transparent;
                color      : var(--global--color-gray);

                &:hover {
                    color: var(--global--color-heading-darken);
                }
            }

            .active {
                color: var(--global--color-gray);
            }

            .breadcrumb-item+.breadcrumb-item {
                padding-left: 0;

                &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 700;
                    font-size  : 11px;
                    padding    : 0 10px;
                    color      : var(--global--color-gray);
                    content    : "\f054";
                }
            }
        }

        @media #{$maxSmall} {
            padding-top: 150px;

            .title-heading {
                margin-bottom: 80px;
            }
        }
    }

    &.page-title-blank {
        .title {
            padding: 205px 0 44px 0;

            @media (min-width:992px) and (max-width:1250px) {
                padding-top: 250px;
            }

            .breadcrumb {
                a {
                    color: var(--global--color-secondary);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }

                .active {
                    color: var(--global--color-heading-darken);
                }
            }

            .breadcrumb-item+.breadcrumb-item {
                &::before {
                    color: var(--global--color-secondary);
                }
            }
        }
    }

    &.page-title-1 {
        background-position-y: 130px;

        @media #{$maxTablet} {
            background-position-y: 100px;
        }

        .breadcrumb {
            margin-bottom: 26px;
        }

        .title {
            padding-top   : 312px;
            padding-bottom: 135px;

            @media #{$maxSmall} {
                padding-top   : 250px;
                padding-bottom: 110px;
            }

            .title-heading {
                margin-bottom: 0;
            }
        }
    }

    &.page-title-2 {
        background-position-y: 1%;

        .breadcrumb {
            margin-bottom: 0px;
        }

        .title {
            padding-top   : 292px;
            padding-bottom: 155px;

            @media #{$maxSmall} {
                padding-top   : 250px;
                padding-bottom: 120px;
            }

            .title-heading {
                margin-bottom: 20px;
            }
        }
    }

    &.page-title-3 {
        overflow             : visible;
        background-position-y: 1px;

        .title {
            text-align    : left;
            padding-bottom: 0px;
            padding-top   : 394px;

            @media #{$maxTablet} {
                text-align : center;
                padding-top: 300px;
            }

            .title-card {
                transform       : translateY(50px);
                margin-top      : -50px;
                padding         : 45px 50px 54px;
                border-radius   : 8px 8px 0 8px;
                background-color: var(--global--color-white);
                box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);

                @media #{$maxSmall} {
                    padding-left : 25px;
                    padding-right: 25px;
                }

                &::before {
                    content         : '';
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    width           : calc(100% - 100px);
                    height          : 2px;
                    background-color: var(--global--color-primary);

                    @media #{$maxSmall} {
                        width: calc(100% - 50px);
                    }
                }

                .title-heading {
                    margin-bottom: 17px;

                    h1 {
                        font-size    : 28px;
                        line-height  : 32px;
                        color        : var(--global--color-heading);
                        font-weight  : 500;
                        margin-bottom: 0;
                    }
                }

                .title-sub-heading {
                    margin-bottom: 26px;

                    p {
                        font-size    : 15px;
                        line-height  : 25px;
                        font-weight  : 400;
                        color        : var(--global--color-body);
                        margin-bottom: 0;
                    }
                }

                .breadcrumb {
                    @media #{$minLarge} {
                        justify-content: flex-start;
                    }

                    a {
                        color: var(--global--color-secondary);

                        &:hover {
                            color: var(--global--color-primary);
                        }
                    }

                    .active {
                        color: var(--global--color-heading-darken);
                    }

                    .breadcrumb-item+.breadcrumb-item {
                        &::before {
                            color: var(--global--color-secondary);
                        }
                    }
                }
            }
        }
    }

    &.page-title-4 {
        background-position-y: 110%;
        overflow             : visible;

        .title {
            text-align    : left;
            padding-bottom: 0px;
            padding-top   : 304px;

            @media #{$maxSmall} {
                text-align: center;
            }

            .breadcrumb {
                margin-bottom: 33px;

                @media #{$minLarge} {
                    justify-content: flex-start;
                }
            }

            .title-card {
                transform       : translateY(50px);
                margin-top      : -50px;
                padding         : 50px 50px 50px;
                border-radius   : 8px 8px 0 8px;
                background-color: var(--global--color-white);
                box-shadow      : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
                display         : flex;

                @media #{$maxSmall} {
                    flex-direction: column;
                    padding-left  : 25px;
                    padding-right : 25px;
                    align-items   : center;
                }

                @media #{$minLarge} {
                    max-width: 515px;
                }

                &::before {
                    content         : '';
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    width           : calc(100% - 100px);
                    height          : 2px;
                    background-color: var(--global--color-primary);

                    @media #{$maxSmall} {
                        width: calc(100% - 50px);
                    }
                }

                .card-icon {
                    margin-right    : 30px;
                    width           : 94px;
                    height          : 94px;
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    flex-shrink     : 0;
                    border-radius   : 8px 0 8px 8px;
                    background-color: #e7f9fc;

                    @media #{$maxSmall} {
                        margin-right : 0;
                        margin-bottom: 25px;
                    }

                    i {
                        &::before {
                            color      : #304370;
                            font-size  : 64px;
                            margin-left: 0;
                        }
                    }
                }

                .card-content {
                    @media #{$maxSmall} {
                        text-align: center;
                    }

                    .title-heading {
                        margin-bottom: 17px;

                        h1 {
                            font-size    : 28px;
                            line-height  : 32px;
                            color        : var(--global--color-heading);
                            font-weight  : 500;
                            margin-bottom: 0;
                        }
                    }

                    .title-sub-heading {
                        margin-bottom: 22px;

                        p {
                            font-size    : 15px;
                            line-height  : 25px;
                            font-weight  : 400;
                            color        : var(--global--color-body);
                            margin-bottom: 0;
                        }
                    }

                    .btn {
                        width              : 143px;
                        height             : 42px;
                        border-color       : rgb(40, 59, 106);
                        border             : 2px solid;
                        transition-property: background, color, border-color;

                        &::before {
                            background-color: var(--global--color-secondary);
                            width           : calc(100% + 4px);
                            height          : calc(100% + 4px);
                            top             : -2px;
                            left            : -2px;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            border-color: var(--global--color-secondary);
                        }

                        @media #{$maxSmall} {
                            display: inline-flex;
                        }
                    }
                }
            }
        }
    }

    &.page-title-5 {
        background-position-y: 100%;
    }
}