/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
.testimonial{
    padding-top: 130px;
    padding-bottom: 0;
    overflow: visible;
    @media #{$maxSmall} {
        padding-top: 70px;
    }
    .testimonials-holder {
        transform: translateY(-8px);
        margin-bottom: 92px;
        position: relative;
        @media #{$maxSmall} {
            margin-bottom: 70px;
        }
        .custom-navs {
            display: inline-flex;
            align-items: center;
            right: 0;
            bottom: 14px;
            position: absolute;
            z-index: 500;
            @media #{$maxTablet} {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                position: relative;
                bottom: auto;
                right: auto;
            }
            a {
                display: inline-flex;
                font-size: 15px;
                font-family: var(--global--font-secondary);
                font-weight: 700;
                color: var(--global--color-body);
                transition: 0.3s ease-in-out;
                text-transform: capitalize;
                cursor: pointer;
                &:hover {
                    color: var(--global--color-primary);
                    .line {
                        span {
                            opacity: 0;
                            visibility: hidden; 
                        }
                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                .line {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 12px;
                    position: relative;
                    span {
                        display: block;
                        width: 12px;
                        height: 2px;
                        background-color: var(--global--color-body);
                        opacity: 1;
                        visibility: visible;
                        transition: 0.3s ease-in-out;
                    }
                    &::after {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        content: '\e901';
                        font-family: 'icomoon';
                        font-size: 10px;
                        color: var(--global--color-primary);
                        display: inline-block;
                        opacity: 0;
                        visibility: hidden;
                        transition: 0.3s ease-in-out;
                    }
                }
                &.prev {
                    margin-right: 20px;
                    .line {
                        margin-right: 8px;
                        &::after {
                            top: 3px;
                            left: 0;
                            transform: rotate(-180deg);
                        }
                    }
                }
                &.next {
                    .line {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .testimonial-panel {
        .testimonial-body {
            padding-left: 40px;
            padding-top: 40px;
            @media #{$maxSmall} {
                padding-left: 30px;
                padding-top: 30px;;
            }
            position: relative;
            .testimonial-content {
                position: relative;
                .testimonial-icon{
                    position: absolute;
                    z-index: -1;
                    top: -66px;
                    left: -40px;
                    @media #{$maxSmall} {
                        top: -56px;
                        left: -30px;
                    }
                    &::before {
                        font-size: 95px;
                        font-family: "Font Awesome 5 Free";
                        content: "\f10d";
                        font-weight: 900;
                        color: #293967;
                    }
                }
                p {
                    font-family  : var(--global--font-heading);
                    color        : var(--global--color-white );
                    font-size    : 25px;
                    font-weight  : 400;
                    line-height  : 43px;
                    margin-bottom: 45px;
                    @media #{$maxSmall} {
                        font-size: 19px;
                        line-height: 1.5;
                    }
                }
                .testimonial-rating {
                    display: flex;
                    align-items: center;
                    @media #{$maxTablet} {
                        justify-content: center;
                    }
                    .num {
                        font-family: var(--global--font-heading);
                        font-weight: 500;
                        font-size: 37px;
                        color: var(--global--color-white );
                        flex-shrink: 0;
                        margin-right: 20px;
                    }
                    .rating-body {
                        p {
                            font-size: 13px;
                            line-height: 23px;
                            color: var(--global--color-gray);
                            margin-bottom: 0;
                            a {
                                color: var(--global--color-gray);
                                text-transform: capitalize;
                                border-bottom: 2px solid var(--global--color-primary);
                                padding-bottom:2px;
                                span {
                                    font-size: 14px;
                                    font-weight: 700;
                                    color: var(--global--color-primary);
                                    font-family: var(--global--font-body);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.testimonial-panel-2 {
            .testimonial-body {
                padding-left: 0;
                padding-right: 15px;
                padding-top: 18px;
                .testimonial-content {
                    .testimonial-icon {
                        top: -23px;
                        right: -15px;
                        left: auto;
                        &::before {
                            color: #e8ebf2;
                        }
                    }
                    p {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 38px;
                        color: var(--global--color-heading);
                        margin-bottom: 48px;
                    }
                    .testimonial-thumb {
                        display: flex;
                        align-items: center;
                        transition: 0.5s ease-in-out;
                        cursor: pointer;
                        @media #{$maxTablet} {
                            justify-content: center;
                        }
                        .thumb-img {
                            width: 80px;
                            height: 80px;
                            border-radius: 8px 0 8px 8px;
                            position: relative;
                            margin-right: 40px;
                            box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
                            img {
                                max-width: 100%;
                                border-radius: 8px 0 8px 8px;
                            }
                            i {
                                transition: 0.5s ease-in-out;
                                transform: translateY(-50%);
                                font-size: 11px;
                                position: absolute;
                                top: 50%;
                                right: -16px;
                                width: 33px;
                                height: 33px;
                                border-radius: 50%;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--global--color-primary);
                                background-color: var(--global--color-white );
                            }
                        }
                        .thumb-body {
                            text-transform: capitalize;
                            h6 {
                                font-family: var(--global--font-body);
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 1.2;
                                margin-bottom: 4px;
                                color: var(--global--color-heading);
                            }
                            p {
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 1.2;
                                color: var(--global--color-body);
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .testimonial-thumbs {
        display: flex;
        @media #{$maxTablet} {
            justify-content: center;
            margin-bottom: 40px;
        }
        .testimonial-thumb {
            flex-shrink: 0;
            margin-right: 40px;
            display: flex;
            flex-direction: column;
            text-align: center;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &:hover , &.active {
                transform: translateY(-10px);
                .thumb-img {
                    i {
                        transform: translateY(-50%) scale(1);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            @media #{$maxSmall} {
                margin-right: 25px;
            }
            @media #{$minLarge} {
                text-align: left;
            }
            .thumb-img {
                width: 80px;
                height: 80px;
                border-radius: 8px 0 8px 8px;
                position: relative;
                margin-bottom: 16px;
                img {
                    max-width: 100%;
                    border-radius: 8px 0 8px 8px;
                }
                i {
                    transition: 0.5s ease-in-out;
                    transform: translateY(-50%) scale(0);
                    opacity: 0;
                    visibility: hidden;
                    font-size: 11px;
                    position: absolute;
                    top: 50%;
                    right: -16px;
                    width: 33px;
                    height: 33px;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--global--color-primary);
                    background-color: var(--global--color-white );
                }
            }
            .thumb-body {
                text-transform: capitalize;
                h6 {
                    font-family: var(--global--font-body);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1.2;
                    margin-bottom: 4px;
                    color: var(--global--color-white );
                }
                p {
                    font-size: 13px;
                    line-height: 1.2;
                    color: var(--global--color-body);
                    margin-bottom: 0;
                }
            }
        }
    }
    .carousel-dots{
        position: relative;
        .owl-dots{
            margin-top: 40px;
            display: flex;
            justify-content: center;
            @media #{$minLarge} {
                margin-top: 0;
                justify-content: flex-end;
                position: absolute;
                right: 0;
                bottom: 45px;
            }
            .owl-dot{
                display: inline-flex;
                &.active {
                    span{
                        background-color: var(--global--color-primary);
                        &::before {
                            background-color: #cff3f7;
                        }
                    }
                    
                }
                span {
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: #687596;
                    margin-right: 20px;
                    transition: 0.3s ease-in-out;
                    position: relative;
                    &::before {
                        content: '';
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: -1;
                        transform: translate(-50%, -50%);
                        width: 25px;
                        height: 25px;
                        background-color: transparent;
                        transition: 0.3s ease-in-out;
                    }
                }
            }
        }
    }
    &.testimonial-1 {
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 84%, rgba(255,255,255,0) 100%);
        padding-top: 128px;
        padding-bottom: 130px;
        @media #{$maxSmall} {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        .section-head {
            font-family: var(--global--font-body);
            font-weight: 700;
            font-size: 16;
            line-height: 1;
            color: var(--global--color-primary);
            text-transform: capitalize;
            margin-bottom: 7px;
            @media #{$maxTablet} {
                text-align: center;
                margin-bottom: 25px;
            }
        }
        .clients-img {
            @media #{$minLarge} {
                transform: translateY(-19px);
                margin-bottom: -19px;
            }
            img {
                max-width: 100%;
                @media #{$maxTablet} {
                    display: block;
                    margin: 0 auto 60px;
                }
            }
        }
        .owl-stage-outer {
            overflow: visible;
            .owl-item {
                opacity: 0;
                &.active {
                    opacity: 1;
                }
            }
        }
    }
    &.testimonial-2 {
        padding-top: 200px;
        @media#{$maxTablet} {
            padding-top: 120px;
            padding-bottom: 70px;
        }
    }
}




